export const FONT_FAMILIES = {
  default: "system-ui, 'SF Pro'",
  monospaced: "monospace, 'SF Mono'",
  rounded: "cursive, 'SF Pro Rounded'",
  serif: "serif, 'New York'",
};

export const FONT_WEIGHTS = {
  ultralight: 100,
  thin: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  heavy: 800,
  black: 900,
};

export const FONT_STYLES = {
  italic: 'italic',
};

export const CUSTOM_FONTS_KEY = 'appcues:builder:custom-fonts';

export const getFontDetails = font => {
  const fontDetails = {
    fontFamily: FONT_FAMILIES.default,
    fontWeight: FONT_WEIGHTS.medium,
  };

  // Handle system fonts
  if (font.includes('System')) {
    const [, family, weight] = font.toLowerCase().split(' ');
    const fontFamily = FONT_FAMILIES[family];
    const fontWeight = FONT_WEIGHTS[weight];

    return {
      ...fontDetails,
      ...(fontFamily && { fontFamily }),
      ...(fontWeight && { fontWeight }),
    };
  }

  // Handle custom fonts
  const [customFamily, weight] = font.split('-');
  const [family, hasItalic] = customFamily.split('Italic');
  const fontFamily = `'${family}', ${FONT_FAMILIES.default}`;
  const fontWeight = FONT_WEIGHTS[weight?.toLowerCase()];
  const fontStyle = hasItalic === '' && 'italic';

  return {
    ...fontDetails,
    fontFamily,
    ...(fontWeight && { fontWeight }),
    ...(fontStyle && { fontStyle }),
  };
};
