import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Picker } from 'emoji-mart';
import useToggle from 'ext/lib/hooks/use-toggle';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import useEscape from 'ext/lib/hooks/use-escape';
import Tether from 'ext/components/Tether';
import { StyleShape } from 'entities/step-children';
import { transformStyles } from 'components/Editor/Primitives';

const Wrapper = styled.div`
  align-self: center;
  display: flex;
  z-index: 1;

  ${({ customStyle }) => customStyle};
`;

const StyledText = styled.span`
  cursor: pointer;
  line-height: 1;
`;

const EmojiWrapper = styled.div`
  width: 24px;
`;

const Emoji = ({ id, text, style, onUpdate }) => {
  const $menu = useRef();
  const $picker = useRef();
  const [isPickerVisible, togglePickerVisible] = useToggle(false);

  useClickOutside([$menu, $picker], isPickerVisible && togglePickerVisible);
  useEscape(isPickerVisible && togglePickerVisible);

  const handleChange = (emoji, event) => {
    onUpdate({ text: emoji.native });
    togglePickerVisible();

    // Prevents the Editor from unselecting the block
    event.stopPropagation();
  };

  const emojiPicker = (
    <EmojiWrapper ref={$picker} role="dialog">
      <Picker onClick={handleChange} title="Select an emoji" native />
    </EmojiWrapper>
  );

  return (
    <Wrapper id={id} customStyle={transformStyles(style)}>
      <Tether
        attachment={emojiPicker}
        placement="bottom-left"
        visible={isPickerVisible}
      >
        <StyledText ref={$menu} onClick={togglePickerVisible}>
          {text}
        </StyledText>
      </Tether>
    </Wrapper>
  );
};

Emoji.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  style: StyleShape,
  onUpdate: PropTypes.func,
};

export default Emoji;
