import { LOCALIZED } from 'lib/block';

/**
 * This function will take a content object and a locale and return the content
 * without localized blocks replaced by the variation for the locale.
 *
 * In other words, this method will return the same canonical structure
 * for blocks, with the translations in place of the localized blocks.
 *
 * @param {object} content - the content of the step with all variations
 * @param {string} locale - the localeId that we want the content to be
 * @return {object} - clear content with translation for the locale w/o localized blocks
 */
export function localizeContent(content, locale) {
  if (!content || !locale) return content;

  if (content?.blockType === LOCALIZED && content?.variations) {
    return locale !== null && content.variations[locale]
      ? content.variations[locale]
      : content.content;
  }

  if (Array.isArray(content)) {
    return content.map(item => localizeContent(item, locale));
  }

  if (typeof content === 'object') {
    return Object.fromEntries(
      Object.entries(content).map(([k, v]) => [k, localizeContent(v, locale)])
    );
  }

  return content;
}
