import { get, set } from 'ext/lib/local-storage';
import { capitalize } from 'utils/strings-handler';
import { FONT_FAMILIES, FONT_WEIGHTS, CUSTOM_FONTS_KEY } from './fonts';

const handleSelectOption = fontName => ({
  label: fontName,
  value: fontName,
});

const getSystemFontNames = () => {
  return Object.keys(FONT_FAMILIES).reduce(
    (acc, fontFamily) => [
      ...acc,
      ...Object.keys(FONT_WEIGHTS).map(
        fontWeight =>
          `System ${capitalize(fontFamily)} ${capitalize(fontWeight)}`
      ),
    ],
    []
  );
};

const getSystemFontOptions = () =>
  getSystemFontNames().map(fontName => handleSelectOption(fontName));

export const getCustomFontNames = () => get(CUSTOM_FONTS_KEY, []);

const getCustomFontOptions = () => {
  const customFontsNames = getCustomFontNames();
  return customFontsNames.map(customFontName =>
    handleSelectOption(customFontName)
  );
};

export const getAllFontNames = () => [
  ...getCustomFontNames(),
  ...getSystemFontNames(),
];

export const setCustomFontName = value => {
  const customFontsNames = getCustomFontNames();
  const newCustomFontNames = [...customFontsNames, value].sort();
  set(CUSTOM_FONTS_KEY, newCustomFontNames);
};

export const getGroupedOptions = () => [
  {
    label: 'Custom Fonts',
    options: getCustomFontOptions(),
  },
  {
    label: 'System Fonts',
    options: getSystemFontOptions(),
  },
];

export const getGroupedOptionsValues = groupedOptions =>
  groupedOptions.flatMap(({ options }) => options);
