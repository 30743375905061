import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const WIDTH = 20;
const DURATION = 1;

const Container = styled.svg`
  height: 1em;
  transform: rotate(180deg);
  width: 1em;

  rect {
    fill: ${({ color }) => color};
  }
`;

const Column = ({ offset, values }) => (
  <rect x={offset} y={0} width={WIDTH}>
    <animate
      attributeName="height"
      calcMode="spline"
      dur={DURATION}
      keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
      keyTimes="0;0.33;0.66;1"
      repeatCount="indefinite"
      values={values.join(';')}
    />
  </rect>
);

Column.propTypes = {
  offset: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.number),
};

function GraphLoader({ className, color = 'var(--white)' }) {
  return (
    <Container
      aria-label="loading"
      className={className}
      color={color}
      viewBox="0 0 100 100"
    >
      <Column offset={100 - WIDTH} values={[25, 100, 60, 25]} />
      <Column offset={50 - WIDTH / 2} values={[60, 40, 100, 60]} />
      <Column offset={0} values={[90, 30, 50, 90]} />
    </Container>
  );
}

GraphLoader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default styled(GraphLoader)``;
