import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { CurrentColor } from 'ext/components/ui';
import { addQuickColor, getQuickColors } from 'ext/lib/color';

const ColorDotContainer = styled.div`
  align-items: center;
  border-radius: 50%;
  border: 2px solid;
  border-color: ${({ isSelected }) =>
    isSelected ? 'var(--cp-quick-option-selected-border)' : 'transparent'};
  cursor: pointer;
  display: flex;
  padding: 4px;
  width: fit-content;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(34px, 1fr));
  gap: 8px;
`;

const QuickColorPicker = ({ onChange, selectedColor }) => {
  const colors = getQuickColors();
  const colorRef = useRef(selectedColor);
  const [hasChangedColor, setHasChangedColor] = useState(false);

  useEffect(() => {
    if (!tinycolor.equals(selectedColor, colorRef.current)) {
      colorRef.current = selectedColor;
      setHasChangedColor(true);
    }
  }, [selectedColor]);

  // add the selected color to local storage during the cleanup phase (unmount)
  useEffect(
    () => () => hasChangedColor && addQuickColor(colorRef.current),
    [hasChangedColor]
  );

  if (colors.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {colors
        // to keep the fix backwards compatibly we filter to show max 6 items here.
        // Just in case the user only opens the color picker and has more than
        // 6 saved in the local storage.
        .filter((_, idx) => idx < 6)
        .map((color, index) => (
          <ColorDotContainer
            isSelected={tinycolor.equals(selectedColor, color)}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onClick={() => onChange({ rgb: tinycolor(color).toRgb() })}
          >
            <CurrentColor color={color} />
          </ColorDotContainer>
        ))}
    </Wrapper>
  );
};

QuickColorPicker.propTypes = {
  onChange: PropTypes.func,
  selectedColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default QuickColorPicker;
