import { v4 as uuid } from 'uuid';
import {
  RATING_NUMBER,
  RATING_STAR,
  RATING_EMOJI,
  TEXT,
  BLOCK_MODES,
} from 'lib/block';
import { isColorLight } from 'utils/color-handler';
import {
  BLACK_COLOR,
  GRAY_COLOR,
  WHITE_COLOR,
} from 'components/SideBarSettings/Shared';

const [HORIZONTAL, VERTICAL, NPS] = BLOCK_MODES[RATING_NUMBER];

export const generateText = ({ index, value, type, isSelected = false }) => {
  if (type === RATING_EMOJI) return ['😦', '🙁', '😐', '🙂', '😀'][index];
  if (type === RATING_STAR) return isSelected ? '★' : '☆';
  return `${value}`;
};

/**
 * Method to generate the styles for the rating options
 *
 * @param {string} type - Type of rating (emoji, star, number)
 * @param {object} customStyle - Custom style object
 * @param {string} displayFormat - Display format (horizontalList, verticalList)
 * @param {boolean} isSelected - Whether the option is selected
 * @param {boolean} mergeStyles - Whether to merge the custom style with the default style or pass it as is
 * @returns {object} Style object
 */
export const generateStyle = ({
  type,
  customStyle,
  displayFormat = HORIZONTAL,
  isSelected = false,
  mergeStyles = true,
}) => {
  const {
    foregroundColor,
    backgroundColor,
    borderColor,
    fontName = 'System Default Regular',
  } = customStyle ?? {};

  const optionStyles = {
    foregroundColor,
    backgroundColor,
    borderColor,
    fontName,
  };

  const horizontalAlignments = {
    marginTrailing: 4,
    marginLeading: 4,
    marginBottom: 8,
  };

  const defaultDarkColor = { light: BLACK_COLOR };
  const defaultGrayColor = { light: GRAY_COLOR };
  const defaultLightColor = { light: WHITE_COLOR };
  const baseColor = backgroundColor || foregroundColor;

  if (type === RATING_EMOJI) {
    return {
      fontName,
      width: 48,
      height: 48,
      fontSize: 30,
      ...horizontalAlignments,
      cornerRadius: 8,
      foregroundColor: defaultGrayColor,
      ...(isSelected && baseColor ? { backgroundColor: baseColor } : {}),
    };
  }

  if (type === RATING_STAR) {
    const mergedStyles = {
      foregroundColor: isSelected
        ? backgroundColor ?? defaultDarkColor
        : foregroundColor ?? defaultDarkColor,
    };

    return {
      fontName,
      width: 48,
      height: 48,
      fontSize: 36,
      ...horizontalAlignments,
      ...(mergeStyles
        ? mergedStyles
        : { foregroundColor: optionStyles.foregroundColor }),
    };
  }

  if (type === RATING_NUMBER) {
    const selectedForegroundColor = {
      light: isColorLight(foregroundColor?.light) ? BLACK_COLOR : WHITE_COLOR,
    };

    const mergedStyles = {
      fontName,
      backgroundColor: isSelected
        ? baseColor ?? defaultDarkColor
        : defaultLightColor,
      foregroundColor: isSelected
        ? selectedForegroundColor
        : foregroundColor ?? defaultDarkColor,
      borderColor: isSelected
        ? baseColor ?? defaultLightColor
        : foregroundColor ?? defaultDarkColor,
    };

    const horizontalStyles = {
      width: 42,
      height: 42,
      ...horizontalAlignments,
      cornerRadius: 24,
    };

    const verticalStyles = {
      width: -1,
      marginTop: 4,
      marginTrailing: 40,
      marginLeading: 40,
      marginBottom: 4,
      cornerRadius: 8,
    };

    return {
      height: 48,
      borderWidth: 1,
      ...((displayFormat === HORIZONTAL || displayFormat === NPS) &&
        horizontalStyles),
      ...(displayFormat === VERTICAL && verticalStyles),
      ...(mergeStyles ? mergedStyles : optionStyles),
    };
  }

  return {};
};

/**
 * Method to generate an array of numbers
 * @param {number} start - Number to start the generation (Eg.: 3 will generate 3, 4, 5, ...)
 * @param {number} max - Number to end the generation (Eg.: 7 will generate 3, 4, 5, 6, 7)
 * @returns {Array[number]} Array of numbers
 */
export const generateArrayRange = (start, max) => {
  return start > max
    ? [start]
    : [...Array.from({ length: max - start + 1 }).keys()].map(
        num => num + start
      );
};

/**
 * Method to generate the options for the rating block
 * @param {number} startFrom - Number to start the generation (Eg.: 3 will generate 3, 4, 5, ...)
 * @param {number} maxValue - Number to end the generation
 * @param {object} option - Option object
 * @param {string} type - Type of rating (emoji, star, number)
 * @param {string} displayFormat - Display format (horizontalList, verticalList, nps)
 * @param {boolean} mergeStyles - Whether to merge the custom style with the default style or pass it as is
 * @returns {Array[option]} Array of options
 */
export const generateOptions = ({
  startFrom = 1,
  maxValue,
  option,
  type,
  displayFormat,
  mergeStyles,
}) => {
  return generateArrayRange(startFrom, maxValue).map((value, index) => ({
    content: {
      ...option.content,
      id: uuid(),
      type: TEXT,
      text: generateText({ index, value, type }),
      style: generateStyle({
        type,
        customStyle: option.content.style,
        displayFormat,
        mergeStyles,
      }),
    },
    selectedContent: {
      ...option.selectedContent,
      id: uuid(),
      type: TEXT,
      text: generateText({ index, value, type, isSelected: true }),
      style: generateStyle({
        type,
        customStyle: option.selectedContent.style,
        displayFormat,
        isSelected: true,
        mergeStyles,
      }),
    },
    value:
      type === RATING_EMOJI ? generateText({ index, value, type }) : `${value}`,
  }));
};

/**
 * This method checks the number of options and the display format to determine the display format
 * If it has more than 6 options and horizontal defined, it will return NPS
 *
 * @param {Array<object>} options - the options array
 * @param {string} displayFormat - Display format (horizontalList, verticalList, nps)
 * @returns {string} Display format (horizontalList, verticalList, nps)
 */
export const generateDiplayFormat = (options, displayFormat) => {
  if (options.length > 6 && displayFormat === HORIZONTAL) {
    return NPS;
  }

  if (displayFormat === NPS && options.length <= 6) {
    return HORIZONTAL;
  }

  return displayFormat;
};
