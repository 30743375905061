/**
 * Paths matching selected entities "/flows/:experienceId/steps/:stepId"
 *
 * Compiled using path-to-regexp
 * see: https://github.com/pillarjs/path-to-regexp
 */
const SELECTED_PATTERN =
  /^#?(?:\/mobile)?\/flows\/([^/]+?)\/?(?:steps\/([^/]+?))?(?:\/(?=$))?$/i;

/**
 * Regex pattern for matching preview index files
 */
const PREVIEW_BUILD_PATTERN = /^\/index\.([\da-f]{40})\.html$/;

/**
 * Determine whether the current URL is a preview URL
 *
 * @param {string} pathname - Location pathname
 * @return {boolean} Whether previewing
 */
export const isPreviewBuild = pathname => {
  return PREVIEW_BUILD_PATTERN.test(pathname);
};

/**
 * Factory for parsing tokens from location
 *
 * @param {RegExp} pattern - Pattern to match
 * @param {function} remap - Token to output mapping function
 *   @param {Partial<Location>} location - Subset of location object
 *     @param {string} location.pathname - Location pathname
 *     @param {string} location.hash - Location hash
 * @return {function} Location parsing function
 */
const parse =
  (pattern, remap) =>
  ({ pathname, hash }) => {
    const path = isPreviewBuild(pathname) ? hash : pathname;
    const matches = path.match(pattern);
    return matches ? remap(matches.slice(1)) : null;
  };

/**
 * Parse pathname or hash for currently selected entities
 */
export const parseSelected = parse(
  SELECTED_PATTERN,
  ([experience, stepChild]) => ({ experience, stepChild })
);
