import { ITEM_FOCUSED, ITEM_INSERTED, ITEM_REMOVED } from 'ext/lib/collections';
import { TYPE as EXPERIENCE, patterns } from 'entities/experiences';
import { TYPE as STEP_GROUP } from 'entities/step-groups';
import { TYPE as STEP_CHILD } from 'entities/step-children';
import { TYPE as TRAIT } from 'entities/trait';
import { TYPE as BLOCK } from 'entities/block';
import { SELECTED_UPDATED } from 'entities/history-log';
import onLoad from './on-load';
import onRemove from './on-remove';

function onFocus(state, action) {
  switch (action.meta.type) {
    case EXPERIENCE:
      return null;
    case STEP_GROUP:
      return {
        stepGroup: action.payload,
      };
    case STEP_CHILD:
      return {
        stepGroup: action.payload.parent,
        stepChild: action.payload.id,
      };
    case TRAIT:
      return {
        ...state,
        trait: action.payload,
        block: null,
      };
    case BLOCK:
      return {
        ...state,
        block: action.payload,
        trait: null,
      };
    default:
      return state;
  }
}

function onInsert(state, action) {
  switch (action.meta.type) {
    case EXPERIENCE:
      return state;
    case STEP_GROUP:
      return {
        stepGroup: Object.keys(action.payload.stepGroups)[0],
        stepChild: Object.keys(action.payload.stepChildren)[0],
      };
    case STEP_CHILD:
      return {
        stepGroup: state.stepGroup,
        stepChild: action.payload.id,
      };
    default:
      return state;
  }
}

export default function selected(state = null, action, store) {
  if (patterns.resolve(action)) {
    return onLoad(state, action);
  }

  switch (action.type) {
    case ITEM_FOCUSED:
      return onFocus(state, action);
    case ITEM_INSERTED:
      return onInsert(state, action);
    case ITEM_REMOVED:
      return onRemove(state, action, store);
    case SELECTED_UPDATED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const selectSelected = state => state.selected;
