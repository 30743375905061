import React from 'react';
import { Button } from '@appcues/sonar';
import PropTypes from 'prop-types';

export const SmartButton = ({ text, prompt, loading, handleSmartText }) => (
  <Button
    variant="secondary"
    loading={loading}
    onClick={() => handleSmartText(prompt)}
  >
    {text}
  </Button>
);

SmartButton.propTypes = {
  text: PropTypes.string,
  prompt: PropTypes.string,
  loading: PropTypes.bool,
  handleSmartText: PropTypes.func,
};
