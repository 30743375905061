import styled from 'styled-components';
import { FontIcon } from 'ext/components/ui';

export const Icon = styled(FontIcon)`
  color: ${({ icon }) =>
    icon === 'trash' ? 'var(--color-pink-500)' : 'inherit'};
`;

const BUTTON_SIZE = '28px';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${BUTTON_SIZE};
  width: ${BUTTON_SIZE};
  font-size: 12px;
  color: var(--color-neutral-600);
  background: var(--color-blue-50);
  border: none;
  cursor: pointer;

  &[data-selected='true'] {
    background: var(--color-blurple-600);

    ${Icon} {
      color: var(--color-neutral-0);
    }
  }
`;

export const Toolbar = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2px;
  position: absolute;
  right: 5px;

  ${Button} {
    &:first-child {
      border-top-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
    }
  }
`;
