import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

const translate = ({ direction, distance }) =>
  ({
    top: `translate3d(0, -${distance}px, 0)`,
    right: `translate3d(${distance}px, 0, 0)`,
    bottom: `translate3d(0, ${distance}px, 0)`,
    left: `translate3d(-${distance}px, 0, 0)`,
    center: 'translate3d(0, 0, 0)',
  }[direction]);

const Container = styled.div`
  opacity: 0;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  transition-duration: ${({ timeout }) => timeout}ms;
  transition-delay: ${({ delay }) => delay}ms;

  &.fade-appear,
  &.fade-enter,
  &.fade-exit,
  &.fade-exit-active,
  &.fade-exit-done {
    opacity: 0;
    transform: ${translate};
  }

  &.fade-appear-active,
  &.fade-appear-done,
  &.fade-enter-active,
  &.fade-enter-done {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const Fade = ({
  children,
  delay = 0,
  distance = 8,
  from = 'center',
  timeout = 200,
  visible = false,
  ...props
}) => (
  <CSSTransition
    appear
    classNames="fade"
    in={visible}
    mountOnEnter
    timeout={delay + timeout}
    unmountOnExit
    // eslint-disable-next-line @appcues/jsx-props-no-spreading
    {...props}
  >
    <Container
      delay={delay}
      distance={distance}
      timeout={timeout}
      direction={from}
    >
      {children}
    </Container>
  </CSSTransition>
);

Fade.propTypes = {
  ...CSSTransition.propTypes,
  children: PropTypes.node,
  delay: PropTypes.number,
  distance: PropTypes.number,
  from: PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'center']),
  timeout: PropTypes.number,
  visible: PropTypes.bool,
};

export default Fade;
