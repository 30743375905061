import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'ext/components/Fade';
import FontIcon, { IconButton } from './FontIcon';

const copyText = (node, withoutHighlight = true) => {
  const range = document.createRange();
  range.selectNodeContents(node);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
  document.execCommand('copy');

  if (withoutHighlight) {
    window.getSelection().removeAllRanges();
  }
};

const theme = `
  --copy-text-color: var(--white);

  --copy-input-background: var(--diel-gray);
  --copy-input-border: var(--diel-gray);

  --copy-button-background: var(--diel-gray);
  --copy-button-border: var(--blurple);
  --copy-button-color: var(--blurple);
  --copy-button-hover-background: var(--diel-gray);
  --copy-button-hover-color: var(--blurple);

  --copy-toast-background: var(--regulus);
  --copy-toast-color: var(--white);

  [data-theme='light'] & {
    --copy-text-color: var(--oh-hi-dark);

    --copy-input-background: var(--white);
    --copy-input-border: var(--sharkbait-ooh-la-la);

    --copy-button-background: var(--luna);
    --copy-button-hover-background: var(--lils-guest-bathroom-soaps);
    --copy-button-hover-color: var(--deep-work);
  }
`;

const Wrapper = styled.div`
  ${theme}

  position: relative;
`;

const InputWrapper = styled.div`
  background-color: var(--copy-input-background);
  border-radius: 6px;
  border: 1px solid var(--copy-input-border);
  display: flex;
  position: relative;
`;

const CopyIcon = styled(FontIcon)`
  color: var(--copy-button-color);
  font-size: 12px;
  margin-left: 6px;
`;

const CopyButton = styled(IconButton)`
  background-color: var(--copy-button-background);
  border-radius: 4px;
  border: 1px solid var(--copy-button-border);
  color: var(--copy-button-color);
  font-size: var(--x-small);
  height: 32px;
  margin: 3px;
  width: 32px;

  &:focus,
  &:hover {
    background-color: var(--copy-button-hover-background);
    box-shadow: none;
    color: var(--copy-button-hover-color);
  }

  &:focus-visible {
    outline: auto;
  }
`;

const Text = styled.span`
  color: var(--copy-text-color);
  flex: 1;
  font-size: var(--regular);
  font-weight: var(--normal);
  padding: 10px 14px;
  word-break: break-word;

  ${({ $wrap }) =>
    $wrap &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

const TextKind = styled.span`
  color: var(--copy-text-color);
  cursor: pointer;
  word-break: break-word;

  ${({ $icon }) =>
    $icon &&
    `
    display: flex;
    align-items: center;
  `}
`;

const FadeWrapper = styled(Fade)`
  position: absolute;
  right: 0;
  top: -40px;
`;

const Toast = styled.span`
  background-color: var(--copy-toast-background);
  border-radius: 6px;
  color: var(--copy-toast-color);
  display: block;
  font-size: var(--regular);
  padding: 8px 12px;
`;

const ClickToCopy = ({ children, onCopy, fade = 2000 }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible) {
      return;
    }

    const timer = setTimeout(() => {
      setVisible(false);
    }, fade);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timer);
    };
  }, [fade, visible]);

  const handleCopy = (node, withoutHighlight) => {
    copyText(node, withoutHighlight);
    setVisible(true);

    if (onCopy) {
      onCopy();
    }
  };

  return (
    <Wrapper>
      <FadeWrapper visible={visible}>
        <Toast role="alert">Copied to clipboard</Toast>
      </FadeWrapper>
      {children(handleCopy)}
    </Wrapper>
  );
};

ClickToCopy.propTypes = {
  fade: PropTypes.number,
  onCopy: PropTypes.func,
  children: PropTypes.func,
};

const BaseTextClickToCopy = ({ text, icon, ...rest }) => {
  const textRef = useRef(null);

  return (
    // eslint-disable-next-line @appcues/jsx-props-no-spreading
    <ClickToCopy {...rest}>
      {handleCopy => (
        <TextKind
          onClick={() => {
            handleCopy(textRef.current, false);
          }}
          ref={textRef}
          $icon={icon}
        >
          {text}
          {icon && <CopyIcon icon="copy" />}
        </TextKind>
      )}
    </ClickToCopy>
  );
};

BaseTextClickToCopy.propTypes = {
  ...ClickToCopy.propTypes,
  text: PropTypes.string.isRequired,
  icon: PropTypes.bool,
};

export const TextClickToCopy = styled(BaseTextClickToCopy)``;

const BaseInputClickToCopy = ({ text, wrap, ...rest }) => {
  const textRef = useRef(null);

  return (
    // eslint-disable-next-line @appcues/jsx-props-no-spreading
    <ClickToCopy {...rest}>
      {handleCopy => (
        <InputWrapper>
          <Text ref={textRef} $wrap={wrap}>
            {text}
          </Text>
          <CopyButton
            icon="copy"
            onClick={() => {
              handleCopy(textRef.current);
            }}
            label="Copy text"
            title="Copy"
          />
        </InputWrapper>
      )}
    </ClickToCopy>
  );
};

BaseInputClickToCopy.propTypes = {
  ...ClickToCopy.propTypes,
  text: PropTypes.string.isRequired,
  wrap: PropTypes.bool,
};

export const InputClickToCopy = styled(BaseInputClickToCopy)``;
