/* globals CLOUDINARY_UPLOAD_URL, CLOUDINARY_API_KEY */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UploadBox } from '@appcues/sonar';
import { selectAccountId } from 'ext/entities/account';
import { parseSvgFile } from './image-upload';

const ImageRecommendation = styled.p`
  color: var(--color-neutral-700);
  font-size: var(--font-size-small);
  margin-bottom: 0;
`;

const ImageUploader = ({ currentImage, description, accountId, onUpload }) => {
  const uploadDescription = description ?? 'Add a background image (optional).';
  const initialImageList = currentImage ? [{ name: currentImage }] : null;

  const handleOnUpload = async files => {
    if (files.length === 0) {
      return [];
    }

    const file = await parseSvgFile(files[0]);

    const data = new FormData();
    data.append('file', file);
    data.append('api_key', CLOUDINARY_API_KEY);
    data.append('timestamp', Math.trunc(Date.now() / 1000));
    data.append('folder', 'mobile-builder');
    data.append('folder', accountId);
    data.append('tags', [`account-${accountId}`, 'mobile'].join(','));

    // Transforming GIFs can have unintended consequences for Cloudinary,
    // like causing the GIF to become to large if it's scaled up, or dropping
    // frames if a transformation doesn't support the full resolution.
    const uploadPreset =
      file && file.type === 'image/gif' ? 'gif_preset' : 'sdruqnxi';
    data.append('upload_preset', uploadPreset);

    const upload = await fetch(CLOUDINARY_UPLOAD_URL, {
      method: 'POST',
      body: data,
    });

    const { secure_url: url, width, height } = await upload.json();

    if (url) {
      onUpload({
        url,
        intrinsicSize: {
          width,
          height,
        },
      });
    }

    // return files list so that render list renders it
    return [file];
  };

  const handleOnRemoveIcon = () => {
    onUpload(null);
  };

  return (
    <>
      <UploadBox.Root
        initialList={initialImageList}
        accept="image/*"
        maxSize="5120"
        description={uploadDescription}
        onUpload={handleOnUpload}
        onListEmpty={handleOnRemoveIcon}
        renderList={(files, actions) => (
          <UploadBox.SimpleFileList files={files} actions={actions} />
        )}
      />
      <ImageRecommendation>
        Use images smaller than 1MB for better user experience
      </ImageRecommendation>
    </>
  );
};

ImageUploader.propTypes = {
  currentImage: PropTypes.string,
  description: PropTypes.string,
  accountId: PropTypes.string,
  onUpload: PropTypes.func,
};

const mapStateToProps = state => ({
  accountId: selectAccountId(state),
});

export default connect(mapStateToProps)(ImageUploader);
