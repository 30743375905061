import styled, { keyframes } from 'styled-components';
// eslint-disable-next-line import/no-namespace
import * as Accordion from '@radix-ui/react-accordion';
import FontIcon from './FontIcon';

const theme = `
  --accordion-header-background: var(--oh-hi-dark);
  --accordion-header-color: var(--white);
  --accordion-content-background: var(--pleather);

  [data-theme='light'] & {
    --accordion-header-background: var(--noreaster);
    --accordion-header-color: var(--pleather);
    --accordion-content-background: var(--white);
  }
`;

const ANIMATION_TIMING = 'cubic-bezier(0.87, 0, 0.13, 1)';

const slideDown = keyframes`
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

export const Root = styled(Accordion.Root)`
  ${theme}
`;

const Header = styled(Accordion.Header).withConfig({
  shouldForwardProp: prop => !['rounded'].includes(prop),
})`
  background: var(--accordion-header-background);
  color: var(--accordion-header-color);
  font-size: var(--regular);
  font-weight: var(--bold);
  margin: 4px 0 0;
  padding: 10px 24px;
  border-radius: ${({ rounded }) => (rounded ? '4px' : '0')};
`;

export { Header };

export const Item = styled(Accordion.Item)`
  overflow: hidden;

  &:first-child ${Header} {
    margin: 0;
  }
`;

export const Trigger = styled(Accordion.Trigger)`
  all: unset;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;

  > ${FontIcon} {
    transition: transform 200ms ${ANIMATION_TIMING};

    [data-state='open'] & {
      transform: rotate(-180deg);
    }
  }
`;

export const Content = styled(Accordion.Content)`
  background: var(--accordion-content-background);

  &[data-state='open'] {
    animation: ${slideDown} 200ms ${ANIMATION_TIMING};
  }

  &[data-state='closed'] {
    animation: ${slideUp} 200ms ${ANIMATION_TIMING};
  }
`;
