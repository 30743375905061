import PropTypes from 'prop-types';
import { Shape as ActionsShape } from 'lib/actions';

const AlignmentShape = PropTypes.oneOf(['leading', 'center', 'trailing']);

export const ColorShape = PropTypes.shape({
  light: PropTypes.string,
  dark: PropTypes.string,
});

const PointShape = PropTypes.oneOf([
  'topLeading',
  'top',
  'topTrailing',
  'leading',
  'center',
  'trailing',
  'bottomLeading',
  'bottom',
  'bottomTrailing',
]);

const GradientShape = PropTypes.shape({
  colors: PropTypes.arrayOf(ColorShape),
  startPoint: PointShape,
  endPoint: PointShape,
});

const ShadowShape = PropTypes.shape({
  color: ColorShape,
  radius: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
});

export const StyleShape = PropTypes.shape({
  verticalAlignment: PropTypes.oneOf(['top', 'center', 'bottom']),
  horizontalAlignment: AlignmentShape,
  paddingTop: PropTypes.number,
  paddingLeading: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingTrailing: PropTypes.number,
  marginTop: PropTypes.number,
  marginLeading: PropTypes.number,
  marginBottom: PropTypes.number,
  marginTrailing: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  fontName: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.oneOf([
    'ultraLight',
    'thin',
    'light',
    'regular',
    'medium',
    'semibold',
    'bold',
    'heavy',
    'black',
  ]),
  letterSpacing: PropTypes.number,
  lineHeight: PropTypes.number,
  textAlignment: AlignmentShape,
  foregroundColor: ColorShape,
  backgroundColor: ColorShape,
  backgroundGradient: GradientShape,
  cornerRadius: PropTypes.number,
  shadow: ShadowShape,
  borderColor: ColorShape,
  borderWidth: PropTypes.number,
});

export const OrientationShape = PropTypes.oneOf(['horizontal', 'vertical']);

export const DistributionShape = PropTypes.oneOf(['equal', 'center']);

export const ContentShape = PropTypes.shape({
  id: PropTypes.string,
  items: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  orientation: OrientationShape,
  distribution: DistributionShape,
  spacing: PropTypes.number,
  style: StyleShape,
  type: PropTypes.string,
});

const baseTargetProperties = {
  contentDistanceFromTarget: PropTypes.number,
  contentPreferredPosition: PropTypes.oneOf(['top', 'bottom']),
  height: PropTypes.number,
  width: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
};

export const TargetRectangleShape = PropTypes.shape({
  config: PropTypes.shape({
    ...baseTargetProperties,
    relativeX: PropTypes.number,
    relativeY: PropTypes.number,
  }),
});

export const TargetElementShape = PropTypes.shape({
  config: PropTypes.shape({
    ...baseTargetProperties,
  }),
});

export const TargetAreaShape = PropTypes.oneOfType([
  TargetRectangleShape,
  TargetElementShape,
]);

export default PropTypes.shape({
  id: PropTypes.string,
  index: PropTypes.number,
  parentId: PropTypes.string,
  content: ContentShape,
  actions: ActionsShape,
});
