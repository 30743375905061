import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: var(--regular);
  font-weight: var(--bold);
`;

export const FieldSet = styled.fieldset`
  border: none;
  margin: 0;
  min-inline-size: auto;
  padding: 0;

  ${Label} {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  & + & {
    margin-top: 16px;
  }
`;
