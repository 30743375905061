import {
  TOOLTIP,
  STEP_TRANSITION_ANIMATION,
  SKIPPABLE_OPTIONS,
} from 'lib/trait';
import { dropShadowTemplate } from './drop-shadow';
import { skippableTemplate } from './skippable';
import { backdropTemplate } from './backdrop';

export const tooltipTemplate = presentation => [
  {
    type: TOOLTIP,
    config: {
      presentationStyle: presentation,
      hidePointer: false,
      pointerBase: 24,
      pointerLength: 12,
      pointerCornerRadius: 0,
      style: {
        backgroundColor: { light: '#FFFFFF' },
        cornerRadius: 8,
        width: 300,
        shadow: dropShadowTemplate,
      },
    },
  },
  skippableTemplate(SKIPPABLE_OPTIONS.MINIMAL),
  backdropTemplate,
  {
    type: STEP_TRANSITION_ANIMATION,
  },
];
