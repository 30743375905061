import {
  REQUEST_SENT,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from 'ext/lib/collections';

export const LOADING = 'loading';
export const LOADED = 'loaded';
export const ERROR = 'error';

export default function status(state = null, { type, meta }) {
  switch (type) {
    case REQUEST_SENT:
      return {
        ...state,
        [meta.type]: LOADING,
      };

    case REQUEST_RESOLVED:
      return {
        ...state,
        [meta.type]: LOADED,
      };

    case REQUEST_REJECTED:
      return {
        ...state,
        [meta.type]: ERROR,
      };

    default:
      return state;
  }
}

export const selectStatusByEntity = (state, entity) => state.status?.[entity];
