import React from 'react';
import { RATING_NUMBER, RATING_STAR, RATING_EMOJI } from 'lib/block';

const numberIcon = (
  <path
    d="M8 0a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8Zm.503 12H6.981a2.044 2.044 0 0 1-1.941-1.4.75.75 0 0 1 1.423-.474.544.544 0 0 0 .518.374h1.55a.97.97 0 0 0 .112-1.935l-2.73-.32a.751.751 0 0 1-.65-.886l.522-2.75A.751.751 0 0 1 6.522 4H9.75a.75.75 0 0 1 0 1.5H7.144l-.256 1.35 1.93.227C10.035 7.222 11 8.275 11 9.528A2.48 2.48 0 0 1 8.503 12Z"
    fill="currentColor"
  />
);

const starIcon = (
  <path
    d="M7.999.242c.279 0 .533.16.655.41l2.079 4.282 4.646.688c.273.04.498.23.585.491a.729.729 0 0 1-.178.743l-3.37 3.34.796 4.713a.726.726 0 0 1-.294.712c-.224.161-.548.182-.767.052L8 13.454l-4.177 2.219c-.218.13-.515.11-.742-.052a.81.81 0 0 1-.319-.712l.822-4.713-3.367-3.34a.725.725 0 0 1 .406-1.234l4.643-.688L7.345.652a.73.73 0 0 1 .654-.41Zm0 2.393L6.408 5.916a.74.74 0 0 1-.549.403l-3.586.528 2.604 2.579a.733.733 0 0 1 .203.64l-.612 3.625 3.188-1.704a.722.722 0 0 1 .685 0l3.189 1.704-.612-3.626a.735.735 0 0 1 .206-.639l2.603-2.58-3.585-.527a.73.73 0 0 1-.549-.403L8 2.635Z"
    fill="currentColor"
  />
);

const emojiIcon = (
  <path
    d="M0 8a8 8 0 0 1 8-8 8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8Zm5.128 2.172a.5.5 0 1 0-.756.656c.694.8 1.906 1.672 3.6 1.672 1.75 0 2.96-.872 3.656-1.672a.5.5 0 1 0-.756-.656c-.563.647-1.516 1.328-2.9 1.328-1.328 0-2.285-.681-2.844-1.328ZM5.513 5.5a.999.999 0 1 0 0 2 1 1 0 0 0 0-2Zm5 2a1 1 0 1 0-.002-2.002 1 1 0 0 0 .002 2.002Z"
    fill="currentColor"
  />
);

const icons = {
  [RATING_NUMBER]: numberIcon,
  [RATING_STAR]: starIcon,
  [RATING_EMOJI]: emojiIcon,
};

const surveyIcon = icon => (
  <svg role="img" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    {icons[icon]}
  </svg>
);

export const RATING_OPTIONS = [
  {
    value: RATING_NUMBER,
    label: 'Number',
    icon: surveyIcon(RATING_NUMBER),
  },
  {
    value: RATING_STAR,
    label: 'Star',
    icon: surveyIcon(RATING_STAR),
  },
  {
    value: RATING_EMOJI,
    label: 'Emoji',
    icon: surveyIcon(RATING_EMOJI),
  },
];

export default RATING_OPTIONS;
