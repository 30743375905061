import { schema, normalize } from 'normalizr';
import { StepChildSchema } from 'entities/step-children';
import {
  merge,
  transform as toConfig,
  revert as toEntity,
  stepGroupMapping,
} from './editor-mapping';

export const StepGroupSchema = new schema.Entity(
  'stepGroups',
  { children: [StepChildSchema] },
  {
    // Create UI-only `editor` field for editing use
    processStrategy: entity => ({
      ...entity,
      editor: toConfig(entity, stepGroupMapping),
    }),
  }
);

const defaults = {
  stepGroups: {},
  stepChildren: {},
};

export const transform = data => {
  const { entities } = normalize(data, StepGroupSchema);
  return { ...defaults, ...entities };
};

export const revert = ({ editor, ...entity }) => {
  const patch = toEntity(editor, stepGroupMapping);
  return merge(entity, patch);
};
