import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { FormField, Label, Input, InlineMessage, Button } from '@appcues/sonar';
import { Select } from 'ext/components/ui';
import { userPropertiesShape } from 'ext/entities/user-properties';
import { LocalesTableShape } from 'ext/entities/locales';
import { LANGUAGES } from 'lib/localization';
import { AddLocaleContainer, Form, ButtonGroup } from './styled';

const DEFAULT_PROPERTY = {
  value: '_lastBrowserLanguage',
  label: 'Last Browser Language',
};

const LANGUAGES_OPTIONS = LANGUAGES.map(({ id, label }) => ({
  value: id,
  label,
}));

const handleTargetCriteriaOptions = (userProperties, userPropertyValue) => {
  const userProperty = userProperties?.find(
    ({ value }) => value === userPropertyValue
  );

  const userPropertyOptions =
    userProperty?.options?.map(({ name }) => ({ value: name, label: name })) ??
    [];

  return userPropertyValue === DEFAULT_PROPERTY.value
    ? LANGUAGES_OPTIONS
    : userPropertyOptions;
};

export const AddLocale = ({
  locales,
  userProperties,
  setIsAddingLanguage,
  isEditingLanguage,
  setIsEditingLanguage,
  onCreateLocale,
  onUpdateLocale,
}) => {
  const [languageName, setLanguageName] = useState('');
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [property, setProperty] = useState(DEFAULT_PROPERTY);
  const [targetCriteria, setTargetCriteria] = useState([]);
  const [hasEditionChanges, setHasEditionChanges] = useState(false);

  const isDisabled =
    languageName === '' ||
    isDuplicate ||
    isEmpty(property) ||
    isEmpty(targetCriteria) ||
    (isEditingLanguage && !hasEditionChanges);

  const handleLanguageName = name => {
    if (isEditingLanguage) {
      setHasEditionChanges(true);
    }

    const hasDuplicate = locales?.some(
      ({ name: localeName }) => localeName.toLowerCase() === name.toLowerCase()
    );

    if (name !== '' && hasDuplicate) {
      setIsDuplicate(true);
    } else {
      setIsDuplicate(false);
      setLanguageName(name);
    }
  };

  const propertyOptions = useMemo(
    () =>
      userProperties?.map(({ value, name }) => ({
        value,
        label: name,
      })),
    [userProperties]
  );

  useEffect(() => {
    if (isEditingLanguage) {
      setLanguageName(isEditingLanguage.name);

      const propertyEditingOption = propertyOptions.find(
        ({ label }) => label === isEditingLanguage.property
      );
      setProperty(propertyEditingOption);

      const targetCriteriaEditingOptions = handleTargetCriteriaOptions(
        userProperties,
        propertyEditingOption.value
      );

      const targetCriteriaEditingOption = targetCriteriaEditingOptions.filter(
        ({ label }) => isEditingLanguage.targetCriteria.includes(label)
      );

      setTargetCriteria(targetCriteriaEditingOption);
    }
  }, [isEditingLanguage, propertyOptions, userProperties]);

  const handlePropertyChange = selected => {
    if (isEditingLanguage) {
      setHasEditionChanges(true);
    }

    setProperty(selected);
    setTargetCriteria([]);
  };

  const targetCriteriaOptions = handleTargetCriteriaOptions(
    userProperties,
    property.value
  );

  const handleTargetCriteriaChange = selected => {
    if (isEditingLanguage) {
      setHasEditionChanges(true);
    }

    setTargetCriteria(selected);
  };

  const onCancelClick = () => {
    setIsAddingLanguage(false);

    if (isEditingLanguage) {
      setIsEditingLanguage();
    }
  };

  const onSaveLocaleClick = () => {
    const locale = {
      name: languageName,
      conditions: {
        and: [
          {
            properties: {
              property: property.value,
              operator: 'in',
              value: targetCriteria.map(item => item.value).join('\n'),
            },
          },
        ],
      },
    };

    setIsAddingLanguage(false);

    if (isEditingLanguage) {
      onUpdateLocale(isEditingLanguage.id, locale);
      setIsEditingLanguage();
      return;
    }

    onCreateLocale(locale);
  };

  return (
    <AddLocaleContainer data-theme="light">
      <Form>
        <FormField>
          <Label htmlFor="language-name">Language name</Label>
          <Input
            id="language-name"
            placeholder="e.g. English"
            value={languageName}
            error={isDuplicate}
            onChange={({ target }) => handleLanguageName(target.value)}
          />
          {isDuplicate && (
            <InlineMessage variant="error">
              Language already exists!
            </InlineMessage>
          )}
        </FormField>
        <FormField>
          <Label htmlFor="property">Property</Label>
          <Select
            id="property"
            name="property"
            value={property}
            options={propertyOptions}
            onChange={handlePropertyChange}
          />
        </FormField>
        <FormField>
          <Label htmlFor="target-criteria">Targeting Criteria</Label>
          <Select
            id="target-criteria"
            name="target-criteria"
            placeholder="Select target criteria"
            value={targetCriteria}
            onChange={handleTargetCriteriaChange}
            options={targetCriteriaOptions}
            isClearable
            isMulti
          />
        </FormField>
      </Form>
      <ButtonGroup>
        <Button onClick={onCancelClick} variant="tertiary">
          Cancel
        </Button>
        <Button
          onClick={onSaveLocaleClick}
          disabled={isDisabled}
          variant="secondary"
        >
          {isEditingLanguage ? 'Save changes' : 'Add'}
        </Button>
      </ButtonGroup>
    </AddLocaleContainer>
  );
};

AddLocale.propTypes = {
  locales: LocalesTableShape,
  userProperties: userPropertiesShape,
  setIsAddingLanguage: PropTypes.func,
  isEditingLanguage: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    property: PropTypes.string,
    targetCriteria: PropTypes.arrayOf(PropTypes.string),
  }),
  setIsEditingLanguage: PropTypes.func,
  onCreateLocale: PropTypes.func,
  onUpdateLocale: PropTypes.func,
};
