import { LOCALIZED } from 'lib/block';

/**
 * Handle required label prefix. If survey block is required we should
 * add an asterisk on label text prefix, or remove it otherwise
 * @param {string} labelText - label text
 * @param {boolean} required - if true, label is required
 * @return {string} Text label updated
 */
export const handleRequiredLabel = (labelText, required) => {
  let handledLabelText = labelText;

  if (required) {
    if (!labelText.startsWith('*')) handledLabelText = `*${labelText}`;
  } else handledLabelText = labelText.replace('*', '');

  return handledLabelText;
};

/**
 * Handle required label prefix for spans structure provide by slate
 * @param {array<object>} spans - spans array structure, e.g: [{ text: 'string', style: {} }, ...]
 * @param {boolean} required - if true, label is required
 * @return {array<object>} Spans array structure with the first text updated
 */
export const handleRequiredLabelSpans = (spans, required) => {
  const [{ text, style } = {}, ...restSpans] = spans ?? [];
  const handledSpanText = handleRequiredLabel(text, required);
  return [{ text: handledSpanText, ...(style && { style }) }, ...restSpans];
};

/**
 * Update label property when required behavior is updated
 * @param {object} label - label content
 * @param {boolean} required - required behavior
 * @return {object} Label updated according to required behavior
 */
export const updateRequiredLabel = (label, required) => {
  const updatedLabelContent = content => {
    return {
      ...content,
      text: handleRequiredLabel(content.text, required),
      ...(content.spans && {
        spans: handleRequiredLabelSpans(content.spans, required),
      }),
    };
  };

  if (label.blockType === LOCALIZED) {
    const updatedVariations = Object.fromEntries(
      Object.entries(label.variations).map(([variation, content]) => [
        variation,
        updatedLabelContent(content),
      ])
    );

    return {
      ...label,
      content: updatedLabelContent(label.content),
      variations: updatedVariations,
    };
  }

  return {
    ...label,
    ...updatedLabelContent(label),
  };
};
