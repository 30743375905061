import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ControlledInput as Input,
  Heading,
  Modal,
  ModalHeader,
  Button,
  ButtonGroup,
  P,
} from 'ext/components/ui';
import { Form, Error } from 'components/Form';
import { getAllFontNames } from './font-group-options';

const CustomFontModal = ({ onAddFont, onClose, visible }) => {
  const [customFont, setCustomFont] = useState('');
  const [error, setError] = useState(false);

  const addedFonts = getAllFontNames();

  const handleChange = event => {
    const newFontName = event.target.value;
    setCustomFont(newFontName);

    if (newFontName && addedFonts.includes(newFontName)) {
      setError('This font has already been added!');
    } else {
      setError(null);
    }
  };

  const handleClose = () => {
    onClose();
    setCustomFont('');
  };

  const handleAddFont = () => {
    onAddFont(customFont);
    handleClose();
  };

  return (
    <Modal onClose={handleClose} visible={visible} theme="light">
      <ModalHeader>
        <Heading>Add custom font</Heading>
        <ModalHeader.Close onClose={handleClose} />
      </ModalHeader>
      <P>
        Enter or paste the desired font name exactly as it appears on your font
        list.
      </P>
      <Form aria-label="Add custom font" role="form">
        <Input
          id="custom-font"
          aria-label="Custom font input"
          placeholder="e.g. Roboto-Regular"
          defaultValue={customFont}
          onChange={handleChange}
        />
      </Form>
      {error && <Error>{error}</Error>}
      <P style={{ marginBottom: 0 }}>
        You can see a full list of fonts available in your app through the
        debugger tool.
      </P>
      <ButtonGroup right>
        <Button
          kind="primary"
          onClick={handleAddFont}
          disabled={!customFont || error}
        >
          Done
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

CustomFontModal.propTypes = {
  onAddFont: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default CustomFontModal;
