import React, { useRef } from 'react';
import styled from 'styled-components';
import { Button, Icon } from '@appcues/sonar';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import useToggle from 'ext/lib/hooks/use-toggle';
import { DropdownButton, Tooltip } from 'ext/components/ui';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { Shape as ExperienceShape } from 'entities/experiences';
import FlowSelector from 'components/FlowSelector';
import FlowDetails from 'components/FlowDetails';
import FlowCreate from './FlowCreate';

const FlowWrapper = styled.div`
  display: flex;

  ${Button} {
    min-height: 37px;
    margin-left: 8px;
  }
`;

export default function FlowManager({ flow }) {
  const { track } = useAnalytics();

  const $button = useRef();
  const $selector = useRef();

  const [isSelectorVisible, toggleSelector] = useToggle();
  const [isCreatorVisible, toggleCreator] = useToggle();
  const [isEditorVisible, toggleEditor] = useToggle();

  useClickOutside([$button, $selector], isSelectorVisible && toggleSelector);

  return (
    <FlowWrapper data-theme="dark">
      <DropdownButton
        ref={$button}
        onClick={() => {
          if (!isSelectorVisible) {
            track('Mobile Builder view', {
              name: 'Opened Flow Selector',
              component: 'FlowManager',
            });
          }
          toggleSelector();
        }}
      >
        {flow ? flow.name : 'Choose existing...'}
      </DropdownButton>

      <FlowSelector
        ref={$selector}
        selected={flow && flow.id}
        onClose={toggleSelector}
        onCreate={toggleCreator}
        visible={isSelectorVisible}
      />

      <FlowCreate onClose={toggleCreator} visible={isCreatorVisible} />

      {flow && (
        <>
          <Tooltip label="Flow details">
            <Button
              aria-label="Edit Flow"
              variant="tertiary"
              onClick={() => {
                track('Mobile Builder view', {
                  name: 'Opened Flow Details Modal',
                  component: 'FlowManager',
                });
                toggleEditor();
              }}
            >
              <Icon icon={faEdit} />
            </Button>
          </Tooltip>

          <FlowDetails
            flow={flow}
            onClose={toggleEditor}
            visible={isEditorVisible}
          />
        </>
      )}
    </FlowWrapper>
  );
}

FlowManager.propTypes = {
  flow: ExperienceShape,
};
