import styled, { css } from 'styled-components';
import {
  DropdownMenu as SonarDropdownMenu,
  Modal as SonarModal,
  ButtonGroup as SonarButtonGroup,
  Button as SonarButton,
  Form as SonarForm,
  FormField as SonarFormField,
  InlineMessage as SonarInlineMessage,
  Pagination as SonarPagination,
  Table as SonarTable,
} from '@appcues/sonar';

const baseMenuItemStyle = css`
  align-items: center;

  &[aria-pressed='true'] {
    color: var(--foreground-button-secondary-active);
    background: var(--background-button-secondary-active);
    font-weight: var(--font-weight-bold);
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }
`;

const DropdownMenuTrigger = styled(SonarDropdownMenu.Trigger)`
  display: flex;
  align-items: center;
  border-radius: 6px;

  svg[data-icon='caret-down'] {
    height: 14px;
  }

  && {
    color: var(--color-neutral-700);
    border: none;
    background: transparent;
  }

  &:hover {
    color: var(--foreground-button-secondary-default);
    background: var(--background-button-secondary-hover);
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
  }
`;

const DropdownMenuTriggerLabel = styled.span`
  font-weight: var(--font-weight-bold);
  margin: 0px var(--spacing-small);
`;

const DropdownMenuContent = styled(SonarDropdownMenu.Content)`
  z-index: 1;
`;

const DropdownMenuItem = styled(SonarDropdownMenu.Item)`
  ${baseMenuItemStyle}
`;

const DropdownMenuSubTrigger = styled(SonarDropdownMenu.SubTrigger)`
  ${baseMenuItemStyle}
`;

const DropdownMenuRightSlot = styled(SonarDropdownMenu.RightSlot)`
  span {
    padding: 0px;
  }
`;

const DropdownMenuSubContent = styled(SonarDropdownMenu.SubContent)`
  z-index: 2;
`;

const DropdownScrollableList = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;

export const DropdownMenu = {
  ...SonarDropdownMenu,
  Trigger: DropdownMenuTrigger,
  TriggerLabel: DropdownMenuTriggerLabel,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
  SubTrigger: DropdownMenuSubTrigger,
  RightSlot: DropdownMenuRightSlot,
  SubContent: DropdownMenuSubContent,
  ScrollableList: DropdownScrollableList,
};

export const InputFile = styled.input`
  display: none;
`;

// This is a workaround adding a container div around
// modal content to enable click outside functionality
const ModalContainer = styled.div`
  margin: calc(var(--spacing-x-x-large) * -1);
  padding: var(--spacing-x-x-large);
`;

export const Modal = {
  ...SonarModal,
  Container: ModalContainer,
};

export const ButtonGroup = styled(SonarButtonGroup)`
  justify-content: flex-end;
`;

export const AddLocaleContainer = styled.div`
  margin: var(--spacing-small) 0px;
`;

export const Form = styled(SonarForm)`
  flex-direction: row;
  gap: var(--spacing-small);
  margin-top: var(--spacing-regular);

  ${SonarFormField} {
    flex: 1;
    padding-bottom: var(--spacing-small);

    ${SonarInlineMessage} {
      position: absolute;
      top: 85px;
    }
  }
`;

// Auxiliary locale table components

const TableRoot = styled(SonarTable.Root)`
  margin-bottom: var(--spacing-x-large);

  ${({ isLoading }) =>
    isLoading &&
    css`
      &:before {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--color-neutral-0);
        opacity: 0.6;
      }
    `}
`;

export const Table = {
  ...SonarTable,
  Root: TableRoot,
};

export const Pagination = styled(SonarPagination)`
  padding-top: 0;
`;

export const TargetCriteria = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 330px;
  gap: var(--spacing-small);
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-x-small);
  width: 72px;

  ${SonarButton} {
    min-width: 20px;
    width: 20px;
    height: 20px;
    padding: 16px;

    &[data-action='confirm'],
    &[data-action='confirm']:hover {
      color: var(--foreground-success);
    }

    &[data-action='cancel'],
    &[data-action='cancel']:hover {
      color: var(--foreground-error);
    }
  }
`;

export const Loading = styled.div`
  z-index: 2;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
`;

export const Translating = styled.p`
  font-size: var(--font-size-x-large);
  margin-left: var(--spacing-regular);
`;

export const ButtonsGroup = styled.div`
  display: flex;
  gap: var(--spacing-small);
`;
