import { useCallback, useMemo } from 'react';
import isEmpty from 'lodash.isempty';
import { get, set, del } from 'object-path-immutable';
import debounce from 'lodash.debounce';
import { THEMES } from 'lib/user-preferences';

const [LIGHT, DARK] = THEMES;

const ERROR_COLOR = '#D6216B';
const TRANSPARENT_COLOR = '#00000000';
export const BLACK_COLOR = '#000000';
export const GRAY_COLOR = '#627293';
export const WHITE_COLOR = '#FFFFFF';

/**
 * Default colors for style properties
 */
export const DEFAULT_COLORS = {
  selectedColor: BLACK_COLOR,
  textPlaceholderColor: GRAY_COLOR,
  unselectedColor: GRAY_COLOR,
  requiredFieldColor: ERROR_COLOR,
};

/**
 * Default values for style properties
 */
export const DEFAULT_VALUES = {
  borderWidth: 0,
  cornerRadius: 0,
  fontName: 'System Default Regular',
  fontSize: 16,
  textAlignment: 'center',
  marginTop: 0,
  marginTrailing: 0,
  marginBottom: 0,
  marginLeading: 0,
  paddingTop: 0,
  paddingTrailing: 0,
  paddingBottom: 0,
  paddingLeading: 0,
  height: '',
  width: '',
  verticalAlignment: 'center',
  horizontalAlignment: 'center',
};

/**
 * Hook for handling block style objects
 *
 * @param {object} params - Hook parameters
 * @param {function} params.onChange - Change handler
 * @param {object} params.style - Block style object
 * @param {string} params.theme - Experience theme
 * @return {[object<(string|number)>, object<function>]} Values and handlers
 */
export default function useStyleSettings({ onChange, style, theme = LIGHT }) {
  // Create object paths based on the current theme
  const paths = useMemo(
    () => ({
      backgroundColor: ['backgroundColor', theme],
      foregroundColor: ['foregroundColor', theme],
      borderColor: ['borderColor', theme],
      selectedColor: ['selectedColor', theme],
      unselectedColor: ['unselectedColor', theme],
      borderWidth: ['borderWidth'],
      cornerRadius: ['cornerRadius'],
      fontName: ['fontName'],
      fontSize: ['fontSize'],
      lineHeight: ['lineHeight'],
      textAlignment: ['textAlignment'],
      marginTop: ['marginTop'],
      marginTrailing: ['marginTrailing'],
      marginBottom: ['marginBottom'],
      marginLeading: ['marginLeading'],
      paddingTop: ['paddingTop'],
      paddingTrailing: ['paddingTrailing'],
      paddingBottom: ['paddingBottom'],
      paddingLeading: ['paddingLeading'],
      width: ['width'],
      height: ['height'],
      verticalAlignment: ['verticalAlignment'],
      horizontalAlignment: ['horizontalAlignment'],
    }),
    [theme]
  );

  // Create event values selectors for the different properties. The properties
  // are grouped by the control element used as they have different signatures
  // for how they return these values.
  const extractValue = useCallback(
    (path, ...args) => {
      switch (true) {
        // Via <Select />
        case path === paths.fontName: {
          const [{ value }] = args;
          return value;
        }

        // Via <Input type="number"/>
        case path === paths.fontSize:
        case path === paths.lineHeight:
        case path === paths.borderWidth:
        case path === paths.width:
        case path === paths.height: {
          const [event] = args;
          const { valueAsNumber } = event.target;
          return Number.isNaN(valueAsNumber) ? null : valueAsNumber;
        }

        // Via <AlignmentPicker/>
        // Via <ColorInput/>
        case path === paths.textAlignment:
        case path === paths.verticalAlignment:
        case path === paths.horizontalAlignment:
        case path === paths.backgroundColor:
        case path === paths.foregroundColor:
        case path === paths.borderColor:
        case path === paths.selectedColor:
        case path === paths.unselectedColor: {
          const [value] = args;
          return value;
        }

        // Via <RangeInput />
        // Via <MarginPaddingEditor />
        case path === paths.cornerRadius:
        case path === paths.marginTop:
        case path === paths.marginTrailing:
        case path === paths.marginBottom:
        case path === paths.marginLeading:
        case path === paths.paddingTop:
        case path === paths.paddingTrailing:
        case path === paths.paddingBottom:
        case path === paths.paddingLeading: {
          const [value] = args;
          return Number.isNaN(value) ? null : Number(value);
        }

        default:
          return null;
      }
    },
    [paths]
  );

  // Debounced change handlers. If the value is undefined or null, the value
  // will be deleted from the style object. Otherwise, the value will be set and
  // the fully updated style object will be returned.
  const handlers = useMemo(() => {
    const handleChangeFor =
      path =>
      (...args) => {
        const value = extractValue(path, ...args);
        const [, extraProp] = args;
        let patch = value == null ? del(style, path) : set(style, path, value);

        // Light theme values are default. We cannot have a dark theme and not a light theme.
        // We can only have a light theme, both themes, or not have theme values
        const [prop, selectedTheme] = path;
        const hasThemeValue = currentTheme =>
          !!get(style, [prop, currentTheme]);

        const handleInvalidThemeValues = (defaultValue, properTheme = LIGHT) =>
          set(patch, [prop, properTheme], defaultValue);

        // Elements can have behavior props. They must not be empty and are handled
        // outside the style object. Some of them have the same structure as style objects,
        // based on themes. If one of these props is empty, we must define a fallback
        const behaviorProp = Object.keys(DEFAULT_COLORS).find(
          behaviorKey => behaviorKey === (extraProp || prop)
        );

        if (behaviorProp && selectedTheme === LIGHT && !value) {
          // If light theme is selected, and value is invalid we set the default color to behavior prop
          patch = handleInvalidThemeValues(DEFAULT_COLORS[behaviorProp]);
        } else {
          // If light theme is selected, dark value is defined and light value is invalid
          // we set transparent fallback value to light theme
          if (selectedTheme === LIGHT && hasThemeValue(DARK) && !value) {
            patch = handleInvalidThemeValues(TRANSPARENT_COLOR);
          }

          // If dark theme is selected, no light value is defined and dark value is valid, we set transparent fallback value to light theme
          if (selectedTheme === DARK && !hasThemeValue(LIGHT) && value) {
            patch = handleInvalidThemeValues(TRANSPARENT_COLOR);
          }
        }

        // Otherwise if we don't have valid values the theme object is removed

        // When border color is applied and we don't have border width defined
        // we add 2px border width as fallback to border color to be visible
        if (prop === 'borderColor' && value && !style.borderWidth) {
          patch = set(patch, 'borderWidth', 2);
        }

        // Remove empty object property from path
        const filteredPatch = Object.fromEntries(
          Object.entries(patch).filter(
            ([, property]) =>
              !(typeof property === 'object' && isEmpty(property))
          )
        );

        onChange(filteredPatch);
      };

    return Object.entries(paths).reduce(
      (acc, [field, path]) => ({
        ...acc,
        [field]: debounce(handleChangeFor(path), 500),
      }),
      {}
    );
  }, [extractValue, onChange, paths, style]);

  // Style values
  const values = useMemo(
    () =>
      Object.entries(paths).reduce(
        (acc, [field, path]) => ({
          ...acc,
          [field]:
            get(style, path, DEFAULT_VALUES[field]) ??
            get(style, [field, LIGHT]),
        }),
        {}
      ),
    [paths, style]
  );

  // Return values and handlers
  return [values, handlers];
}
