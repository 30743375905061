import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DIRECTIONS } from 'lib/user-preferences';
import {
  selectUserPreferences,
  updateUserPreferences,
} from 'entities/user-preferences';
import { Container, Label, AlignmentButton } from './styled';
import AlignmentIcons from './AlignmentIcons';

const [LTR] = DIRECTIONS;

const alignmentPositions = {
  'horizontal-leading': 'leading',
  'horizontal-center': 'center',
  'horizontal-trailing': 'trailing',
  'vertical-top': 'top',
  'vertical-center': 'center',
  'vertical-bottom': 'bottom',
};

const alignmentOptions = Object.keys(alignmentPositions);
const alignmentValues = [...new Set(Object.values(alignmentPositions))];

const handleAlignmentPosition = (horizontalAlignment, verticalAlignment) => {
  const horizontalPosition = `horizontal-${horizontalAlignment}`;
  const verticalPosition = `vertical-${verticalAlignment}`;
  return [horizontalPosition, verticalPosition];
};

const AlignmentPicker = ({
  alignments,
  horizontalAlignmentOnly,
  direction,
  onClick,
}) => {
  const [selected, setSelected] = useState(alignments || []);
  const [horizontalSelected, verticalSelected] = selected ?? [];

  useEffect(() => {
    if (alignments?.length) {
      const [horizontalAlignment, verticalAlignment] = alignments;
      setSelected(
        handleAlignmentPosition(horizontalAlignment, verticalAlignment)
      );
    }
  }, [alignments]);

  const handleClick = position => {
    const [axis, align] = position.split('-');

    if (axis === 'horizontal') {
      onClick.horizontalAlignment(align);
      setSelected(previousSelected => {
        const [, verticalDirection] = previousSelected;
        return [verticalDirection, position];
      });
    } else {
      onClick.verticalAlignment(align);
      setSelected(previousSelected => {
        const [horizontalDirection] = previousSelected;
        return [horizontalDirection, position];
      });
    }
  };

  const icon = position => {
    if (position === 'horizontal-leading') {
      return `horizontal-${direction === LTR ? 'left' : 'right'}`;
    }

    if (position === 'horizontal-trailing') {
      return `horizontal-${direction === LTR ? 'right' : 'left'}`;
    }

    return position;
  };

  const orderedAlignmentOptions = (() => {
    const arr = [...alignmentOptions];
    if (direction !== LTR) {
      // RTL swaps horizontal-leading and horizontal-trailing
      [arr[0], arr[2]] = [arr[2], arr[0]];
    }
    return arr;
  })();

  return (
    <Container>
      <Label>Align</Label>

      {orderedAlignmentOptions
        .filter(
          position =>
            !horizontalAlignmentOnly || position.includes('horizontal')
        )
        .map(position => (
          <AlignmentButton
            key={position}
            onClick={() => handleClick(position)}
            selected={
              position === horizontalSelected || position === verticalSelected
            }
            aria-label={position}
            title={position}
          >
            <AlignmentIcons icon={icon(position)} />
          </AlignmentButton>
        ))}
    </Container>
  );
};

AlignmentPicker.propTypes = {
  alignments: PropTypes.arrayOf(PropTypes.oneOf(alignmentValues)),
  horizontalAlignmentOnly: PropTypes.bool,
  direction: PropTypes.oneOf(DIRECTIONS),
  onClick: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.objectOf(PropTypes.func),
  ]),
};

const mapStateToProps = state => ({
  direction: selectUserPreferences(state).direction,
});

const mapDispatchToProps = {
  onUserPreferencesUpdate: updateUserPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlignmentPicker);
