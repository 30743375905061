import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Switch } from '@appcues/sonar';
import { faExpand } from '@fortawesome/pro-solid-svg-icons/faExpand';
import { faCompress } from '@fortawesome/pro-solid-svg-icons/faCompress';
import {
  Accordion,
  FieldSet,
  FontIcon,
  ControlledInput as Input,
  Label,
  RadioButton,
  RadioButtonGroup,
} from 'ext/components/ui';
import MarginPaddingEditor from 'ext/components/MarginPaddingEditor';
import { Shape as BlockContentShape } from 'entities/block';
import { BUTTON, EMOJI, IMAGE, BLOCK_LABELS } from 'lib/block';
import AlignmentPicker from 'components/AlignmentPicker';
import {
  selectUserPreferences,
  updateUserPreferences,
} from 'entities/user-preferences';
import { DIRECTIONS } from 'lib/user-preferences';
import useStyleSettings from './use-style-settings';
import useAccordionClick from './use-accordion-click';
import {
  Controls,
  GroupedField,
  GroupedFieldSet,
  RadioButtonLabel,
} from './styled';

const [LTR] = DIRECTIONS;

// Spacing types
const SPACING = {
  padding: 'padding',
  margin: 'margin',
};

// Spacing path mappings
const paths = (spacing, direction) => {
  const rightAlignment = direction === LTR ? 'right' : 'left';
  const leftAlignment = direction === LTR ? 'left' : 'right';

  return {
    top: `${spacing}Top`,
    bottom: `${spacing}Bottom`,
    [rightAlignment]: `${spacing}Trailing`,
    [leftAlignment]: `${spacing}Leading`,
  };
};

// Content modes
const CONTENT_MODE = {
  fit: 'fit',
  fill: 'fill',
};

const SizeSpacing = ({
  content,
  direction,
  onChange,
  hasPadding = false,
  hasMargin = false,
  horizontalAlignmentOnly = false,
}) => {
  const trackAccordion = useAccordionClick();

  const { id, contentMode, style, parentType, type } = content ?? {};
  const isButton = type === BUTTON;

  const handleChange = useCallback(
    patch => {
      onChange({
        blockId: id,
        contentChunk: { style: patch },
        useOriginalId: isButton,
      });
    },
    [id, isButton, onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
  });

  const handleValues = spacing =>
    Object.entries(paths(spacing, direction)).reduce(
      (acc, [position, layout]) => ({
        ...acc,
        [position]: valueFor[layout],
      }),
      {}
    );

  const handleContentModeChange = useCallback(
    value => () => {
      onChange({ blockId: id, contentChunk: { contentMode: value } });
    },
    [id, onChange]
  );

  const handleFullWidthChange = useCallback(
    isFullWidth => {
      const { width, ...stylesWithoutWidth } = style ?? {};

      onChange({
        blockId: id,
        contentChunk: {
          style: {
            ...(isFullWidth
              ? { ...stylesWithoutWidth, width: -1 }
              : { ...stylesWithoutWidth }),
          },
        },
        useOriginalId: true,
      });
    },
    [id, style, onChange]
  );

  const isFullWidth = valueFor.width === -1;

  const handleUpdates = (spacing, position, value) =>
    handleChangeFor[paths(spacing, direction)[position]](value);

  const marginProps = hasMargin && {
    margin: handleValues(SPACING.margin),
    onMarginUpdate: (position, value) =>
      handleUpdates(SPACING.margin, position, value),
  };

  const paddingProps = hasPadding && {
    padding: handleValues(SPACING.padding),
    onPaddingUpdate: (position, value) =>
      handleUpdates(SPACING.padding, position, value),
  };

  return (
    <Accordion.Item value="size-spacing">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion(BLOCK_LABELS[type], 'Size & spacing')}
        >
          Size & spacing
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          {type === IMAGE && (
            <FieldSet>
              <RadioButtonGroup>
                <RadioButton
                  onClick={handleContentModeChange(CONTENT_MODE.fill)}
                  selected={contentMode === CONTENT_MODE.fill}
                >
                  <RadioButtonLabel>
                    <Icon icon={faExpand} />
                    Fill
                  </RadioButtonLabel>
                </RadioButton>
                <RadioButton
                  onClick={handleContentModeChange(CONTENT_MODE.fit)}
                  selected={contentMode === CONTENT_MODE.fit}
                >
                  <RadioButtonLabel>
                    <Icon icon={faCompress} />
                    Fit
                  </RadioButtonLabel>
                </RadioButton>
              </RadioButtonGroup>
            </FieldSet>
          )}
          {parentType === EMOJI && (
            <FieldSet>
              <Label htmlFor="size-spacing-font">Size</Label>
              <Input
                id="size-spacing-font"
                defaultValue={valueFor.fontSize}
                onChange={handleChangeFor.fontSize}
                type="number"
                placeholder="Auto"
              />
            </FieldSet>
          )}

          {parentType !== EMOJI && (
            <>
              <GroupedFieldSet>
                <GroupedField half>
                  <Label htmlFor="size-spacing-width">Width</Label>
                  <Input
                    id="size-spacing-width"
                    defaultValue={isFullWidth ? '100%' : valueFor.width}
                    onChange={handleChangeFor.width}
                    type={isFullWidth ? 'text' : 'number'}
                    placeholder="Auto"
                    disabled={isFullWidth}
                  />
                </GroupedField>

                <GroupedField half>
                  <Label htmlFor="size-spacing-height">Height</Label>
                  <Input
                    id="size-spacing-height"
                    defaultValue={valueFor.height}
                    onChange={handleChangeFor.height}
                    type="number"
                    placeholder="Auto"
                  />
                </GroupedField>
              </GroupedFieldSet>

              {isButton && (
                <FieldSet>
                  <Switch
                    id="full-width"
                    checked={valueFor.width === -1}
                    onCheckedChange={handleFullWidthChange}
                    fullWidth
                  >
                    <Switch.Label htmlFor="full-width">Full width</Switch.Label>
                  </Switch>
                </FieldSet>
              )}
            </>
          )}
          <FieldSet>
            <AlignmentPicker
              alignments={[
                valueFor.horizontalAlignment,
                valueFor.verticalAlignment,
              ]}
              horizontalAlignmentOnly={horizontalAlignmentOnly}
              onClick={handleChangeFor}
            />
          </FieldSet>
          <FieldSet key={id}>
            <MarginPaddingEditor
              /* eslint-disable @appcues/jsx-props-no-spreading */
              {...paddingProps}
              {...marginProps}
              /* eslint-enable @appcues/jsx-props-no-spreading */
              label={BLOCK_LABELS[type]}
            />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
};

SizeSpacing.propTypes = {
  content: BlockContentShape,
  hasMargin: PropTypes.bool,
  hasPadding: PropTypes.bool,
  horizontalAlignmentOnly: PropTypes.bool,
  direction: PropTypes.oneOf(DIRECTIONS),
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
  direction: selectUserPreferences(state).direction,
});

const mapDispatchToProps = {
  onUserPreferencesUpdate: updateUserPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(SizeSpacing);
