import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Accordion, FieldSet, FontIcon, Label } from 'ext/components/ui';
import { Switch } from '@appcues/sonar';
import { EditorShape } from 'entities/step-groups';
import InputWithValidation from 'components/InputWithValidation';
import {
  Controls,
  GroupedFieldSet,
  GroupedField,
  useAccordionClick,
  useStyleSettings,
} from 'components/SideBarSettings/Shared';

export default function Pointer({ editor, onChange }) {
  const trackAccordion = useAccordionClick();
  const { pointer: { hidden, width, height, cornerRadius } = {} } =
    editor || {};
  const checked = !hidden;

  const handleChange = useCallback(
    patch => {
      // prevent having a pointer with no width or height
      if (!patch?.width || !patch?.height) return;
      onChange({ pointer: patch });
    },
    [onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style: {
      checked,
      width,
      height,
      cornerRadius,
    },
  });

  const handleShowPointer = showPointer => {
    const pointer = {
      ...editor.pointer,
      hidden: !showPointer,
    };
    onChange({ pointer });
  };

  return (
    <Accordion.Item value="pointer">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Tooltip', 'Pointer')}>
          Pointer
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Switch
              id="show"
              checked={checked}
              onCheckedChange={handleShowPointer}
              fullWidth
            >
              <Switch.Label htmlFor="show">Show</Switch.Label>
            </Switch>
          </FieldSet>

          {checked && (
            <>
              <GroupedFieldSet>
                <GroupedField half>
                  <Label htmlFor="pointer-base">Base</Label>
                  <InputWithValidation
                    id="pointer-base"
                    value={valueFor.width}
                    onChange={handleChangeFor.width}
                    type="number"
                    min="0"
                  />
                </GroupedField>

                <GroupedField half>
                  <Label htmlFor="pointer-height">Height</Label>
                  <InputWithValidation
                    id="pointer-height"
                    value={valueFor.height}
                    onChange={handleChangeFor.height}
                    type="number"
                    min="0"
                  />
                </GroupedField>
              </GroupedFieldSet>

              <FieldSet>
                <InputWithValidation
                  label="Corner radius"
                  value={valueFor.cornerRadius}
                  onChange={handleChangeFor.cornerRadius}
                  max={10}
                  min={0}
                  range
                />
              </FieldSet>
            </>
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Pointer.propTypes = {
  editor: EditorShape,
  onChange: PropTypes.func,
};
