import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Root, Thumb } from '@radix-ui/react-switch';

const theme = `
  --switch-label: var(--white);
  --switch-track-checked: var(--tint-catton-candy);
  --switch-thumb-checked: var(--primary-light);

  [data-theme='light'] & {
    --switch-label: var(--pleather);
    --switch-track-checked: var(--luna);
    --switch-thumb-checked: var(--blurple);
  }
`;

const SwitchContainer = styled.div`
  ${theme}
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SwitchLabel = styled.label`
  color: var(--switch-label);
  margin-right: 8px;
  font-weight: var(--bold);
  font-size: var(--regular);

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    color: var(--dirty-water);
    pointer-events: none;
  `}
`;

const StyledSwitch = styled(Root)`
  width: 32px;
  height: 16px;
  margin-top: 1px;
  background-color: var(--tint-dirty-water);
  overflow: hidden;
  outline: none;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color ease 0.3s;

  &[data-state='checked'] {
    background-color: var(--switch-track-checked);
  }

  &[data-disabled] {
    cursor: not-allowed;
  }
`;

const StyledThumb = styled(Thumb)`
  display: block;
  z-index: 2;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 9999px;
  text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  transform: translateX(-1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(10px);
    background-color: var(--switch-thumb-checked);
  }
`;

const Switch = ({
  label,
  onChange,
  // eslint-disable-next-line unicorn/no-useless-undefined
  checked: initialValue = undefined,
  className,
  disabled = false,
}) => {
  const id = label.replace(/ /g, '');

  return (
    <SwitchContainer className={className}>
      <SwitchLabel htmlFor={id} disabled={disabled}>
        {label}
      </SwitchLabel>
      <StyledSwitch
        id={id}
        checked={initialValue}
        onCheckedChange={onChange}
        disabled={disabled}
        aria-label={label}
      >
        <StyledThumb />
      </StyledSwitch>
    </SwitchContainer>
  );
};

Switch.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Switch;
