/* global BUILDER_DEVTOOLS */

import { get } from 'ext/lib/local-storage';

// Localstorage key for storing whether the DevTools are explicitly enabled
export const ENABLED_KEY = 'appcues:devtools';

export const enabled = () => {
  try {
    const state = get(ENABLED_KEY);

    // Check if the DevTools are explicitly enabled or enabled
    const disable = state === false;
    const enable = state === true;

    // Enable DevTools if they are not explicitly disabled and either in local
    // development or explicitly enabled
    return !disable && (BUILDER_DEVTOOLS || enable);
  } catch {
    return false;
  }
};
