/* eslint-disable no-restricted-imports */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// imported separetely to enable tree shaking
import { Text } from 'ext/components/ui/Text';
import { MoreInfo, StatusMessage } from 'ext/components/ui/StatusMessage';

const Container = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
`;

export default function RedBarOfDeath({ reload }) {
  /**
   * Reload click handler that will use a provided reload callback if it exists,
   * otherwise fallback to using the current window's reload
   *
   * @param {Event} event - Click event
   * @return {void}
   */
  const handleClick = event => {
    if (reload) {
      reload(event);
    } else {
      window.location.reload();
    }
  };

  return (
    <Container>
      <StatusMessage aria-label="error" kind="error">
        <Text bold>The Appcues Builder ran into a problem.</Text>
        <MoreInfo onClick={handleClick}>Reload builder</MoreInfo>
      </StatusMessage>
    </Container>
  );
}

RedBarOfDeath.propTypes = {
  reload: PropTypes.func,
};
