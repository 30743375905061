import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Button, Icon } from '@appcues/sonar';
import {
  selectUserProperties,
  userPropertiesShape,
} from 'ext/entities/user-properties';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import useEscape from 'ext/lib/hooks/use-escape';
import {
  create as createLocale,
  remove as removeLocale,
  update as updateLocale,
  Shape as LocalesShape,
  TYPE as LOCALES_TYPE,
} from 'ext/entities/locales';
import { selectStatusByEntity } from 'entities/status';
import { getLanguageFromCode } from 'lib/localization';
import { LocalesTable } from './LocalesTable';
import { AddLocale } from './AddLocale';
import { Modal, ButtonGroup } from './styled';

const onLearnMoreClick = () =>
  window.open(
    'https://docs.appcues.com/en_US/localizing-mobile-experiences',
    '_blank'
  );

export const LocalesModal = ({
  locales = [],
  userProperties,
  status,
  open,
  onClose,
  onCreateLocale,
  onRemoveLocale,
  onUpdateLocale,
}) => {
  const $modal = useRef();

  const [isAddingLanguage, setIsAddingLanguage] = useState(false);
  const [isEditingLanguage, setIsEditingLanguage] = useState();

  const handleModalClose = () => {
    setIsAddingLanguage(false);
    setIsEditingLanguage();
    onClose();
  };

  useClickOutside([$modal], open && handleModalClose);
  useEscape(open && handleModalClose);

  const localesTable = locales.map(({ id, name, conditions }) => {
    const { property, value } = conditions.and[0].properties;

    const propertyName = userProperties?.find(
      userProperty => userProperty.value === property
    )?.name;

    const targetCriteriaNames = value
      .split('\n')
      .map(targetCriteriaCode => getLanguageFromCode(targetCriteriaCode));

    return {
      id,
      name,
      property: propertyName,
      targetCriteria: targetCriteriaNames,
    };
  });

  return (
    <Modal.Root open={open} size="large">
      <Modal.Container ref={$modal}>
        <Modal.Title>Localization Settings</Modal.Title>
        <Modal.Close aria-label="Close" onClick={handleModalClose}>
          <Icon icon={faXmark} size="large" />
        </Modal.Close>
        {isAddingLanguage || isEditingLanguage ? (
          <AddLocale
            locales={localesTable}
            userProperties={userProperties}
            setIsAddingLanguage={setIsAddingLanguage}
            isEditingLanguage={isEditingLanguage}
            setIsEditingLanguage={setIsEditingLanguage}
            onCreateLocale={onCreateLocale}
            onUpdateLocale={onUpdateLocale}
          />
        ) : (
          <ButtonGroup>
            <Button onClick={onLearnMoreClick} variant="tertiary">
              Learn more
              <Icon icon={faExternalLinkAlt} />
            </Button>
            <Button onClick={setIsAddingLanguage} variant="secondary">
              <Icon icon={faPlus} />
              Add language
            </Button>
          </ButtonGroup>
        )}
        <LocalesTable
          locales={localesTable}
          status={status}
          isEditingLanguage={isEditingLanguage}
          setIsEditingLanguage={setIsEditingLanguage}
          onRemoveLocale={onRemoveLocale}
        />
      </Modal.Container>
    </Modal.Root>
  );
};

LocalesModal.propTypes = {
  locales: PropTypes.arrayOf(LocalesShape),
  userProperties: userPropertiesShape,
  status: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateLocale: PropTypes.func,
  onRemoveLocale: PropTypes.func,
  onUpdateLocale: PropTypes.func,
};

const mapStateToProps = state => ({
  userProperties: selectUserProperties(state),
  status: selectStatusByEntity(state, LOCALES_TYPE),
});

const mapDispatchToProps = {
  onCreateLocale: createLocale,
  onRemoveLocale: removeLocale,
  onUpdateLocale: updateLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalesModal);
