import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { assign } from 'object-path-immutable';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import { targetElementTemplate } from 'entities/step-children';
import { ScreenLayoutShape } from 'entities/screens';
import { SelectorArea, Selector, SelectorDisplayName } from './styled';

export function TargetElement({
  targetElement,
  selectors,
  handleTraitsUpdate,
  onTargetPlacementChange,
}) {
  const $selectorArea = useRef();
  const [showDisplayName, setShowDisplayName] = useState();

  const currentSelectorId = targetElement?.id;

  useClickOutside([$selectorArea], onTargetPlacementChange);

  const handleTargetElement = selectorId => {
    if (currentSelectorId === selectorId) return;

    const selector = selectors.find(({ id }) => id === selectorId);

    const baseTargetElement = currentSelectorId
      ? targetElement
      : targetElementTemplate;

    const updatedTargetElement = assign(baseTargetElement, 'config', selector);

    handleTraitsUpdate(updatedTargetElement);
    onTargetPlacementChange();
  };

  return (
    <SelectorArea ref={$selectorArea}>
      {selectors.map(({ id, displayName, width, height, x, y }) => (
        <Selector
          key={id}
          onClick={() => handleTargetElement(id)}
          onMouseEnter={() => setShowDisplayName(id)}
          onMouseLeave={() => setShowDisplayName()}
          style={{ width, height, top: y, left: x }}
        >
          <SelectorDisplayName aria-hidden={showDisplayName !== id}>
            {displayName}
          </SelectorDisplayName>
        </Selector>
      ))}
    </SelectorArea>
  );
}

TargetElement.propTypes = {
  targetElement: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectors: PropTypes.arrayOf(ScreenLayoutShape),
  onTargetPlacementChange: PropTypes.func,
  handleTraitsUpdate: PropTypes.func,
};
