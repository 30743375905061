import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isValidHex } from 'ext/lib/color';
import { FontIcon } from 'ext/components/ui';
import EditableInput from './EditableInput';

const FieldsGroup = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  max-width: 244px;
  width: 100%;

  input {
    text-align: center;
  }
`;

const FieldsWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
`;

const SwitchColorType = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  display: flex;
  height: 22px;
  padding: 0;
  width: 22px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
`;

const EyeDropperBtn = styled.button`
  cursor: pointer;
  margin: 0;
  margin-left: -5px;
  margin-top: 13px;
  border: none;
  background: unset;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const HEX_VIEW = 'hex';
const RGB_VIEW = 'rgb';
const HSL_VIEW = 'hsl';

const ensureHSLNumber = value => {
  if (typeof value === 'string' && value.includes('%')) {
    return Number(value.replace('%', ''));
  }
  return Number(value);
};

const alphaUpdate = (source, color, value) => {
  let alpha = Number.parseFloat(value);

  if (alpha < 0) {
    alpha = 0;
  } else if (alpha > 1) {
    alpha = 1;
  }
  return {
    ...color,
    a: Math.round(alpha * 100) / 100,
    source,
  };
};

const isEyeDropperAPIAvailable = !!window.EyeDropper;

const Fields = ({ hsl, rgb, hex, onChange }) => {
  const [view, setView] = useState('');

  useEffect(() => {
    if (hsl.a === 1 && view !== HEX_VIEW) {
      setView(HEX_VIEW);
    } else if (view !== RGB_VIEW && view !== HSL_VIEW) {
      setView(RGB_VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hsl.a]);

  const handleViews = () => {
    switch (view) {
      case HEX_VIEW: {
        setView(RGB_VIEW);
        break;
      }

      case RGB_VIEW: {
        setView(HSL_VIEW);
        break;
      }

      case HSL_VIEW: {
        if (hsl.a === 1) {
          setView(HEX_VIEW);
        } else {
          setView(RGB_VIEW);
        }
        break;
      }

      default:
        break;
    }
  };

  // HEX helper
  const handleHexChange = (color, e) => {
    if (isValidHex(color)) {
      onChange(
        {
          hex: color,
          source: HEX_VIEW,
        },
        e
      );
    }
  };

  // HSL helpers
  const hslUpdate = (key, value) => {
    const { h, s, l, a } = hsl;

    return {
      h,
      s,
      l,
      a,
      [key]: value || 0,
      source: HSL_VIEW,
    };
  };

  const handleHueChange = (newValue, e) => {
    const update = hslUpdate('h', newValue);
    onChange(update, e);
  };

  const handleSaturationChange = (newValue, e) => {
    const update = hslUpdate('s', ensureHSLNumber(newValue));
    onChange(update, e);
  };

  const handleLightnessChange = (newValue, e) => {
    const update = hslUpdate('l', ensureHSLNumber(newValue));
    onChange(update, e);
  };

  const handleHslAlphaChange = (newValue, e) => {
    const update = alphaUpdate(HSL_VIEW, hsl, newValue);
    onChange(update, e);
  };

  // RGB helpers
  const rgbUpdate = (key, value) => {
    const { r, g, b, a } = rgb;

    return {
      r,
      g,
      b,
      a,
      [key]: value || 0,
      source: RGB_VIEW,
    };
  };

  const handleRedChange = (newValue, e) => {
    const update = rgbUpdate('r', newValue);
    onChange(update, e);
  };

  const handleGreenChange = (newValue, e) => {
    const update = rgbUpdate('g', newValue);
    onChange(update, e);
  };

  const handleBlueChange = (newValue, e) => {
    const update = rgbUpdate('b', newValue);
    onChange(update, e);
  };

  const handleRgbAlphaChange = (newValue, e) => {
    const update = alphaUpdate(RGB_VIEW, rgb, newValue);
    onChange(update, e);
  };

  const handleEyeDropperSelection = async () => {
    try {
      const result = await new window.EyeDropper().open();

      handleHexChange(result.sRGBHex);
    } catch {
      // User canceled the picker
      // do nothing.
    }
  };

  return (
    <FieldsWrapper>
      <FieldsGroup>
        {isEyeDropperAPIAvailable && (
          <EyeDropperBtn onClick={handleEyeDropperSelection}>
            <FontIcon
              size="1x"
              icon="eye-dropper"
              color="var(--cp-color-type-btn-bg)"
            />
          </EyeDropperBtn>
        )}

        {(() => {
          switch (view) {
            case RGB_VIEW:
              return (
                <>
                  <EditableInput
                    size="small"
                    label="r"
                    value={rgb.r}
                    onChange={handleRedChange}
                  />
                  <EditableInput
                    size="small"
                    label="g"
                    value={rgb.g}
                    onChange={handleGreenChange}
                  />
                  <EditableInput
                    size="small"
                    label="b"
                    value={rgb.b}
                    onChange={handleBlueChange}
                  />
                  <EditableInput
                    size="small"
                    label="a"
                    value={rgb.a}
                    onChange={handleRgbAlphaChange}
                  />
                </>
              );
            case HSL_VIEW:
              return (
                <>
                  <EditableInput
                    size="small"
                    label="h"
                    value={Math.round(hsl.h)}
                    onChange={handleHueChange}
                  />
                  <EditableInput
                    size="small"
                    label="s"
                    value={`${Math.round(hsl.s * 100)}%`}
                    onChange={handleSaturationChange}
                  />
                  <EditableInput
                    size="small"
                    label="l"
                    value={`${Math.round(hsl.l * 100)}%`}
                    onChange={handleLightnessChange}
                  />
                  <EditableInput
                    size="small"
                    label="a"
                    value={hsl.a}
                    onChange={handleHslAlphaChange}
                  />
                </>
              );
            default:
              return (
                <EditableInput
                  size="small"
                  label="hex"
                  value={hex}
                  onChange={handleHexChange}
                />
              );
          }
        })()}
        <SwitchColorType aria-label="Change Color Type" onClick={handleViews}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5327 13.2864C16.8007 13.2864 17.0285 13.3802 17.2161 13.5678C17.4037 13.7554 17.4975 13.9832 17.4975 14.2513C17.4975 14.5193 17.4037 14.7471 17.2161 14.9347L12.4322 19.7186C12.2446 19.9062 12.0168 20 11.7487 20C11.4807 20 11.2529 19.9062 11.0653 19.7186L6.28141 14.9347C6.0938 14.7471 6 14.5193 6 14.2513C6 13.9832 6.0938 13.7554 6.28141 13.5678C6.46901 13.3802 6.69682 13.2864 6.96482 13.2864H16.5327ZM17.2161 9.06533C17.4037 9.25293 17.4975 9.48074 17.4975 9.74874C17.4975 10.0168 17.4037 10.2446 17.2161 10.4322C17.0285 10.6198 16.8007 10.7136 16.5327 10.7136H6.96482C6.69682 10.7136 6.46901 10.6198 6.28141 10.4322C6.0938 10.2446 6 10.0168 6 9.74874C6 9.48074 6.0938 9.25293 6.28141 9.06533L11.0653 4.28141C11.2529 4.0938 11.4807 4 11.7487 4C12.0168 4 12.2446 4.0938 12.4322 4.28141L17.2161 9.06533Z"
              fill="var(--cp-color-type-btn-bg)"
            />
          </svg>
        </SwitchColorType>
      </FieldsGroup>
    </FieldsWrapper>
  );
};

Fields.propTypes = {
  onChange: PropTypes.func,
  hex: PropTypes.string,
  hsl: PropTypes.shape({
    h: PropTypes.number,
    s: PropTypes.number,
    l: PropTypes.number,
    a: PropTypes.number,
  }),
  rgb: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
};

export default Fields;
