import styled from 'styled-components';
import { Button, Icon } from '@appcues/sonar';
import { ORIENTATIONS } from 'lib/user-preferences';

const [PORTRAIT, LANDSCAPE] = ORIENTATIONS;

const commonButtonStyles = `
  min-width: 18px;
  width: 18px;
  height: 18px;
  padding: 12px;
`;

export const Container = styled.div`
  border: 1px solid var(--color-neutral-500);
  border-radius: 6px;
  min-width: 345px;
  height: 268px;
  margin: 12px 0;
  overflow-y: auto;
`;

export const Item = styled.div`
  border-radius: 6px;
  display: flex;
  height: 82px;
  margin: 4px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-neutral-50);
  }

  &[aria-selected='true'] {
    background-color: var(--background-button-secondary-active);
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &[aria-hidden='true'] {
    display: none;
  }
`;

const SCREEN_THUMBNAIL_SIZES = {
  [PORTRAIT]: {
    height: '66px',
    width: '32px',
  },
  [LANDSCAPE]: {
    height: '16px',
    width: '32px',
  },
};

export const ItemImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;

  ${SCREEN_THUMBNAIL_SIZES[PORTRAIT]}
`;

export const ItemImage = styled.img`
  align-self: flex-start;
  border: 1px solid var(--color-neutral-200);

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }

  ${({ isLoading }) =>
    isLoading && `animation: skeleton-loading 1s linear infinite alternate;`}

  ${({ orientation }) => SCREEN_THUMBNAIL_SIZES[orientation || PORTRAIT]};
`;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 32px;

  ${Icon} {
    color: var(--color-neutral-500);
  }
`;

export const RenameWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const RenameActions = styled.div`
  display: flex;
  margin-left: 6px;

  ${Button} {
    ${commonButtonStyles}
  }
`;

export const ScreenInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ItemTitle = styled.p`
  color: var(--color-neutral-700);
  font-size: 14px;
  margin: 4px 0 0;
`;

export const ItemDescription = styled.p`
  color: var(--color-neutral-600);
  font-size: 12px;
`;

export const DropdownWrapper = styled.div`
  ${({ isHidden }) => isHidden && `display: none;`};
`;

export const MoreOptionsButton = styled(Button)`
  ${commonButtonStyles}
  border: 4px solid transparent;
`;
