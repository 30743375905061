import styled, { css } from 'styled-components';
import { Label, Input } from '@appcues/sonar';
import { easing } from 'ext/lib/style/easing';
import { Select } from 'ext/components/ui';

export const Wrapper = styled.div`
  position: absolute;
  left: calc(50% - var(--side-bar-width) / 2);
  transform: translateX(-50%);
  z-index: 1;
`;

export const Menu = styled.menu`
  display: flex;
  background-color: var(--white);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  margin: 0;
  padding: 12px;
  color: var(--foreground-secondary);

  ${Select} {
    min-width: auto;

    div[class$='control'] {
      border: 0;
      min-height: auto;
    }

    div[class$='ValueContainer'] {
      padding-left: 0;
    }

    div[class$='indicatorContainer'] {
      margin-right: 0;
    }

    div[class$='singleValue'] {
      color: var(--foreground-secondary);
      font-weight: var(--bold);
    }

    div[id$='listbox'] {
      min-width: 250px;
    }
  }
`;

export const Group = styled.div``;

export const Button = styled.button`
  width: 26px;
  height: 26px;
  border: 0;
  border-radius: 6px;
  background: none;
  color: var(--foreground-tertiary);
  margin: 0 3px;
  padding: 5px;
  transition: ${easing('background', 'color')};
  cursor: pointer;

  &:hover {
    color: var(--foreground-button-secondary-default);
    background: var(--background-button-secondary-hover);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--foreground-button-secondary-active);
      background: var(--background-button-secondary-active);
    `}

  ${({ isDisabled }) => isDisabled && `opacity: 0.5; cursor: not-allowed;`}

  ${({ isRotated }) => isRotated && `transform: rotate(90deg)`}
`;

export const ScreenResolution = styled.div`
  align-items: center;
  color: var(--color-neutral-500);
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-bold);
  margin-left: 6px;
`;

export const EmbedWidthSize = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 6px;

  ${Label} {
    p {
      color: var(--foreground-secondary);
      line-height: inherit;
    }
  }

  ${Input} {
    min-height: 26px;
    height: 26px;
    width: 75px;
    margin-left: 6px;
    padding: 0px 10px;

    input {
      line-height: 24px;
    }
  }
`;
