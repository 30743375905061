import { get, set } from 'ext/lib/local-storage';
import { getDevice } from 'lib/device';
import {
  USER_PREFERENCES,
  THEMES,
  ORIENTATIONS,
  EMBED,
  LOCALE,
  DIRECTIONS,
} from './user-preferences';

const [LIGHT] = THEMES;
const [LTR] = DIRECTIONS;
const [PORTRAIT] = ORIENTATIONS;
const USER_PREFERENCES_KEY = 'appcues:builder:user-preferences';

const defaultUserPreferences = {
  theme: LIGHT,
  orientation: PORTRAIT,
  direction: LTR,
  viewport: getDevice().value,
  embed: EMBED.default,
  locale: {
    id: LOCALE.id,
    name: LOCALE.name,
    experienceId: null,
  },
};

/**
 * Get user preferences
 * @param {preference} string - preference key
 * @return {value} Preference value if preference key is specified or all preferences if not
 */
export const getUserPreferences = preference => {
  const userPreferences = {
    ...defaultUserPreferences,
    ...get(USER_PREFERENCES_KEY),
  };
  return userPreferences?.[preference] ?? userPreferences;
};

/**
 * Set user preference validating if the key value pair is valid
 * @param {preference} string - preference key
 * @param {value} object - preference value, could be simple string or object with additional properties
 */
export const setUserPreferences = (preference, value) => {
  const ignoredKeys = ['embed', 'locale'];
  const preferenceValues = USER_PREFERENCES[preference] ?? {};
  const isValidKeyValue =
    ignoredKeys.includes(preference) || preferenceValues.includes?.(value);

  if (isValidKeyValue) {
    const userPreferences = get(USER_PREFERENCES_KEY, {});
    const updatedUserPreferences = { ...userPreferences, [preference]: value };

    set(USER_PREFERENCES_KEY, updatedUserPreferences);
  }
};
