import styled, { css } from 'styled-components';
import { ToolBarToggle } from 'ext/components/ui';
import { easing } from 'ext/lib/style';

const theme = `
  --sidebar-background: var(--oh-hi-dark);
  --sidebar-header-background: var(--pleather);
  --sidebar-header-box-shadow: none;
  --sidebar-header-color: var(--white);

  [data-theme='light'] & {
    --sidebar-background: var(--white);
    --sidebar-header-background: var(--white);
    --sidebar-header-box-shadow: var(--level-1);
    --sidebar-header-color: var(--pleather);
  }
`;

const dockLeft = css`
  left: 0;
  transform: ${({ collapsed }) =>
    collapsed ? 'translateX(-var(--side-bar-width))' : 'translateX(0)'};

  ${ToolBarToggle} {
    top: 17px;
    border-top-right-radius: 4px;
    left: 335px;
    transform: rotate(90deg);
  }
`;

const dockRight = css`
  right: 0;
  transform: ${({ collapsed }) =>
    collapsed ? 'translateX(var(--side-bar-width))' : 'translateX(0)'};

  ${ToolBarToggle} {
    top: 17px;
    border-top-left-radius: 4px;
    right: 335px;
    transform: rotate(-90deg);
  }
`;

export const Bar = styled.div`
  ${theme}

  background: var(--sidebar-background);
  box-shadow: 0px -4px 16px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  transition: ${easing('transform')};
  width: var(--side-bar-width);

  height: ${({ isBottomBarCollapsed }) =>
    isBottomBarCollapsed ? '100%' : 'calc(100% - var(--bottom-bar-height))'};

  ${({ position }) => (position === 'left' ? dockLeft : dockRight)}
`;

export const Header = styled.header`
  align-items: center;
  background: var(--sidebar-header-background);
  box-shadow: var(--sidebar-header-box-shadow);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 54px;
  justify-content: space-between;
  padding: 8px 24px;
`;

export const Title = styled.h2`
  color: var(--sidebar-header-color);
  font-size: var(--large);
  font-weight: var(--semi-bold);
`;
