import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { selectPreview } from 'entities/user-interface';
import { StyleShape } from 'entities/step-children';
import { BlockContentShape } from 'entities/block';
import { ERROR_COLOR, ERROR_MESSAGE, INPUT_COLOR } from 'lib/user-interface';
import { THEMES } from 'lib/user-preferences';
import { transformStyles } from 'components/Editor/Primitives';
import { TextContainer } from 'components/Editor/Containers';
import TextField from './TextField';
import handleErrorStyle from './error-style-handler';
import ErrorMessage from './ErrorMessage';

const TextInputContainer = styled.div``;

const TextInput = ({
  id,
  label,
  placeholder,
  numberOfLines,
  required,
  errorLabel,
  textFieldStyle,
  style,
  theme,
  selectedPreview,
}) => {
  const styles = transformStyles(style, theme);

  const {
    [ERROR_COLOR]: previewErrorColor,
    [ERROR_MESSAGE]: previewErrorMessage,
    [INPUT_COLOR]: previewInputColor,
  } = selectedPreview(id);

  const { id: labelId, text: labelText, spans, style: labelStyle } = label;
  const { text: errorMessage, style: errorStyle } = errorLabel ?? {};
  const { color: errorColor, ...errorStyles } = transformStyles(
    errorStyle,
    theme
  );

  const labelStyles = previewErrorColor
    ? handleErrorStyle({ style: labelStyle, color: errorColor })
    : labelStyle;

  return (
    <TextInputContainer id={id} style={styles}>
      <TextContainer
        id={labelId}
        text={labelText}
        spans={spans}
        label={{
          errorColor: labelStyles.color,
          required,
        }}
        style={labelStyles}
        theme={theme}
      />
      <TextField
        id={id}
        placeholder={placeholder}
        numberOfLines={numberOfLines}
        textFieldStyle={textFieldStyle}
        previewInputColor={previewInputColor}
        error={{ previewErrorColor, errorColor }}
        theme={theme}
      />
      {required && previewErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          color={errorColor}
          transformedStyle={errorStyles}
        />
      )}
    </TextInputContainer>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  label: BlockContentShape,
  placeholder: BlockContentShape,
  numberOfLines: PropTypes.number,
  required: PropTypes.bool,
  errorLabel: BlockContentShape,
  textFieldStyle: StyleShape,
  style: StyleShape,
  theme: PropTypes.oneOf(THEMES),
  selectedPreview: PropTypes.func,
};

const mapStateToProps = state => ({
  selectedPreview: blockId => selectPreview(state, blockId),
});

export default connect(mapStateToProps)(TextInput);
