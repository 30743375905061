import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useToggle from 'ext/lib/hooks/use-toggle';
import {
  Accordion,
  FieldSet,
  FontIcon,
  Label,
  LightButton,
  ControlledInput as Input,
  Select,
} from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import { selectUserPreferences } from 'entities/user-preferences';
import { selectPreviewType, updatePreview } from 'entities/user-interface';
import { THEMES } from 'lib/user-preferences';
import { INPUT_COLOR } from 'lib/user-interface';
import ColorInput from 'components/ColorInput';
import InputWithValidation from 'components/InputWithValidation';

import {
  DEFAULT_COLORS,
  Controls,
  GroupedFieldSet,
  GroupedField,
  HelpLabel,
  useStyleSettings,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';
import {
  CustomFontButton,
  CustomFontModal,
  FontGroupOptions,
  getGroupedOptions,
  getGroupedOptionsValues,
  setCustomFontName,
} from 'components/SideBarSettings/Shared/Fonts';

export function TextStyle({
  content,
  onChange,
  selectedPreviewType,
  onPreviewUpdate,
  theme,
}) {
  const trackAccordion = useAccordionClick();
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const {
    id: blockId,
    textFieldStyle,
    placeholder: { id: placeholderId, style: placeholderStyle },
  } = content ?? {};

  const blockPreviewType = selectedPreviewType(blockId, INPUT_COLOR);

  const groupedOptions = getGroupedOptions();
  const groupedOptionsValues = getGroupedOptionsValues(groupedOptions);

  const handleChange = useCallback(
    ({ elementId, isPlaceholder } = {}) =>
      patch => {
        onChange({
          blockId: elementId,
          style: patch,
          isPlaceholder,
        });
      },
    [onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange({}),
    style: textFieldStyle,
    theme,
  });

  const [placeholderValueFor, placeholderHandleChangeFor] = useStyleSettings({
    onChange: handleChange({
      elementId: placeholderId,
      isPlaceholder: true,
    }),
    style: placeholderStyle,
    theme,
  });

  const handleSelectedFontValue = groupedOptionsValues.find(
    ({ value }) => value === valueFor.fontName
  );

  // If there's a custom font for the text that's not currently in the options (ie in localStorage), add it
  if (handleSelectedFontValue === undefined) {
    setCustomFontName(valueFor.fontName);
  }

  const handleCustomFontButtonClick = () => {
    toggleIsModalOpen();
    setIsMenuOpen(false);
  };

  const handleAddFont = value => {
    handleChangeFor.fontName({ value });
    setCustomFontName(value);
  };

  return (
    <Accordion.Item value="text-style">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion('TextField', 'Text style')}
        >
          Text style
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Label htmlFor="text-font">Font</Label>
            <Select
              id="text-font"
              options={groupedOptions}
              formatGroupLabel={FontGroupOptions}
              placeholder="Select font"
              value={handleSelectedFontValue}
              onChange={handleChangeFor.fontName}
              portal
              menuIsOpen={isMenuOpen}
              onMenuOpen={() => setIsMenuOpen(true)}
              onMenuClose={() => setIsMenuOpen(false)}
              menuButton={
                <CustomFontButton onClick={handleCustomFontButtonClick} />
              }
            />
            <CustomFontModal
              onAddFont={handleAddFont}
              onClose={toggleIsModalOpen}
              visible={isModalOpen}
            />
          </FieldSet>
          <GroupedFieldSet>
            <GroupedField half>
              <Label htmlFor="text-font-size">Font size</Label>
              <InputWithValidation
                id="text-font-size"
                value={valueFor.fontSize}
                onChange={handleChangeFor.fontSize}
                type="number"
                min="0"
              />
            </GroupedField>

            <GroupedField half>
              <Label htmlFor="text-line-height">Line height</Label>
              <Input
                id="text-line-height"
                value={valueFor.lineHeight}
                onChange={handleChangeFor.lineHeight}
                type="number"
                placeholder="Auto"
                min="0"
              />
            </GroupedField>
          </GroupedFieldSet>

          <FieldSet>
            <Label htmlFor="text-placeholder-color">Placeholder color</Label>
            <ColorInput
              id="text-placeholder-color"
              color={placeholderValueFor.foregroundColor}
              placeholder={DEFAULT_COLORS.textPlaceholderColor}
              onChange={e =>
                placeholderHandleChangeFor.foregroundColor(
                  e,
                  'textPlaceholderColor'
                )
              }
            />
          </FieldSet>

          <GroupedFieldSet>
            <GroupedField>
              <Label htmlFor="text-color">
                Input color
                <HelpLabel>
                  Option to set the color <br />
                  for the input text
                </HelpLabel>
              </Label>
              <ColorInput
                id="text-color"
                color={valueFor.foregroundColor}
                onChange={handleChangeFor.foregroundColor}
              />
            </GroupedField>

            <GroupedField>
              <LightButton
                aria-label="Preview input text"
                aria-pressed={blockPreviewType.show}
                kind="tertiary"
                onClick={() =>
                  onPreviewUpdate({
                    blockId,
                    previewType: blockPreviewType.type ?? INPUT_COLOR,
                    show: !(blockPreviewType.show ?? false),
                  })
                }
              >
                <FontIcon icon="eye" />
              </LightButton>
            </GroupedField>
          </GroupedFieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

TextStyle.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
  selectedPreviewType: PropTypes.func,
  onPreviewUpdate: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
};

const mapStateToProps = state => ({
  selectedPreviewType: (blockId, previewType) =>
    selectPreviewType(state, { blockId, previewType }),
  theme: selectUserPreferences(state).theme,
});

const mapDispatchToProps = {
  onPreviewUpdate: updatePreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextStyle);
