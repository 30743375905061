import React from 'react';
import PropTypes from 'prop-types';
import BlockContainer from 'components/BlockContainer';

const Block = ({ id, blockType, children, onDrop }) => (
  <BlockContainer id={id} blockType={blockType} onDrop={onDrop}>
    {children}
  </BlockContainer>
);

Block.propTypes = {
  id: PropTypes.string,
  blockType: PropTypes.string,
  children: PropTypes.node,
  onDrop: PropTypes.func,
};

export default Block;
