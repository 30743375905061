/**
 * Reducer enhancer that injects full state to reducer
 *
 * @param {function} reducer - Reducer to enhance
 * @param {string} field - Field on state assigned to reducer
 * @return {Reducer} Enhanced reducer that has all state access
 */
export default (reducer, field) =>
  (state = {}, action) => {
    const reduced = reducer(state[field], action, state);
    return reduced !== state[field] ? { ...state, [field]: reduced } : state;
  };
