import { createActionConfig } from './create-action-config';

export const type = '@appcues/launch-experience';
export const getLaunchExperience = experienceID =>
  createActionConfig({
    type,
    config: {
      experienceID,
    },
  });
