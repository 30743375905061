/* istanbul ignore file */

import React, { useEffect, useMemo, useContext } from 'react';
import { createPortal } from 'react-dom';
import { StyleSheetManager } from 'styled-components';
import RootContext from 'ext/components/RootContext';
import { getPortalRoot } from 'ext/lib/document';

const Portal = ({ children, timeout = 0, container = null }) => {
  const $builder = useContext(RootContext);
  const $portal = container ?? getPortalRoot($builder);

  const $el = useMemo(() => {
    const $div = $builder.createElement('div');
    Object.defineProperty($div, 'ownerDocument', { value: $builder });
    return $div;
  }, [$builder]);

  useEffect(() => {
    $portal.append($el);

    return () => {
      setTimeout(() => {
        $el.remove();
      }, timeout);
    };
  }, [$el, $portal, timeout]);

  return createPortal(
    // NOTE: Inject component styles rendered by Portal within scope. This ensures
    // that components using Portals rendered within iframes or shadow roots are
    // still styled correctly since those containers will inject it's styled
    // component styles within themselves. But given the portal root exists
    // outside of these containers, those styles are inaccessible unless the
    // component is used in the app root at which the styles are injected within
    // scope. This _should_ fix that by ensuring any component rendered via
    // portals will have their styles injected at the app root where the portal
    // root also exists.
    <StyleSheetManager target={$portal}>
      <div>{children}</div>
    </StyleSheetManager>,
    $el
  );
};

export default Portal;
