/* eslint-disable no-param-reassign */
import { POINTER_EDGE } from './variables';

const { TOP, BOTTOM, LEFT, RIGHT } = POINTER_EDGE;

/**
 * Update the position of the tooltip content to fit properly inside the drawn tooltip.
 * @param {DOMNode} contentNode - DOM node that represents only the content
 * @param {DOMNode} tooltipNode - DOM node that represents the whole tooltip (content and pointer)
 * @param {Object} tooltipFrame - Defines the dimensions and position of the tooltip
 * @param {Object} tooltipConfig - configuration for the tooltip, including styles
 * @param {string} actualPointerEdge - Defines the position of the pointer (TOP, BOTTOM, LEFT, RIGHT)
 * @param {Object} pointers - Defines witch corners has the border radius overwritten
 */
export function calculateContentPosition({
  contentNode,
  tooltipNode,
  tooltipFrame,
  tooltipConfig,
  actualPointerEdge,
  ...pointers
}) {
  const { height, width, xOrigin, yOrigin } = tooltipFrame;
  const { hidePointer, pointerLength, style } = tooltipConfig;

  tooltipNode.style.width = `${width}px`;
  tooltipNode.style.height = `${height}px`;
  tooltipNode.style.left = `${xOrigin}px`;
  tooltipNode.style.top = `${yOrigin}px`;

  // We 0 out the corner radius if the specific corner was overridden because
  // the pointer replaces the roundedness
  const boxCornerRadius =
    (style?.cornerRadius ?? 0) - (style?.borderWidth ?? 0) / 2;

  const setOverrideRadius = corner =>
    pointers[`overrides${corner}Corner`] ? 0 : `${boxCornerRadius}px`;

  contentNode.style.borderTopLeftRadius = setOverrideRadius('TopLeft');
  contentNode.style.borderTopRightRadius = setOverrideRadius('TopRight');
  contentNode.style.borderBottomRightRadius = setOverrideRadius('BottomRight');
  contentNode.style.borderBottomLeftRadius = setOverrideRadius('BottomLeft');

  const additionalPointerHeight = hidePointer ? 0 : pointerLength;
  const additionalBorderWidth = style?.borderWidth ?? 0;

  const setMargin = position => {
    return `${
      additionalBorderWidth +
      (actualPointerEdge === position ? additionalPointerHeight : 0)
    }px`;
  };

  contentNode.style.marginTop = setMargin(TOP);
  contentNode.style.marginBottom = setMargin(BOTTOM);
  contentNode.style.marginLeft = setMargin(LEFT);
  contentNode.style.marginRight = setMargin(RIGHT);

  if (actualPointerEdge === TOP || actualPointerEdge === BOTTOM) {
    contentNode.style.width = `${width - additionalBorderWidth * 2}px`;
    contentNode.style.height = `${
      height - additionalBorderWidth * 2 - additionalPointerHeight
    }px`;
  } else if (actualPointerEdge === LEFT || actualPointerEdge === RIGHT) {
    contentNode.style.width = `${
      width - additionalBorderWidth * 2 - additionalPointerHeight
    }px`;
    contentNode.style.height = `${height - additionalBorderWidth * 2}px`;
  }
}
