import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from './Input';

const UnitLabel = styled.span`
  border-radius: 0 6px 6px 0;
  background-color: var(--oh-hi-dark);
  display: flex;
  align-items: center;
  text-align: center;
  font-size: var(--regular);
  padding: 8px 16px;
  color: var(--white);
`;

const NumberInput = styled(Input)`
  width: 100%;
  margin-right: 8px;
  border-radius: 6px 0 0 6px;
`;

const InputUnitContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;

  ${NumberInput} {
    margin: 0;
  }
`;

export default function InputUnit({ unit, ...props }) {
  return (
    <InputUnitContainer>
      <NumberInput
        // eslint-disable-next-line @appcues/jsx-props-no-spreading
        {...props}
        type="number"
      />
      <UnitLabel>{unit}</UnitLabel>
    </InputUnitContainer>
  );
}

InputUnit.propTypes = {
  unit: PropTypes.string,
};
