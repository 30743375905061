import { TYPE, SLATE_UPDATED } from './actions';

const initialState = {
  id: null,
  editor: {},
};

export default function slate(state = initialState, action) {
  const { type, payload } = action ?? {};
  const { id, editor } = payload ?? {};

  switch (type) {
    case SLATE_UPDATED:
      return {
        ...state,
        id,
        editor,
      };
    default:
      return state;
  }
}

export const selectSlate = state => state[TYPE];
