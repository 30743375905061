import React from 'react';
import styled from 'styled-components';
import { Alert } from '@appcues/sonar';

const AlertWrapper = styled.div`
  margin-top: 16px;
`;

export const SurveyCarouselWarning = () => (
  <AlertWrapper>
    <Alert.Root variant="warning">
      <Alert.Message>
        <Alert.Description>
          The swiping action does not submit survey answers. Carousel steps are
          not recommended for survey flows.
        </Alert.Description>
      </Alert.Message>
    </Alert.Root>
  </AlertWrapper>
);

export default SurveyCarouselWarning;
