import React from 'react';
import { StatusMessage } from 'ext/components/ui';

export default function PublishedBanner() {
  return (
    <StatusMessage aria-label="published">
      This flow is live! Click Target &amp; Publish to push any changes to your
      users.
    </StatusMessage>
  );
}
