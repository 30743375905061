import React from 'react';
import PropTypes from 'prop-types';
import {
  FontIcon,
  ToolBarToggle,
  MiniButton,
  ControlGroup,
  Tooltip,
} from 'ext/components/ui';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import SideBarIcon from './SideBarIcon';
import { Bar, Header, Title } from './styled';

export default function SideBar({
  children,
  title,
  collapsed = false,
  position = 'right',
  onClickToggle,
  onClickMove,
  isBottomBarCollapsed = true,
  className,
}) {
  const moveLabel = position === 'left' ? 'Move to right' : 'Move to left';
  const { track } = useAnalytics();

  return (
    <Bar
      collapsed={collapsed}
      position={position}
      isBottomBarCollapsed={isBottomBarCollapsed}
      className={className}
    >
      {onClickToggle && (
        <ToolBarToggle
          onClick={() => {
            track('Builder interaction', {
              name: 'Toggled Sidebar Visibility',
              component: 'SideBar',
              type: 'Show/Hide Button',
            });
            onClickToggle();
          }}
          role="button"
        >
          {collapsed ? 'show' : 'hide'}
          <FontIcon icon={collapsed ? 'caret-up' : 'caret-down'} />
        </ToolBarToggle>
      )}
      <Header className={className}>
        <Title>{title}</Title>
        <ControlGroup>
          {onClickMove && (
            <Tooltip label={moveLabel} placement="left">
              <MiniButton
                onClick={() => {
                  track('Builder interaction', {
                    name: 'Toggled Sidebar Position',
                    component: 'SideBar',
                  });
                  onClickMove();
                }}
                aria-label={moveLabel}
              >
                <SideBarIcon position={position} />
              </MiniButton>
            </Tooltip>
          )}

          {onClickToggle && (
            <Tooltip label="Close" placement="left">
              <MiniButton
                onClick={() => {
                  track('Builder interaction', {
                    name: 'Toggled Sidebar Visibility',
                    component: 'SideBar',
                    type: 'x button',
                  });
                  onClickToggle();
                }}
              >
                <FontIcon icon="times-circle" />
              </MiniButton>
            </Tooltip>
          )}
        </ControlGroup>
      </Header>
      {children}
    </Bar>
  );
}

SideBar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  onClickToggle: PropTypes.func,
  onClickMove: PropTypes.func,
  collapsed: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
  isBottomBarCollapsed: PropTypes.bool,
  className: PropTypes.string,
};
