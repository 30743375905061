import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const theme = ` 
  --radio-button-margin-right: 2px;
  --radio-button-border: none;
  --radio-button-color: var(--myst);
  --radio-button-background: var(--diel-gray);
  --radio-button-selected: var(--myst);
  --radio-button-selected-border: none;
  --radio-button-selected-background: var(--bludacris);

  [data-theme='light'] & {
    --radio-button-margin-right: 0px;
    --radio-button-border: 1px solid var(--diel-gray);
    --radio-button-color: var(--pleather);
    --radio-button-background: var(--white);
    --radio-button-selected: var(--blurple);
    --radio-button-selected-border: 1px solid var(--blurple);
    --radio-button-selected-background: var(--luna);
  }
`;

const ContentWrapper = styled.span``;

export const RadioButton = styled(Button).attrs(({ children, selected }) => ({
  'aria-checked': selected,
  children: <ContentWrapper>{children}</ContentWrapper>,
  role: 'radio',
  kind: 'inverted',
}))`
  font-weight: var(--bold);
  color: var(--radio-button-color);
  background: var(--radio-button-background);

  :hover:not([disabled]) {
    color: var(--radio-button-color);
  }

  > ${ContentWrapper} {
    opacity: 0.8;
  }

  :hover:not([disabled]) > ${ContentWrapper} {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.6;
  }

  ${({ selected }) =>
    selected &&
    `
      color: var(--radio-button-selected);
      background: var(--radio-button-selected-background);

      &&& {
        border: var(--radio-button-selected-border);

        & + ${RadioButton} {
          border-left: 0;
        }

        :first-of-type {
          border-right: var(--radio-button-border);
        }

        :last-of-type {
          border-left: var(--radio-button-border);
        }

        :disabled {
          background: var(--radio-button-selected-background);
          opacity: 0.6;
        }
      }

      &:hover:not([disabled]){ {
        color: var(--radio-button-selected);
      }

      > ${ContentWrapper} {
        opacity: 1;
      }
    `}
`;

export default RadioButton;

export const RadioButtonGroup = styled.div.attrs(() => ({
  role: 'radiogroup',
}))`
  ${theme}

  align-items: stretch;
  display: flex;
  justify-content: space-between;

  > ${RadioButton} {
    border-radius: 0;
    flex-basis: 0;
    flex-grow: 1;
    margin-right: var(--radio-button-margin-right);
    border: var(--radio-button-border);
    border-right: none;

    :first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    :last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: var(--radio-button-border);
      margin-right: 0;
    }
  }
`;
