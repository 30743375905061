import { useState, useEffect } from 'react';
import { CUSTOM_COMPONENT_PROPERTY_TYPE as PROPERTY_TYPE } from 'lib/block';

const defaultFor = type =>
  ({
    [PROPERTY_TYPE.BOOLEAN]: true,
    [PROPERTY_TYPE.NUMBER]: 0,
    [PROPERTY_TYPE.STRING]: '',
  }[type]);

const initialComponent = {
  identifier: '',
  description: '',
  properties: [
    {
      name: '',
      description: '',
      type: PROPERTY_TYPE.STRING,
      default: defaultFor(PROPERTY_TYPE.STRING),
    },
  ],
};

export function useCustomComponentForm(editingComponentTemplate) {
  const [component, setComponent] = useState(initialComponent);
  const [error, setError] = useState({});

  useEffect(() => {
    if (editingComponentTemplate) {
      setComponent(editingComponentTemplate.content);
    }
  }, [editingComponentTemplate]);

  const handleError = (key, validation) => {
    setError(prevState => {
      const updatedError = { ...prevState };
      if (validation) {
        updatedError[key] = validation;
      } else {
        delete updatedError[key];
      }
      return updatedError;
    });
  };

  const handleTextChange = (key, value) => {
    if (key === 'identifier') {
      handleError(key, value === '' ? 'This field is required' : null);
    }
    setComponent(prevState => ({ ...prevState, [key]: value }));
  };

  const handlePropertyChange = (propertyIndex, key, value) => {
    setComponent(prevState => {
      const { properties: updatedProperties, ...other } = prevState;
      updatedProperties[propertyIndex][key] = value;

      if (key === 'type') {
        updatedProperties[propertyIndex].default = defaultFor(value);
      }

      handleError(
        `${propertyIndex}-name`,
        updatedProperties[propertyIndex].name === ''
          ? 'This field is required'
          : null
      );

      return { ...other, properties: updatedProperties };
    });
  };

  const resetForm = () => {
    setComponent(initialComponent);
    setError({});
  };

  const handleAddProperty = () => {
    setComponent(prevState => ({
      ...prevState,
      properties: [
        ...prevState.properties,
        {
          name: '',
          description: '',
          type: PROPERTY_TYPE.STRING,
          default: '',
        },
      ],
    }));
  };

  const handleRemoveProperty = (event, propertyIndex) => {
    event.preventDefault();
    setComponent(prevState => ({
      ...prevState,
      properties: prevState.properties.filter((_, i) => i !== propertyIndex),
    }));
    handleError(`${propertyIndex}-name`, null);
  };

  const handleSave = onSave => {
    if (component.identifier === '') {
      handleError('identifier', 'This field is required');
      return false;
    }

    if (Object.keys(error).length > 0) return false;

    const trimmedComponent = {
      ...component,
      properties: component.properties.filter(property => property.name !== ''),
    };

    onSave(trimmedComponent);
    return true;
  };

  return {
    component,
    error,
    handleError,
    handleTextChange,
    handlePropertyChange,
    handleAddProperty,
    handleRemoveProperty,
    handleSave,
    resetForm,
    setComponent,
  };
}
