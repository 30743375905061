/**
 * Merge multiple references assigned to an element
 *
 * Based on https://github.com/facebook/react/issues/8873#issuecomment-275423780
 *
 * @params {React.RefObject} refs - List of refs to merge
 * @return {void}
 */
export default function mergeRefs(refs = []) {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    });
  };
}
