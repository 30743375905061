import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createStore } from 'redux';
import StyleProvider from 'styles/StyleProvider';
import rootReducer from 'root/root-reducer';
import { ContentShape } from 'entities/step-children';

export default function Providers({ children, stepChildren, stepChild }) {
  const state = {
    stepChildren,
    selected: {
      stepChild,
    },
  };

  const store = createStore(rootReducer, state);

  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <StyleProvider>{children}</StyleProvider>
      </DndProvider>
    </Provider>
  );
}

Providers.propTypes = {
  children: PropTypes.node.isRequired,
  stepChildren: PropTypes.objectOf(
    PropTypes.shape({
      [PropTypes.string]: ContentShape,
    })
  ),
  stepChild: PropTypes.string,
};
