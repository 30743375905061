import { useState, useEffect, useCallback } from 'react';

// 400 (sidebar) + 32 (margin) = 432
const SIDEBAR_SIZE = 432;

// 54 (top bar) + 128 (bottom bar with banners) +  32 (margin) + 50 (actions toolbar + tooltip) = 264
const VERTICAL_BARS_SIZE = 264;

/**
 * A custom React hook that scales content to fit in enclosed area.
 * @param {object} enclosedArea - Area that encloses the content.
 * @param {object} contentSize - Content size with width and height.
 * @returns {array} - An array with scale and setScale.
 */
export default function useContentScale(enclosedArea, contentSize) {
  const [scale, setScale] = useState(1);

  const { width: contentWidth, height: contentHeight } = contentSize ?? {};

  const handleScaleSize = useCallback(
    (areaWidth, areaHeight) => {
      const scaleSize =
        Math.min(
          (areaWidth - SIDEBAR_SIZE) / contentWidth,
          (areaHeight - VERTICAL_BARS_SIZE) / contentHeight
        ) || 1;

      // Set scale to 1 if content is smaller than enclosed area
      return scaleSize > 1 ? 1 : scaleSize;
    },
    [contentWidth, contentHeight]
  );

  // Scale content to fit in enclosed area
  const handleSetScale = useCallback(() => {
    if (enclosedArea?.current) {
      const { width: areaWidth, height: areaHeight } =
        enclosedArea.current.getBoundingClientRect();
      setScale(handleScaleSize(areaWidth, areaHeight));
    } else {
      // Scale content when enclosed area is not available
      setScale(handleScaleSize(window.innerWidth, window.innerHeight));
    }
  }, [enclosedArea, handleScaleSize]);

  // Scale on the first render
  useEffect(() => {
    handleSetScale();
  }, [contentWidth, contentHeight, handleSetScale]);

  return [scale, handleSetScale];
}
