/**
 * Experience types
 *
 * @constant
 */
export const DEFAULT_TYPE = 'mobile';
export const EMBED_TYPE = 'mobile_embed';

/**
 * Experience types list
 *
 * @constant
 */
export const EXPERIENCE_TYPES = [DEFAULT_TYPE, EMBED_TYPE];
