import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'ext/components/Fade';
import Portal from 'ext/components/Portal';
import useEscape from 'ext/lib/hooks/use-escape';
import { ButtonGroup } from './Button';
import { Heading, P, Link } from './Text';

/**
 * NOTE: Unlike other theme styles where the component inherits from a parent
 *       [data-theme] attribute, the modal applies the style when the modal
 *       itself has an [data-theme] attribute.
 */
const dataTheme = `
  --modal-background: #2a2a2a;
  --modal-box-shadow: 0 0 16px var(--tint-black);
  --modal-contained-background: var(--oh-hi-dark);
  --modal-dialog-width: auto;
  --modal-link-color: var(--noreaster);
  --modal-text-color: var(--noreaster);

  &[data-theme='light'] {
    --modal-background: rgba(36, 42, 53, 0.4);
    --modal-box-shadow: 0 4px 16px rgba(71, 88, 114, 0.2);
    --modal-contained-background: var(--white);
    --modal-dialog-width: 400px;
    --modal-link-color: var(--blurple);
    --modal-text-color: var(--pleather);
  }
`;

/**
 * Fullscreen container to remove Fade from layout flow
 *
 * @type {ReactElement}
 */
const Fullscreen = styled.div`
  bottom: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: visibility 0s 0s;
  visibility: visible;
  width: 100vw;
  z-index: 1;

  ${({ visible, timeout }) =>
    !visible &&
    `
      transition: visibility 0s ${timeout}ms;
      visibility: hidden;
    `}
`;

/**
 * Fullscreen flex container to hold dialog and overlay
 *
 * @type {ReactElement}
 */
const Container = styled.div`
  ${dataTheme}

  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
`;

/**
 * Overlay backdrop between page and dialog to capture clicks
 *
 * @type {ReactElement}
 */
const Overlay = styled.div`
  height: 100%;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  z-index: 2;
  background: var(--modal-background);
`;

/**
 * Centered dialog holding provided content
 *
 * @type {ReactElement}
 */
const Dialog = styled.div`
  z-index: 3;
  color: var(--modal-text-color);

  ${Link} {
    color: var(--modal-link-color);
  }

  ${({ contained, wide }) =>
    contained &&
    `
      background: var(--modal-contained-background);
      border-radius: 12px;
      box-shadow: var(--modal-box-shadow);
      min-width: 300px;
      padding: ${wide ? '56px' : '24px 32px'};
      position: relative;
      width: var(--modal-dialog-width);
    `}

  & > ${Heading}:first-child {
    margin-top: 0;
  }

  ${P} {
    max-width: 360px;
  }

  ${ButtonGroup} {
    margin-top: 24px;
  }
`;

/**
 * We add a click trap to the <Container /> so that click events don't escape
 * the modal and trigger click handlers in parents of the <Modal />. This is due
 * to the way that React Portals bubble events. See more here:
 * https://reactjs.org/docs/portals.html#event-bubbling-through-portals
 *
 * If we run into issues with other event types, the <Container /> will be a
 * good place to add a trap for those events, as well.
 */
const stopBubblingEvent = e => {
  e.stopPropagation();
};

/**
 * Modal component that mounts through a Portal. By default will show
 * immediately when mounted unless `visible` explicitly provided through
 * configurable fade-in
 *
 * @type {ReactElement}
 */
const Modal = ({
  children,
  className,
  contained = true,
  onClose,
  theme = 'dark',
  timeout = 200,
  visible = true,
  wide = false,
}) => {
  /*
   * Disable body scroll when visible
   */
  useEffect(() => {
    if (!visible) {
      return () => {};
    }

    const {
      style: { overflow },
    } = document.body;

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = overflow;
    };
  }, [visible]);

  useEscape(visible ? onClose : null);

  return (
    <Portal timeout={timeout}>
      <Fullscreen visible={visible} timeout={timeout}>
        <Fade visible={visible} timeout={timeout}>
          <Container
            onClick={stopBubblingEvent}
            onMouseDown={stopBubblingEvent}
            data-theme={theme}
          >
            <Overlay aria-label="overlay" onClick={onClose} role="button" />
            <Dialog
              className={className}
              contained={contained}
              role="dialog"
              wide={wide}
            >
              {children}
            </Dialog>
          </Container>
        </Fade>
      </Fullscreen>
    </Portal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contained: PropTypes.bool,
  onClose: PropTypes.func,
  theme: PropTypes.oneOf(['dark', 'light']),
  timeout: PropTypes.number,
  visible: PropTypes.bool,
  wide: PropTypes.bool,
};

export default styled(Modal)``;
