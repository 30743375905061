import React from 'react';
import {
  TEXT,
  IMAGE,
  HERO,
  BUTTON,
  VIDEO,
  EMOJI,
  IMAGE_WITH_TEXT,
  CUSTOM_COMPONENT,
  TEXT_INPUT,
  MULTI_SELECT,
  SINGLE_SELECT,
  RATING,
  BLOCK_LABELS,
  CONTENT,
  SURVEY,
} from 'lib/block';

const textIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.56604 6C6.25342 6 6 6.25342 6 6.56604C6 6.87865 6.25342 7.13208 6.56604 7.13208H11.434L11.434 17.434C11.434 17.7466 11.6874 18 12 18C12.3126 18 12.566 17.7466 12.566 17.434V7.13208H17.434C17.7466 7.13208 18 6.87865 18 6.56604C18 6.25342 17.7466 6 17.434 6H12H6.56604Z"
    fill="currentColor"
  />
);

const imageIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.25 7.5V16H9.58334L14.3183 12.7219C14.7357 12.433 15.281 12.4061 15.7248 12.6527L18.75 14.3333V7.5H5.25ZM4.66667 6.25C4.29848 6.25 4 6.54848 4 6.91667V16.5833C4 16.9515 4.29848 17.25 4.66667 17.25H19.3333C19.7015 17.25 20 16.9515 20 16.5833V6.91667C20 6.54848 19.7015 6.25 19.3333 6.25H4.66667ZM9.5 12.25C10.3284 12.25 11 11.5784 11 10.75C11 9.92157 10.3284 9.25 9.5 9.25C8.67157 9.25 8 9.92157 8 10.75C8 11.5784 8.67157 12.25 9.5 12.25Z"
    fill="currentColor"
  />
);

const heroIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18.75 12.4545H5.25V15.8636H18.75V12.4545ZM4 12.4545V7.90909V7.60606C4 7.27134 4.29848 7 4.66667 7H19.3333C19.7015 7 20 7.27134 20 7.60606V7.90909V12.4545V16.3939C20 16.7287 19.7015 17 19.3333 17H4.66667C4.29848 17 4 16.7287 4 16.3939V12.4545Z"
    fill="currentColor"
  />
);

const buttonIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.25 14.75V9.25H18.75V14.75H5.25ZM19 8H5C4.44772 8 4 8.44772 4 9V15C4 15.5523 4.44772 16 5 16H19C19.5523 16 20 15.5523 20 15V9C20 8.44772 19.5523 8 19 8ZM9 11.375C8.65482 11.375 8.375 11.6548 8.375 12C8.375 12.3452 8.65482 12.625 9 12.625H15C15.3452 12.625 15.625 12.3452 15.625 12C15.625 11.6548 15.3452 11.375 15 11.375H9Z"
    fill="currentColor"
  />
);

const videoIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18.75 12C18.75 15.7279 15.7279 18.75 12 18.75C8.27208 18.75 5.25 15.7279 5.25 12C5.25 8.27208 8.27208 5.25 12 5.25C15.7279 5.25 18.75 8.27208 18.75 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM14.8434 12C14.8434 12.165 14.762 12.3193 14.6259 12.4126L11.4542 14.5842C11.3011 14.6891 11.1026 14.7005 10.9385 14.614C10.7744 14.5275 10.6717 14.3572 10.6717 14.1717V9.82831C10.6717 9.6428 10.7744 9.47254 10.9385 9.38602C11.1026 9.2995 11.3011 9.31095 11.4542 9.41575L14.6259 11.5874C14.762 11.6807 14.8434 11.835 14.8434 12ZM14.3434 12L11.1717 9.82831V9.82833L14.3433 12L11.1717 14.1717V14.1717L14.3434 12Z"
    fill="currentColor"
  />
);

const emojiIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18.75 12C18.75 15.7279 15.7279 18.75 12 18.75C8.27208 18.75 5.25 15.7279 5.25 12C5.25 8.27208 8.27208 5.25 12 5.25C15.7279 5.25 18.75 8.27208 18.75 12ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM8.95835 12.8332C9.23369 12.6267 9.62395 12.6818 9.83153 12.9558L9.83502 12.9603C9.83922 12.9656 9.84701 12.9753 9.85831 12.9887C9.88094 13.0157 9.91737 13.0575 9.96688 13.1091C10.0664 13.213 10.2158 13.3537 10.4093 13.4944C10.7986 13.7776 11.3389 14.0416 12 14.0416C12.6611 14.0416 13.2014 13.7776 13.5907 13.4944C13.7842 13.3537 13.9336 13.213 14.0331 13.1091C14.0827 13.0575 14.1191 13.0157 14.1417 12.9887C14.153 12.9753 14.1608 12.9656 14.165 12.9603L14.1685 12.9558C14.3761 12.6818 14.7663 12.6267 15.0417 12.8332C15.3178 13.0403 15.3738 13.4321 15.1667 13.7082L14.6667 13.3332C15.1667 13.7082 15.1662 13.7089 15.166 13.7091L15.1653 13.71L15.1636 13.7123L15.1589 13.7184L15.1451 13.7361C15.1338 13.7504 15.1185 13.7693 15.0992 13.7923C15.0606 13.8383 15.0059 13.9007 14.9356 13.974C14.7956 14.1201 14.5908 14.3127 14.326 14.5054C13.7986 14.8889 13.0056 15.2916 12 15.2916C10.9945 15.2916 10.2014 14.8889 9.67408 14.5054C9.40919 14.3127 9.20443 14.1201 9.0644 13.974C8.99412 13.9007 8.9394 13.8383 8.90084 13.7923C8.88154 13.7693 8.86622 13.7504 8.85496 13.7361L8.8411 13.7184L8.83646 13.7123L8.83471 13.71L8.83398 13.7091L8.83335 13.7082L9.33335 13.3332C8.83335 13.7082 8.8332 13.708 8.83335 13.7082C8.62624 13.4321 8.68221 13.0403 8.95835 12.8332ZM14.75 10C14.75 10.4142 14.4142 10.75 14 10.75C13.5858 10.75 13.25 10.4142 13.25 10C13.25 9.58579 13.5858 9.25 14 9.25C14.4142 9.25 14.75 9.58579 14.75 10ZM10 10.75C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10C9.25 10.4142 9.58579 10.75 10 10.75Z"
    fill="currentColor"
  />
);

const imageWithIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M21.6842 7.80629H11.5913V9.06791H21.6842V7.80629ZM19.7918 10.9604H11.5913V12.222H19.7918V10.9604ZM11.5913 14.1145H19.7918V15.3761H11.5913V14.1145ZM9.20486 10.5119C9.13412 10.4133 9.03745 10.3542 8.91484 10.3345L6.84932 10.024L5.92975 8.07241C5.87316 7.95413 5.79063 7.87528 5.68217 7.83586C5.57371 7.79643 5.46524 7.79643 5.35678 7.83586C5.24832 7.87528 5.16579 7.95413 5.1092 8.07241L4.18962 10.024L2.12411 10.3345C2.0015 10.3542 1.90483 10.4133 1.83409 10.5119C1.76336 10.6104 1.73035 10.7189 1.73506 10.8371C1.73978 10.9554 1.78458 11.0589 1.86946 11.1476L3.36908 12.6704L3.0154 14.829C2.99653 14.9472 3.01775 15.0581 3.07906 15.1616C3.14036 15.2651 3.22761 15.3316 3.34078 15.3612C3.45396 15.3908 3.56243 15.376 3.66617 15.3169L5.51947 14.3115L7.37278 15.3169C7.47652 15.376 7.58499 15.3908 7.69817 15.3612C7.81134 15.3316 7.89859 15.2651 7.95989 15.1616C8.0212 15.0581 8.04242 14.9472 8.02355 14.829L7.66987 12.6704L9.16949 11.1476C9.25437 11.0589 9.29917 10.9554 9.30389 10.8371C9.3086 10.7189 9.27559 10.6104 9.20486 10.5119ZM8.36309 10.9702L6.94836 12.4191L7.27375 14.4741L5.51947 13.4984L3.7652 14.4741L4.09059 12.4191L2.67586 10.9702L4.64234 10.6745L5.51947 8.81164L6.39661 10.6745L8.36309 10.9702Z"
    fill="currentColor"
  />
);

const textInput = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17.9953 5.0651C17.9083 4.97826 17.7675 4.97831 17.6806 5.06519L11.51 11.2358C11.4683 11.2775 11.4448 11.3342 11.4448 11.3932V13.3573C11.4448 13.4802 11.5445 13.5799 11.6674 13.5799H13.6315C13.6905 13.5799 13.7471 13.5564 13.7889 13.5147L19.9607 7.34288C20.0025 7.30112 20.0259 7.24447 20.0259 7.18542C20.0259 7.12636 20.0024 7.06973 19.9606 7.02799L17.9953 5.0651ZM5.16298 7.88011C5.26734 7.77575 5.40888 7.71712 5.55646 7.71712H10.1329C10.4403 7.71712 10.6894 7.96626 10.6894 8.27358C10.6894 8.58091 10.4403 8.83004 10.1329 8.83004H6.11292V17.8871H17.2039V13.3586C17.2039 13.0512 17.4531 12.8021 17.7604 12.8021C18.0677 12.8021 18.3168 13.0512 18.3168 13.3586V18.4435C18.3168 18.7508 18.0677 19 17.7604 19H5.55646C5.24914 19 5 18.7508 5 18.4435V8.27358C5 8.126 5.05863 7.98446 5.16298 7.88011Z"
    fill="currentColor"
  />
);

const singleSelect = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18.75 11.9929C18.75 15.7175 15.7279 18.7368 12 18.7368C8.27208 18.7368 5.25 15.7175 5.25 11.9929C5.25 8.26827 8.27208 5.24889 12 5.24889C15.7279 5.24889 18.75 8.26827 18.75 11.9929ZM20 11.9929C20 16.4072 16.4183 19.9857 12 19.9857C7.58172 19.9857 4 16.4072 4 11.9929C4 7.57853 7.58172 4 12 4C16.4183 4 20 7.57853 20 11.9929ZM12 14.9902C13.6569 14.9902 15 13.6483 15 11.9929C15 10.3375 13.6569 8.99557 12 8.99557C10.3431 8.99557 9 10.3375 9 11.9929C9 13.6483 10.3431 14.9902 12 14.9902Z"
    fill="currentColor"
  />
);

const multiSelect = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17.7143 4C18.975 4 20 5.0223 20 6.28368V17.7021C20 18.9616 18.975 19.9857 17.7143 19.9857H6.28571C5.02321 19.9857 4 18.9616 4 17.7021V6.28368C4 5.0223 5.02321 4 6.28571 4H17.7143ZM16.1357 10.4157C16.525 10.0268 16.525 9.39162 16.1357 9.00268C15.7464 8.61374 15.1107 8.61374 14.7214 9.00268L10.8571 12.8635L9.27857 11.2864C8.88929 10.8974 8.25357 10.8974 7.86429 11.2864C7.47393 11.6753 7.47393 12.3104 7.86429 12.6994L10.15 14.9831C10.5393 15.372 11.175 15.372 11.5643 14.9831L16.1357 10.4157Z"
    fill="currentColor"
  />
);

const rating = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.84 3.939c.478-.955 1.842-.955 2.321 0l2.168 4.325 4.835.696c1.07.154 1.494 1.471.714 2.219l-3.486 3.345.824 4.731c.184 1.057-.923 1.868-1.876 1.374L12 18.381l-4.339 2.248c-.953.494-2.06-.317-1.876-1.374l.824-4.731-3.486-3.345c-.78-.748-.357-2.065.714-2.219l4.835-.696 2.168-4.325Zm1.16.902L9.928 8.977c-.19.38-.555.642-.976.703l-4.617.664 3.328 3.193c.311.299.454.733.38 1.158l-.787 4.521 4.147-2.148a1.3 1.3 0 0 1 1.195 0l4.147 2.148-.787-4.521a1.296 1.296 0 0 1 .38-1.158l3.328-3.193-4.618-.664a1.298 1.298 0 0 1-.975-.703L12 4.841Z"
    fill="currentColor"
  />
);

const customComponentIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="m14.67 5.875-4.05 13.05c-.136.357-.49.554-.845.444-.357-.135-.557-.49-.444-.843l4.05-13.05a.674.674 0 1 1 1.288.4Zm2.516 2.458 3.6 3.375a.656.656 0 0 1 .214.467.725.725 0 0 1-.214.517l-3.6 3.375a.672.672 0 0 1-.953-.03.673.673 0 0 1 .03-.954l3.075-2.908-3.074-2.858a.673.673 0 0 1-.031-.953.673.673 0 0 1 .953-.031Zm-9.45.984-3.074 2.858 3.074 2.908c.273.256.287.68.031.954a.673.673 0 0 1-.953.03l-3.6-3.375A.732.732 0 0 1 3 12.175c0-.16.077-.34.213-.467l3.6-3.375a.673.673 0 0 1 .954.031.673.673 0 0 1-.03.953Z"
    fill="currentColor"
  />
);

export const CONTENT_MENU_OPTIONS = {
  [CONTENT]: {
    [TEXT]: {
      icon: textIcon,
      text: BLOCK_LABELS[TEXT],
    },
    [IMAGE]: {
      icon: imageIcon,
      text: BLOCK_LABELS[IMAGE],
    },
    [HERO]: {
      icon: heroIcon,
      text: BLOCK_LABELS[HERO],
    },
    [BUTTON]: {
      icon: buttonIcon,
      text: BLOCK_LABELS[BUTTON],
    },
    [VIDEO]: {
      icon: videoIcon,
      text: BLOCK_LABELS[VIDEO],
    },
    [EMOJI]: {
      icon: emojiIcon,
      text: BLOCK_LABELS[EMOJI],
    },
    [IMAGE_WITH_TEXT]: {
      icon: imageWithIcon,
      text: BLOCK_LABELS[IMAGE_WITH_TEXT],
    },
    [CUSTOM_COMPONENT]: {
      icon: customComponentIcon,
      text: BLOCK_LABELS[CUSTOM_COMPONENT],
    },
  },
  [SURVEY]: {
    [TEXT_INPUT]: {
      icon: textInput,
      text: BLOCK_LABELS[TEXT_INPUT],
    },
    [SINGLE_SELECT]: {
      icon: singleSelect,
      text: BLOCK_LABELS[SINGLE_SELECT],
    },
    [MULTI_SELECT]: {
      icon: multiSelect,
      text: BLOCK_LABELS[MULTI_SELECT],
    },
    [RATING]: {
      icon: rating,
      text: BLOCK_LABELS[RATING],
    },
  },
};

export default CONTENT_MENU_OPTIONS;
