import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FontIcon from './FontIcon';
import { Heading } from './Text';

const theme = `
  --modal-header-x-color: var(--white);

  [data-theme="light"] & {
    --modal-header-x-color: var(--pleather);
  }
`;

const CloseButton = styled.button`
  ${theme}

  background: transparent;
  border: none;
  color: var(--modal-header-x-color);
  cursor: pointer;
  font-size: var(--x-large);
  height: 16px;
  line-height: 0;
  margin: 0;
  padding: 0;
  width: 16px;

  > ${FontIcon} {
    width: inherit;
    height: inherit;
  }
`;

export const ModalHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 8px 0 16px;

  ${Heading} {
    font-size: var(--large);
    font-weight: var(--semi-bold);
    margin: 0;
  }
`;

const Close = ({ onClose }) => (
  <CloseButton aria-label="Close modal" onClick={onClose}>
    <FontIcon icon="times" />
  </CloseButton>
);

Close.propTypes = {
  onClose: PropTypes.func,
};

ModalHeader.Close = Close;

export default ModalHeader;
