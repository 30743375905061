import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Input = styled.input`
  background: var(--white);
  border-radius: 6px;
  border: 1px solid var(--sharkbait-ooh-la-la);
  height: 40px;
  text-align: center;
  width: 68px;
`;

const isValidNumber = value => /^-?[\d.]+(?:e-?\d+)?$/.test(value);

export const formatValue = ({ replaceRegex, suffix, target, inputType }) => {
  const { selectionStart, value } = target;
  const formattedValue = value.replace(replaceRegex, '');

  // Whenever a backspace is pressed within the input
  // we should make sure it deletes the value ignoring
  // the suffix.
  // in this case the deletion should be manually
  // instead of relying in the native behavior
  // since the suffix won't be removed.
  const shouldDeleteValueManually =
    selectionStart > value.length - suffix.length &&
    selectionStart <= formattedValue.length + suffix.length &&
    inputType === 'deleteContentBackward';

  return shouldDeleteValueManually
    ? `${formattedValue}`.slice(0, formattedValue.length - 1)
    : formattedValue;
};

function InputWithSuffix({
  className,
  onChange,
  value,
  placeholder,
  suffix,
  ariaLabel,
  id,
  onFocus,
  onBlur,
}) {
  const findAnyCharacter = useMemo(
    () => new RegExp(`[${suffix}]`, 'gmi'),
    [suffix]
  );

  const handleValueValidation = ({ target, nativeEvent }) => {
    const formattedValue = formatValue({
      replaceRegex: findAnyCharacter,
      suffix,
      target,
      inputType: nativeEvent.inputType,
    });

    // only set value if value entered is a valid number or is empty
    if (isValidNumber(formattedValue) || !formattedValue)
      onChange(formattedValue);
  };

  return (
    <Input
      className={className}
      onChange={handleValueValidation}
      value={value ? `${value}${suffix}` : ''}
      placeholder={placeholder}
      id={id}
      aria-label={ariaLabel}
      type="text"
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}

InputWithSuffix.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

InputWithSuffix.defaultProps = {
  id: '',
  ariaLabel: '',
};

export default styled(InputWithSuffix)``;
