import { v4 as uuid } from 'uuid';
import { set } from 'object-path-immutable';
import { BLOCK, LOCALIZED, TEXT } from 'lib/block';
import { DEFAULT_LOCALE, getLocaleVariations } from 'lib/localization';

const isDefaultLocale = locale => locale === DEFAULT_LOCALE.id;

const isLocalizedContent = content =>
  content.content?.type === BLOCK && content.content?.blockType === LOCALIZED;

const getDefaultStyle = (defaultOption, locale) => {
  if (!locale) {
    return defaultOption.content.style;
  }

  const defaultStyle = defaultOption.content.content.style;
  const variationStyle = defaultOption.content.variations?.[locale]?.style;

  return isDefaultLocale(locale) ? defaultStyle : variationStyle;
};

const createContent = (defaultOption, optionText, locale) => ({
  id: uuid(),
  type: TEXT,
  text: optionText,
  style: getDefaultStyle(defaultOption, locale),
});

function createLocalizedContent(defaultOption, optionText, locales) {
  const variations = locales.reduce((acc, locale) => {
    if (!isDefaultLocale(locale)) {
      acc[locale] = createContent(defaultOption, optionText, locale);
    }
    return acc;
  }, {});

  return {
    blockType: LOCALIZED,
    id: uuid(),
    type: BLOCK,
    content: createContent(defaultOption, optionText, DEFAULT_LOCALE.id),
    variations,
  };
}

function createNewOption(defaultOption, optionText, locales) {
  const content =
    locales.length > 0
      ? createLocalizedContent(defaultOption, optionText, locales)
      : createContent(defaultOption, optionText);

  return {
    value: optionText,
    content,
  };
}

function updateExistingOption(originalOption, optionText, locale) {
  let updatedOption = { ...originalOption };

  if (isLocalizedContent(originalOption)) {
    updatedOption.value = isDefaultLocale(locale)
      ? optionText
      : originalOption.value;

    const textPath = isDefaultLocale(locale)
      ? 'content.content.text'
      : `content.variations.${locale}.text`;

    updatedOption = set(updatedOption, textPath, optionText);
  } else {
    updatedOption = set(updatedOption, 'value', optionText);
    updatedOption = set(updatedOption, 'content.text', optionText);
  }

  return updatedOption;
}

/**
 *
 * @param {Array<object>} options - Array of options with or without variations
 * @param {Array<string>} optionsText - Array of strings with the new options
 * @param {string} locale - Locale id that will be used to update the options
 * @returns {Array<object>} - Array of options with the new options
 */
function optionsHandler(options, optionsText, locale = DEFAULT_LOCALE.id) {
  const [defaultOption] = options;

  const newOptionsText = optionsText
    .split('\n')
    .filter(text => text && text.length > 0);

  if (newOptionsText.length === 0) {
    return options;
  }

  return newOptionsText.map((optionText, index) => {
    const originalOption = options[index];

    if (!originalOption) {
      return createNewOption(
        defaultOption,
        optionText,
        getLocaleVariations(options)
      );
    }

    return updateExistingOption(originalOption, optionText, locale);
  });
}

export default optionsHandler;
