import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import InputWithSuffix from 'ext/components/InputWithSuffix';
import {
  isValidRange,
  getContainerMetaData,
  getAngle,
  isValidNumber,
  formatInputValue,
} from './AngleInput.helpers';

const Container = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Label = styled.label`
  font-weight: var(--bold);
  font-size: var(--regular);
  color: var(--background);
`;

export const Angle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 2px solid var(--angle-border-color);
  margin-right: 52px;
`;

const Pointer = styled.div.attrs(props => ({
  style: {
    transform: `rotate(${props.rotate})`,
  },
}))`
  cursor: pointer;
  width: 2px;
  height: 2px;
  background: blue;
  will-change: transform;

  &:before {
    content: '';
    height: 30px;
    width: 2px;
    display: block;
    background: var(--angle-border-color);
    position: absolute;
    bottom: -1px;
  }

  &:after {
    content: '';
    height: 14px;
    width: 14px;
    display: block;
    background: var(--angle-pointer-bg);
    position: absolute;
    bottom: 21px;
    border-radius: 50%;
    left: -6px;
    border: 2px solid var(--white);
    box-shadow: 0px 2px 6px 0px rgba(71, 88, 114, 0.1);
  }
`;

const MAX_DEGREE = 360;
const THROTTLE_TIME = 100;

const AngleInput = ({
  className,
  label,
  onAngleChange,
  initialValue,
  throttleTime = THROTTLE_TIME,
}) => {
  const angleInputRef = useRef(null);

  const [rotate, setRotate] = useState({
    val: initialValue,
    humanVal: formatInputValue(initialValue),
  });

  useEffect(() => {
    setRotate({
      val: initialValue,
      humanVal: formatInputValue(initialValue),
    });
  }, [initialValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMouseMove = useCallback(
    throttle(e => {
      const containerMetaData = getContainerMetaData(angleInputRef?.current);

      const isMouseWithinInputBoundaries =
        isValidRange(containerMetaData.boundaries.x, e.clientX) &&
        isValidRange(containerMetaData.boundaries.y, e.clientY);

      if (!isMouseWithinInputBoundaries) return;

      const angle = getAngle(e.clientX, e.clientY, containerMetaData.center);

      setRotate({
        val: angle,
        humanVal: formatInputValue(angle),
      });
      onAngleChange(angle);
    }, throttleTime),
    [setRotate, onAngleChange]
  );

  const handleOnChange = humanValue => {
    let degree = 90;
    let humanVal = 0;

    if (isValidNumber(humanValue) && humanValue <= MAX_DEGREE) {
      humanVal = humanValue;
      degree = 90 - humanValue;
    }

    setRotate({
      val: degree,
      humanVal: `${humanVal}`,
    });
    onAngleChange(`${degree}`);
  };

  const handlePointerMouseDown = () => {
    window.addEventListener('mousemove', onMouseMove);
  };

  useEffect(() => {
    window.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', onMouseMove);
    });
  }, [onMouseMove, onAngleChange]);

  return (
    <Container className={className}>
      {label && <Label htmlFor="angle">{label}</Label>}
      <Content>
        <Angle ref={angleInputRef}>
          <Pointer
            rotate={`${rotate.val}deg`}
            onMouseDown={handlePointerMouseDown}
          />
        </Angle>

        <InputWithSuffix
          id="angle"
          ariaLabel="type angle value"
          value={rotate.humanVal}
          onChange={handleOnChange}
          suffix="°"
        />
      </Content>
    </Container>
  );
};

AngleInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onAngleChange: PropTypes.func.isRequired,
  initialValue: PropTypes.number.isRequired,
  throttleTime: PropTypes.number,
};

AngleInput.defaultProps = {
  label: 'Angle',
};

export default styled(AngleInput)``;
