export const INTERACTION_ATTEMPTED = 'INTERACTION_ATTEMPTED';
export const INTERACTION_COMPLETED = 'INTERACTION_COMPLETED';

export const attemptInteraction = interaction => ({
  type: INTERACTION_ATTEMPTED,
  payload: interaction,
});

export const completeInteraction = interaction => ({
  type: INTERACTION_COMPLETED,
  payload: interaction,
});
