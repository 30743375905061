export function saveXLIFF(experienceName, language, xliffContents) {
  const filename = `${experienceName} Default-${language}.xliff`;

  // create a temporary link to download the file
  const element = document.createElement('a');

  // set the content of the file
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(xliffContents)}`
  );
  element.setAttribute('download', filename);

  // make the link invisible and append it to the document
  element.style.display = 'none';
  document.body.append(element);

  // trigger the download
  element.click();

  element.remove();
}
