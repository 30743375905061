import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'ext/lib/hooks/use-toggle';
import { FieldSet, Label, Select } from 'ext/components/ui';
import { mergeProperties } from 'utils/object-handler';
import { BlockContentShape } from 'entities/block';
import { DEFAULT_VALUES } from 'components/SideBarSettings/Shared';
import {
  CustomFontButton,
  CustomFontModal,
  FontGroupOptions,
  getGroupedOptions,
  getGroupedOptionsValues,
  setCustomFontName,
} from 'components/SideBarSettings/Shared/Fonts';

export default function RatingFont({ options, onChange }) {
  const [optionValue] = options;
  const { content: { style: { fontName = DEFAULT_VALUES.fontName } } = {} } =
    optionValue ?? {};

  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const groupedOptions = getGroupedOptions();
  const groupedOptionsValues = getGroupedOptionsValues(groupedOptions);

  const handleSelectedFontValue = groupedOptionsValues.find(
    ({ value }) => value === fontName
  );

  const handleCustomFontButtonClick = () => {
    toggleIsModalOpen();
    setIsMenuOpen(false);
  };

  const handleFontChange = ({ value }) => {
    const updatedOptions = options.map(option =>
      mergeProperties(option, ['content', 'selectedContent'], {
        style: {
          fontName: value,
        },
      })
    );

    onChange({ options: updatedOptions });
  };

  const handleAddFont = value => {
    handleFontChange({ value });
    setCustomFontName(value);
  };

  return (
    <FieldSet>
      <Label htmlFor="text-font">Font</Label>
      <Select
        id="text-font"
        options={groupedOptions}
        formatGroupLabel={FontGroupOptions}
        placeholder="Select font"
        value={handleSelectedFontValue}
        onChange={handleFontChange}
        portal
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        menuButton={<CustomFontButton onClick={handleCustomFontButtonClick} />}
      />
      <CustomFontModal
        onAddFont={handleAddFont}
        onClose={toggleIsModalOpen}
        visible={isModalOpen}
      />
    </FieldSet>
  );
}

RatingFont.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      content: BlockContentShape,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};
