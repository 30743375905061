/* global REDUX_DEVTOOLS */
/* eslint-disable global-require */
import { compose } from 'redux';

// Use the Redux devtools composer if the extension exists
const enhance = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'builder-ui' })
  : compose;

function getReduxConfig() {
  if (REDUX_DEVTOOLS) {
    const Reactotron = require('reactotron-react-js').default;
    const { reactotronRedux } = require('reactotron-redux');
    const sagaPlugin = require('reactotron-redux-saga');

    const reactotron = Reactotron.configure({
      name: 'builder-ui',
    })
      .use(reactotronRedux())
      .use(sagaPlugin())
      .connect();

    const sagaMonitor = reactotron.createSagaMonitor();

    return {
      composeEnhancers: enhancers =>
        enhance(enhancers, reactotron.createEnhancer()),
      sagaMonitor,
      logStateChange: (store, name) => {
        store.subscribe(() => {
          Reactotron.display({
            name: `${name} state updated`,
            value: store.getState(),
          });
        });
      },
    };
  }

  return {
    composeEnhancers: compose,
    sagaMonitor: undefined,
    logStateChange: () => {},
  };
}

export default getReduxConfig;
