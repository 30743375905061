import {
  SINGLE_SELECT,
  MULTI_SELECT,
  OPTION_SELECT,
  BLOCK_MODES,
} from 'lib/block';
import { getLocaleVariations } from 'lib/localization';
import { generateElementTemplate } from 'components/Editor/Templates/';

const SELECT_OPTIONS = new Set([SINGLE_SELECT, MULTI_SELECT]);
const [SINGLE, MULTI] = BLOCK_MODES[OPTION_SELECT];

/**
 * Update survey type, keeping common properties across survey block types
 * @param {string} from - previous selected block
 * @param {string} to - current selected block
 * @param {object} blockProperties - previous selected block properties
 * @param {function} onChange - update the block in the root content
 * @return {object} Updated block with the type changed
 */
const updateSurveyType = ({ from, to, blockProperties, onChange }) => {
  const { id: blockId, primitiveBlock } = blockProperties;
  const { id: elementId, required, label, style, errorLabel } = primitiveBlock;

  // If the type change occurs between selects we preserve the element content
  if (SELECT_OPTIONS.has(from) && SELECT_OPTIONS.has(to)) {
    const contentChunk = {
      blockType: to,
      content: {
        ...primitiveBlock,
        selectMode: to === SINGLE_SELECT ? SINGLE : MULTI,
        ...(to === SINGLE_SELECT ? { maxSelections: 0 } : {}),
      },
    };

    return onChange({ blockId, contentChunk, useOriginalId: true });
  }

  // Otherwise we create a fresh element content based on the
  // new type selected keeping the ids, required, labels and style
  const elementTemplate = generateElementTemplate({
    template: to,
    blockId,
    elementId,
    locales: getLocaleVariations(primitiveBlock),
  });

  const { horizontalAlignment, width } = elementTemplate.content.style ?? {};

  const contentChunk = {
    ...elementTemplate,
    content: {
      ...elementTemplate.content,
      ...(required && { required }),
      ...(required &&
        [SINGLE_SELECT, MULTI_SELECT].includes(to) && { minSelections: 1 }),
      label,
      style: {
        ...style,
        horizontalAlignment,
        width,
      },
      errorLabel,
    },
  };

  return onChange({ blockId, contentChunk, useOriginalId: true });
};

export default updateSurveyType;
