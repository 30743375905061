import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { assign } from 'object-path-immutable';
import { Icon, Switch } from '@appcues/sonar';
import { faRectangle } from '@fortawesome/pro-solid-svg-icons/faRectangle';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import {
  Accordion,
  FieldSet,
  FontIcon,
  RadioButton,
  RadioButtonGroup,
  Label,
} from 'ext/components/ui';
import {
  backdropCircleTemplate,
  backdropRectangleTemplate,
} from 'entities/step-children';
import { BACKDROP_KEYHOLE } from 'lib/trait';
import InputWithValidation from 'components/InputWithValidation';
import {
  Controls,
  RadioButtonLabel,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';

const SHAPE = {
  CIRCLE: 'circle',
  RECTANGLE: 'rectangle',
};

export default function HighlightElement({
  backdropKeyhole,
  handleTraitsUpdate,
}) {
  const trackAccordion = useAccordionClick();

  const {
    shape,
    blurRadius = 0,
    cornerRadius = 0,
    spreadRadius = 0,
  } = backdropKeyhole?.config || {};

  const handleBackdropShowChange = show => {
    if (show) {
      handleTraitsUpdate(backdropCircleTemplate);
    } else {
      handleTraitsUpdate([], BACKDROP_KEYHOLE);
    }
  };

  const handleShapeChange = shapeValue => {
    handleTraitsUpdate(
      shapeValue === SHAPE.CIRCLE
        ? backdropCircleTemplate
        : backdropRectangleTemplate
    );
  };

  const handleTraitChange = debounce((trait, patch) => {
    const updatedBackdropKeyhole = assign(trait, 'config', patch);
    handleTraitsUpdate(updatedBackdropKeyhole);
    // Decrease debounce time to avoid lose the previous change if the user
    // updates the left and top values faster than 500ms (default debounce time)
  }, 250);

  const handleBackdropKeyHole = (key, value) => {
    handleTraitChange(backdropKeyhole, {
      [key]: Number(value),
    });
  };

  return (
    <Accordion.Item value="highlight">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion('Tooltip', 'Highlight')}
        >
          Highlight
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Switch
              id="show"
              checked={!!backdropKeyhole}
              onCheckedChange={handleBackdropShowChange}
              fullWidth
            >
              <Switch.Label htmlFor="show">Show</Switch.Label>
            </Switch>
          </FieldSet>

          {!!backdropKeyhole && (
            <>
              <FieldSet>
                <Label>Shape</Label>
                <RadioButtonGroup>
                  <RadioButton
                    onClick={() => handleShapeChange(SHAPE.CIRCLE)}
                    selected={shape === SHAPE.CIRCLE}
                  >
                    <RadioButtonLabel>
                      <Icon icon={faCircle} />
                      Circle
                    </RadioButtonLabel>
                  </RadioButton>
                  <RadioButton
                    onClick={() => handleShapeChange(SHAPE.RECTANGLE)}
                    selected={shape === SHAPE.RECTANGLE}
                  >
                    <RadioButtonLabel>
                      <Icon icon={faRectangle} />
                      Rectangle
                    </RadioButtonLabel>
                  </RadioButton>
                </RadioButtonGroup>
              </FieldSet>

              <FieldSet>
                <Label htmlFor="highlight-padding">Padding</Label>
                <InputWithValidation
                  id="highlight-padding"
                  value={spreadRadius}
                  onChange={({ target: { valueAsNumber } }) =>
                    handleBackdropKeyHole('spreadRadius', valueAsNumber)
                  }
                  type="number"
                  placeholder="0"
                />
              </FieldSet>

              {shape === SHAPE.CIRCLE && (
                <FieldSet>
                  <InputWithValidation
                    label="Feather"
                    value={blurRadius}
                    onChange={value =>
                      handleBackdropKeyHole('blurRadius', value)
                    }
                    max={100}
                    min={0}
                    range
                  />
                </FieldSet>
              )}

              {shape === SHAPE.RECTANGLE && (
                <FieldSet>
                  <InputWithValidation
                    label="Corner radius"
                    value={cornerRadius}
                    onChange={value =>
                      handleBackdropKeyHole('cornerRadius', value)
                    }
                    max={100}
                    min={0}
                    range
                  />
                </FieldSet>
              )}
            </>
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

HighlightElement.propTypes = {
  backdropKeyhole: PropTypes.shape({
    config: PropTypes.shape({
      shape: PropTypes.string,
      blurRadius: PropTypes.number,
      cornerRadius: PropTypes.number,
    }),
  }),
  handleTraitsUpdate: PropTypes.func,
};
