import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  FieldSet,
  FontIcon,
  Label,
  RadioButtonGroup,
  RadioButton,
} from 'ext/components/ui';
import MarginPaddingEditor from 'ext/components/MarginPaddingEditor';
import { StyleShape } from 'entities/step-children';
import {
  Controls,
  useStyleSettings,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';

// Spacing types
const SPACING = {
  margin: 'margin',
};

// Spacing path mappings
const paths = spacing => ({
  top: `${spacing}Top`,
  right: `${spacing}Trailing`,
  bottom: `${spacing}Bottom`,
  left: `${spacing}Leading`,
});

export default function AlignmentSpacing({ style, onChange }) {
  const trackAccordion = useAccordionClick();

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange,
    style,
  });

  const getStylesValues = spacing =>
    Object.entries(paths(spacing)).reduce(
      (acc, [position, layout]) => ({
        ...acc,
        [position]: style?.[layout] ?? 8,
      }),
      {}
    );

  const handleUpdates = (spacing, position, value) =>
    handleChangeFor[paths(spacing)[position]](value);

  const marginProps = {
    margin: getStylesValues(SPACING.margin),
    onMarginUpdate: (position, value) =>
      handleUpdates(SPACING.margin, position, value),
  };

  return (
    <Accordion.Item value="alignment-spacing">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Close X', 'Spacing')}>
          Spacing
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Label>Alignment</Label>
            <RadioButtonGroup>
              <RadioButton
                onClick={() => handleChangeFor.horizontalAlignment('leading')}
                selected={valueFor.horizontalAlignment === 'leading'}
              >
                Leading
              </RadioButton>
              <RadioButton
                onClick={() => handleChangeFor.horizontalAlignment('trailing')}
                selected={
                  valueFor.horizontalAlignment === 'trailing' ||
                  !style?.horizontalAlignment
                }
              >
                Trailing
              </RadioButton>
            </RadioButtonGroup>
          </FieldSet>

          <FieldSet>
            {/* eslint-disable-next-line @appcues/jsx-props-no-spreading */}
            <MarginPaddingEditor {...marginProps} label="X" />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

AlignmentSpacing.propTypes = {
  id: PropTypes.string,
  spacingLabel: PropTypes.string,
  style: StyleShape,
  onChange: PropTypes.func,
};
