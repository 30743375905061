import { BLOCK, VIDEO } from 'lib/block';

const videoTemplate = ({ blockId, elementId }) => ({
  type: BLOCK,
  blockType: VIDEO,
  id: blockId,
  content: {
    type: VIDEO,
    id: elementId,
    embed: `<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/1mvrvh12ss?seo=false&videoFoam=true" title="Appcues Mobile - take control of mobile adoption" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div><script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>`,
    intrinsicSize: { width: 16, height: 9 },
  },
});

export default videoTemplate;
