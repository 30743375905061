import { useMemo } from 'react';
import isEmpty from 'lodash.isempty';

/**
 * Application modes
 *
 * @constant
 */
export const MODES = {
  new: 'new',
  edit: 'edit',
  empty: 'empty',
};

export default function useMode({ experience, selected }) {
  const mode = useMemo(() => {
    switch (true) {
      // Experience selected, but has no steps
      case !!experience && isEmpty(experience.steps):
        return MODES.new;

      // Experience selected and has steps
      case !!(experience && selected):
        return MODES.edit;

      // No experience selected
      default:
        return MODES.empty;
    }
  }, [experience, selected]);

  return mode;
}
