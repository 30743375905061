/**
 * Gets the shadow object from SDK which uses x and y
 * and converts it to the Builder format which uses angle and distance
 *
 * @param {object} shadow - Shadow object in SDK format
 * @returns {object} - Shadow object in Builder format
 */
export function convertShadowFromSDK(shadow) {
  const { x, y, radius, color } = shadow || {};
  // Get the distance in pixels using Pythagoras theorem
  const distance = Math.round(Math.sqrt(x ** 2 + y ** 2));

  // Get the angle in degrees using Math.atan2() and convert to degrees,
  // with 0 degrees at the 3 o'clock position
  const angle = Math.round((Math.atan2(y, x) * 180) / Math.PI + 90);

  return {
    color,
    radius: Number(radius),
    distance,
    angle,
  };
}

/**
 * Gets the shadow object from Builder UI which uses angle and distance
 * and converts it to the SDK format which uses x and y
 *
 * @param {object} shadow - Shadow object in Builder format
 * @returns {object} - Shadow object in SDK format
 */
export function convertShadowToSDK(shadow) {
  const { angle, radius, distance, color } = shadow || {};
  // Convert the angle to radians, subtracting 90 degrees to adjust to 0 at the 3 o'clock position
  const radians = ((angle - 90) * Math.PI) / 180;

  // Calculate the x and y values using trigonometry
  const x = Math.round(distance * Math.cos(radians));
  const y = Math.round(distance * Math.sin(radians));

  return {
    color,
    radius: Number(radius),
    x,
    y,
  };
}
