/* global APPCUES_ENV, DEBUG_LOGGER, AMPLITUDE_API_KEY */
import { createContext } from 'react';
import amplitude from 'amplitude-js';
import {
  selectAccountId,
  selectEmail,
  selectIsSpoofing,
  selectUserId,
} from 'ext/entities/account';
import { createSegmentAnalyticsClient } from 'ext/lib/segment-analytics';

const log =
  (prefix, client) =>
  (...args) =>
    // eslint-disable-next-line no-console
    console.log(`%c[${client}:${prefix}]`, 'color: #5c5cff', ...args);

export const createAnalyticsClient = (
  state,
  baseProperties = {},
  clientMode = false
) => {
  const amplitudeStub = {
    logEvent: log('logEvent', 'amplitude'),
  };

  const segmentClient = createSegmentAnalyticsClient(
    {
      userId: selectUserId(state),
      accountId: selectAccountId(state),
      isSpoofing: selectIsSpoofing(state),
      email: selectEmail(state),
    },
    baseProperties,
    clientMode
  );

  const shouldTrackEvent =
    !DEBUG_LOGGER &&
    typeof AMPLITUDE_API_KEY !== 'undefined' &&
    !selectIsSpoofing(state);

  if (shouldTrackEvent) {
    amplitude.getInstance().init(AMPLITUDE_API_KEY, selectUserId(state));
  }

  const amplitudeClient = shouldTrackEvent
    ? amplitude.getInstance()
    : amplitudeStub;

  // on client mode we need to group by userId
  const userId = selectUserId(state);
  const identifyProps = {
    userId,
    traits: {
      env: APPCUES_ENV,
      accountId: selectAccountId(state),
      email: selectEmail(state),
    },
  };

  segmentClient.identify({ ...identifyProps });

  return {
    page: () => {
      segmentClient.page();
    },

    track: (type, properties = {}) => {
      segmentClient.track(properties);

      amplitudeClient.logEvent(type, {
        env: APPCUES_ENV,
        accountId: selectAccountId(state),
        email: selectEmail(state),
        ...baseProperties,
        ...properties,
      });
    },
  };
};

export default createContext({});
