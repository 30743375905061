import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Accordion, FieldSet, FontIcon } from 'ext/components/ui';
import MarginPaddingEditor from 'ext/components/MarginPaddingEditor';
import { StyleShape } from 'entities/step-children';
import { TextAlignmentPicker } from 'components/AlignmentPicker';
import {
  selectUserPreferences,
  updateUserPreferences,
} from 'entities/user-preferences';
import { DIRECTIONS } from 'lib/user-preferences';
import useStyleSettings from './use-style-settings';
import useAccordionClick from './use-accordion-click';
import { Controls } from './styled';

const [LTR] = DIRECTIONS;

// Spacing types
const SPACING = {
  padding: 'padding',
  margin: 'margin',
};

// Spacing path mappings
const paths = (spacing, direction) => {
  const rightAlignment = direction === LTR ? 'right' : 'left';
  const leftAlignment = direction === LTR ? 'left' : 'right';

  return {
    top: `${spacing}Top`,
    bottom: `${spacing}Bottom`,
    [rightAlignment]: `${spacing}Trailing`,
    [leftAlignment]: `${spacing}Leading`,
  };
};

const AlignmentSpacing = ({
  hasTextAlignment = false,
  hasPadding = false,
  hasMargin = false,
  spacingLabel,
  style,
  direction,
  onChange,
}) => {
  const trackAccordion = useAccordionClick();

  const handleChange = useCallback(
    patch => {
      onChange({ style: patch });
    },
    [onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
  });

  const handleValues = spacing =>
    Object.entries(paths(spacing, direction)).reduce(
      (acc, [position, layout]) => ({
        ...acc,
        [position]: valueFor[layout],
      }),
      {}
    );

  const handleUpdates = (spacing, position, value) =>
    handleChangeFor[paths(spacing, direction)[position]](value);

  const marginProps = hasMargin && {
    margin: handleValues(SPACING.margin),
    onMarginUpdate: (position, value) =>
      handleUpdates(SPACING.margin, position, value),
  };

  const paddingProps = hasPadding && {
    padding: handleValues(SPACING.padding),
    onPaddingUpdate: (position, value) =>
      handleUpdates(SPACING.padding, position, value),
  };

  const title = hasTextAlignment ? 'Alignment & spacing' : 'Spacing';

  const handleTextAlignmentChange = useCallback(
    value => {
      onChange({
        style: { ...style, textAlignment: value, horizontalAlignment: value },
      });
    },
    [style, onChange]
  );

  return (
    <Accordion.Item value="alignment-spacing">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion(spacingLabel, title)}>
          {title}
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          {hasTextAlignment && (
            <FieldSet>
              <TextAlignmentPicker
                alignment={valueFor.textAlignment}
                onClick={handleTextAlignmentChange}
              />
            </FieldSet>
          )}

          <FieldSet>
            <MarginPaddingEditor
              /* eslint-disable @appcues/jsx-props-no-spreading */
              {...paddingProps}
              {...marginProps}
              /* eslint-enable @appcues/jsx-props-no-spreading */
              label={spacingLabel}
            />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
};

AlignmentSpacing.propTypes = {
  hasAlignment: PropTypes.bool,
  hasTextAlignment: PropTypes.bool,
  hasMargin: PropTypes.bool,
  hasPadding: PropTypes.bool,
  spacingLabel: PropTypes.string,
  style: StyleShape,
  direction: PropTypes.oneOf(DIRECTIONS),
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
  direction: selectUserPreferences(state).direction,
});

const mapDispatchToProps = {
  onUserPreferencesUpdate: updateUserPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlignmentSpacing);
