import {
  CANCELED,
  REQUEST_SENT,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
  NEW_ITEM,
  ITEM_CREATED,
  ITEM_UPDATED,
  ITEM_REMOVED,
  ITEM_FOCUSED,
  ITEM_INSERTED,
  ITEM_REPLACED,
  ITEM_FLUSHED,
  ITEM_PRUNED,
} from './action-types';
/**
 * NOTE: Most of the collection lifecycle creator here mirrors the
 *       implementation in Studio. However, there are a few notiable
 *       differences:
 *         - Single lifecycle creator for both entity and collections
 *         - No `read`, `reset`, or `drop` actions, but includes `focus`
 *         - `update` and `remove` behavior controlled by variadic parameters
 *           rather than `collection` meta field
 *
 *       Eventually we should have both implementations be the same but for
 *       backwards compatibility purposes, they may differ slightly.
 */

export const createPatternCreator =
  type =>
  (actionType, meta = {}) =>
  action =>
    action.type === actionType &&
    action.meta &&
    action.meta.type === type &&
    Object.keys(meta).every(key => meta[key] === action.meta[key]);

/**
 * Returns action creators tied to a specific data type
 *
 * @param {string} type - Entity data type
 * @return {Actions} Entity action creators
 */
export default function createLifecycleFor(type) {
  const createPatternFor = createPatternCreator(type);

  return {
    /**
     * UI Actions
     *
     * The following actions are dispatched from the UI e.g. components to
     * initiate sagas.
     */

    /**
     * Action describing that some action is being canceled. For example,
     * this can be bound to a e.g. cancel button to halt the progress of a
     * running saga.
     */
    cancel: () => ({
      type: CANCELED,
      meta: { type },
    }),

    /**
     * Action describing that the request to the server was sent. Typically used
     * to set requests status to e.g. fetching or pending. Note that this action
     * is typically not used anymore but still kept for historical and
     * potentially backwards compability purposes.
     */
    send: () => ({
      type: REQUEST_SENT,
      meta: { type },
    }),

    /**
     * Action describing that the process for creating a new item has started.
     * For example, this action can be used when entering the intermediary step
     * of creating an item such as a wizard or multi-step process, but before
     * the actual creation has been triggered. Most scenarios may not require
     * this action and can use `create` directly.
     */
    add: itemType => ({
      type: NEW_ITEM,
      payload: itemType,
      meta: { type },
    }),

    /**
     * Action describing the creation of an item. Commonly bound to elements
     * like save buttons to kick of sagas that will ask the API to create the
     * item given the provided properties.
     *
     * @method {POST}
     * @triggers {insert}
     */
    create: item => ({
      type: ITEM_CREATED,
      payload: item,
      meta: { type },
    }),

    /**
     * Action describing the update of an entity or item in a collection.
     * Similar to `create`, this action is commonly bound to elements like
     * update or save buttons to update properties of an existing item. Note
     * that the action is variadic to support both entities and collection. This
     * means that if an ID is provided as the first parameter and the updated
     * properties (or delta) is provided as the second, it is assumed this type
     * is a collection. Otherwise if only a single argument of delta is
     * provided, then it is assumed this type is an entity.
     *
     * @method {PUT|PATCH}
     * @triggers {replace|flush}
     */
    update: (...args) => {
      if (args.length === 1) {
        const [payload] = args;
        return {
          type: ITEM_UPDATED,
          payload,
          meta: { type },
        };
      }
      const [id, delta] = args;
      return {
        type: ITEM_UPDATED,
        payload: {
          id,
          delta,
        },
        meta: { type },
      };
    },

    /**
     * Action describing the removal of an item. Commonly bound to elements
     * performing destructive actions. Similar to `update`, providing an ID as
     * the first and only parameter indicates this type is a collection and the
     * omission indicates the type is an entity.
     *
     * @method {DELETE}
     * @triggers {prune}
     */
    remove: (id, parent) => ({
      type: ITEM_REMOVED,
      payload: { id },
      meta: { type, parent },
    }),

    /**
     * Action describing the selection of e.g. an entity. For example, the
     * `focus` action is commonly bound to the act of selecting a flow from a
     * dropdown or a step in a flow. This action can then be subscribed to
     * update the URL, fetch the entity, etc.
     */
    focus: (id, parent) => ({
      type: ITEM_FOCUSED,
      payload: parent ? { id, parent } : id,
      meta: { type },
    }),

    /**
     * Server/Saga Actions
     *
     * The following actions are dispatched within sagas typically before
     * interacting with a server or in response to results from a server.
     */

    /**
     * Action describing the return of an entity or collection from the server.
     * This action is typically used when fetching the entirety of an entity or
     * collection such as the initial load of a listing page or detail page.
     *
     * @method {GET}
     * @listens {sent}
     */
    resolve: data => ({
      type: REQUEST_RESOLVED,
      payload: data,
      meta: { type, partial: false },
    }),

    /**
     * Action describing the return of part of an entity or collection
     * from an API request. This action is very similar to `resolve` above
     * except that rather than receiving everything, it may only have received
     * e.g. updated values of an entity or another page of a paginated
     * collection.
     *
     * @method {GET}
     * @listens {sent}
     */
    append: data => ({
      type: REQUEST_RESOLVED,
      payload: data,
      meta: { type, partial: true },
    }),

    /**
     * Action describing that an item has been created by the server and inserted
     * into the store. Optionally takes a `parent` descriptor if the item has a
     * parent entity associated with it.
     *
     * @method {POST}
     * @listens {create}
     */
    insert: (item, parent) => ({
      type: ITEM_INSERTED,
      payload: item,
      meta: { type, parent },
    }),

    /**
     * Action describing that an item has been replaced by the server. Similar
     * to `insert` above except this is typically used to describe the
     * replacement of an existing entity or item in a collection while `insert`
     * is for the first time the item has been created.
     *
     * @method {PUT|PATCH}
     * @listens {update}
     */
    replace: item => ({
      type: ITEM_REPLACED,
      payload: item,
      meta: { type },
    }),

    /**
     * Action describing that an item has been updated by the server. Similar to
     * `replace` except this action is reserved for partial updates rather than
     * full updates or replacements. For example, if an entity or item in a
     * collection has some properties that were updated, the saga would respond
     * with `flush` versus if the entire entity or object was replaced.
     *
     * @method {PUT|PATCH}
     * @listens {update}
     */
    flush: (id, delta = {}) => ({
      type: ITEM_FLUSHED,
      payload: { id, delta },
      meta: { type },
    }),

    /**
     * Action describing that an item has been removed from the server and/or
     * the store.
     *
     * @method {DELETE}
     * @listens {remove}
     */
    prune: id => ({
      type: ITEM_PRUNED,
      payload: { id },
      meta: { type },
    }),

    /**
     * Action describing the failure of some execution within a saga. Typically
     * called in the catch block of a saga to indicate that an error has
     * occurred so the UI can handle it appropriately.
     */
    reject: err => ({
      type: REQUEST_REJECTED,
      payload: err,
      error: true,
      meta: { type },
    }),

    /**
     * a set of filter functions for use with all flavors of `take`
     * @param {Action} - action to test against pattern
     */
    patterns: {
      // UI Action Patterns
      cancel: createPatternFor(CANCELED),
      send: createPatternFor(REQUEST_SENT),
      add: createPatternFor(NEW_ITEM),
      create: createPatternFor(ITEM_CREATED),
      update: createPatternFor(ITEM_UPDATED),
      remove: createPatternFor(ITEM_REMOVED),
      focus: createPatternFor(ITEM_FOCUSED),

      // Server Action Patterns
      resolve: createPatternFor(REQUEST_RESOLVED, { partial: false }),
      append: createPatternFor(REQUEST_RESOLVED, { partial: true }),
      insert: createPatternFor(ITEM_INSERTED),
      replace: createPatternFor(ITEM_REPLACED),
      flush: createPatternFor(ITEM_FLUSHED),
      prune: createPatternFor(ITEM_PRUNED),
      reject: createPatternFor(REQUEST_REJECTED),
    },
  };
}
