import { IOS } from 'lib/platform';

export const handleInsets = metadata => {
  if (metadata?.insets && metadata?.osName !== IOS) {
    return metadata.insets;
  }

  return {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
};
