import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectAccountId } from 'ext/entities/account';
import { StatusMessage, MoreInfo } from 'ext/components/ui';
import { despoof } from 'entities/auth';

export function SpoofingBanner({ accountId, onDespoof }) {
  return (
    <StatusMessage aria-label="spoofing">
      Warning: you are currently spoofing account {accountId}.{' '}
      <MoreInfo onClick={onDespoof}>Stop spoofing</MoreInfo>
    </StatusMessage>
  );
}

SpoofingBanner.propTypes = {
  accountId: PropTypes.string,
  onDespoof: PropTypes.func,
};

const mapStateToProps = state => ({
  accountId: selectAccountId(state),
});

const mapDispatchToProps = {
  onDespoof: () => despoof(),
};

export default connect(mapStateToProps, mapDispatchToProps)(SpoofingBanner);
