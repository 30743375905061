import { selectExperience } from 'entities/experiences';
import { selectStepGroup, TYPE as STEP_GROUP } from 'entities/step-groups';
import { TYPE as STEP_CHILD } from 'entities/step-children';

/**
 * Find neighboring (previous and next) step IDs of deleted entity
 *
 * @param {object} options
 * @param {string[]} orderedIds - Ordered list of step IDs for entity
 * @param {Collection} entities - Entities collection
 * @param {number} index - Index of deleted entity
 * @return {[(number|null),(number|null)]} IDs of Previous and next entity
 */
const findNeighbors = (siblings, target) => {
  const pos = siblings.indexOf(target);
  return [siblings[pos - 1] || null, siblings[pos + 1] || null];
};

/**
 * Handler for when step group is removed
 *
 * NOTE: There is an implicit assumption here that this handler would only be
 *       called when the last step child in a group is removed. Otherwise the
 *       remove step child handler below would handle the transaction through
 *       the `Settings` component dispatch the appropriate action.
 *
 * @param {Action} action - Remove action
 * @param {object} allState - Full redux state provided by allstate
 * @return {Selected} Next selected state
 */
function onGroupRemove(action, allState) {
  const {
    payload: { id },
  } = action;

  const { steps } = selectExperience(allState);

  // Reset selection if no other groups exist
  if (steps.length === 1) {
    return null;
  }

  const [previous, next] = findNeighbors(steps, id);

  // If there are groups prior to the one deleted, selected the last step (or
  // entirety if action group) of the previous group
  if (previous) {
    const { children = [] } = selectStepGroup(allState, previous);
    return {
      stepGroup: previous,
      stepChild: children[children.length - 1],
    };
  }

  // Otherwise select the first step (or entirety if action group) of next group
  const { children: [step] = [] } = selectStepGroup(allState, next);

  return {
    stepGroup: next,
    stepChild: step,
  };
}

/**
 * Handler for when step child is removed
 *
 * NOTE: There is an implicit assumption here that this handler would only be
 *       called if the step child being removed is NOT the last in its group. In
 *       the scenario that the last step child in a group is removed, the remove
 *       step group handler would handle the case instead.
 *
 * @param {Action} action - Remove action
 * @param {object} allState - Full redux allState provided by allstate
 * @return {Selected} Next selected state
 */
function onChildRemove(action, allState) {
  const {
    payload: { id },
    meta: { parent },
  } = action;

  const { children } = selectStepGroup(allState, parent);

  const [previous, next] = findNeighbors(children, id);

  // Select previous step if one exists, otherwise select the next step
  return {
    stepGroup: parent,
    stepChild: previous || next,
  };
}

export default function onRemove(state, action, allState) {
  switch (action.meta.type) {
    case STEP_GROUP:
      return onGroupRemove(action, allState);
    case STEP_CHILD:
      return onChildRemove(action, allState);
    default:
      return state;
  }
}
