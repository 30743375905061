import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import {
  selectExperience,
  patterns as patternsExperience,
} from 'entities/experiences';
import {
  selectStepGroup,
  patterns as patternsStepGroup,
} from 'entities/step-groups';
import { patterns as patternsStepChildren } from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import { prune, patterns, send, resolve, reject } from './actions';
import { selectScreen } from './reducer';

export function* fetchScreen() {
  const logger = yield getContext('logger');

  try {
    const { appId } = yield select(selectExperience);
    const selected = yield select(selectSelected);
    const { stepGroup: stepGroupId } = selected || {};
    const stepGroup = yield select(selectStepGroup, stepGroupId);
    const { editor } = stepGroup || {};
    const { id: screenId } = editor?.screen || {};
    const selectedScreen = yield select(selectScreen);

    // If there's no stepGroupId or screenId, we
    // should delete the screen on the current state
    if (!stepGroupId || !screenId) {
      yield put(resolve(null));
      return;
    }

    if (screenId && selectedScreen?.id !== screenId) {
      const api = yield getContext('api');
      // start loading
      yield put(send());
      const response = yield call(api.getScreen, appId, screenId);
      yield put(resolve(response));
    }
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
  }
}

function* updateScreen({ payload }) {
  const { id: screenId, delta } = payload;
  const { preserveEntity, ...rest } = delta;
  const logger = yield getContext('logger');
  try {
    const { appId } = yield select(selectExperience);
    const api = yield getContext('api');
    const response = yield call(api.updateScreen, appId, screenId, rest);

    if (preserveEntity) return;
    yield put(resolve(response));
  } catch (error) {
    logger.error(error);
  }
}

function* removeScreen({ payload }) {
  const { id: screenId } = payload;
  const logger = yield getContext('logger');
  try {
    const { appId } = yield select(selectExperience);
    const api = yield getContext('api');

    yield call(api.removeScreen, appId, screenId);
    yield put(prune(screenId));
  } catch (error) {
    logger.error(error);
  }
}

export default function* screenSaga() {
  yield takeEvery(
    [
      patternsExperience.insert,
      patternsStepGroup.remove,
      patternsStepGroup.insert,
      patternsStepChildren.focus,
    ],
    fetchScreen
  );
  yield takeEvery(patterns.update, updateScreen);
  yield takeEvery(patterns.remove, removeScreen);
}
