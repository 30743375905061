import React from 'react';
import PropTypes from 'prop-types';
import { Divider as StyledDivider } from './styled';

const Divider = ({ size = 'small' }) => <StyledDivider $size={size} />;

Divider.propTypes = {
  size: PropTypes.oneOf(['x-small', 'small', 'regular']),
};

export default Divider;
