import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';

export const Wrapper = styled.div`
  position: absolute;
  inset-inline-end: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  width: 60px;
  display: flex;
  justify-content: end;
  align-items: stretch;
  pointer-events: none;

  ${({ newRow }) =>
    newRow &&
    `
    bottom: auto;
    left: 0;
    height: 30px;
    width: 100%;
  `}

  ${({ isLast }) =>
    isLast &&
    `
    top: auto; 
    bottom: 0; 
    align-items: end;
  `}
`;

export const Line = styled.div`
  display: flex;
  width: 4px;
  justify-content: center;
  align-items: stretch;
  background: var(--color-blue-500);
  opacity: ${({ isOver }) => (isOver ? 1 : 0)};

  ${({ newRow }) =>
    newRow &&
    `
    height: 4px;
    width: 100%;
  `}
`;

const DropZone = ({
  id,
  type,
  onDrop,
  isLastInRow = false,
  newRow = false,
  isLast,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: type,
    drop: item => {
      const draggingId = item.id;
      const droppingId = id;

      // Don't replace items with themselves
      if (draggingId === droppingId) {
        return;
      }

      onDrop({ draggingId, droppingId, isLastInRow, newRow, isLast });
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <Wrapper ref={drop} newRow={newRow} isLast={isLast}>
      <Line isOver={isOver} newRow={newRow} />
    </Wrapper>
  );
};

DropZone.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onDrop: PropTypes.func,
  isLastInRow: PropTypes.bool,
  newRow: PropTypes.bool,
  isLast: PropTypes.bool,
};

export default DropZone;
