import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontIcon, Button } from 'ext/components/ui';

const CustomFontButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 7px 12px;

  ${FontIcon} {
    margin-right: 7px;
  }

  ${Button} {
    background: var(--white);
    border: 1px solid var(--sharkbait-ooh-la-la);
    color: var(--pleather);

    &:hover {
      color: var(--pleather);
    }
  }
`;

const CustomFontButton = ({ onClick }) => (
  <CustomFontButtonWrapper>
    <Button onClick={onClick}>
      <FontIcon icon="plus" />
      Add Custom Font
    </Button>
  </CustomFontButtonWrapper>
);

CustomFontButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CustomFontButton;
