import {
  call,
  getContext,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { INITIALIZE } from 'ext/root/root-actions';
import { UPDATE_TRANSLATION, AUTO_TRANSLATE } from 'ext/entities/locales';
import { selectSelected } from 'entities/selected';
import { fetchScreen, fetchScreensSummary } from 'entities/screens';
import {
  selectStepChildren,
  update as updateStepChild,
  focus as focusStepChild,
} from 'entities/step-children';
import { selectStepGroup, selectStepGroups } from 'entities/step-groups';
import {
  UNDO,
  REDO,
  selectCanonicalExperience,
  selectHistoryLog,
  updateSelected,
} from 'entities/history-log';
import {
  MODES,
  createTemplateStepChild,
  createTemplateStepGroup,
  updateStepGroup,
  getStepToFocus,
} from 'lib/templates';
import { selectStepGroupFirstChild } from 'lib/selectors';
import {
  hasPreviousAction,
  transformPreviousActionIntoNext,
} from 'lib/actions';
import {
  generateXLIFF,
  getLocaleVariations,
  addTranslations,
} from 'lib/localization';
import { selectExperience } from './reducer';
import { transform, revert } from './schema';
import {
  insert,
  send,
  reject,
  resolve,
  flush,
  movePatterns,
  patterns,
  STEPS_UPDATED,
  TEMPLATE_APPLIED,
} from './actions';
import { removeParentIds, updateStepsContent } from './mappers';

function* fetchExperience(id) {
  const logger = yield getContext('logger');
  try {
    const { getExperience } = yield getContext('api');
    const { experience } = yield call(getExperience, id);

    yield put(resolve(transform(experience)));
    yield call(fetchScreensSummary);
    yield call(fetchScreen);
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
  }
}

function* createExperience({ payload }) {
  const logger = yield getContext('logger');
  try {
    const api = yield getContext('api');

    const { experience } = yield call(api.createExperience, payload);
    yield put(resolve(transform(experience)));
  } catch (error) {
    logger.error(error);
  }
}

function* flushExperience(action) {
  const logger = yield getContext('logger');
  try {
    const {
      payload: { id, ...delta },
    } = action;
    const api = yield getContext('api');

    yield call(api.updateExperience, id, delta);
    yield put(flush(id));
  } catch (error) {
    logger.error(error);
  }
}

/**
 * A method to replace the entire experience with the new values
 * Can be used for undo/redo and for updating translations
 *
 * For Undo/Redo, the payload is not passed in,
 * so we get the canonical experience from the history log
 *
 * For translation, containsLocalizedBlocks is passed within the payload
 * to indicate whether the experience contains localized blocks
 *
 * @param {object} payload - This is optional and is used for translating
 * @param {string} type - Defines the action type that called this method
 */
function* replaceEntireExperience({ payload, type }) {
  const logger = yield getContext('logger');
  try {
    const isLocalization = type === UPDATE_TRANSLATION;
    const updatedExperience = isLocalization
      ? payload
      : yield select(selectCanonicalExperience);

    if (isLocalization) {
      // start loading
      yield put(send());
    }

    const { id, steps, containsLocalizedBlocks = false } = updatedExperience;

    const updatedSteps = removeParentIds(steps);

    const api = yield getContext('api');
    const updated = yield call(
      api.updateExperience,
      id,
      {
        steps: updatedSteps,
        containsLocalizedBlocks,
      },
      true
    );

    // If we are updating translations, we don't need to update the selected
    if (!isLocalization) {
      const {
        current: { selected },
      } = yield select(selectHistoryLog);

      yield put(updateSelected(selected));
    }

    yield put(insert({ ...transform(updated), isLocalization }));

    if (isLocalization) {
      // end loading
      yield put(resolve({ ...transform(updated), isLocalization }));
    }
  } catch (error) {
    logger.error(error);
  }
}

function* updateSteps({ payload }) {
  const logger = yield getContext('logger');
  try {
    const experience = yield select(selectExperience);
    const stepChildren = yield select(selectStepChildren);
    const stepGroups = yield select(selectStepGroups);
    const { stepGroup: stepGroupId } = yield select(selectSelected);
    const stepGroup = yield select(selectStepGroup, stepGroupId);
    const api = yield getContext('api');

    const stepGroupsUpdated = updateStepsContent({
      contents: stepGroups,
      condition: contentId => stepGroup.id === contentId,
      delta: payload.stepGroup,
    });

    const stepChildrenUpdated = updateStepsContent({
      contents: stepChildren,
      condition: contentId => stepGroup.children.includes(contentId),
      delta: payload.stepChildren,
    });

    const { steps: updatedSteps } = revert(experience, {
      stepGroups: stepGroupsUpdated,
      stepChildren: stepChildrenUpdated,
    });

    const steps = removeParentIds(updatedSteps);

    const updated = yield call(
      api.updateExperience,
      experience.id,
      { steps },
      true
    );

    yield put(insert({ ...transform(updated), isStepsUpdate: true }));
  } catch (error) {
    logger.error(error);
  }
}

function* applyTemplate({ payload }) {
  const { template, mode, presentation, skipTraits } = payload;
  const logger = yield getContext('logger');

  try {
    const experience = yield select(selectCanonicalExperience);
    const selected = yield select(selectSelected);
    const api = yield getContext('api');
    const stepChildren = yield select(selectStepChildren);

    const currentStepContent = stepChildren[selected?.stepChild]?.content;
    const locales = getLocaleVariations(currentStepContent);

    const templateStepChild = createTemplateStepChild({
      template,
      presentation,
      locales,
    });

    const templateStepGroup = createTemplateStepGroup({
      template,
      presentation,
      skipTraits,
      templateStepChild,
    });

    const updatedSteps = [
      ...experience.steps.map(stepGroup =>
        updateStepGroup({
          stepGroup,
          selected,
          mode,
          templateStepChild,
          template,
          presentation,
          skipTraits,
        })
      ),
      ...(mode === MODES.ADD_GROUP ? [templateStepGroup] : []),
    ];

    const steps = removeParentIds(updatedSteps);
    const updated = yield call(
      api.updateExperience,
      experience.id,
      { steps },
      true
    );

    const transformed = transform(updated);
    const { id, parentId } = getStepToFocus({
      experience: transformed,
      mode,
      selected,
    });

    yield put(insert({ ...transformed, isStepsUpdate: true }));
    yield put(focusStepChild(id, parentId));
  } catch (error) {
    logger.error(error);
  }
}
function* moveStepGroup({ payload }) {
  const logger = yield getContext('logger');

  try {
    const { child: stepGroupId, to: toIndex, targetStepId, action } = payload;
    const data = { targetStepId, action };
    const api = yield getContext('api');
    const { id: experienceId } = yield select(selectExperience);
    const { id: stepChildId, actions } = yield select(
      selectStepGroupFirstChild,
      stepGroupId
    );

    // If a step is moved to the first position and it has a previous action,
    // we need to transform it into a next action to trigger the following steps.
    if (toIndex === 0 && hasPreviousAction(actions)) {
      const updatedActions = transformPreviousActionIntoNext(actions);
      const delta = { actions: updatedActions };
      yield put(updateStepChild({ id: stepChildId, delta }));
    }

    yield call(api.moveStep, experienceId, stepGroupId, data);
    yield put(flush(experienceId));
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
  }
}

function* autoTranslateExperience({ payload }) {
  const logger = yield getContext('logger');

  try {
    const { canonicalExperience, localeValue, localeId } = payload;
    const api = yield getContext('api');
    const xliffContents = yield generateXLIFF(
      canonicalExperience,
      localeId,
      localeValue
    );

    // start loading
    yield put(send());

    const updatedXLIFF = yield call(api.autoTranslate, xliffContents);
    const updatedExperience = yield addTranslations(
      updatedXLIFF,
      canonicalExperience,
      localeId
    );

    const updatedSteps = removeParentIds(updatedExperience.steps);

    const updated = yield call(
      api.updateExperience,
      canonicalExperience.id,
      { steps: updatedSteps },
      true
    );

    yield put(insert({ ...transform(updated), isLocalization: true }));

    // end loading
    yield put(resolve({ ...transform(updated), isLocalization: true }));
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
  }
}

/**
 * Handles fetching an experience via user selection or predetermined by the
 * route, e.g. 'Open in builder'
 */
function* initialize(action) {
  if (patterns.focus(action)) {
    yield call(fetchExperience, action.payload);
  }
  if (action.type === INITIALIZE) {
    const selected = yield select(selectSelected);
    const id = selected?.experience;
    if (id) {
      yield call(fetchExperience, id);
    }
  }
}

export default function* experiencesSaga() {
  yield takeLatest([patterns.focus, INITIALIZE], initialize);
  yield takeEvery(patterns.create, createExperience);
  yield takeEvery(patterns.update, flushExperience);
  yield takeEvery(movePatterns.move, moveStepGroup);
  yield takeEvery(STEPS_UPDATED, updateSteps);
  yield takeEvery(TEMPLATE_APPLIED, applyTemplate);
  yield takeEvery([UNDO, REDO, UPDATE_TRANSLATION], replaceEntireExperience);
  yield takeEvery(AUTO_TRANSLATE, autoTranslateExperience);
}
