export const CANCELED = '@collection/CANCELED';

export const REQUEST_SENT = '@collection/REQUEST_SENT';
export const REQUEST_RESOLVED = '@collection/REQUEST_RESOLVED';
export const REQUEST_REJECTED = '@collection/REQUEST_REJECTED';

export const NEW_ITEM = '@collection/NEW_ITEM';
export const ITEM_CREATED = '@collection/ITEM_CREATED';
export const ITEM_UPDATED = '@collection/ITEM_UPDATED';
export const ITEM_REMOVED = '@collection/ITEM_REMOVED';
export const ITEM_FOCUSED = '@collection/ITEM_FOCUSED';

export const ITEM_INSERTED = '@collection/ITEM_INSERTED';
export const ITEM_REPLACED = '@collection/ITEM_REPLACED';
export const ITEM_FLUSHED = '@collection/ITEM_FLUSHED';
export const ITEM_PRUNED = '@collection/ITEM_PRUNED';

export const CHILD_MOVED = '@collection/CHILD_MOVED';
