import React, { useRef, useState, useEffect, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const theme = `
  --range-slider-fill: var(--blurple);
  --range-slider-stroke: var(--myst);
  --range-slider-label: var(--white);

  --range-input-border: none;
  --range-input-background: var(--noreaster);

  [data-theme="light"] & {
    --range-slider-label: var(--pleather);
    --range-slider-stroke: var(--dirty-water);

    --range-input-border: 1px solid var(--sharkbait-ooh-la-la);
    --range-input-background: var(--white);
  }
`;

// ref:
// https://css-tricks.com/sliding-nightmare-understanding-range-input/
const Range = styled.input.attrs(() => ({
  type: 'range',
}))`
  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  margin: 11px 0 6px 0;
  padding: 0;
  height: 10px;
  background: transparent;

  &::-webkit-slider-runnable-track {
    box-sizing: border-box;
    border: none;
    width: 100%;
    height: 4px;
    background: var(--range-slider-stroke);
    background: linear-gradient(
        var(--range-slider-fill),
        var(--range-slider-fill)
      )
      0 / var(--current-percentage) 100% no-repeat var(--range-slider-stroke);
    border-radius: 10px;
  }

  &::-webkit-slider-thumb {
    margin-top: -5px;
    box-sizing: border-box;
    border: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: var(--range-slider-fill);
    border: 2px solid var(--white);
    box-shadow: 0px 2px 6px 0px rgba(71, 88, 114, 0.1);
  }
`;

const Container = styled.div`
  ${theme}

  display: flex;
  align-items: flex-end;
`;

const Input = styled.input`
  background: var(--range-input-background);
  border-radius: 6px;
  border: var(--range-input-border);
  height: 40px;
  margin-left: 12px;
  text-align: center;
  width: 68px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: var(--bold);
  font-size: ${props => `var(--${props.size})`};
  color: var(--range-slider-label);
`;

const RangeInput = ({
  className,
  min,
  max,
  value,
  onChange,
  label,
  size = 'regular',
}) => {
  const id = useRef(`range-${uuid()}`);
  const calculateCurrentPercentage = useCallback(
    currentValue =>
      // Prevent percentage from being calculated outside the minimum interval
      // showing the slider properly
      currentValue < min ? 0 : ((currentValue - min) / (max - min)) * 100,
    [min, max]
  );

  const [state, setState] = useState({
    value,
    currentPercentage: calculateCurrentPercentage(value),
  });

  useEffect(() => {
    setState({
      value,
      currentPercentage: calculateCurrentPercentage(value),
    });
  }, [value, calculateCurrentPercentage]);

  const handleOnChange = event => {
    const { value: targetValue } = event.target;
    const newValue = targetValue || 0;

    setState({
      value: newValue,
      currentPercentage: calculateCurrentPercentage(newValue),
    });

    onChange(newValue, event);
  };

  return (
    <Container className={className}>
      <Label htmlFor={id.current} size={size}>
        {label}
        <Range
          id={id.current}
          onChange={handleOnChange}
          style={{ '--current-percentage': `${state.currentPercentage}%` }}
          value={state.value}
          min={min}
          max={max}
        />
      </Label>

      <Input
        aria-label={`${label} number input`}
        type="number"
        value={state.value}
        min={min}
        max={max}
        onChange={handleOnChange}
      />
    </Container>
  );
};

RangeInput.propTypes = {
  className: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  size: PropTypes.oneOf(['regular', 'small', 'x-small']),
};

export default styled(RangeInput)``;
