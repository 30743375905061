import styled from 'styled-components';
import { easing } from 'ext/lib/style';
import FontIcon from './FontIcon';

const ToolBarToggle = styled.div`
  align-items: center;
  background-color: rgba(36, 42, 53, 0.6);

  color: var(--text-color);
  cursor: pointer;
  display: flex;
  font-size: var(--xx-small);
  font-weight: var(--bold);
  height: 20px;
  justify-content: center;
  position: absolute;
  text-transform: uppercase;

  transition: ${easing('background-color', 'color')};
  width: 54px;

  &:hover {
    background-color: var(--background-dark);
    color: var(--white);
  }

  ${FontIcon} {
    margin-left: 5px;
  }
`;

export default ToolBarToggle;
