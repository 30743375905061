import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  FieldSet,
  FontIcon,
  Label,
  Select,
} from 'ext/components/ui';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { Switch } from '@appcues/sonar';
import { EditorShape } from 'entities/step-groups';
import {
  targetElementTemplate,
  targetRectangleTemplate,
  backdropRectangleTemplate,
  backdropCircleTemplate,
} from 'entities/step-children';
import { Shape as TraitsShape } from 'entities/trait';
import { PRESENTATIONS, ANCHORED } from 'lib/presentation';
import { PLATFORMS, ALLOWED_ANCHORED } from 'lib/platform';
import {
  TOOLTIP,
  SKIPPABLE_OPTIONS,
  TARGET_ELEMENT,
  TARGET_RECTANGLE,
  updateTraits,
} from 'lib/trait';
import { Controls, useAccordionClick } from 'components/SideBarSettings/Shared';

const PRESENTATION_OPTIONS = PRESENTATIONS[TOOLTIP];

export default function Details({
  traits,
  editor,
  platform,
  onChange,
  onStepsUpdate,
}) {
  const { track } = useAnalytics();
  const trackAccordion = useAccordionClick();
  const { presentation, skippable } = editor ?? {};
  const { ignoreBackdropTap = false } = skippable ?? {};

  const handleTrack = useCallback(
    name => {
      track('Mobile Builder interaction', {
        name,
        component: 'TooltipSettings - Details',
      });
    },
    [track]
  );

  const handleTypeUpdate = useCallback(
    ({ value }) => {
      if (value === presentation) return;

      handleTrack(`Changed type to ${value}`);

      const isAnchored = value === ANCHORED;
      const traitToDelete = isAnchored ? TARGET_RECTANGLE : TARGET_ELEMENT;
      const traitToAdd = isAnchored
        ? [targetElementTemplate, backdropRectangleTemplate]
        : [targetRectangleTemplate, backdropCircleTemplate];

      const updatedTraitsList = updateTraits({
        traits,
        updatedTraits: traitToAdd,
        traitTypeToDelete: traitToDelete,
      });

      onStepsUpdate({
        stepChildren: { traits: updatedTraitsList },
        stepGroup: { editor: { ...editor, presentation: value } },
      });
    },
    [editor, handleTrack, onStepsUpdate, presentation, traits]
  );

  const handleSkippableUpdate = useCallback(
    value => {
      const { MINIMAL, HIDDEN } = SKIPPABLE_OPTIONS;
      const buttonAppearance = value ? MINIMAL : HIDDEN;
      handleTrack(`${value ? 'Added' : 'Removed'} Tooltip close`);
      onChange({ skippable: { ...skippable, buttonAppearance } });
    },
    [onChange, skippable, handleTrack]
  );

  const handleTapOutUpdate = value => {
    handleTrack(`${value ? 'Added' : 'Removed'} Tap out to dismiss`);
    onChange({ skippable: { ...skippable, ignoreBackdropTap: !value } });
  };

  const options = useMemo(() => {
    const entries = Object.entries(PRESENTATION_OPTIONS);
    return entries.map(([value, label]) => ({ value, label }));
  }, []);

  return (
    <Accordion.Item value="details">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Tooltip', 'Details')}>
          Details
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          {ALLOWED_ANCHORED.includes(platform) && (
            <FieldSet>
              <Label>Type</Label>
              <Select
                onChange={handleTypeUpdate}
                options={options}
                portal
                value={options.find(({ value }) => presentation === value)}
              />
            </FieldSet>
          )}

          <FieldSet>
            <Switch
              id="close-x"
              checked={skippable?.buttonAppearance !== SKIPPABLE_OPTIONS.HIDDEN}
              onCheckedChange={handleSkippableUpdate}
              fullWidth
            >
              <Switch.Label htmlFor="close-x">Close X</Switch.Label>
            </Switch>
          </FieldSet>

          <FieldSet>
            <Switch
              id="tap-out"
              checked={!ignoreBackdropTap}
              onCheckedChange={handleTapOutUpdate}
              fullWidth
            >
              <Switch.Label htmlFor="tap-out">Tap out to dismiss</Switch.Label>
            </Switch>
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Details.propTypes = {
  traits: TraitsShape,
  editor: EditorShape,
  platform: PropTypes.oneOf(PLATFORMS),
  onChange: PropTypes.func,
  onStepsUpdate: PropTypes.func,
};
