import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from '@appcues/sonar';

const Label = styled.p`
  color: var(--cp-color-type-label-color);
  font-size: var(--regular);
  margin: 4px 0 0;
  text-align: center;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  // hack to make input from sonar with a smaller padding than its default
  width: 100%;
  > div {
    padding: var(--spacing-small);
  }

  ${Input} {
    width: 100%;
  }
`;

export const EditableInput = ({ value, label, onChange }) => {
  const [inputValue, setInputValue] = useState(String(value).toUpperCase());
  const $input = useRef();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleClick = () => {
    $input.current.focus();
  };

  const handleChange = e => {
    setInputValue(e.target.value);
    onChange(e.target.value, e);
  };

  return (
    <Wrapper onClick={handleClick}>
      <Input
        role="textbox"
        aria-label={label}
        ref={$input}
        value={inputValue}
        onChange={handleChange}
        spellCheck="false"
        size="small"
      />
      <Label>{label}</Label>
    </Wrapper>
  );
};

EditableInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default EditableInput;
