import React from 'react';
import styled from 'styled-components';
import BlockDivider from 'components/BlockDivider';

export const EmptyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160px;
  padding-top: 60px;

  [data-apc-block='block-divider'] {
    margin-top: -35px;
    opacity: 1;
  }
`;

export const EmptyContentPlaceholder = styled.p``;

const EmptyContent = () => (
  <EmptyContentWrapper>
    <BlockDivider isEmpty />
    <EmptyContentPlaceholder>Click to add some content</EmptyContentPlaceholder>
  </EmptyContentWrapper>
);

export default EmptyContent;
