import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FieldSet, DebouncedInput } from 'ext/components/ui';
import { Error } from 'components/Form';

export const Input = styled(DebouncedInput)`
  && {
    ${({ isInvalidUrl }) =>
      isInvalidUrl && 'border-color: var(--border-input-error);'}
  }
`;

export default function InputUrl({ url, onChange, isValidUrl }) {
  const isInvalidUrl = url && !isValidUrl;

  return (
    <FieldSet>
      <Input
        id="go-to-url-input"
        defaultValue={url || ''}
        placeholder="e.g. myapp://account/settings"
        onChange={onChange}
        type="text"
        wait={1000}
        isInvalidUrl={isInvalidUrl}
      />
      {isInvalidUrl && <Error>Please enter a valid URL</Error>}
    </FieldSet>
  );
}

InputUrl.propTypes = {
  url: PropTypes.string,
  onChange: PropTypes.func,
  isValidUrl: PropTypes.bool,
};
