import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, FieldSet, FontIcon } from 'ext/components/ui';
import { Switch } from '@appcues/sonar';
import { EditorShape, backdropTemplate } from 'entities/step-groups';
import { TRAIT_LABELS } from 'lib/trait';
import { THEMES } from 'lib/user-preferences';
import ColorInput from 'components/ColorInput';
import useAccordionClick from './use-accordion-click';
import useStyleSettings from './use-style-settings';
import { Controls, GroupedField } from './styled';

export default function Backdrop({ editor, theme, onChange }) {
  const trackAccordion = useAccordionClick();
  const { backdrop, type } = editor || {};
  const traitLabel = TRAIT_LABELS[type];

  const handleBackdropShowChange = checked => {
    if (checked) {
      onChange({ backdrop: backdropTemplate.config });
    } else {
      onChange(null, 'backdrop');
    }
  };

  const handleChange = patch => {
    onChange({ backdrop: patch });
  };

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style: { ...backdrop },
    theme,
  });

  return (
    <Accordion.Item value="backdrop">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion(traitLabel, 'Backdrop')}
        >
          Backdrop
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Switch
              id="show"
              checked={!!backdrop}
              onCheckedChange={handleBackdropShowChange}
              fullWidth
            >
              <Switch.Label htmlFor="show">Show</Switch.Label>
            </Switch>
          </FieldSet>

          {backdrop && (
            <FieldSet>
              <GroupedField>
                <ColorInput
                  id="backdrop-color"
                  label="Backdrop color"
                  color={valueFor.backgroundColor}
                  onChange={handleChangeFor.backgroundColor}
                />
              </GroupedField>
            </FieldSet>
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Backdrop.propTypes = {
  editor: EditorShape,
  theme: PropTypes.oneOf(THEMES),
  onChange: PropTypes.func,
};
