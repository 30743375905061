/**
 * Remove parentId property from steps (groups or children)
 * since the API does not accept it when updating the entire experience
 *
 * @param {object} steps - Steps (groups or children)
 * @return {object} Steps without parentId property
 */
export function removeParentIds(steps) {
  // If it's an array, apply the function to each element
  if (Array.isArray(steps)) {
    return steps.map(step => removeParentIds(step));
  }

  // If it's an object, remove the parentId property and process its children
  if (typeof steps === 'object') {
    const { parentId, index, ...rest } = steps;
    if (rest.children) {
      rest.children = removeParentIds(rest.children);
    }
    return rest;
  }

  return steps;
}

/**
 * Update steps (groups or children) based on condition
 *
 * @param {object} contents - Steps (groups or children)
 * @param {object} condition - Condition to be evaluated
 * @param {object} delta - Portion of the object to be updated
 * @return {object} Steps elements updated based on condition
 */
export const updateStepsContent = ({ contents, condition, delta }) =>
  Object.fromEntries(
    Object.entries(contents).map(([contentId, content]) => [
      contentId,
      {
        ...content,
        ...(condition(contentId) ? delta : {}),
      },
    ])
  );
