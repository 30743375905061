import { css, keyframes } from 'styled-components';
import { STANDARD } from 'lib/presentation';
import { GAPS } from './variables';

/**
 * Handle slideout position based on the orientation and alignments chosen
 * @param {string} orientation - Device orientation (portrait or landscape)
 * @param {string} horizontalAlignment - Horizontal alignment (leading, center or trailing)
 * @param {string} verticalAlignment - Vertical alignment (top, center or bottom)
 * @returns {css} - Returns the css template literal with the position
 */
const handleSlideoutPosition = ({
  orientation,
  horizontalAlignment,
  verticalAlignment,
}) => css`
  position: absolute;

  /**
  * When applying slideout animation in centered modal cases,
  * center is not a valid value for vertical alignment.
  * To align the modal in this case we must use bottom and translation property combined.
  * It is important to note that the bottom value must also be a valid number
  * as it is used in the slideout animation.
  * For the other cases (top and bottom) we bypass the value to be used as property
   */
  ${verticalAlignment === 'center'
    ? `
      bottom: ${GAPS[STANDARD][orientation].center};
      transform: translateY(50%);
    `
    : `${verticalAlignment}: ${GAPS[STANDARD][orientation].vertical};`}

  ${horizontalAlignment === 'leading' &&
  `left: ${GAPS[STANDARD][orientation].horizontal};`}

  ${horizontalAlignment === 'trailing' &&
  `right: ${GAPS[STANDARD][orientation].horizontal};`}
`;

/**
 * Handle the properly slideout animation based on the modal position
 * @param {string} horizontalAlignment - Horizontal alignment (leading, center or trailing)
 * @param {string} verticalAlignment - Vertical alignment (top, center or bottom)
 * @returns {css} - Returns the css template literal with the animation
 */
const handleSlideoutAnimation = (horizontalAlignment, verticalAlignment) => {
  let alignment = 'bottom';
  if (horizontalAlignment === 'leading') alignment = 'left';
  if (horizontalAlignment === 'trailing') alignment = 'right';
  if (horizontalAlignment === 'center') alignment = verticalAlignment;

  const animation = keyframes`
      from {
        ${alignment === 'center' ? 'bottom: 25%;' : `${alignment}: -75%;`}
        ${alignment === 'center' && 'opacity: 0;'}
      }
      ,
      to {
        transform: translate(0);
        ${alignment === 'center' && 'opacity: 1;'}
      }
  `;

  return css`
    animation: ${animation} 1s ease-in-out;
  `;
};

/**
 * Handle with slideout styles such as position and animation
 * @param {string} orientation - Device orientation (portrait or landscape)
 * @param {string} horizontalAlignment - Horizontal alignment (leading, center or trailing)
 * @param {string} verticalAlignment - Vertical alignment (top, center or bottom)
 * @returns {css} - Returns the css template literal with the styles
 */
export const handleSlideoutStyles = ({
  orientation,
  horizontalAlignment,
  verticalAlignment,
}) => css`
  max-height: calc(100% - ${GAPS[STANDARD][orientation].vertical} * 2);

  ${handleSlideoutPosition({
    orientation,
    horizontalAlignment,
    verticalAlignment,
  })}

  ${handleSlideoutAnimation(horizontalAlignment, verticalAlignment)}
`;
