import { STANDARD, COVER_SHEET } from 'lib/presentation';
import { ORIENTATIONS } from 'lib/user-preferences';

const [PORTRAIT, LANDSCAPE] = ORIENTATIONS;

export const GAPS = {
  [STANDARD]: {
    [PORTRAIT]: {
      horizontal: '4%',
      vertical: '5.5%',
      relative: '5.5%',
      center: '50%',
    },
    [LANDSCAPE]: {
      horizontal: '9%',
      vertical: '5%',
      relative: '2.5%',
      center: '50%',
    },
  },
  [COVER_SHEET]: {
    [PORTRAIT]: {
      vertical: '7%',
    },
  },
};
