import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { createEditor, Editor, Range, Text as SlateText } from 'slate';
import { Slate, Editable as SlateEditable, withReact } from 'slate-react';
import { StyleShape } from 'entities/step-children';
import { parseToSlate, parseFromSlate } from 'lib/slate';
import { THEMES } from 'lib/user-preferences';
import { transformStyles } from 'components/Editor/Primitives';

const outlineStyle = '2px solid var(--color-blue-500)';

const Wrapper = styled.div`
  z-index: 1;
  flex: 1;
  display: grid;
  align-items: center;
  width: ${({ filled }) => filled && '100%'};
  outline: ${({ selected }) => selected && outlineStyle};

  &:hover {
    outline: ${({ hovered }) => hovered && outlineStyle};
  }
`;

const StyledText = styled.span`
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-word;
  min-width: 8px;
  width: 100%;
  cursor: ${({ readOnly }) => (readOnly ? 'pointer' : 'text')};
`;

const Text = ({
  id,
  stepId,
  text,
  spans,
  label,
  fillSpace,
  readOnly,
  isSelected,
  isInnerSelected,
  isComposedBlock,
  style,
  theme,
  onBlur,
  onChange,
  onClick,
  onSlateUpdate,
}) => {
  const initialText = useRef(text);
  const initialSpans = useRef(spans);

  const editor = useMemo(() => withReact(createEditor()), []);

  const [lastActiveSelection, setLastActiveSelection] = useState();

  useEffect(() => {
    if (editor.selection != null) setLastActiveSelection(editor.selection);
  }, [editor.selection]);

  const isBlockSelected = isComposedBlock ? isInnerSelected : isSelected;

  const initialValue = readOnly
    ? initialText.current
    : parseToSlate(
        initialSpans?.current?.length > 0
          ? initialSpans.current
          : initialText.current
      );

  // We use ref in order to avoid rerenders
  // and we use this check so we're sure the new text
  // is coming from an action from outside and not from user typing
  if (text !== initialText.current) {
    editor.selection = null;
    initialText.current = text;
    editor.children = parseToSlate(
      initialSpans?.current?.length > 0 ? spans : text
    );
  }

  if (!isEqual(spans, initialSpans.current)) {
    initialSpans.current = spans;
    editor.children = parseToSlate(
      initialSpans?.current?.length > 0 ? spans : text
    );
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce(value => {
      const { text: slateText, spans: slateSpans } = parseFromSlate(value);
      const { text: initialValueText } = parseFromSlate(initialValue);

      // Do nothing if the text is empty and it's a label
      if (slateText.trim().length === 0 && !!label) {
        return;
      }

      if (slateText !== initialValueText) {
        initialText.current = slateText;
        initialSpans.current = slateSpans;
        onChange({ slateText, slateSpans });
      }
      onSlateUpdate({ id, editor });
    }, 500),
    [editor, id, initialValue, onChange, onSlateUpdate]
  );

  const handleBlur = ({ target: { textContent } }) => {
    onBlur({ slateText: textContent });
  };

  const {
    backgroundColor,
    color,
    fontSize,
    fontFamily,
    fontWeight,
    lineHeight,
    ...wrapperStyles
  } = transformStyles(style, theme);

  const textStyles = {
    backgroundColor,
    color,
    fontSize,
    fontFamily,
    fontWeight,
    lineHeight,
  };

  const decorate = ([node, path]) => {
    const [operation] = editor?.operations ?? [];

    if (
      SlateText.isText(node) &&
      lastActiveSelection != null &&
      operation?.type !== 'insert_text'
    ) {
      const intersection = Range.intersection(
        lastActiveSelection,
        Editor.range(editor, path)
      );

      if (intersection == null) {
        return [];
      }

      const range = {
        highlighted: true,
        ...intersection,
      };

      return [range];
    }
    return [];
  };

  const renderLeaf = useCallback(
    ({ attributes, children, leaf }) => (
      <span
        // eslint-disable-next-line @appcues/jsx-props-no-spreading
        {...attributes}
        style={{
          ...transformStyles(leaf, theme),
          ...(label?.errorColor && { color: label?.errorColor }),
          ...(leaf.highlighted && isBlockSelected && { background: '#91C3FB' }),
        }}
      >
        {children}
      </span>
    ),
    [theme, label?.errorColor, isBlockSelected]
  );

  const textValue = readOnly ? (
    initialValue
  ) : (
    <Slate editor={editor} value={initialValue} onChange={handleChange}>
      <SlateEditable
        decorate={decorate}
        renderLeaf={renderLeaf}
        onBlur={handleBlur}
      />
    </Slate>
  );

  // We add the key to provoke a rerender
  // so slate can render the correct text
  return (
    <Wrapper
      key={stepId}
      id={id}
      filled={fillSpace}
      selected={isInnerSelected && !readOnly}
      hovered={isComposedBlock && !readOnly}
      style={wrapperStyles}
      data-testid={id}
    >
      <StyledText onClick={onClick} readOnly={readOnly} style={textStyles}>
        {textValue}
      </StyledText>
    </Wrapper>
  );
};

Text.propTypes = {
  id: PropTypes.string,
  stepId: PropTypes.string,
  text: PropTypes.string,
  spans: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      style: StyleShape,
    })
  ),
  label: PropTypes.shape({
    errorColor: PropTypes.string,
    required: PropTypes.bool,
  }),
  fillSpace: PropTypes.bool,
  readOnly: PropTypes.bool,
  isSelected: PropTypes.bool,
  isInnerSelected: PropTypes.bool,
  isComposedBlock: PropTypes.bool,
  style: StyleShape,
  theme: PropTypes.oneOf(THEMES),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onSlateUpdate: PropTypes.func,
};

export default Text;
