function getFileWithXmlTag(fileContent, file) {
  return new File(
    [`<?xml version="1.0" encoding="utf-8"?> ${fileContent}`],
    file.name,
    {
      type: file.type,
      lastModified: file.lastModified,
    }
  );
}

export async function parseSvgFile(file) {
  return new Promise(resolve => {
    if (file.type !== 'image/svg+xml') {
      resolve(file);
      return;
    }

    const reader = new FileReader();

    reader.addEventListener('load', function fileReader() {
      const fileContent = reader.result;
      // this regex matches
      // => <?xml ...anything in between the start and end of the tag... ?>
      // => <?xml
      // encoding="utf-8"
      // version="1.0"
      // ?>
      // multiple lines as well
      //
      const pattern = /<\?xml(.*?)?>/;
      const xmlTag = new RegExp(pattern, 'gis');
      const matches = fileContent.match(xmlTag);

      if (matches === null) {
        return resolve(getFileWithXmlTag(fileContent, file));
      }

      // our issue is related to version
      // the svg may contain a xml tag with other configs on it
      // but if it doesn't contains version then we can't upload
      if (matches.some(match => !match.includes('version'))) {
        return resolve(
          getFileWithXmlTag(fileContent.replace(xmlTag, ''), file)
        );
      }

      return resolve(file);
    });

    reader.readAsText(file);
  });
}
