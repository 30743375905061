import styled from 'styled-components';
import { easing } from 'ext/lib/style';

export const ContentMenuWrapper = styled.div`
  background-color: var(--noreaster);
  box-shadow: var(--level-3);
  border-radius: 6px;
  padding: 18px;
`;

export const Category = styled.h5`
  color: var(--sharkbait-ooh-la-la);
  font-size: var(--small);
  font-weight: var(--bold);
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const ContentMenuGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ templateColumns }) =>
    `repeat(${templateColumns}, 110px)`};
  gap: 16px 18px;
`;

export const ContentMenuButton = styled.button`
  background-color: var(--white);
  border: 1px solid var(--myst);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${easing('background-color')};
  cursor: pointer;
  padding: 15px 0;

  &:hover {
    box-shadow: var(--level-1);

    svg {
      border-radius: 50%;
      background-color: var(--luna);
      transition: ${easing('background-color')};
    }
  }
`;

export const ContentMenuButtonElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    height: 36px;
    width: 36px;
    color: var(--blurple);
    margin-bottom: 10px;
  }
`;
