import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { easing } from 'ext/lib/style/easing';

library.add(fas, far);

const FontIcon = styled(FontAwesomeIcon)``;

export default FontIcon;

const Button = styled.button`
  align-items: center;
  background: none;
  border-radius: 6px;
  border: none;
  color: var(--sharkbait-ooh-la-la);
  display: flex;
  font-size: 16px;
  height: 24px;
  justify-content: center;
  transition: ${easing('color')};
  width: 24px;

  :hover {
    cursor: pointer;
  }

  :focus {
    outline: 2px solid var(--blues-cues);
    outline-offset: -2px;
  }

  ${FontIcon} {
    width: 100%;
  }
`;

const BaseIconButton = ({
  icon,
  label,
  onClick,
  type = 'button',
  ...props
}) => {
  return (
    // eslint-disable-next-line @appcues/jsx-props-no-spreading
    <Button aria-label={label} onClick={onClick} type={type} {...props}>
      <FontIcon icon={icon} />
    </Button>
  );
};

BaseIconButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export const IconButton = styled(BaseIconButton)``;
