import { IOS, ANDROID } from 'lib/platform';

const ios = [
  { label: 'iPhone 6/6S/7/8/SE', value: '375x667' },
  { label: 'iPhone 6/7/8 Plus', value: '414x736' },
  { label: 'iPhone X/XS', value: '375x812' },
  { label: 'iPhone 11/11 Pro Max', value: '414x896' },
  { label: 'iPhone 12/13/14', value: '390x844', isDefault: true },
  { label: 'iPhone 13 Pro Max', value: '428x926' },
  { label: 'iPhone 14/15 Pro', value: '393x852' },
  { label: 'iPhone 14/15 Pro Max', value: '430x932' },
  { label: 'iPad Air (4th gen)', value: '820x1180', isTablet: true },
  { label: 'iPad mini (6th gen)', value: '744x1133', isTablet: true },
  { label: 'iPad 10.2"', value: '768x1024', isTablet: true },
  { label: 'iPad Pro 11"', value: '834x1194', isTablet: true },
  { label: 'iPad Pro 12.9"', value: '1024x1366', isTablet: true },
];

const android = [
  { label: 'Google Pixel 3', value: '412x824' },
  { label: 'Google Pixel 3 XL', value: '412x847' },
  { label: 'Google Pixel 4 / XL', value: '412x869' },
  { label: 'Google Pixel 5', value: '393x851' },
  { label: 'Google Pixel 6', value: '412x915' },
  { label: 'Google Pixel 7', value: '412x732', isDefault: true },
  { label: 'LG G5/G6/G7/G8', value: '360x720' },
  { label: 'Samsung Galaxy S8/S9', value: '360x740' },
  { label: 'Samsung Galaxy S10', value: '360x760' },
  { label: 'Samsung Galaxy S20/S21', value: '360x800' },
  { label: 'Samsung Galaxy S21 Ultra', value: '384x854' },
  { label: 'Samsung Galaxy S22', value: '360x780' },
  { label: 'Samsung Galaxy S22 Ultra', value: '360x772' },
  { label: 'Samsung Galaxy Tab 10', value: '800x1280', isTablet: true },
];

export const deviceOptions = {
  [IOS]: ios,
  [ANDROID]: android,
};

export const viewportOptions = Object.values(deviceOptions)
  .flat()
  .map(({ value }) => value);
