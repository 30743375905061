import { SURVEY, BLOCK_CATEGORIES } from './block';

/**
 * Get Survey blocks
 * @param {object} content - root content of an step children
 * @return {array} blocks - an array of survey blocks
 */
export const getSurveyBlocks = content => {
  const innerItems = content?.items?.flatMap(({ items }) => items) ?? [];
  return innerItems.filter(({ blockType }) =>
    BLOCK_CATEGORIES[SURVEY].includes(blockType)
  );
};

/**
 * Check if content has survey block type
 * @param {object} content - root content of an step children
 * @return {boolean} Represents the appearance or not of survey block in content
 */
export const hasSurveyBlockType = content => {
  return getSurveyBlocks(content).length > 0;
};
