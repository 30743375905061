import tinycolor from 'tinycolor2';
import { get, set } from 'ext/lib/local-storage';

export const getQuickColors = () => get('apc_quick_colors', []);

export const addQuickColor = color => {
  const colors = getQuickColors();
  const alreadyExists = colors.some(item => tinycolor.equals(item, color));

  if (alreadyExists) return;

  const newColors = [tinycolor(color).toRgbString(), ...colors.slice(0, 5)];
  set('apc_quick_colors', newColors);
};

export const generateColor = ({ r, g, b, a }) => `rgba(${r},${g},${b},${a})`;

export const isValidHex = hex => {
  // disable hex4, hex3, and hex8
  const lh = String(hex)[0] === '#' ? 1 : 0;
  return (
    hex.length !== 3 + lh &&
    hex.length !== 4 + lh &&
    hex.length < 7 + lh &&
    tinycolor(hex).isValid()
  );
};

export const isCSSVariable = value => value?.toString()?.includes('var(--');
