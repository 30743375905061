import React from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, Icon } from '@appcues/sonar';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare';
import { faBoxArchive } from '@fortawesome/pro-solid-svg-icons/faBoxArchive';
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons/faTrashCan';
import useToggle from 'ext/lib/hooks/use-toggle';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { capitalize } from 'utils/strings-handler';
import { SCREEN_STATE } from 'entities/screens';
import { DropdownWrapper, MoreOptionsButton } from './styled';

const { ACTIVE, ARCHIVED } = SCREEN_STATE;

const Actions = ({
  id,
  state,
  isHidden,
  isSelected,
  onRename,
  onUpdate,
  onRemove,
  onUnselect,
}) => {
  const { track } = useAnalytics();
  const [dropdownVisible, toggleDropdownVisible] = useToggle(false);

  const handleTrack = action =>
    track('Mobile Builder interaction', {
      name: `${capitalize(action)} Screen Capture`,
      component: 'ScreenList',
    });

  const handleRename = event => {
    handleTrack('Renamed');
    onRename();
    event.stopPropagation();
  };

  const handleState = (event, newState) => {
    handleTrack(newState);
    onUpdate(id, { state: newState, preserveEntity: true });

    if (isSelected && newState === ARCHIVED) {
      onUnselect();
    }

    event.stopPropagation();
  };

  const handleRemove = event => {
    handleTrack('Removed');
    onRemove(id);
    event.stopPropagation();
  };

  return (
    <DropdownWrapper isHidden={isHidden}>
      <DropdownMenu.Root
        open={dropdownVisible}
        onOpenChange={toggleDropdownVisible}
        modal
      >
        <DropdownMenu.Trigger asChild>
          <MoreOptionsButton
            iconOnly
            variant="tertiary"
            aria-label="More options"
          >
            <Icon icon={faEllipsisV} colorToken="color-neutral-700" />
          </MoreOptionsButton>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content>
            {state === ACTIVE && (
              <>
                <DropdownMenu.Item onClick={event => handleRename(event)}>
                  <Icon icon={faPenToSquare} /> Rename
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onClick={event => handleState(event, ARCHIVED)}
                >
                  <Icon icon={faBoxArchive} /> Archive
                </DropdownMenu.Item>
              </>
            )}

            {state === ARCHIVED && (
              <>
                <DropdownMenu.Item
                  onClick={event => handleState(event, ACTIVE)}
                >
                  <Icon icon={faUpload} /> Restore
                </DropdownMenu.Item>
                <DropdownMenu.Item onClick={handleRemove}>
                  <Icon icon={faTrashCan} /> Delete
                </DropdownMenu.Item>
              </>
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </DropdownWrapper>
  );
};

Actions.propTypes = {
  id: PropTypes.string,
  state: PropTypes.oneOf(Object.values(SCREEN_STATE)),
  isHidden: PropTypes.bool,
  isSelected: PropTypes.bool,
  onRename: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onUnselect: PropTypes.func,
};

export default Actions;
