import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { INITIALIZE } from 'ext/root/root-actions';
import keyBy from 'lodash.keyby';
import { selectExperience } from 'entities/experiences';
import { CUSTOM_COMPONENT_TYPE, CUSTOM_COMPONENT_CONTENT_TYPE } from './shape';
import { patterns, flush, insert, prune, reject, resolve } from './actions';

function* fetchCustomComponents() {
  const logger = yield getContext('logger');
  try {
    const api = yield getContext('api');
    const response = yield call(api.getCustomComponents);

    yield put(resolve(keyBy(response, 'id')));
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
  }
}

function* createCustomComponent({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { name, content } = payload;
    const { appId } = yield select(selectExperience);

    const contentBody = {
      name,
      type: CUSTOM_COMPONENT_TYPE,
      contentType: CUSTOM_COMPONENT_CONTENT_TYPE,
      content,
      sourceAppId: appId,
    };
    const api = yield getContext('api');
    const response = yield call(api.createCustomComponent, contentBody);
    yield put(insert(response));
  } catch (error) {
    logger.error(error);
  }
}

function* updateCustomComponent({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { id, name, content } = payload;
    const { appId } = yield select(selectExperience);

    const contentBody = {
      ...(name && { name }),
      content,
      sourceAppId: appId,
    };

    const api = yield getContext('api');
    const response = yield call(api.updateCustomComponent, id, contentBody);
    yield put(flush(id, response));
  } catch (error) {
    logger.error(error);
  }
}

function* removeCustomComponent({ payload }) {
  const { id: componentId } = payload;
  const logger = yield getContext('logger');
  try {
    const api = yield getContext('api');

    yield call(api.removeCustomComponent, componentId);
    yield put(prune(componentId));
  } catch (error) {
    logger.error(error);
  }
}

export default function* saga() {
  yield takeEvery(INITIALIZE, fetchCustomComponents);
  yield takeEvery(patterns.create, createCustomComponent);
  yield takeEvery(patterns.update, updateCustomComponent);
  yield takeEvery(patterns.remove, removeCustomComponent);
}
