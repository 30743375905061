import { revert } from 'entities/experiences';
import { UNDO, REDO, CURRENT_UPDATED, HISTORY_LOG_UPDATED } from './actions';

const initialState = {
  undos: [],
  redos: [],
  current: {},
};

export default function historyLog(state = initialState, action) {
  const { type, payload } = action ?? {};
  const { undos, redos, current } = state;

  switch (type) {
    case CURRENT_UPDATED: {
      return {
        ...state,
        current: {
          ...state.current,
          ...payload,
        },
      };
    }
    case UNDO:
      if (undos.length > 0) {
        return {
          current: undos[undos.length - 1],
          undos: undos.slice(0, -1),
          redos: [...redos, current],
        };
      }
      return state;
    case REDO:
      if (redos.length > 0) {
        return {
          current: redos[redos.length - 1],
          redos: redos.slice(0, -1),
          undos: [...undos, current],
        };
      }
      return state;
    case HISTORY_LOG_UPDATED: {
      return {
        ...state,
        current: payload,
        redos: [],
        undos: [...undos, current],
      };
    }
    default:
      return state;
  }
}

export const selectHistoryLogEntities = ({
  experience,
  stepGroups,
  stepChildren,
  selected,
  slate,
  userInterface,
}) => ({
  experience,
  stepGroups,
  stepChildren,
  selected,
  slate,
  userInterface,
});

export const selectHistoryLog = state => state.historyLog;

export const selectCanonicalExperience = state => {
  const { current } = selectHistoryLog(state);
  const { experience, stepGroups, stepChildren } = current;

  return revert(experience, { stepGroups, stepChildren });
};
