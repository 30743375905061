const handleErrorStyle = ({
  style = {},
  key = 'color',
  color = '#D6216B',
} = {}) => ({
  ...style,
  [key]: color,
});

export default handleErrorStyle;
