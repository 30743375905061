import { BLOCK, EMOJI, TEXT } from 'lib/block';

const emojiTemplate = ({ blockId, elementId }) => ({
  type: BLOCK,
  blockType: EMOJI,
  id: blockId,
  content: {
    type: TEXT,
    id: elementId,
    text: '🙂',
    style: {
      fontSize: 100,
      textAlignment: 'center',
      horizontalAlignment: 'center',
    },
  },
});

export default emojiTemplate;
