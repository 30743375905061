import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BottomBar, Button, ButtonGroup } from 'ext/components/ui';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import useToggle from 'ext/lib/hooks/use-toggle';
import { Shape as ExperienceShape } from 'entities/experiences';
import { Shape as SelectedShape } from 'entities/selected';
import { MODES } from 'hooks/use-mode';
import { Steps } from './Steps';
import FlowManager from './FlowManager';
import FlowCreate from './FlowCreate';
import PreviewMobileFlow, { useOpenPreview } from './PreviewMobileFlow';
import SpoofingBanner from './SpoofingBanner';
import PublishedBanner from './PublishedBanner';
import BottomMenuManager from './BottomMenuManager';
import UndoRedo from './UndoRedo';
import Divider from './Divider';

const EmptyState = styled.span``;

export default function ManagerBar({
  accountId,
  experience,
  isSpoofing,
  mode,
  selected,
}) {
  const { track } = useAnalytics();
  const [creating, toggle] = useToggle();
  const preview = useOpenPreview(selected);

  const messages = useMemo(
    () => [
      ...(isSpoofing ? [<SpoofingBanner key="spoofing" />] : []),
      ...(experience?.published ? [<PublishedBanner key="published" />] : []),
    ],
    [experience, isSpoofing]
  );

  return (
    <BottomBar messages={messages} menu={<BottomMenuManager />}>
      <FlowManager flow={experience} />

      {mode === MODES.empty && (
        <>
          <EmptyState>
            Choose an existing flow or click “Create New Flow”
          </EmptyState>
          <Button
            kind="secondary"
            onClick={() => {
              track('Mobile Builder view', {
                name: 'Opened Create New Flow Modal',
                component: 'ManagerBar',
              });
              toggle();
            }}
          >
            Create New Flow
          </Button>
          <FlowCreate onClose={toggle} visible={creating} />
        </>
      )}

      {mode === MODES.edit && (
        <>
          <Steps selected={selected} />
          <ButtonGroup right data-theme="dark">
            <UndoRedo />

            <Divider />

            <PreviewMobileFlow
              flow={experience}
              accountId={accountId}
              visible={preview}
            />

            <Button
              as="a"
              href={`${STUDIO_URL}/mobile/flows/${experience.id}/settings?account=${accountId}`}
              kind="primary"
              rel="noreferrer"
              target="_blank"
              onClick={() => {
                track('Mobile Builder interaction', {
                  name: 'Clicked Target and Publish Flow Button',
                  component: 'ManagerBar',
                });
              }}
            >
              Target & Publish
            </Button>
          </ButtonGroup>
        </>
      )}
    </BottomBar>
  );
}

ManagerBar.propTypes = {
  accountId: PropTypes.string,
  experience: ExperienceShape,
  isSpoofing: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(MODES)),
  selected: SelectedShape,
};
