import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Flyout,
  Form,
  FormField,
  Icon,
  Label,
  Input,
  InlineMessage,
} from '@appcues/sonar';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Shape as CustomComponentShape } from 'entities/custom-components';
import { OptionalText } from './styled';
import { PropertyForm } from './PropertyForm';
import { useCustomComponentForm } from './use-custom-component-form';

export function CustomComponentFlyout({
  editingComponentTemplate,
  open,
  onOpenChange,
  handleCreateComponent,
  handleUpdateComponent,
}) {
  const {
    component,
    error,
    handleTextChange,
    handlePropertyChange,
    handleAddProperty,
    handleRemoveProperty,
    handleSave,
    resetForm,
  } = useCustomComponentForm(editingComponentTemplate);

  const handleOpenChange = () => {
    resetForm();
    onOpenChange();
  };

  const onSave = () => {
    const success = handleSave(trimmedComponent => {
      if (editingComponentTemplate?.id) {
        handleUpdateComponent(editingComponentTemplate.id, trimmedComponent);
      } else {
        handleCreateComponent(trimmedComponent);
      }
    });

    if (success) {
      handleOpenChange();
    }
  };

  return (
    <Flyout.Root open={open} onOpenChange={handleOpenChange}>
      <Flyout.Header>
        <Flyout.Title>
          {`${editingComponentTemplate ? 'Edit' : 'Add'} custom component`}
        </Flyout.Title>
      </Flyout.Header>

      <Flyout.Content>
        <Form role="form">
          <FormField>
            <Label>Component identifier</Label>
            <Input
              id="identifier"
              value={component.identifier}
              placeholder="componentName"
              onChange={({ target }) =>
                handleTextChange('identifier', target.value)
              }
            />
            {!error.identifier ? (
              <InlineMessage>
                This should match the component name defined in your application
              </InlineMessage>
            ) : (
              <InlineMessage variant="error">{error.identifier}</InlineMessage>
            )}
          </FormField>

          <FormField>
            <Label>
              Description<OptionalText>(optional)</OptionalText>
            </Label>
            <Input
              id="description"
              value={component.description}
              placeholder="Brief description of functionality"
              onChange={({ target }) =>
                handleTextChange('description', target.value)
              }
            />
          </FormField>

          {component.properties.map((property, index) => (
            <PropertyForm
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              property={property}
              index={index}
              error={error}
              onPropertyChange={handlePropertyChange}
              onRemoveProperty={handleRemoveProperty}
            />
          ))}
        </Form>

        <Button variant="secondary" onClick={handleAddProperty}>
          <Icon icon={faPlus} />
          Add property
        </Button>
      </Flyout.Content>

      <Flyout.Footer>
        <Flyout.Actions>
          <Flyout.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Flyout.Close>
          <Button
            variant="primary"
            onClick={onSave}
            disabled={Object.keys(error).length > 0}
          >
            Save
          </Button>
        </Flyout.Actions>
      </Flyout.Footer>
    </Flyout.Root>
  );
}

CustomComponentFlyout.propTypes = {
  editingComponentTemplate: PropTypes.shape(CustomComponentShape),
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
  handleCreateComponent: PropTypes.func,
  handleUpdateComponent: PropTypes.func,
};

export default CustomComponentFlyout;
