import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { PAGING_DOTS } from 'lib/trait';
import { selectSelected } from 'entities/selected';
import { selectStepGroup } from 'entities/step-groups';
import { StyleShape } from 'entities/step-children';
import { focus } from 'entities/trait';
import { THEMES } from 'lib/user-preferences';
import { transformStyles } from 'components/Editor/Primitives';

const DEFAULT_MARGIN_BOTTOM = 10;

const DotsContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 8px;
  margin-bottom: ${({ margins: { bottom = 0 } }) =>
    `${DEFAULT_MARGIN_BOTTOM + bottom}px;`};

  ${({ isEmbed, margins }) =>
    isEmbed &&
    css`
      margin-right: 0;
      margin-left: 0;
      left: calc(50% + ${margins.left} - ${margins.right});
      transform: translateX(-50%);
    `}

  &[data-selected='true'] {
    outline: 2px solid var(--color-blue-500);
    outline-offset: 4px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Dot = styled.span`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background: ${({ isActive, activeColor, inactiveColor }) =>
    isActive ? activeColor : inactiveColor};
`;

const PagingDots = ({
  style,
  theme,
  steps,
  isEmbed,
  selectedStep,
  selectedTrait,
  onClick,
}) => {
  if (steps?.length <= 1 || !style) return null;

  const isSelected = selectedTrait === PAGING_DOTS;

  const { marginBottom, ...pagingDotsStyle } = style;

  const {
    color: activeColor,
    backgroundColor: inactiveColor,
    ...containerStyles
  } = transformStyles(pagingDotsStyle, theme);

  const handleClick = () => {
    if (!isSelected) {
      onClick(PAGING_DOTS);
    }
  };

  return (
    <DotsContainer
      aria-label="Paging dots"
      data-selected={isSelected}
      onClick={handleClick}
      style={containerStyles}
      margins={{
        bottom: marginBottom,
        right: containerStyles.marginRight || '0px',
        left: containerStyles.marginLeft || '0px',
      }}
      isEmbed={isEmbed}
    >
      {steps?.map(step => (
        <Dot
          aria-label="dot"
          key={step}
          activeColor={activeColor}
          inactiveColor={inactiveColor}
          isActive={step === selectedStep}
        />
      ))}
    </DotsContainer>
  );
};

PagingDots.propTypes = {
  style: StyleShape,
  theme: PropTypes.oneOf(THEMES),
  steps: PropTypes.arrayOf(PropTypes.string),
  isEmbed: PropTypes.bool,
  selectedStep: PropTypes.string,
  selectedTrait: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  const {
    stepGroup: stepGroupId,
    stepChild: selectedStep,
    trait: selectedTrait,
  } = selectSelected(state) ?? {};
  const { children: steps } = selectStepGroup(state, stepGroupId) ?? {};

  return { steps, selectedStep, selectedTrait };
};

const mapDispatchToProps = {
  onClick: focus,
};

export default connect(mapStateToProps, mapDispatchToProps)(PagingDots);
