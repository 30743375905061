import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PickerCircle = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
`;

const theme = `
  --cp-border: 1px solid #D0D7E4;
  --cp-box-shadow: none;
  
  [data-theme="light"] & {
    --cp-border: none;
    --cp-box-shadow: inset 0px 0px 4px rgba(11, 26, 56, 0.4);
  }
`;

const Background = styled(PickerCircle)`
  ${theme}
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg mask='url(%23mask0)'%3E%3Crect width='7.11111' height='7.11111' transform='matrix(1 0 0 -1 7.44434 14.5555)' fill='black' fill-opacity='0.25'/%3E%3Crect width='7.11111' height='7.11111' transform='matrix(1 0 0 -1 0.333252 7.44446)' fill='black' fill-opacity='0.25'/%3E%3C/g%3E%3C/svg%3E%0A");
  display: inline-block;
  position: relative;
`;

const Color = styled(PickerCircle)`
  ${({ color }) => color && `background: ${color};`}
  position: absolute;
  border: var(--cp-border);
  box-shadow: var(--cp-box-shadow);
`;

const CurrentColor = ({ color, role, size, ...props }) => (
  // eslint-disable-next-line @appcues/jsx-props-no-spreading
  <Background size={size} role={role} {...props}>
    {color && (
      <Color color={color} aria-label="Current color overlay" size={size} />
    )}
  </Background>
);

CurrentColor.defaultProps = {
  role: 'button',
  size: 22,
};

CurrentColor.propTypes = {
  color: PropTypes.string,
  role: PropTypes.string,
  size: PropTypes.number,
};

export default CurrentColor;
