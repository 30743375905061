import { createCollectionReducer } from 'ext/lib/collections';

import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

/** SELECTORS */

export const selectUserAccounts = state => state.accounts || {};

export const selectHasMultipleAccounts = state =>
  Object.keys(selectUserAccounts(state)).length > 1;
