import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { assign } from 'object-path-immutable';
import { Button, Icon } from '@appcues/sonar';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons/faCrosshairs';
import { Accordion, FieldSet, Label, FontIcon } from 'ext/components/ui';
import { Shape as ScreenShape } from 'entities/screens';
import { targetElementTemplate } from 'entities/step-children';
import { TARGET_RECTANGLE, TARGET_ELEMENT } from 'lib/trait';
import { parseScreenSelectors } from 'lib/parse-screen-selectors';
import InputWithValidation from 'components/InputWithValidation';
import SelectorsList from 'components/SelectorsList';
import {
  Controls,
  HelpLabel,
  GroupedFieldSet,
  GroupedField,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';

const RELATIVE_POSITIONS = {
  X: 'left',
  Y: 'top',
};

export default function TargetPlacement({
  targetArea,
  targetPlacement,
  isAnchored,
  selectedScreen,
  handleTraitsUpdate,
  onTargetPlacementUpdate,
}) {
  const trackAccordion = useAccordionClick();

  const { config } = targetArea ?? targetElementTemplate;
  const {
    id: currentSelectorId,
    relativeX = 0,
    relativeY = 0,
    contentPreferredPosition,
    contentDistanceFromTarget,
  } = config ?? {};

  const selectors = parseScreenSelectors(selectedScreen?.layout).sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );
  const disableAdjustTargetPlacement =
    isAnchored && (!selectedScreen || selectors.length === 0);

  const handleDistance = debounce((key, { target: { valueAsNumber } }) => {
    const percentageValue = key === 'relativeX' || key === 'relativeY';

    const updatedTargetRectangle = assign(targetArea, 'config', {
      [key]: percentageValue ? valueAsNumber / 100 : valueAsNumber,
    });

    handleTraitsUpdate(updatedTargetRectangle);
    // Decrease debounce time to avoid lose the previous change if the user
    // updates the left and top values faster than 500ms (default debounce time)
  }, 250);

  const handleSelectorClick = selector => {
    if (selector.id === currentSelectorId) return;

    const updatedConfig = {
      contentPreferredPosition,
      contentDistanceFromTarget,
      ...selector,
    };

    const updatedTargetElement = assign(
      targetElementTemplate,
      'config',
      updatedConfig
    );

    handleTraitsUpdate(updatedTargetElement);
  };

  return (
    <Accordion.Item value="target-position">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion('Tooltip', 'Target Position')}
        >
          Target position
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Button
              variant="secondary"
              onClick={onTargetPlacementUpdate}
              aria-pressed={targetPlacement}
              disabled={disableAdjustTargetPlacement}
              fluid
            >
              <Icon icon={faCrosshairs} /> Adjust target placement
            </Button>
          </FieldSet>

          {isAnchored && (
            <FieldSet>
              <Label>
                Select target element{' '}
                <HelpLabel>
                  Target selectors for reliable <br /> positioning across device
                  sizes
                </HelpLabel>
              </Label>

              <SelectorsList
                currentSelectorId={currentSelectorId}
                selectedScreenId={selectedScreen?.id}
                selectors={selectors}
                onSelectorClick={handleSelectorClick}
              />
            </FieldSet>
          )}

          {!isAnchored && (
            <GroupedFieldSet>
              {Object.entries(RELATIVE_POSITIONS).map(([key, position]) => {
                const id = `distance-${position}`;
                const relativeValue = Math.round(
                  (key === 'X' ? relativeX : relativeY) * 100
                );

                return (
                  <GroupedField key={id} half>
                    <Label htmlFor={id}>Distance from {`${position}`}</Label>
                    <InputWithValidation
                      id={id}
                      ariaLabel={`relative distance from ${position}`}
                      value={relativeValue}
                      onChange={event =>
                        handleDistance(`relative${key}`, event)
                      }
                      type="number"
                      placeholder="0"
                      suffix="%"
                      max="100"
                      min="0"
                    />
                  </GroupedField>
                );
              })}
            </GroupedFieldSet>
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

TargetPlacement.propTypes = {
  targetArea: PropTypes.shape({
    type: PropTypes.oneOf([TARGET_RECTANGLE, TARGET_ELEMENT]),
    config: PropTypes.shape({
      relativeX: PropTypes.number,
      relativeY: PropTypes.number,
    }),
  }),
  selectedScreen: ScreenShape,
  isAnchored: PropTypes.bool,
  targetPlacement: PropTypes.bool,
  handleTraitsUpdate: PropTypes.func,
  onTargetPlacementUpdate: PropTypes.func,
};
