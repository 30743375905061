import PropTypes from 'prop-types';

export default PropTypes.shape({
  stepGroup: PropTypes.string,
  stepChild: PropTypes.string,
  trait: PropTypes.string,
  block: PropTypes.string,
  /**
   * NOTE: `selected.experience` is a special, temporary state value that only
   *       exists via store hydration on load. The value is derived from the URL
   *       via `lib/location#parseSelected` and is used to verify that the step
   *       ID in the URL is valid. In the case where the step ID is invalid
   *       and/or does not exist, it the `entities/selected/reducer#on-load`
   *       handler will derive the first step from the first group of the
   *       experience. This value then cleared upon the next action dispatch
   *       cycle so should not be used outside of load.
   */
  experience: PropTypes.string,
});
