import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Accordion, FieldSet, FontIcon } from 'ext/components/ui';
import MarginPaddingEditor from 'ext/components/MarginPaddingEditor';
import { StyleShape } from 'entities/step-children';
import AlignmentPicker from 'components/AlignmentPicker';
import {
  Controls,
  useStyleSettings,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';

// Spacing types
const SPACING = {
  padding: 'padding',
  margin: 'margin',
};

// Spacing path mappings
const paths = spacing => ({
  top: `${spacing}Top`,
  right: `${spacing}Trailing`,
  bottom: `${spacing}Bottom`,
  left: `${spacing}Leading`,
});

export default function AlignmentSpacing({ style, spacingLabel, onChange }) {
  const trackAccordion = useAccordionClick();

  const { backgroundImage } = style ?? {};

  const title = backgroundImage ? 'Alignment & spacing' : 'Spacing';

  const handleBackgroundImageAlignment = useCallback(
    ({ horizontalAlignment, verticalAlignment }) => {
      onChange({
        style: {
          ...style,
          backgroundImage: {
            ...style.backgroundImage,
            ...(horizontalAlignment && { horizontalAlignment }),
            ...(verticalAlignment && { verticalAlignment }),
          },
        },
      });
    },
    [style, onChange]
  );

  const handleAlignment = {
    horizontalAlignment: horizontalAlignment =>
      handleBackgroundImageAlignment({ horizontalAlignment }),
    verticalAlignment: verticalAlignment =>
      handleBackgroundImageAlignment({ verticalAlignment }),
  };

  const handleChange = useCallback(
    patch => {
      onChange({ style: patch });
    },
    [onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
  });

  const handleValues = spacing =>
    Object.entries(paths(spacing)).reduce(
      (acc, [position, layout]) => ({
        ...acc,
        [position]: valueFor[layout],
      }),
      {}
    );

  const handleUpdates = (spacing, position, value) =>
    handleChangeFor[paths(spacing)[position]](value);

  const marginProps = {
    margin: handleValues(SPACING.margin),
    onMarginUpdate: (position, value) =>
      handleUpdates(SPACING.margin, position, value),
  };

  const paddingProps = {
    padding: handleValues(SPACING.padding),
    onPaddingUpdate: (position, value) =>
      handleUpdates(SPACING.padding, position, value),
  };

  return (
    <Accordion.Item value="alignment-spacing">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Hero', title)}>
          {title}
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          {backgroundImage && (
            <FieldSet>
              <AlignmentPicker
                alignments={[
                  backgroundImage.horizontalAlignment,
                  backgroundImage.verticalAlignment,
                ]}
                onClick={handleAlignment}
              />
            </FieldSet>
          )}

          <FieldSet>
            <MarginPaddingEditor
              /* eslint-disable @appcues/jsx-props-no-spreading */
              {...paddingProps}
              {...marginProps}
              /* eslint-enable @appcues/jsx-props-no-spreading */
              label={spacingLabel}
            />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

AlignmentSpacing.propTypes = {
  style: StyleShape,
  spacingLabel: PropTypes.string,
  onChange: PropTypes.func,
};
