import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FieldSet } from 'ext/components/ui';
import { Switch } from '@appcues/sonar';
import { update } from 'entities/step-children';
import { TARGET_INTERACTION } from 'lib/trait';
import { Shape as ActionsShape } from 'lib/actions';
import {
  Actions,
  HelpLabel,
  actionsMapper,
} from 'components/SideBarSettings/Shared';

export function TargetAction({ targetInteraction, handleTraitsUpdate }) {
  const { actions: prevActions = [], enablePassThrough = false } =
    targetInteraction?.config ?? {};

  const handleActionsUpdate = newActions => {
    const updatedTargetInteraction = {
      ...targetInteraction,
      config: {
        ...targetInteraction?.config,
        actions: actionsMapper({ prevActions, newActions }),
      },
    };

    handleTraitsUpdate(updatedTargetInteraction);
  };

  const handlePassThroughToggle = () => {
    const updatedTargetInteraction = {
      ...targetInteraction,
      config: {
        ...targetInteraction?.config,
        enablePassThrough: !enablePassThrough,
      },
    };

    handleTraitsUpdate(updatedTargetInteraction);
  };

  return (
    <Actions
      actions={prevActions}
      context={TARGET_INTERACTION}
      onActionsChange={handleActionsUpdate}
    >
      <FieldSet>
        <Switch
          id="passthrough"
          checked={enablePassThrough}
          onCheckedChange={handlePassThroughToggle}
          fullWidth
        >
          <Switch.Label htmlFor="passthrough">
            Allow interaction with app
            <HelpLabel>
              Allow taps in target area to flow
              <br />
              through to highlighted view.
              <br />
              Requires SDK versions ≥ 4.2.0
            </HelpLabel>
          </Switch.Label>
        </Switch>
      </FieldSet>
    </Actions>
  );
}

TargetAction.propTypes = {
  targetInteraction: PropTypes.shape({
    type: PropTypes.oneOf([TARGET_INTERACTION]),
    config: PropTypes.shape({
      enablePassThrough: PropTypes.bool,
      actions: PropTypes.arrayOf(ActionsShape),
    }),
  }),
  handleTraitsUpdate: PropTypes.func,
};

const mapDispatchToProps = {
  onUpdate: update,
};

export default connect(null, mapDispatchToProps)(TargetAction);
