import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { del } from 'object-path-immutable';
import { Button, Icon, Input } from '@appcues/sonar';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { selectStepGroup, EditorShape } from 'entities/step-groups';
import { selectStepChild } from 'entities/step-children';
import { Shape as TraitsShape } from 'entities/trait';
import { selectSelected } from 'entities/selected';
import { ANCHORED } from 'lib/presentation';
import {
  selectScreensSummaryList,
  update as updateScreen,
  remove as removeScreen,
  Shape as ScreenShape,
  SCREEN_STATE,
} from 'entities/screens';
import { stepsUpdate } from 'entities/experiences';
import { updateTraits, TARGET_ELEMENT } from 'lib/trait';
import Actions from './Actions';
import ScreenImageWrapper from './ScreenImageWrapper';
import {
  Container,
  Item,
  RenameWrapper,
  RenameActions,
  ScreenInfoWrapper,
  ItemTitle,
  ItemDescription,
} from './styled';
import { handleDate, handleFilterSearch } from './helpers';
import noneScreen from './none.png';

const { ARCHIVED } = SCREEN_STATE;

export const ScreenList = ({
  editor,
  traits,
  list,
  searchFilter,
  showArchived = false,
  onScreenUpdate,
  onScreenRemove,
  onStepsUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [renamingScreen, setRenamingScreen] = useState();

  const selectedScreenId = editor?.screen?.id;
  const isAnchored = editor?.presentation === ANCHORED;

  const filteredList = list?.filter(screen =>
    handleFilterSearch(screen, searchFilter, ['displayName', 'deviceModel'])
  );

  const handleScreenSelection = ({ id, metadata }) => {
    if (id === selectedScreenId) return;

    const updatedEditor = id
      ? { ...editor, screen: { id, metadata } }
      : del(editor, 'screen');

    // Clear previous target element child trait on anchored cases
    // to avoid invalid selectors on screen change
    const updatedTraits = updateTraits({
      traits,
      ...(isAnchored && { traitTypeToDelete: TARGET_ELEMENT }),
    });

    onStepsUpdate({
      stepChildren: { traits: updatedTraits },
      stepGroup: { editor: updatedEditor },
    });
  };

  const onScreenRename = (id, displayName) => {
    setRenamingScreen({ id, displayName });
    setIsEditing(true);
  };

  const handleCleanRename = event => {
    setRenamingScreen();
    setIsEditing(false);
    event.stopPropagation();
  };

  const handleCancelRename = event => {
    handleCleanRename(event);
  };

  const handleSaveRename = (event, id) => {
    onScreenUpdate(id, {
      displayName: renamingScreen.displayName,
      preserveEntity: true,
    });
    handleCleanRename(event);
  };

  return (
    <Container role="menu">
      <Item onClick={handleScreenSelection} aria-selected={!selectedScreenId}>
        <ScreenImageWrapper src={noneScreen} />
        <ScreenInfoWrapper>
          <ItemTitle>None</ItemTitle>
        </ScreenInfoWrapper>
      </Item>

      {filteredList?.map(
        ({
          id,
          displayName,
          metadata,
          presignedScreenshotImageUrl,
          state,
          updatedAt,
        }) => {
          const deviceModel = metadata?.deviceModel || 'Unknown device';
          const appVersion = metadata?.appVersion || '0.0.0';
          const isSelected = selectedScreenId === id;
          const isRenaming = renamingScreen?.id === id;
          const isArchived = state === ARCHIVED;

          return (
            <Item
              key={id}
              onClick={() =>
                !isEditing &&
                !isArchived &&
                handleScreenSelection({ id, metadata })
              }
              aria-selected={isSelected}
              aria-disabled={isArchived}
              aria-hidden={!showArchived && isArchived}
            >
              <ScreenImageWrapper
                src={presignedScreenshotImageUrl?.presignedUrl}
                alt={displayName}
                state={state}
                orientation={metadata.deviceOrientation}
              />

              {isRenaming ? (
                <RenameWrapper>
                  <Input
                    value={renamingScreen.displayName}
                    onClick={event => event.stopPropagation()}
                    onChange={({ target }) => onScreenRename(id, target.value)}
                    onKeyDown={event =>
                      event.key === 'Enter' && handleSaveRename(event, id)
                    }
                  />
                  <RenameActions>
                    <Button
                      variant="tertiary"
                      onClick={event => handleCancelRename(event)}
                    >
                      <Icon icon={faXmark} />
                    </Button>
                    <Button
                      variant="tertiary"
                      onClick={event => handleSaveRename(event, id)}
                    >
                      <Icon icon={faCheck} />
                    </Button>
                  </RenameActions>
                </RenameWrapper>
              ) : (
                <ScreenInfoWrapper>
                  <ItemTitle role="menuitem">{displayName}</ItemTitle>
                  <ItemDescription>
                    {`${deviceModel} - v${appVersion} - ${handleDate(
                      updatedAt
                    )}`}
                  </ItemDescription>
                </ScreenInfoWrapper>
              )}
              <Actions
                id={id}
                state={state}
                isHidden={isRenaming}
                isSelected={isSelected}
                onRename={() => onScreenRename(id, displayName)}
                onUpdate={onScreenUpdate}
                onRemove={onScreenRemove}
                onUnselect={handleScreenSelection}
              />
            </Item>
          );
        }
      )}
    </Container>
  );
};

ScreenList.propTypes = {
  editor: EditorShape,
  traits: TraitsShape,
  list: PropTypes.arrayOf(ScreenShape),
  searchFilter: PropTypes.string,
  showArchived: PropTypes.bool,
  onScreenUpdate: PropTypes.func,
  onScreenRemove: PropTypes.func,
  onStepsUpdate: PropTypes.func,
};

const mapStateToProps = state => {
  const { stepGroup, stepChild } = selectSelected(state) ?? {};
  const { editor } = selectStepGroup(state, stepGroup) ?? {};
  const { traits } = selectStepChild(state, stepChild) ?? {};
  const list = selectScreensSummaryList(state);

  return {
    editor,
    traits,
    list,
  };
};

const mapDispatchToProps = {
  onScreenUpdate: updateScreen,
  onScreenRemove: removeScreen,
  onStepsUpdate: stepsUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenList);
