import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import RootContext from 'ext/components/RootContext';

/*
 * TODO: Add test for individual helpers. Currently the helpers have some
 *       parameters that are largely dependent on the result on each other. The
 *       results are also fairly large such as `positions` so setup of each test
 *       proved to be a challenge in it of itself. We should add them at some
 *       point though just to confirm things like the optimal placement
 *       algorithm work as intended.
 */

/**
 * Safe helper to call `getBoundingClientRect` with fallback to zeros
 *
 * @param {?Element} $element - Element to get bounds
 * @return {DOMRect} Element's rect
 */
const getBoundingClientRect = $element =>
  $element
    ? $element.getBoundingClientRect()
    : { top: 0, right: 0, bottom: 0, left: 0, height: 0, width: 0 };

/**
 * Calculate all possible positions for attachment
 *
 * NOTE: The order defined here is the order repositioning will be attempted.
 *
 * @param {object} object
 * @param {object} object.dimensions - Attachment dimensions
 * @param {object} offset - Perpendicular and parallel offsets
 * @param {DOMRect} rect - Target rect
 * @return {object} All possible top/left positions attachment
 */
const getPossiblePositions = ({
  dimensions: { height, width },
  offset,
  rect,
}) => {
  // Determine center coordinates for target element
  const center = {
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2,
  };

  // Normalize offset/shift to prevent NaN
  const shift = { x: 0, y: 0, ...offset };

  return {
    'top-left': {
      top: rect.top - height - shift.y,
      left: rect.left - width - shift.x,
    },

    top: {
      top: rect.top - height - shift.y,
      left: center.x - width / 2,
    },

    'top-right': {
      top: rect.top - height - shift.y,
      left: rect.right + shift.x,
    },

    'right-top': {
      top: rect.top - height - shift.x,
      left: rect.right + shift.y,
    },

    right: {
      top: center.y - height / 2,
      left: rect.right + shift.y,
    },

    'right-bottom': {
      top: rect.bottom + shift.x,
      left: rect.right + shift.y,
    },

    'bottom-right': {
      top: rect.bottom + shift.y,
      left: rect.right + shift.x,
    },

    bottom: {
      top: rect.bottom + shift.y,
      left: center.x - width / 2,
    },

    'bottom-left': {
      top: rect.bottom + shift.y,
      left: rect.left - width - shift.x,
    },

    'left-bottom': {
      top: rect.bottom + shift.x,
      left: rect.left - width - shift.y,
    },

    left: {
      top: center.y - height / 2,
      left: rect.left - width - shift.y,
    },

    'left-top': {
      top: rect.top - height - shift.x,
      left: rect.left - width - shift.y,
    },
  };
};

/**
 * Naively determine optimal placement via overflow detection
 *
 * NOTE: Currently the logic here is naive in the sense that it will just check
 *       all of the possible positions in the order they are defined above.
 *       Ideally though, we should check vertical and horizontal overflows
 *       separately to better determime what placement should be checked next.
 *
 * @param {object} object
 * @param {object} object.dimensions - Attachment dimensions
 * @param {string} placement - Preferred attachment placement
 * @param {object} positions - All possible attachment positions
 * @return {string} Optimal placement
 */
const getOptimalPlacement = ({
  dimensions: { height, width },
  placement,
  positions,
  allowedPlacements,
}) => {
  // Depending on whether or not we're in quirks mode, we need to get the
  // viewport size (minus scrollbars) from the clientHeight & clientWidth of
  // either the documentElement or body:
  // https://www.w3.org/TR/2016/WD-cssom-view-1-20160317/#dom-element-clientheight
  const { clientHeight, clientWidth } =
    document.compatMode === 'BackCompat'
      ? window.document.body
      : window.document.documentElement;

  // Sort positions so that initial placement is the first to attempt
  const { [placement]: first, ...rest } = positions;
  const options = { [placement]: first, ...rest };

  // Calculate optimal position based on which position has the least amount of
  // overflow on all sides.
  const [{ position: optimal }] = Object.keys(options)
    .filter(option => allowedPlacements.includes(option))
    .reduce((scores, attempt) => {
      const { top, left } = positions[attempt];

      // Amount of overflow on each side of the attachment
      const overflows = {
        top: -1 * top,
        right: left + width - clientWidth,
        bottom: top + height - clientHeight,
        left: -1 * left,
      };

      // Sum of overflow with negative values (non-overflow) normalized to zero
      const score = Object.values(overflows).reduce(
        (sum, overflow) => sum + Math.max(overflow, 0),
        0
      );

      return [...scores, { position: attempt, score }];
    }, [])
    .sort((a, b) => a.score - b.score);

  return optimal;
};

/**
 * Get the direction the fade should enter/exit from
 *
 * @param {string} placement - Optimal placement of attachment
 * @return {string} Direction of fade
 */
const getFadeDirection = placement => {
  const [side] = placement.split('-');

  return {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[side];
};

export default ({
  attached,
  offset,
  placement,
  visible,
  allowedPlacements,
  minTop,
}) => {
  const $builderRoot = useContext(RootContext);
  const target = useRef();
  const attachment = useRef();

  const [tether, setTether] = useState({});

  // Determine the optimal top/left coordinates and direction of fade animation
  // for tethered attachment from based on the position of the target
  const reposition = useCallback(() => {
    const { current: $target } = target;
    const { current: $attachment } = attachment;

    const rect = getBoundingClientRect($target);
    const { height, width } = getBoundingClientRect($attachment);

    const dimensions = { height, width };

    const positions = getPossiblePositions({ dimensions, offset, rect });
    const optimal = getOptimalPlacement({
      dimensions,
      placement,
      positions,
      allowedPlacements,
    });

    const { top, left } = positions[optimal];

    // make sure top value will have at least space for the builder dropdown
    const position = {
      top: Math.max(top, minTop),
      left,
    };

    setTether({
      fade: getFadeDirection(optimal),
      placement: optimal,
      position,
    });
  }, [minTop, offset, placement, allowedPlacements]);

  // If the attachment has been attached based on `Fade#onEnter`, manually
  // trigger repositioning once more to pick up the dimensions of the attachment
  // to ensure correct positioning
  useEffect(() => {
    if (attached) {
      reposition();
    }
  }, [attached, reposition]);

  // Bind scroll and resize event listeners to trigger repositioning
  useEffect(() => {
    // Skip binding handlers if the attachment is not visible
    if (!visible) {
      return;
    }

    // Create new debounced reposition handler
    const handler = debounce(reposition, 100);

    // Immediately reposition on mount and force attachment to render. The
    // position and fade may actually be incorrect here, but will be corrected
    // once attached via `Fade#onEnter`. This is needed so that Fade renders the
    // attachment at all so the subsequent reposition can capture the actual
    // dimensions of the attachment
    reposition();

    window.addEventListener('resize', handler);
    window.addEventListener('scroll', handler);
    $builderRoot.addEventListener('scroll', handler, true);

    // this is to enable Tether monitor effectively the positions
    // of both target and attachment at all times

    const observer = new ResizeObserver(handler);
    const { current: $target } = target;
    const { current: $attachment } = attachment;

    if ($target) {
      observer.observe($target);
    }
    if ($attachment) {
      observer.observe($attachment);
    }
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('scroll', handler);
      $builderRoot.removeEventListener('scroll', handler, true);
      if ($target) observer.unobserve($target);
      if ($attachment) observer.unobserve($attachment);
    };
  }, [reposition, visible, $builderRoot, target, attachment]);

  return [{ attachment, target }, tether];
};
