import React from 'react';
import styled from 'styled-components';
import { Button, Input, Icon } from '@appcues/sonar';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';

export const Wrapper = styled.div`
  ${Input} {
    width: 100%;
  }
`;

export const Container = styled.ol`
  border: 1px solid var(--color-neutral-500);
  border-radius: 6px;
  min-width: 314px;
  width: 100%;
  height: 200px;
  margin-top: 12px;
  padding: 0;
  overflow-y: auto;
`;

export const Loading = styled.div`
  color: var(--color-neutral-800);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Item = styled.li`
  display: block;
  color: var(--color-neutral-800);
  padding: 8px 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: var(--color-neutral-50);
  }

  &[aria-selected='true'] {
    background-color: var(--background-button-secondary-active);
  }
`;

const WarningIcon = styled(Icon).attrs({
  icon: faTriangleExclamation,
})`
  color: var(--color-yellow-400);
`;

export const WarningIconButton = styled(Button).attrs({
  'aria-label': 'warning icon button',
  iconOnly: 'true',
  variant: 'tertiary',
  children: <WarningIcon />,
})`
  && {
    width: 16px;
    height: 16px;
    padding: 9px 12px;
    min-width: 16px;
  }
`;
