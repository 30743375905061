import { v4 as uuid } from 'uuid';

/**
 * Replace ids in an object
 *
 * @param {element} element - element to replace IDs to be cloned
 * @return {element} element with replaced IDs
 */
export default function replaceIds(element, newId = uuid()) {
  // As soon as we find a primitive value, we return it
  if (typeof element !== 'object' || element === null) {
    return element;
  }

  // Replace ids in arrays
  if (Array.isArray(element)) {
    return element.map(item => replaceIds(item, uuid()));
  }

  // Replace ids in objects
  return Object.entries(element).reduce((acc, [key, value]) => {
    if (key === 'id') {
      return { ...acc, [key]: newId };
    }
    return { ...acc, [key]: replaceIds(value, uuid()) };
  }, {});
}
