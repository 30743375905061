import { EFFECTS } from 'lib/trait';

export const effectsTemplate = presentation => ({
  type: EFFECTS,
  config: {
    presentationStyle: presentation,
    duration: 2000,
    intensity: 1,
    style: {
      colors: ['#d62828', '#fcbf49', '#003049'],
    },
  },
});
