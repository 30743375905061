import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Input, Switch } from '@appcues/sonar';
import { Accordion, FieldSet, FontIcon, Label } from 'ext/components/ui';
import startCase from 'lodash.startcase';
import { Shape as CustomComponentShape } from 'entities/custom-components';
import { Shape as BlockContentShape } from 'entities/block';
import { CUSTOM_COMPONENT_PROPERTY_TYPE as PROPERTY_TYPE } from 'lib/block';
import {
  Controls,
  HelpLabel,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';

function CustomComponent({ content, component, onChange }) {
  const trackAccordion = useAccordionClick();
  const { id, config = {} } = content ?? {};
  const { content: componentDefinition } = component ?? {};

  const handleValueChange = useCallback(
    (key, newValue) => {
      onChange({
        blockId: id,
        contentChunk: {
          config: {
            ...config,
            [key]: newValue,
          },
        },
      });
    },
    [id, config, onChange]
  );

  const debouncedHandleValueChange = useMemo(
    () => debounce(handleValueChange, 300),
    [handleValueChange]
  );

  const fieldFor = (name, type, description) => {
    const label = startCase(name);

    if (type === PROPERTY_TYPE.BOOLEAN) {
      return (
        <FieldSet key={name}>
          <Switch
            id={name}
            checked={config[name]}
            onCheckedChange={() => handleValueChange(name, !config[name])}
            fullWidth
          >
            <Switch.Label htmlFor={name}>
              {label}
              {description && <HelpLabel>{description}</HelpLabel>}
            </Switch.Label>
          </Switch>
        </FieldSet>
      );
    }

    const inputType = type === PROPERTY_TYPE.NUMBER ? 'number' : 'text';

    const handleChange = ({ target }) => {
      const newValue =
        type === PROPERTY_TYPE.NUMBER
          ? Number.parseFloat(target.value)
          : target.value;

      debouncedHandleValueChange(name, newValue);
    };

    return (
      <FieldSet key={name}>
        <Label htmlFor={name}>
          {label}
          {description && <HelpLabel>{description}</HelpLabel>}
        </Label>
        <Input
          id={name}
          defaultValue={config[name]}
          onChange={handleChange}
          type={inputType}
          placeholder={label}
        />
      </FieldSet>
    );
  };

  if (componentDefinition?.properties.length === 0) {
    return null;
  }

  return (
    <Accordion.Item value="configuration">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion('Custom Component', 'Configuration')}
        >
          Configuration
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          {componentDefinition?.properties.map(({ name, type, description }) =>
            fieldFor(name, type, description)
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

CustomComponent.propTypes = {
  content: BlockContentShape,
  component: CustomComponentShape,
  onChange: PropTypes.func,
};

export default CustomComponent;
