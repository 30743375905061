/* eslint-disable no-shadow */

/**
 * Build paths to content block
 *
 * @param {Node} node - Node such as a step or content block
 * @param {array<string>} path - Current path
 * @param {string} variationKey - Variation key
 * @return {object<array<string>>} Collected paths
 */
export default function collect(node, path = [], variationKey = '') {
  if (!node) return {};

  function handleVariations(node, path) {
    if (!node.variations) return {};

    return Object.keys(node.variations).reduce(
      (acc, key) => ({
        ...acc,
        ...collect(node.variations[key], [...path, 'variations', key], key),
      }),
      {}
    );
  }

  function handleNode(node, path) {
    if (!node) return {};

    return {
      ...collect(node, path),
      ...handleVariations(node, path),
    };
  }

  function handleFields(node, path, fields) {
    return fields.reduce(
      (acc, field) => ({
        ...acc,
        ...handleNode(node?.[field], [...path, 'content', field]),
      }),
      {}
    );
  }

  function handleItemsOrOptions(path, itemsOrOptions, field) {
    return itemsOrOptions.reduce((acc, item, index) => {
      return {
        ...acc,
        ...handleNode(item, [...path, field, index]),
        ...handleNode(item?.content, [...path, field, index, 'content']),
      };
    }, {});
  }

  const { id, content, items, options } = node;

  const idPath = {};
  const idKey = variationKey ? `${id}/${variationKey}` : id;
  if (idKey) idPath[idKey] = path;

  const contentPaths = handleNode(content, [...path, 'content']);

  const fieldPaths = handleFields(content, path, [
    'errorLabel',
    'label',
    'placeholder',
  ]);

  const itemsOrOptions = options || items;
  const itemsOrOptionPaths = itemsOrOptions
    ? handleItemsOrOptions(path, itemsOrOptions, options ? 'options' : 'items')
    : {};

  return {
    ...idPath,
    ...contentPaths,
    ...fieldPaths,
    ...itemsOrOptionPaths,
  };
}
