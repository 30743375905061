import React from 'react';
import PropTypes from 'prop-types';
import { Shape as AuthShape } from 'lib/auth';
import { Shape as UserShape } from 'entities/user';
import Providers from './Providers';

export default function Root({ auth, user, children }) {
  return (
    <Providers auth={auth} user={user}>
      {children}
    </Providers>
  );
}

Root.propTypes = {
  auth: AuthShape,
  user: UserShape,
  children: PropTypes.node.isRequired,
};
