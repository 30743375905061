import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { STICKY_OPTIONS } from 'lib/block';
import ToolbarButton from './ToolbarButton';
import { Toolbar } from './styled';

const ActionsToolbar = forwardRef(
  ({ sticky: { row, handleSticky } = {}, handleClone, handleDelete }, ref) => (
    <Toolbar ref={ref}>
      {handleSticky && (
        <ToolbarButton type="sticky" onClick={handleSticky} row={row} />
      )}
      {handleClone && <ToolbarButton type="clone" onClick={handleClone} />}
      {handleDelete && <ToolbarButton type="delete" onClick={handleDelete} />}
    </Toolbar>
  )
);

ActionsToolbar.propTypes = {
  sticky: PropTypes.shape({
    row: PropTypes.shape({
      id: PropTypes.string,
      ableToStickyPosition: PropTypes.oneOf(STICKY_OPTIONS),
      isSticky: PropTypes.bool,
    }),
    handleSticky: PropTypes.func,
  }),
  handleClone: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default ActionsToolbar;
