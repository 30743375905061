import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import PropertyInputs from './PropertyInputs';

const MarginTheme = `
  --margin-background: var(--color-neutral-700);
  --margin-color: var(--color-neutral-200);
  --margin-border-color: var(--border-input-default);
  

  [data-theme='light'] & {
    --margin-background: var(--light-text-color);
    --margin-color: var(--background);
    --margin-border-color: var(--text-color);
  }
`;

const PaddingTheme = `
  --padding-background: var(--color-neutral-500);

  [data-theme='light'] & {
    --padding-background: var(--text-color);
  }
`;

const CenterTheme = `
  --center-background: var(--color-neutral-950);
  --center-color: var(--color-neutral-200);

  [data-theme='light'] & {
    --center-background: var(--disabled);
    --center-color: var(--white);
  }
`;

const MarginContainer = styled.div`
  ${MarginTheme}
  background: var(--margin-background);
  border-radius: 6px;
  border: 1px solid var(--margin-border-color);
  padding: 26px 38px;
  position: relative;

  &::after {
    color: var(--margin-color);
    content: attr(aria-label);
    display: inline-block;
    font-size: 10px;
    font-style: normal;
    font-weight: bold;
    left: 9px;
    position: absolute;
    top: 4px;
    width: 0;
  }
`;

const PaddingContainer = styled(MarginContainer)`
  ${PaddingTheme}
  background: var(--padding-background);
  padding: 24px 44px;

  ${({ hasMargin }) =>
    !hasMargin &&
    `
    background: var(--noreaster);
    border: none;
  `}
`;

const Center = styled.div`
  ${CenterTheme}
  background: var(--center-background);
  border-radius: 6px;
  color: var(--center-color);
  font-size: var(--x-small);
  line-height: 18px;
  height: 18px;
  width: 100%;
  text-align: center;
  padding: 0 8px;
`;

export const PaddingEditor = ({
  onPaddingUpdate,
  padding,
  hasMargin,
  label = 'Content',
}) => (
  <PaddingContainer aria-label="Padding" hasMargin={hasMargin}>
    <PropertyInputs
      namePrefix="padding"
      horizontalGap="2px"
      left={padding.left}
      top={padding.top}
      right={padding.right}
      bottom={padding.bottom}
      onUpdate={onPaddingUpdate}
    />
    <Center>{label}</Center>
  </PaddingContainer>
);

export const MarginEditor = ({
  onMarginUpdate,
  margin,
  label = 'Content',
  children,
}) => (
  <MarginContainer aria-label="Margin">
    <PropertyInputs
      namePrefix="margin"
      horizontalGap="3px"
      left={margin.left}
      top={margin.top}
      right={margin.right}
      bottom={margin.bottom}
      onUpdate={onMarginUpdate}
    />
    {children || <Center>{label}</Center>}
  </MarginContainer>
);

export const MarginPaddingEditor = ({
  onMarginUpdate,
  onPaddingUpdate,
  margin,
  padding,
  label = 'Content',
}) => {
  if (!margin && !padding) return null;

  if (padding && !margin) {
    return (
      <PaddingEditor
        onPaddingUpdate={onPaddingUpdate}
        padding={padding}
        hasMargin={false}
        label={label}
      />
    );
  }

  return (
    <MarginEditor onMarginUpdate={onMarginUpdate} margin={margin} label={label}>
      {padding && (
        <PaddingEditor
          onPaddingUpdate={onPaddingUpdate}
          padding={padding}
          label={label}
          hasMargin
        />
      )}
    </MarginEditor>
  );
};

const PositionsShape = PropTypes.shape({
  left: PropTypes.number,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
});

MarginEditor.propTypes = {
  onMarginUpdate: PropTypes.func,
  margin: PositionsShape,
  label: PropTypes.string,
  children: PropTypes.node,
};

PaddingEditor.propTypes = {
  onPaddingUpdate: PropTypes.func,
  padding: PositionsShape,
  label: PropTypes.string,
  hasMargin: PropTypes.bool,
};

MarginPaddingEditor.propTypes = {
  onMarginUpdate: PropTypes.func,
  onPaddingUpdate: PropTypes.func,
  margin: PositionsShape,
  padding: PositionsShape,
  label: PropTypes.string,
};

export default MarginPaddingEditor;
