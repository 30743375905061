import { get } from 'ext/lib/local-storage';

export const DEMOCUES_ID = 'democues';
export const DEMOCUES_KEY = `appcues:preview:${DEMOCUES_ID}`;

export const TYPES = {
  DEBUGGER: 'debugger',
  PREVIEW: 'preview',
  CAPTURE_SCREEN: 'capture_screen',
  PUSH_PREVIEW: 'push_preview',
};

/**
 * Generate preview deep link from app and experience IDs
 *
 * @param {string} type - Type of the deeplink to generate
 * @param {string} appId - App ID
 * @param {string} experienceId - Experience ID in case it's a type=preview
 * @param {string} pushId - push ID in case it's a type=push_preview
 * @param {string} sdkToken - JWT token from custom session for Mobile SDK to be used in capture screen
 * @param {string} localeId - Locale ID to be used for display in specific language
 * @param {string} userId - User ID to be used for personalization
 *
 * @return {string} Mobile deep link
 */
export const generateDeeplink = ({
  type,
  appId,
  experienceId,
  pushId,
  sdkToken,
  localeId,
  userId,
  timestamp,
}) => {
  const previewId = get(DEMOCUES_KEY) ? DEMOCUES_ID : appId;
  const baseLink = `appcues-${previewId}://sdk`;

  const queryParams = {
    locale_id: localeId,
    user_id: userId,
    token: sdkToken,
    t: timestamp,
  };

  const searchParams = new URLSearchParams(
    Object.entries(queryParams).filter(([, value]) => value)
  ).toString();

  const queryString = searchParams === '' ? '' : `?${searchParams}`;

  const types = {
    [TYPES.DEBUGGER]: `debugger${queryString}`,
    [TYPES.PREVIEW]: `experience_preview/${experienceId}${queryString}`,
    [TYPES.CAPTURE_SCREEN]: `capture_screen${queryString}`,
    [TYPES.PUSH_PREVIEW]: `push_preview/${pushId}${queryString}`,
  };

  return `${baseLink}/${types[type]}`;
};
