import React from 'react';
import PropTypes from 'prop-types';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faPalette } from '@fortawesome/free-solid-svg-icons/faPalette';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons/faCrosshairs';
import { Tabs } from '@appcues/sonar';
import { Accordion } from 'ext/components/ui';
import { EditorShape } from 'entities/step-groups';
import { Shape as TraitsShape } from 'entities/trait';
import { Shape as ScreenShape } from 'entities/screens';
import { Shape as ActionsShape } from 'lib/actions';
import { THEMES } from 'lib/user-preferences';
import { getSelectedTab } from 'lib/user-interface';
import {
  TARGET_RECTANGLE,
  TARGET_ELEMENT,
  BACKDROP_KEYHOLE,
  TARGET_INTERACTION,
} from 'lib/trait';
import { ANCHORED } from 'lib/presentation';
import { PLATFORMS } from 'lib/platform';
import {
  Content,
  Container,
  Navigation,
  DropShadow,
  Backdrop,
  AlignmentSpacing,
} from 'components/SideBarSettings/Shared';
import Details from './Details';
import Pointer from './Pointer';
import Highlight from './Highlight';
import HighlightElement from './HighlightElement';
import TargetPlacement from './TargetPlacement';
import TargetAction from './TargetAction';
import TooltipPosition from './TooltipPosition';

export function TooltipSettings({
  editor,
  platform,
  selectedStepGroup,
  selectedStepChild,
  selectedScreen,
  actions,
  traits,
  isStepGroupFirstChild,
  selectedSideBarTab,
  targetPlacement,
  theme,
  handleActionsUpdate,
  handleTraitsUpdate,
  onChange,
  onSideBarTabUpdate,
  onStepsUpdate,
  onTargetPlacementUpdate,
}) {
  const { presentation } = editor ?? {};
  const isAnchored = presentation === ANCHORED;

  let targetArea;
  let backdropKeyhole;
  let targetInteraction;

  traits?.forEach(trait => {
    const { type } = trait;
    if (isAnchored ? type === TARGET_ELEMENT : type === TARGET_RECTANGLE) {
      targetArea = trait;
    }
    if (type === BACKDROP_KEYHOLE) backdropKeyhole = trait;
    if (type === TARGET_INTERACTION) targetInteraction = trait;
  });

  const selectedTab = getSelectedTab({
    selectedSideBarTab,
    isStepGroupFirstChild,
    isAnchored,
  });

  const handleTabClick = (tab = null) => {
    onSideBarTabUpdate({ stepGroup: selectedStepGroup, tab });
  };

  return (
    <Content>
      <Tabs.Root
        key={selectedStepChild}
        defaultValue={selectedTab}
        align="stretch"
      >
        <Tabs.List>
          <Tabs.Trigger
            value="style"
            icon={faPalette}
            onClick={() => handleTabClick('style')}
          >
            Style
          </Tabs.Trigger>
          <Tabs.Trigger
            value="target"
            icon={faCrosshairs}
            onClick={() => handleTabClick('target')}
          >
            Target
          </Tabs.Trigger>
          {isStepGroupFirstChild && (
            <Tabs.Trigger
              value="navigation"
              icon={faShare}
              onClick={() => handleTabClick('navigation')}
            >
              Navigation
            </Tabs.Trigger>
          )}
        </Tabs.List>
        <Tabs.Content value="style">
          <Accordion.Root
            collapsible="true"
            defaultValue={['details', 'container']}
            type="multiple"
          >
            <Details
              traits={traits}
              editor={editor}
              platform={platform}
              onChange={onChange}
              onStepsUpdate={onStepsUpdate}
            />

            <Container editor={editor} theme={theme} onChange={onChange} />

            <Pointer editor={editor} onChange={onChange} />

            <DropShadow editor={editor} theme={theme} onChange={onChange} />

            <Backdrop editor={editor} theme={theme} onChange={onChange} />

            <AlignmentSpacing
              style={editor.style}
              spacingLabel="Content"
              onChange={onChange}
              hasPadding
            />
          </Accordion.Root>
        </Tabs.Content>
        <Tabs.Content value="target">
          <Accordion.Root
            collapsible="true"
            defaultValue={['target-position', 'highlight']}
            type="multiple"
          >
            <TargetPlacement
              isAnchored={isAnchored}
              selectedScreen={selectedScreen}
              targetArea={targetArea}
              targetPlacement={targetPlacement}
              handleTraitsUpdate={handleTraitsUpdate}
              onTargetPlacementUpdate={onTargetPlacementUpdate}
            />

            {!isAnchored ? (
              <Highlight
                isAnchored={isAnchored}
                backdropKeyhole={backdropKeyhole}
                targetRectangle={targetArea}
                handleTraitsUpdate={handleTraitsUpdate}
              />
            ) : (
              <HighlightElement
                backdropKeyhole={backdropKeyhole}
                handleTraitsUpdate={handleTraitsUpdate}
              />
            )}

            <TargetAction
              targetInteraction={targetInteraction}
              handleTraitsUpdate={handleTraitsUpdate}
            />

            <TooltipPosition
              targetArea={targetArea}
              handleTraitsUpdate={handleTraitsUpdate}
            />
          </Accordion.Root>
        </Tabs.Content>
        {isStepGroupFirstChild && (
          <Tabs.Content value="navigation">
            <Navigation
              stepGroup={selectedStepGroup}
              actions={actions}
              handleActionsUpdate={handleActionsUpdate}
            />
          </Tabs.Content>
        )}
      </Tabs.Root>
    </Content>
  );
}

TooltipSettings.propTypes = {
  editor: EditorShape,
  platform: PropTypes.oneOf(PLATFORMS),
  selectedStepGroup: PropTypes.string,
  selectedStepChild: PropTypes.string,
  selectedScreen: ScreenShape,
  actions: ActionsShape,
  traits: TraitsShape,
  isStepGroupFirstChild: PropTypes.bool,
  selectedSideBarTab: PropTypes.string,
  targetPlacement: PropTypes.bool,
  theme: PropTypes.oneOf(THEMES),
  handleActionsUpdate: PropTypes.func,
  handleTraitsUpdate: PropTypes.func,
  onChange: PropTypes.func,
  onStepsUpdate: PropTypes.func,
  onSideBarTabUpdate: PropTypes.func,
  onTargetPlacementUpdate: PropTypes.func,
};

export default TooltipSettings;
