import { FONT_FAMILIES, FONT_WEIGHTS } from './fonts';

export const getFontDetails = font => {
  const fontDetails = {
    fontFamily: FONT_FAMILIES.default,
    fontWeight: FONT_WEIGHTS.medium,
  };

  // Handle system fonts
  if (font.includes('System')) {
    const [, family, weight] = font.toLowerCase().split(' ');
    const fontFamily = FONT_FAMILIES[family];
    const fontWeight = FONT_WEIGHTS[weight];

    return {
      ...fontDetails,
      ...(fontFamily && { fontFamily }),
      ...(fontWeight && { fontWeight }),
    };
  }

  // Handle custom fonts
  const [customFamily, weight] = font.split('-');
  const [family, hasItalic] = customFamily.split('Italic');
  const fontFamily = `'${family}', ${FONT_FAMILIES.default}`;
  const fontWeight = FONT_WEIGHTS[weight?.toLowerCase()];
  const fontStyle = hasItalic === '' && 'italic';

  return {
    ...fontDetails,
    fontFamily,
    ...(fontWeight && { fontWeight }),
    ...(fontStyle && { fontStyle }),
  };
};
