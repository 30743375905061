import { modalTemplate } from 'entities/step-groups';
import { targetRectangleTemplate } from 'entities/step-children';
import { continueType } from 'lib/actions';
import { ANCHORED, FLOATING } from 'lib/presentation';
import {
  EMBED,
  MODAL,
  SCREEN,
  TARGET_ELEMENT,
  TARGET_INTERACTION,
  TARGET_RECTANGLE,
  TOOLTIP,
} from 'lib/trait';

// Remove any custom step destinations in favor of basic "next step"
const mapActions = actions =>
  actions.map(action =>
    action.type === continueType ? { ...action, config: {} } : action
  );

// Filter out styles that are not supported by the new presentation style
const filterModalStyles = (style, newPresentation, oldPresentation) => {
  if (newPresentation === oldPresentation) return style;
  const {
    borderColor,
    borderWidth,
    verticalAlignment,
    horizontalAlignment,
    width,
    ...rest
  } = style || {};
  return rest;
};

const mapModal = (currentConfig, trait, presentation) => {
  const newPresentation =
    presentation ??
    currentConfig?.presentationStyle ??
    trait.config.presentationStyle;
  const baseTemplate = modalTemplate(newPresentation)[0];

  return {
    ...baseTemplate,
    config: {
      ...baseTemplate.config,
      style: {
        ...baseTemplate.config.style,
        ...filterModalStyles(
          trait.config.style,
          newPresentation,
          trait.config.presentationStyle
        ),
      },
    },
  };
};

const handleTargetElement = (trait, traitConfigs, presentation) => {
  if (presentation === FLOATING) return targetRectangleTemplate;
  if (traitConfigs[TARGET_RECTANGLE])
    return { type: TARGET_RECTANGLE, config: traitConfigs[TARGET_RECTANGLE] };
  if (traitConfigs[TARGET_ELEMENT])
    return { type: TARGET_ELEMENT, config: traitConfigs[TARGET_ELEMENT] };
  return trait.type === TARGET_RECTANGLE ? targetRectangleTemplate : null;
};

const handleTargetRectangle = (trait, traitConfigs, presentation) => {
  if (presentation === ANCHORED) return null;
  if (traitConfigs[TARGET_RECTANGLE])
    return { type: TARGET_RECTANGLE, config: traitConfigs[TARGET_RECTANGLE] };
  if (traitConfigs[TARGET_ELEMENT])
    return { type: TARGET_ELEMENT, config: traitConfigs[TARGET_ELEMENT] };
  return trait;
};

export const mapTraits = ({
  currentTraits = [],
  templateTraits,
  presentation,
}) => {
  const traitConfigs = Object.fromEntries(
    currentTraits.map(item => [item.type, item.config])
  );

  const traitMappers = {
    // Group traits
    [EMBED]: trait => ({
      ...trait,
      config: { ...trait.config, frameID: traitConfigs[EMBED]?.frameID },
    }),
    [MODAL]: trait => mapModal(traitConfigs[MODAL], trait, presentation),
    [TOOLTIP]: trait => ({
      ...trait,
      config: {
        ...trait.config,
        presentationStyle:
          presentation ??
          traitConfigs[TOOLTIP]?.presentationStyle ??
          trait.config.presentationStyle,
      },
    }),
    // Child traits
    [SCREEN]: () => null,
    [TARGET_INTERACTION]: trait => ({
      ...trait,
      config: {
        ...trait.config,
        actions: trait.config?.actions
          ? mapActions(trait.config.actions)
          : undefined,
      },
    }),
    [TARGET_ELEMENT]: trait =>
      handleTargetElement(trait, traitConfigs, presentation),
    [TARGET_RECTANGLE]: trait =>
      handleTargetRectangle(trait, traitConfigs, presentation),
  };

  const updatedTraits = (templateTraits ?? [])
    .map(trait => (traitMappers[trait.type] || (t => t))(trait))
    .filter(Boolean);

  if (traitConfigs[SCREEN]) {
    updatedTraits.push({ type: SCREEN, config: traitConfigs[SCREEN] });
  }

  return updatedTraits;
};

export const mapStepChild = ({
  existingStepChild = {},
  step,
  presentation,
}) => ({
  ...existingStepChild,
  ...step,
  traits: mapTraits({
    currentTraits: existingStepChild?.traits,
    templateTraits: step.traits,
    presentation,
  }),
  actions: Object.fromEntries(
    Object.entries(step.actions).map(([blockId, actions]) => [
      blockId,
      mapActions(actions),
    ])
  ),
});
