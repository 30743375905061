import { v4 as uuid } from 'uuid';
import {
  BLOCK,
  TEXT,
  SINGLE_SELECT,
  OPTION_SELECT,
  BLOCK_MODES,
} from 'lib/block';

const [SINGLE] = BLOCK_MODES[OPTION_SELECT];

const singleSelectTemplate = ({ blockId, elementId }) => ({
  type: BLOCK,
  blockType: SINGLE_SELECT,
  id: blockId,
  content: {
    type: OPTION_SELECT,
    id: elementId,
    label: {
      type: TEXT,
      id: uuid(),
      text: 'Question label',
      style: {
        marginBottom: 12,
        foregroundColor: { light: '#000000' },
        horizontalAlignment: 'leading',
        textAlignment: 'leading',
      },
    },
    selectMode: SINGLE,
    options: [
      {
        value: 'Yes',
        content: {
          id: uuid(),
          type: TEXT,
          text: 'Yes',
          style: {
            textAlignment: 'leading',
            foregroundColor: { light: '#000000' },
          },
        },
      },
      {
        value: 'No',
        content: {
          id: uuid(),
          type: TEXT,
          text: 'No',
          style: {
            textAlignment: 'leading',
            foregroundColor: { light: '#000000' },
          },
        },
      },
    ],
    controlPosition: 'leading',
    displayFormat: 'verticalList',
    selectedColor: { light: '#000000' },
    unselectedColor: { light: '#627293' },
    accentColor: { light: '#FFFFFF' },
    style: {
      horizontalAlignment: 'leading',
      marginTop: 12,
      marginLeading: 24,
      marginTrailing: 24,
      marginBottom: 12,
    },
    errorLabel: {
      type: TEXT,
      id: uuid(),
      text: 'Required field',
      style: {
        foregroundColor: { light: '#D6216B' },
        horizontalAlignment: 'leading',
        textAlignment: 'leading',
        fontSize: 14,
        marginTop: 10,
      },
    },
  },
});

export default singleSelectTemplate;
