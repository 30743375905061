import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useToggle from 'ext/lib/hooks/use-toggle';
import useEscape from 'ext/lib/hooks/use-escape';
import Tether from 'ext/components/Tether';

import FontIcon from './FontIcon';
import Input from './Input';
import { MenuOverlay } from './Menu';

const theme = `
  --dropdown-input-color: var(--noreaster);
  --dropdown-menu-background: var(--diel-gray);
  --dropdown-option-color: var(--noreaster);
  --dropdown-option-bg: var(--sharkbait-ooh-la-la);
  --dropdown-active: var(--noreaster);
  --dropdown-disabled: var(--dirty-water);
  
  [data-theme='light'] & {
    --dropdown-input-color: var(--sharkbait-ooh-la-la);
    --dropdown-menu-background: var(--white);
    --dropdown-option-color: var(--oh-hi-dark);
    --dropdown-option-bg: var(--noreaster);
    --dropdown-active: var(--oh-hi-dark);
    --dropdown-disabled: var(--dirty-water);
  }
`;

const Container = styled.div`
  ${theme}

  color: var(--dropdown-input-color);
  position: relative;
`;

const Select = styled.div`
  position: relative;

  ${Input} {
    color: var(--dropdown-active);
    cursor: pointer;

    &[readonly] {
      color: var(--dropdown-active);
      cursor: pointer;
    }

    &[disabled] {
      cursor: default;
    }
  }

  ${FontIcon} {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: calc(50% - 0.5em);
  }
`;

const Menu = styled.ul`
  ${theme}

  background: var(--dropdown-menu-background);
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  top: 100%;
  left: 0;
  list-style: none;
  margin: 4px 0 0;
  max-height: 240px;
  padding: 0;
  position: absolute;
  overflow-y: scroll;
  z-index: 1;
  color: var(--light-text-color);
`;

const Description = styled.p`
  font-size: var(--regular);
  margin: 12px 0;
  font-weight: var(--normal);
`;

const Option = styled.li`
  color: var(--dropdown-option-color);
  cursor: pointer;
  padding: 12px;

  ${props =>
    props.title ? `font-weight: var(--bold);` : `font-weight: var(--normal);`}

  ${props =>
    !props.disabled &&
    `
    &:hover {
      background: var(--dropdown-option-bg);
    }
  `}

  ${props =>
    props.disabled &&
    `
      cursor: not-allowed;
      color: var(--dropdown-disabled);
  `}

  ${props =>
    props.gap === 'small' &&
    `
    padding: 8px 12px;

    ${Description} {
      margin: 4px 0px;
    }
  `}

  &[data-selected='true'] {
    background: var(--dropdown-option-bg);
  }

  &[data-value=''] {
    font-style: italic;
  }
`;

// modals contains z-index 3
// so we are adding z-index 4 here
// to make sure Dropdown within modals
// will appear on top of it
// due to a conflict on portals usage.
const TetherStyled = styled(Tether)`
  z-index: 4;
`;

const IconWrapper = styled.div`
  display: inline-block;
  width: 15px;
  margin-right: 12px;
`;

// We let option to add and icon from FontAwesome or a custom icon
const OptionIcon = ({ icon }) => {
  return (
    <IconWrapper>
      {typeof icon === 'string' ? (
        <FontIcon size="xs" icon={icon} aria-label={icon} />
      ) : (
        icon
      )}
    </IconWrapper>
  );
};

/**
 * Default dropdown option to represent no value
 * @type {Option}
 */
const NONE = { label: 'None', value: '' };

/**
 * Basic dropdown component
 *
 * Features:
 *   - Uncontrolled components
 *   - Styled to look like input
 *   - Fullscreen click zone to close menu when open
 *   - Default `none` option to deselect value
 *
 * Future considerations:
 *   - Search field within dropdown menu
 *   - Configurable input style/component
 *   - Arrow/keyboard functionality
 */
const Dropdown = ({
  className,
  id,
  defaultValue = '',
  disabled,
  none = false,
  onChange,
  options: provided = [],
  placeholder = 'Select an option',
  gap = 'normal',
  container = null,
}) => {
  const [open, toggleOpen] = useToggle();
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const containerRef = useRef();
  const menuWidth = containerRef?.current?.getBoundingClientRect().width ?? 300;

  useEscape(() => {
    if (open) toggleOpen();
  });

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    const onScroll = () => {
      if (open) {
        toggleOpen();
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [toggleOpen, open]);

  /**
   * Append NONE option if allowed
   *
   * @type {Option[]}
   */
  const options = useMemo(() => {
    return none ? [NONE, ...provided] : provided;
  }, [none, provided]);

  /**
   * Find the selected label based on the selected value
   *
   * @type {string}
   */
  const selectedLabel = useMemo(() => {
    const option = options.find(({ value }) => value === selectedValue);

    if (option) {
      return option.label;
    }

    return none ? NONE.label : '';
  }, [none, options, selectedValue]);

  /**
   * Call provided change handler on click and close menu
   *
   * @param {SyntheticEvent} event - click event
   * @param {boolean} isDisabled - whether option is disabled
   * @return {void}
   */
  const handleChange = (value, isDisabled) => event => {
    if (!isDisabled) {
      onChange(value, event);
      setSelectedValue(value);
      toggleOpen();
    }
  };

  /**
   * Toggle menu when enabled
   *
   * @return {void}
   */
  const handleClick = () => {
    if (!disabled) {
      toggleOpen();
    }
  };

  const menu = ({ ref }) => (
    <>
      <MenuOverlay aria-label="overlay" onClick={toggleOpen} role="button" />
      <Menu
        ref={ref}
        aria-label="menu"
        style={{
          width: menuWidth,
        }}
      >
        {options.map(({ icon, label, value, description, isDisabled }) => (
          <Option
            data-selected={value === selectedValue}
            data-value={value}
            key={value}
            onClick={handleChange(value, isDisabled)}
            role="listitem"
            disabled={isDisabled}
            title={String(Boolean(description))}
            gap={gap}
          >
            {icon && <OptionIcon icon={icon} />}
            {label}
            {description && (
              <Description aria-label="description">{description}</Description>
            )}
          </Option>
        ))}
      </Menu>
    </>
  );

  return (
    <Container className={className} ref={containerRef}>
      <TetherStyled
        container={container}
        attachment={menu}
        placement="bottom-left"
        visible={open}
        offset={{
          x: -menuWidth,
        }}
      >
        <Select onClick={handleClick}>
          <Input
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            readOnly
            role="listbox"
            value={selectedLabel}
          />
          {!disabled && <FontIcon icon="caret-down" />}
        </Select>
      </TetherStyled>
    </Container>
  );
};

OptionIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Dropdown.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  none: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  gap: PropTypes.oneOf(['normal', 'small']),
  // eslint-disable-next-line react/forbid-prop-types
  container: PropTypes.any,
};

export default styled(Dropdown)``;
