import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomColorPicker from './CustomColorPicker';

const ColorPicker = ({ color, onChange, onChangeComplete }) => {
  const [currentColor, setCurrentColor] = useState(color);

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const handleChange = newColor => {
    setCurrentColor(newColor.rgb);
    onChange?.(newColor.rgb);
  };

  const handleChangeComplete = newColor => {
    setCurrentColor(newColor.rgb);
    onChangeComplete?.(newColor.rgb);
  };

  return (
    <CustomColorPicker
      color={currentColor}
      onChange={handleChange}
      onChangeComplete={handleChangeComplete}
    />
  );
};

ColorPicker.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onChangeComplete: PropTypes.func,
};

export { ColorPicker };
