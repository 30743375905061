/**
 * Traits types
 *
 * @constant
 */
export const MODAL = '@appcues/modal';
export const TOOLTIP = '@appcues/tooltip';
export const EMBED = '@appcues/embedded';
export const SKIPPABLE = '@appcues/skippable';
export const CAROUSEL = '@appcues/carousel';
export const PAGING_DOTS = '@appcues/paging-dots';
export const BACKGROUND_CONTENT = '@appcues/background-content';
export const BACKDROP = '@appcues/backdrop';
export const BACKDROP_KEYHOLE = '@appcues/backdrop-keyhole';
export const TARGET_RECTANGLE = '@appcues/target-rectangle';
export const TARGET_ELEMENT = '@appcues/target-element';
export const TARGET_INTERACTION = '@appcues/target-interaction';
export const STEP_TRANSITION_ANIMATION = '@appcues/step-transition-animation';
export const SCREEN = '@appcues/screen-capture';
export const EFFECTS = '@appcues/effects';

/**
 * Traits
 *
 * @constant
 */
export const TRAITS = [
  MODAL,
  TOOLTIP,
  EMBED,
  SKIPPABLE,
  CAROUSEL,
  PAGING_DOTS,
  BACKGROUND_CONTENT,
  BACKDROP,
  BACKDROP_KEYHOLE,
  TARGET_RECTANGLE,
  TARGET_ELEMENT,
  TARGET_INTERACTION,
  STEP_TRANSITION_ANIMATION,
  SCREEN,
  EFFECTS,
];

/**
 * Trait labels
 *
 * @constant
 */
export const TRAIT_LABELS = {
  [MODAL]: 'Modal',
  [TOOLTIP]: 'Tooltip',
  [EMBED]: 'Embed',
  [PAGING_DOTS]: 'Step indicator',
  [SKIPPABLE]: 'Close X',
};

/**
 * Trait preview id selectors
 *
 * @constant
 */
export const PREVIEW_MODAL_ID = 'preview-modal-id';
export const PREVIEW_TOOLTIP_ID = 'preview-tooltip-id';
export const PREVIEW_EMBED_ID = 'preview-embed-id';

/**
 * DO NOT CHANGE THIS!
 * Selector to be used on the image service
 * See more: https://dev-docs.appcues.com/prod/builder-ui/docs/The%20Mobile%20Builder/image-service
 * @constant
 */
export const IMAGE_SERVICE_SELECTOR = 'image-service-selector';

/**
 * Layout traits
 *
 * @constant
 */
export const LAYOUT_TRAITS = [MODAL, TOOLTIP, EMBED];

/**
 * Effects presentations
 *
 * @constant
 */
export const CONFETTI = 'confetti';

/**
 * Skippable options
 *
 * @constant
 */
export const SKIPPABLE_OPTIONS = {
  DEFAULT: 'default',
  MINIMAL: 'minimal',
  HIDDEN: 'hidden',
};
