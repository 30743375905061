import styled from 'styled-components';
import { ButtonGroup } from '@appcues/sonar';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${ButtonGroup} {
    justify-content: flex-end;
    margin-top: 8px;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 12px;
  color: var(--pleather);
  font-size: var(--regular);
  font-weight: var(--bold);
`;

export const Error = styled.span`
  color: var(--foreground-error);
  font-size: var(--x-small);
  margin-top: 8px;
`;
