import React from 'react';

export const TooltipIcon = (
  <svg role="img" viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg">
    <rect
      x=".099976"
      y=".59998"
      width="12.8"
      height="8.7273"
      rx="2"
      fill="#5C5CFF"
    />
    <path d="m6.5 13.4-2.5194-4.3637h5.0387l-2.5193 4.3637z" fill="#5C5CFF" />
  </svg>
);
