import useAnalytics from 'ext/lib/hooks/use-analytics';

const useAccordionClick = () => {
  const { track } = useAnalytics();

  const trackAccordion = (accordion, component) =>
    track('Mobile Builder interaction', {
      name: `Clicked ${accordion} ${component} Accordion`,
      component,
    });

  return trackAccordion;
};

export default useAccordionClick;
