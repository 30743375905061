import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import { selectSelected } from 'entities/selected';
import { selectStepChild, ContentShape } from 'entities/step-children';
import { selectActiveLocale } from 'entities/locales';
import { PREVIEW_MODAL_ID } from 'lib/trait';
import {
  STICKY_OPTIONS,
  getRowAndBlockIndex,
  getLastRowIndex,
} from 'lib/block';
import BlockDivider, { HorizontalDragIndicator } from 'components/BlockDivider';
import { BLOCK_TYPE } from 'components/BlockContainer';
import DropZone from 'components/Editor/DropZone';
import {
  RowSelection,
  DragHandle,
  BlockOverlay,
  StickyOverlay,
} from './styled';

const ROW_TYPE = 'ROW_TYPE';

const RowContainer = ({
  id,
  children,
  sticky,
  content,
  hasActiveLocale,
  onDrop,
}) => {
  const [previewModalSpacing, setPreviewModalSpacing] = useState(0);
  const [rowIndex] = getRowAndBlockIndex(content, id);
  const isFirstRow = rowIndex === 0;
  const isLastRow = rowIndex === getLastRowIndex(content);

  const ref = useRef(null);
  const { offsetHeight: height, offsetWidth: width } = ref.current ?? {};

  // This calculates the padding-bottom of the preview modal
  // so we can use to create a spacer on the bottom sticky row
  // to compensate this spacing
  const $previewModal = document.querySelector(`#${PREVIEW_MODAL_ID}`);
  const previewModalPadding =
    ($previewModal && getComputedStyle($previewModal)?.paddingBottom) || 0;

  useEffect(() => {
    if (sticky) {
      setPreviewModalSpacing(previewModalPadding);
    }
  }, [sticky, previewModalPadding]);

  const [{ isDragging }, drag] = useDrag({
    type: ROW_TYPE,
    item: { id },
    canDrag: !hasActiveLocale,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isAbove, canDrop, isOver }, drop] = useDrop({
    accept: ROW_TYPE,
    drop: item => {
      const draggingId = item.id;
      const droppingId = id;

      // Don't replace items with themselves
      if (draggingId === droppingId) {
        return;
      }

      onDrop({ draggingId, droppingId, isRow: true });
    },
    canDrop: item => {
      return item.id !== id;
    },
    collect: monitor => ({
      isAbove: monitor.getDifferenceFromInitialOffset()?.y < 0,
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  drag(drop(ref));

  return (
    <>
      {sticky && isLastRow && <div style={{ height: previewModalSpacing }} />}
      <RowSelection
        ref={ref}
        data-row-id={id}
        isDragging={isDragging}
        sticky={sticky}
      >
        {hasActiveLocale ? (
          children
        ) : (
          <>
            <StickyOverlay id={`sticky-row-overlay-${id}`} />
            {canDrop && isOver && <HorizontalDragIndicator isAbove={isAbove} />}
            <DragHandle />
            <BlockDivider
              rowId={id}
              position="top"
              isFirstRow={isFirstRow}
              isLastRow={isLastRow}
            />
            <>
              {children}
              {/* DropZone is used here to enable the option to move blocks
            from other lines to the last position of the desired line */}
              <DropZone id={id} type={BLOCK_TYPE} onDrop={onDrop} isLastInRow />
              <BlockDivider rowId={id} position="right" />
            </>
            <BlockDivider
              rowId={id}
              position="bottom"
              isFirstRow={isFirstRow}
              isLastRow={isLastRow}
            />
            {isLastRow && (
              <DropZone
                id={id}
                onDrop={onDrop}
                type={BLOCK_TYPE}
                newRow
                isLast
              />
            )}
            {isDragging && <BlockOverlay height={height} width={width} />}
          </>
        )}
      </RowSelection>
    </>
  );
};

RowContainer.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  sticky: PropTypes.oneOf(STICKY_OPTIONS),
  content: ContentShape,
  hasActiveLocale: PropTypes.bool,
  onDrop: PropTypes.func,
};

const mapStateToProps = state => {
  const { stepChild: stepId } = selectSelected(state) ?? {};
  const { content } = selectStepChild(state, stepId) ?? {};
  const { hasActiveLocale } = selectActiveLocale(state) ?? {};

  return { content, hasActiveLocale };
};

export default connect(mapStateToProps)(RowContainer);
