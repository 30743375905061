const isValidMailToUrl = url => {
  // regex to match mailto urls, e.g. mailto:test@appcues
  const regexPattern = /^mailto:\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  return regexPattern.test(url);
};

export const isValidURl = (url, baseURL = '') => {
  if (typeof url !== 'string') return false;

  // check for mailto url
  if (isValidMailToUrl(url)) return true;

  // check for protocol-less URLS
  // in this case we force a protocol just to check if URL is valid
  // url[2] !== '/' is just to make sure we don't accept ///something.com
  if (url.startsWith('//') && url[2] !== '/') {
    return isValidURl(`https:${url}`);
  }

  // check for relative paths
  // in this case we force a baseURL
  // check the comment on line 25 to understand why.
  if (url.startsWith('/') && !baseURL) {
    return isValidURl(url, window.location.origin);
  }

  try {
    const newUrl = baseURL ? new URL(url, baseURL) : new URL(url);
    const matchesExpectedProtocols = ['http:', 'https:'].includes(
      newUrl.protocol
    );

    if (baseURL) {
      // When calling new URL() with a base URL and a relative path
      // if the function returns the .origin equal the base URL
      // it means the relative path is a valid relative path.
      // Otherwise, the function won't fail :/ but will return
      // the origin as the protocol + relative path
      // meaning that this relative path is invalid.
      // e.g
      // new URL('//blah', 'http://appcues.com')
      // will return origin === "http://blah" which is INVALID.
      // and new URL('/blah', 'http://appcues.com')
      // will return origin === "http://appcues.com" which is VALID.
      return matchesExpectedProtocols && newUrl.origin === baseURL;
    }

    return matchesExpectedProtocols;
  } catch {
    return false;
  }
};

export const isValidInAppUrl = url => {
  const regexPattern = /.:\/\//;

  if (typeof url !== 'string') return false;
  if (url.startsWith('http://') || url.startsWith('https://')) return false;

  return regexPattern.test(url);
};
