export const UNDO = '@historyLog/UNDO';
export const undoAction = () => ({ type: UNDO });

export const REDO = '@historyLog/REDO';
export const redoAction = () => ({ type: REDO });

export const CURRENT_UPDATED = '@historyLog/CURRENT_UPDATED';
export const updateCurrent = payload => ({
  type: CURRENT_UPDATED,
  payload,
});

export const HISTORY_LOG_UPDATED = '@historyLog/HISTORY_LOG_UPDATED';
export const updateHistoryLog = payload => ({
  type: HISTORY_LOG_UPDATED,
  payload,
});

export const SELECTED_UPDATED = '@historyLog/SELECTED_UPDATED';
export const updateSelected = payload => ({ type: SELECTED_UPDATED, payload });
