import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { Icon, Switch } from '@appcues/sonar';
import { faPalette } from '@fortawesome/free-solid-svg-icons/faPalette';
import {
  Accordion,
  FieldSet,
  FontIcon,
  Label,
  RadioButtonGroup,
  RadioButton,
} from 'ext/components/ui';
import { EditorShape } from 'entities/step-groups';
import { SKIPPABLE_OPTIONS, TRAIT_LABELS } from 'lib/trait';
import { THEMES } from 'lib/user-preferences';
import ColorInput from 'components/ColorInput';
import InputWithValidation from 'components/InputWithValidation';
import {
  Content,
  Controls,
  useStyleSettings,
} from 'components/SideBarSettings/Shared';
import AlignmentSpacing from './AlignmentSpacing';

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const DEFAULT_STYLES = {
  backgroundColor: { light: '#edf2fad9' },
  foregroundColor: { light: '#627293' },
};

export function SkippableSettings({ editor, onChange, theme = 'light' }) {
  const { skippable } = editor;
  const { buttonStyle } = skippable || {};
  const hasCustomStyle = !!(
    buttonStyle?.backgroundColor || buttonStyle?.foregroundColor
  );
  const hasBackgroundCircle =
    skippable?.buttonAppearance === SKIPPABLE_OPTIONS.DEFAULT;

  const handleBackgroundCircle = hasCircle => {
    const { DEFAULT, MINIMAL } = SKIPPABLE_OPTIONS;
    const buttonAppearance = hasCircle ? DEFAULT : MINIMAL;

    const {
      backgroundColor = DEFAULT_STYLES.backgroundColor,
      foregroundColor = DEFAULT_STYLES.foregroundColor,
      ...alignmentStyles
    } = buttonStyle || {};

    const buttonStyles = hasCircle
      ? { backgroundColor, foregroundColor }
      : { foregroundColor };

    onChange({
      skippable: {
        ...skippable,
        buttonStyle: { ...buttonStyles, ...alignmentStyles },
        buttonAppearance,
      },
    });
  };

  const handleCustomColor = addCustomStyle => {
    // If the button already has custom styles, don't overwrite them
    if (addCustomStyle === hasCustomStyle) return;

    const defaultButtonStyles = addCustomStyle
      ? {
          foregroundColor: DEFAULT_STYLES.foregroundColor,
          ...(hasBackgroundCircle && {
            backgroundColor: DEFAULT_STYLES.backgroundColor,
          }),
        }
      : {};

    const { backgroundColor, foregroundColor, ...alignmentStyles } =
      buttonStyle || {};

    onChange({
      skippable: {
        ...skippable,
        buttonStyle: { ...alignmentStyles, ...defaultButtonStyles },
      },
    });
  };

  const handleChange = patch => {
    onChange({
      skippable: { ...skippable, buttonStyle: patch },
    });
  };

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style: buttonStyle,
    theme,
  });

  const handleSizeChange = value => {
    const size = Number.isNaN(value) ? null : Number(value);

    onChange({
      skippable: {
        ...skippable,
        buttonStyle: { ...buttonStyle, width: size, height: size },
      },
    });
  };

  return (
    <Content>
      <Accordion.Root
        collapsible="true"
        defaultValue={['style', 'alignment-spacing']}
        type="multiple"
      >
        <Accordion.Item value="style">
          <Accordion.Header>
            <Accordion.Trigger>
              Style
              <FontIcon size="sm" icon="chevron-down" />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <Controls>
              <FieldSet>
                <InputWithValidation
                  label="Size"
                  value={valueFor.width || 30}
                  onChange={debounce(handleSizeChange, 500)}
                  max={100}
                  min={30}
                  range
                />
              </FieldSet>

              <FieldSet>
                <Switch
                  id="background-circle"
                  checked={hasBackgroundCircle}
                  onCheckedChange={handleBackgroundCircle}
                  fullWidth
                >
                  <Switch.Label htmlFor="background-circle">
                    Background circle
                  </Switch.Label>
                </Switch>
              </FieldSet>

              <FieldSet>
                <Label>Color</Label>
                <RadioButtonGroup>
                  <RadioButton
                    onClick={() => handleCustomColor(false)}
                    selected={!hasCustomStyle}
                  >
                    Default
                  </RadioButton>
                  <RadioButton
                    onClick={() => handleCustomColor(true)}
                    selected={hasCustomStyle}
                  >
                    <StyledIcon icon={faPalette} />
                    Custom
                  </RadioButton>
                </RadioButtonGroup>
              </FieldSet>

              {hasCustomStyle && (
                <>
                  <FieldSet>
                    <Label htmlFor="skippable-x-color">X Color</Label>
                    <ColorInput
                      id="skippable-x-color"
                      color={valueFor.foregroundColor}
                      onChange={handleChangeFor.foregroundColor}
                    />
                  </FieldSet>
                  {hasBackgroundCircle && (
                    <FieldSet>
                      <Label htmlFor="skippable-circle-color">
                        Circle Color
                      </Label>
                      <ColorInput
                        id="skippable-circle-color"
                        color={valueFor.backgroundColor}
                        onChange={handleChangeFor.backgroundColor}
                      />
                    </FieldSet>
                  )}
                </>
              )}
            </Controls>
          </Accordion.Content>
        </Accordion.Item>
        <AlignmentSpacing
          style={buttonStyle}
          spacingLabel={TRAIT_LABELS[SKIPPABLE_OPTIONS]}
          onChange={handleChange}
        />
      </Accordion.Root>
    </Content>
  );
}

SkippableSettings.propTypes = {
  editor: EditorShape,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
};

export default SkippableSettings;
