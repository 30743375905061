import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Icon width/height ratio
 */
const ratio = 9 / 11;

const Wrapper = styled.div`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size * ratio}px`};
`;

function BrandMark({ className, size = 22 }) {
  return (
    <Wrapper className={className} size={size}>
      <svg viewBox="0 0 86 107.4">
        <path
          fill="var(--secondary)"
          d="M37.7,58.6l30.1,47.1c0.8,1.1,1.9,1.7,3.3,1.7h11.1c2.1,0,3.8-1.7,3.8-3.8V3.8c0-3.6-4.5-5.2-6.9-2.4,L37.7,54C36.8,55.2,36.8,57.2,37.7,58.6z"
        />

        <path
          fill="var(--secondary)"
          d="M3.9,107.3h19.8c2.1,0,3.8-1.7,3.8-3.8V78.2c0-3.6-4.5-5.2-6.9-2.4L0.8,101.1,C-1.1,103.8,0.8,107.3,3.9,107.3z"
        />
      </svg>
    </Wrapper>
  );
}

BrandMark.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default styled(BrandMark)``;
