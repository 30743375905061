import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Chyron = styled.div`
  padding: 0 24px;
  position: fixed;
  bottom: ${({ position }) => (position === 'bottom' ? '32px' : 'auto')};
  top: ${({ position }) => (position === 'top' ? '32px' : 'auto')};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  height: 48px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-dark);
  border-radius: 6px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
  opacity: 0.95;
  color: rgba(255, 255, 255, 0.7);
  font-size: var(--regular);
`;

Chyron.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom']),
};

Chyron.defaultProps = {
  position: 'bottom',
};

export default Chyron;
