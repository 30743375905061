import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { set } from 'ext/lib/local-storage';
import { Logo, P } from 'ext/components/ui';
import StyleProvider from 'styles/StyleProvider';
import LightTheme from 'components/LightTheme';
import { DEMOCUES_KEY, TYPES, generateDeeplink } from './generate-deeplink';
import { Wrapper, Dialog, Title, ExternalLink } from './styled';

const ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.appcues.mobileshowcase';
const IOS_APP_LINK =
  'https://apps.apple.com/us/app/appcues-mobile-showcase/id6464720573';
const SHOWCASE_APP_ID = '16daf46b-3231-4e4a-bb3c-273a4e9100dd';

/**
 * Component to render a preview proxy page that will redirect to the app
 * Example of pages that will use this component:
 *
 * - Democues: ?democues=enabled|disabled
 * - Debugger: ?type=debugger&appId=<appId>
 * - Experience Preview: ?type=preview&appId=<appId>&experienceId=<experienceId>
 * - Capture Screen: ?type=capture_screen&appId=<appId>&sdkToken=<sdkToken>
 * - Push Preview: ?type=push_preview&appId=<appId>&pushId=<pushId>
 *
 */
export default function PreviewProxy({ params }) {
  let deepLink = '';
  const { type, appId, accountId, experienceId, localeId, democues, pushId } =
    params;

  const showcaseParams =
    type === 'push_preview'
      ? { locale_id: localeId, content_account_id: accountId, t: Date.now() }
      : { locale_id: localeId, t: Date.now() };

  const searchParams = new URLSearchParams(
    Object.entries(showcaseParams).filter(([, value]) => value)
  ).toString();
  const queryString = searchParams === '' ? '' : `?${searchParams}`;

  const showcaseLink =
    type === 'push_preview'
      ? `appcues-${SHOWCASE_APP_ID}://sdk/push_preview/${pushId}${queryString}`
      : `appcues-showcase://preview/${accountId}/${appId}/${experienceId}${queryString}`;

  const allowShowcase = type === 'preview' || type === 'push_preview';

  if (democues) {
    set(DEMOCUES_KEY, democues === 'enabled');
  } else {
    deepLink = generateDeeplink({ ...params, timestamp: Date.now() });
    window.location.replace(deepLink);
  }
  const [currentLinks, setCurrentLinks] = useState({
    showcaseLink,
    deepLink,
  });

  // Every time an SDK link is tapped, we update a timestamp param.
  // This is not used by the SDK at all, but it ensures all mobile
  // frameworks will attempt to re-process the link if it is tapped
  // again, allowing preview of the same content multiple times, if needed.
  const handleLinkClick = linkType => {
    const url = new URL(currentLinks[linkType]);
    url.searchParams.set('t', Date.now());
    setCurrentLinks(prevLinks => ({
      ...prevLinks,
      [linkType]: url.toString(),
    }));
  };

  return (
    <StyleProvider>
      <LightTheme>
        <Wrapper>
          <Dialog>
            <Logo />
            <Title bold>
              {democues
                ? `Democues mode ${democues}!`
                : 'Redirecting to your app'}
            </Title>

            {!democues && (
              <P>
                If you are not redirected to your app,{' '}
                <ExternalLink
                  href={currentLinks.deepLink}
                  onClick={() => handleLinkClick('deepLink')}
                >
                  click this link.
                </ExternalLink>
              </P>
            )}

            <P>
              For troubleshooting help, please see our{' '}
              <ExternalLink href="https://docs.appcues.com/">
                documentation.
              </ExternalLink>
            </P>

            {allowShowcase && (
              <P>
                SDK not installed? Preview in the Showcase app instead,{' '}
                <ExternalLink
                  href={currentLinks.showcaseLink}
                  onClick={() => handleLinkClick('showcaseLink')}
                >
                  click this link.
                </ExternalLink>{' '}
                <br />
                Get the app here: <br />
                <ExternalLink href={ANDROID_APP_LINK}>
                  Android
                </ExternalLink> or{' '}
                <ExternalLink href={IOS_APP_LINK}>iOS</ExternalLink>
              </P>
            )}
          </Dialog>
        </Wrapper>
      </LightTheme>
    </StyleProvider>
  );
}

PreviewProxy.propTypes = {
  params: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
    appId: PropTypes.string.isRequired,
    accountId: PropTypes.string,
    experienceId: PropTypes.string,
    pushId: PropTypes.string,
    localeId: PropTypes.string,
    userId: PropTypes.string,
    sdkToken: PropTypes.string,
    democues: PropTypes.oneOf(['enabled', 'disabled']),
  }),
};
