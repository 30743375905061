import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@appcues/sonar';
import { FieldSet } from 'ext/components/ui';
import {
  closeType,
  submitFormType,
  getSubmitForm,
  Shape as ActionsShape,
} from 'lib/actions';

export default function SubmitFormOption({ actions, onChange }) {
  const hasSubmitForm = actions.some(action => action.type === submitFormType);
  const isCloseType = actions.length === 1 && actions[0].type === closeType;

  const handleSubmitFormChange = submitForm => {
    onChange({
      submitForm: submitForm
        ? [getSubmitForm({ skipValidation: isCloseType })]
        : [],
    });
  };

  return (
    <FieldSet>
      <Switch
        id="submit-survey"
        checked={hasSubmitForm}
        onCheckedChange={handleSubmitFormChange}
        fullWidth
      >
        <Switch.Label htmlFor="submit-survey">
          Submit survey responses
        </Switch.Label>
      </Switch>
    </FieldSet>
  );
}

SubmitFormOption.propTypes = {
  actions: PropTypes.arrayOf(ActionsShape),
  onChange: PropTypes.func,
};
