import { STYLE, TARGET, NAVIGATION } from './user-interface';

/**
 * Get selected sidebar tab
 * @param {object} selectedSideBarTab - Tab id and name from user-interface
 * @param {isStepGroupFirstStep} boolean - If the selected step is the first step of the group
 * @param {boolean} isAnchored - If the selected step belongs to an anchored group
 * @return {string} Selected tab name
 */
export const getSelectedTab = ({
  selectedSideBarTab,
  isStepGroupFirstChild,
  isAnchored,
}) => {
  const isNavigation = selectedSideBarTab === NAVIGATION;
  if (isNavigation && isStepGroupFirstChild) return NAVIGATION;
  if (!isNavigation && selectedSideBarTab) return selectedSideBarTab;
  return isAnchored ? TARGET : STYLE;
};
