import { assign } from 'object-path-immutable';
import { continueType as defaultActionType, submitFormType } from 'lib/actions';
import { generateElementTemplate } from 'components/Editor/Templates';

/**
 * Update actions object with default actions related to new button element
 * @param {object} actions - current actions from step
 * @param {string} buttonId - created button id
 * @param {boolean} addSubmitForm - should submit form action be added
 * @return {object} Updated actions object
 */
export const updateButtonActions = ({
  actions,
  buttonId,
  addSubmitFormAction,
}) => {
  const generatedTemplate = generateElementTemplate({
    template: addSubmitFormAction ? submitFormType : defaultActionType,
    elementId: buttonId,
  });

  if (generatedTemplate) {
    return assign(actions, null, generatedTemplate);
  }

  return actions;
};
