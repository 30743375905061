import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'ext/components/ui';

const EditButton = styled.button.attrs(({ icon }) => ({
  children: <FontIcon icon={icon} />,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: var(--white);
  position: absolute;
  bottom: 10px;
  right: 4px;
  width: 28px;
  height: 28px;
  background: var(--color-blue-600);
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;

  &:hover {
    background: var(--color-blue-700);
  }
`;

export default EditButton;
