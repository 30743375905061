import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import mergeRefs from 'ext/lib/merge-refs';
import { Tooltip } from 'ext/components/ui';
import { selectSelected } from 'entities/selected';
import { selectStepGroup, EditorShape } from 'entities/step-groups';
import { selectStepChild, ContentShape } from 'entities/step-children';
import { Shape as TraitsShape } from 'entities/trait';
import {
  getTargetElementSelector,
  TOOLTIP,
  LAYOUT_TRAITS,
  TRAIT_LABELS,
} from 'lib/trait';
import {
  STANDARD,
  ANCHORED,
  EMBED_PRESENTATION,
  PRESENTATIONS,
  PRESENTATIONS_TYPES,
} from 'lib/presentation';
import { hasSurveyBlockType } from 'lib/block';
import { capitalize } from 'utils/strings-handler';
import { useDnd, STEP_CHILD } from './use-dnd';
import StepIcon from './Icons';
import { StepChildWrapper, Label, NavigationIcon, WarningIcon } from './styled';

const StepChild = forwardRef(
  (
    {
      id,
      index,
      editor,
      selected,
      layoutTrait,
      presentation,
      isSingleStep,
      isSwappable,
      isMenuOpen,
      hasNavigation,
      content,
      traits,
      onClick,
      onDrag,
      onDrop,
    },
    ref
  ) => {
    const options = { id, index, onDrag, onDrop };
    const [{ dragging, draggable, droppable }, connectors] = useDnd(
      STEP_CHILD,
      options
    );

    const handleClick = () => onClick(id);

    const { frameID } = editor ?? {};
    const isEmbed = presentation?.includes('embed');

    /**
     * The existence of `ref` means this step child is the only step child
     * in its step group and thus will be used as the drag handle for
     * reordering the step group since a separate drag handle will not
     * exist. Otherwise, we can assume that it is not the only child and
     * the child will instead be used as both the drag and drop target for
     * reordering itself within the step group using its own connectors.
     */
    const connector = ref || mergeRefs([connectors.drag, connectors.drop]);

    const visible = isMenuOpen ? false : draggable ? null : false;

    const handleLabel = preferredLabel => {
      const presentationLabel = isEmbed
        ? EMBED_PRESENTATION
        : PRESENTATIONS[layoutTrait][presentation];
      const traitLabel =
        presentation === STANDARD || layoutTrait === TOOLTIP
          ? ` ${TRAIT_LABELS[layoutTrait]}`
          : '';
      const label = capitalize(`${presentationLabel}${traitLabel} step`);

      return <Label>{preferredLabel || label}</Label>;
    };

    const handleStepInfo = () => {
      if (hasNavigation && index === 0) {
        return {
          icon: <NavigationIcon />,
          label: handleLabel(),
        };
      }

      if (!isSingleStep && isSwappable && hasSurveyBlockType(content)) {
        return {
          icon: <WarningIcon />,
          label: handleLabel(
            'Carousel steps are not \n recommended for survey flows'
          ),
        };
      }

      if (isEmbed && !frameID) {
        return {
          icon: <WarningIcon />,
          label: 'No frame ID targeted',
        };
      }

      if (presentation === ANCHORED) {
        const targetElementSelector = getTargetElementSelector(traits);

        if (!targetElementSelector) {
          return {
            icon: <WarningIcon />,
            label: 'No target element selected',
          };
        }

        if (targetElementSelector?.isDuplicated) {
          return {
            icon: <WarningIcon />,
            label: handleLabel(
              'The selected target ID is not \n unique on this screen capture'
            ),
          };
        }
      }

      return { label: handleLabel() };
    };

    return (
      <Tooltip visible={visible} label={handleStepInfo().label}>
        <StepChildWrapper
          ref={connector}
          aria-label="step-child"
          aria-selected={selected}
          dragging={dragging}
          droppable={droppable}
          onClick={handleClick}
          selected={selected}
        >
          {handleStepInfo().icon}
          <StepIcon layoutTrait={layoutTrait} presentation={presentation} />
        </StepChildWrapper>
      </Tooltip>
    );
  }
);

StepChild.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  editor: EditorShape,
  selected: PropTypes.bool,
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  presentation: PropTypes.oneOf(PRESENTATIONS_TYPES),
  isSingleStep: PropTypes.bool,
  isSwappable: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  hasNavigation: PropTypes.bool,
  content: ContentShape,
  traits: TraitsShape,
  onClick: PropTypes.func,
  onDrag: PropTypes.func,
  onDrop: PropTypes.func,
};

const mapStateToProps = (state, { id }) => {
  const { stepGroup: stepGroupId } = selectSelected(state) ?? {};
  const { editor } = selectStepGroup(state, stepGroupId) ?? {};
  const { content, traits } = selectStepChild(state, id) ?? {};

  return { editor, content, traits };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  StepChild
);
