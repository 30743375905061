import { set, del, insert } from 'object-path-immutable';
import move from 'ext/lib/move';
import { getElement, getNodePath } from 'lib/block';
import { generateRow } from 'components/Editor/Templates';

/**
 * Move entire rows inside the content
 * @param {object} content - root content of an step children
 * @param {number} from - index of the element to move from
 * @param {number} to - index of the element to move to
 * @returns {object} Updated content tree with updated element position
 */
export const moveRow = (content, from, to) => {
  const updatedItems = move(content.items, from, to);
  return set(content, 'items', updatedItems);
};

/**
 * Move blocks inside the same row
 * @param {object} content - root content of an step children
 * @param {number} rowIndex - index of the row where the blocks are moving
 * @param {number} from - index of the element to move from
 * @param {number} to - index of the element to move to
 * @returns {object} Updated content tree with updated element position
 */
export const moveInsideRow = (content, rowIndex, from, to) => {
  const newItems = move(content.items[rowIndex].items, from, to);
  return set(content, ['items', rowIndex, 'items'], newItems);
};

/**
 * Move blocks between rows
 * @param {object} content - root content of an step children
 * @param {number} rowIndex - index of the row where the blocks are moving
 * @param {string} draggingId - id of the element that is being dragged
 * @param {number} to - index of the element to move to
 * @returns {object} Updated content tree with updated element position
 */
export const moveBetweenRows = (content, rowIndex, draggingId, to) => {
  const draggingPath = getNodePath(content, draggingId);
  const draggingItem = getElement(content, draggingId);

  const contentWithoutDraggingItem = del(content, draggingPath);

  const newContent = insert(
    contentWithoutDraggingItem,
    ['items', rowIndex, 'items'],
    draggingItem,
    to
  );

  // Cleaning empty stacks if we moved all the items inside the row
  const itemsWithoutEmptyStacks = newContent.items.filter(
    item => item.items.length > 0
  );

  return { ...newContent, items: itemsWithoutEmptyStacks };
};

/**
 * Move a block to a new space and create a new row to it
 * @param {object} content - root content of an step children
 * @param {string} draggingId - id of the element that is being dragged
 * @param {boolean} isLast - decide if the element should be added at the top or bottom of the modal
 * @returns {object} Updated content tree with updated element position
 */
export const moveAndCreateRow = (content, draggingId, rowIndex = 0) => {
  const draggingItem = getElement(content, draggingId);
  const draggingPath = getNodePath(content, draggingId);
  const elementWithRow = generateRow([draggingItem]);

  const contentWithoutDraggingItem = del(content, draggingPath);

  const newContent = insert(
    contentWithoutDraggingItem,
    ['items'],
    elementWithRow,
    rowIndex ?? contentWithoutDraggingItem.items.length - 1
  );

  // Cleaning empty stacks if we moved all the items inside the row
  const itemsWithoutEmptyStacks = newContent.items.filter(
    item => item.items.length > 0
  );

  return { ...newContent, items: itemsWithoutEmptyStacks };
};
