import { BLOCK, IMAGE } from 'lib/block';

export const DEFAULT_IMAGE_URL =
  'https://images.appcues.com/v1658371422/mobile-builder/default-image.jpg';

const imageTemplate = ({ blockId, elementId, content } = {}) => ({
  type: BLOCK,
  blockType: IMAGE,
  id: blockId,
  content: {
    type: IMAGE,
    id: elementId,
    imageUrl: DEFAULT_IMAGE_URL,
    blurHash: 'LPCIQ,~BeSNGb^WVjskBayaeayay',
    contentMode: 'fill',
    intrinsicSize: {
      width: 1920,
      height: 1080,
    },
    accessibilityLabel: '',
    style: {
      verticalAlignment: 'center',
      horizontalAlignment: 'center',
      ...content?.style,
    },
  },
});

export default imageTemplate;
