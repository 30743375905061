import { del, get, insert } from 'object-path-immutable';
import { generateRow } from 'components/Editor/Templates';
import {
  getNodePath,
  getRowAndBlockIndex,
  handleRemoveStickyRow,
} from 'lib/block';
import replaceIds from './replace-ids';

/**
 * Delete element in content tree
 * @param {object} content - root content of an step children
 * @param {string} blockId - block id inside items array
 * @return {object} Updated content tree without deleted element
 */
export const deleteElement = (content, blockId) => {
  const contentWithoutElement = del(content, getNodePath(content, blockId));
  const itemsWithoutEmptyStacks = contentWithoutElement.items.filter(
    item => item.items?.length > 0
  );

  return { content: { ...content, items: itemsWithoutEmptyStacks } };
};

/**
 * Clone element and add it in content tree
 * @param {object} content - root content of an step children
 * @param {string} elementId - element id to be cloned
 * @return {object} Updated content tree with cloned element
 */
export const cloneElement = (content, elementId) => {
  const blockPath = getNodePath(content, elementId);
  const [rowIndex, blockIndex] = getRowAndBlockIndex(content, elementId);

  const element = get(content, blockPath);
  const clonedElement = replaceIds(element);
  const row = get(content, ['items', rowIndex]);

  // If the row has only one block, we clone the block
  // and add the new element to a new row below
  let updatedContent = content;
  if (row.items.length === 1) {
    const newRow = generateRow([clonedElement]);
    updatedContent = insert(content, 'items', newRow, rowIndex + 1);
  } else {
    updatedContent = insert(
      content,
      ['items', rowIndex, 'items'],
      clonedElement,
      blockIndex + 1
    );
  }

  const { id, sticky } = row;
  if (sticky) {
    updatedContent = handleRemoveStickyRow({
      content: updatedContent,
      rowId: id,
      rowIndex: rowIndex + 1,
    });
  }

  return { content: updatedContent, elementId: clonedElement.content?.id };
};
