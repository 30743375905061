import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Accordion, Label, FieldSet } from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import { selectUserPreferences } from 'entities/user-preferences';
import ContentActions from 'components/SideBarSettings/Content/ContentActions';
import { IMAGE_WITH_TEXT, BLOCK_LABELS } from 'lib/block';
import { THEMES } from 'lib/user-preferences';
import ColorInput from 'components/ColorInput';
import {
  AlignmentSpacing,
  Content,
  Controls,
  useStyleSettings,
} from 'components/SideBarSettings/Shared';

export function ImageWithTextSettings({ content, onChange, theme }) {
  const { id, style } = content ?? {};

  const handleChange = useCallback(
    patch => {
      onChange({
        blockId: id,
        contentChunk: { style: patch },
        useOriginalId: true,
      });
    },
    [id, onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
    theme,
  });

  const handleContentChange = updatedContent => {
    onChange({
      blockId: id,
      contentChunk: updatedContent,
      useOriginalId: true,
    });
  };

  return (
    <Content>
      <Controls>
        <FieldSet>
          <Label htmlFor="image-text-color-input">Background Color</Label>
          <ColorInput
            id="image-text-color-input"
            color={valueFor.backgroundColor}
            onChange={handleChangeFor.backgroundColor}
          />
        </FieldSet>
      </Controls>

      <Accordion.Root
        collapsible="true"
        defaultValue={['alignment-spacing']}
        type="multiple"
      >
        <AlignmentSpacing
          style={style}
          onChange={handleContentChange}
          spacingLabel={BLOCK_LABELS[IMAGE_WITH_TEXT]}
          hasPadding
          hasMargin
        />
        <ContentActions blockId={id} context={IMAGE_WITH_TEXT} />
      </Accordion.Root>
    </Content>
  );
}

ImageWithTextSettings.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
};

const mapStateToProps = state => ({
  theme: selectUserPreferences(state).theme,
});

export default connect(mapStateToProps)(ImageWithTextSettings);
