import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@appcues/sonar';
import { faPalette } from '@fortawesome/free-solid-svg-icons/faPalette';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons/faCrosshairs';
import { Accordion } from 'ext/components/ui';
import { EditorShape } from 'entities/step-groups';
import { ContentShape } from 'entities/step-children';
import { hasSurveyBlockType } from 'lib/block';
import { THEMES } from 'lib/user-preferences';
import {
  Content,
  AlignmentSpacing,
  Container,
} from 'components/SideBarSettings/Shared';
import Details from './Details';
import FrameTarget from './FrameTarget';

export function EmbedSettings({
  selectedStepChild,
  isStepGroupFirstChild,
  editor,
  content,
  stepChildrenIds = [],
  theme,
  onChange,
}) {
  const { frameID, style } = editor;
  const isSingleStep = stepChildrenIds.length === 1;
  const hasSurveyBlock = hasSurveyBlockType(content);

  const styleSection = (
    <Accordion.Root
      collapsible="true"
      defaultValue={['details', 'container']}
      type="multiple"
    >
      <Details
        editor={editor}
        isSingleStep={isSingleStep}
        hasSurveyBlock={hasSurveyBlock}
        onChange={onChange}
      />

      <Container editor={editor} theme={theme} onChange={onChange} />

      <AlignmentSpacing
        style={style}
        spacingLabel="Content"
        onChange={onChange}
        hasPadding
        hasMargin
      />
    </Accordion.Root>
  );

  const tabs = (
    <Tabs.Root key={selectedStepChild} defaultValue="style" align="stretch">
      <Tabs.List>
        <Tabs.Trigger value="style" icon={faPalette}>
          Style
        </Tabs.Trigger>
        <Tabs.Trigger value="target" icon={faCrosshairs}>
          Target
        </Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="style">{styleSection}</Tabs.Content>
      <Tabs.Content value="target">
        <FrameTarget frameID={frameID} onChange={onChange} />
      </Tabs.Content>
    </Tabs.Root>
  );

  return <Content>{isStepGroupFirstChild ? tabs : styleSection}</Content>;
}

EmbedSettings.propTypes = {
  selectedStepChild: PropTypes.string,
  isStepGroupFirstChild: PropTypes.bool,
  editor: EditorShape,
  content: ContentShape,
  stepChildrenIds: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.oneOf(THEMES),
  onChange: PropTypes.func,
};

export default EmbedSettings;
