import styled from 'styled-components';
import { Modal as SonarModal, Icon as SonarIcon } from '@appcues/sonar';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';

// This is a workaround adding a container div around
// modal content to enable click outside functionality
const ModalContainer = styled.div`
  margin: calc(var('--spacing-x-large') * -1);
  padding: var('--spacing-x-large');
`;

export const Modal = {
  ...SonarModal,
  Container: ModalContainer,
};

export const WarningIcon = styled(SonarIcon).attrs({
  icon: faTriangleExclamation,
})`
  color: var(--color-yellow-600);
`;

export const ErrorContainer = styled.div`
  margin-top: var(--spacing-regular);
`;

export const ScrollableErrorContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
  margin-top: var(--spacing-small);
`;
