import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

const theme = `
  --input-color: var(--white);
  --input-background: var(--diel-gray);
  --input-border: 1px solid var(--diel-gray);
  --input-placeholder-color: var(--myst);
  --input-disabled-color: var(--myst);
  --input-readonly-color: var(--dirty-water);
  --input-active: var(--sharkbait-ooh-la-la);

  [data-theme='light'] & {
    --input-color: var(--pleather);
    --input-background: var(--white);
    --input-border: var(--border-width-x-small) solid var(--border-input-default);
    --input-placeholder-color: var(--sharkbait-ooh-la-la);
    --input-disabled-color: var(--myst);
    --input-readonly-color: var(--dirty-water);
    --input-active: var(--diel-gray);
  }
`;

const sharedStyle = css`
  ${theme}

  background: var(--input-background);
  border: var(--input-border);
  border-radius: 6px;
  color: var(--input-color);
  font-size: var(--regular);
  line-height: 24px;
  outline: none;
  padding: 8px 12px;
  text-align: ${({ align = 'start' }) => align};
  width: 100%;

  &::placeholder {
    color: var(--input-placeholder-color);
    font-style: italic;
  }

  &[readonly] {
    color: var(--input-readonly-color);
    cursor: default;
  }

  &[disabled] {
    color: var(--input-disabled-color);
  }

  &:hover {
    border-color: var(--input-active);
  }

  &:focus {
    border-color: var(--input-active);
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    appearance: none;
  }
`;

const Input = styled.input`
  ${sharedStyle}
`;

export default Input;

export const TextArea = styled.textarea`
  ${sharedStyle}

  cursor: auto;
  resize: none;
`;

export function ControlledInput({ defaultValue = '', onChange, ...props }) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleOnChange = event => {
    setValue(event.target.value);
    onChange(event);
  };

  return (
    <Input
      // eslint-disable-next-line @appcues/jsx-props-no-spreading
      {...props}
      onChange={handleOnChange}
      value={value}
    />
  );
}

ControlledInput.propTypes = {
  ...Input.propTypes,
  onChange: PropTypes.func,
};

// NOTE: Change debounced handler prop name to prevent React warning
export function DebouncedInput({
  defaultValue,
  onChange,
  wait = 500,
  inputType = 'input',
  ...props
}) {
  const Component = inputType === 'input' ? Input : TextArea;
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const debouncedOnChange = useMemo(() => {
    return debounce(onChange, wait);
  }, [onChange, wait]);

  return (
    <Component
      // eslint-disable-next-line @appcues/jsx-props-no-spreading
      {...props}
      role="textbox"
      onChange={event => {
        event.persist();
        debouncedOnChange(event);
        setValue(event.target.value);
      }}
      value={value}
    />
  );
}

DebouncedInput.propTypes = {
  ...Input.propTypes,
  wait: PropTypes.number,
};
