import PropTypes from 'prop-types';
import { THEMES, ORIENTATIONS, DIRECTIONS } from 'lib/user-preferences';

export const LocaleShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  experienceId: PropTypes.string,
});

export default PropTypes.shape({
  theme: PropTypes.oneOf(THEMES),
  direction: PropTypes.oneOf(DIRECTIONS),
  orientation: PropTypes.oneOf(ORIENTATIONS),
  viewport: PropTypes.string,
  embed: PropTypes.string,
  locale: LocaleShape,
});
