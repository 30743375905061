/**
 * Method to remove locales from an experience object
 *
 * @param {Object} experience - the canonical experience object
 * @param {String} locale - locale to remove or 'all' to remove all locales
 * @return the experience object without the locales passed
 */
export function delocalize(experience, locale) {
  let removeContainsLocalizedBlocks = false;

  const removeVariations = content => {
    let newContent = { ...content };

    if (newContent.variations) {
      if (newContent.variations[locale]) {
        const { [locale]: _, ...rest } = newContent.variations;
        newContent.variations = rest;
      }

      // if there are no variations left, remove the variations block
      // and also remove the localized block around it
      if (Object.keys(newContent.variations).length === 0 || locale === 'all') {
        const { variations, ...rest } = newContent;
        newContent = rest.content;

        // we also inform the experience level that there are no more
        // localized blocks so we should remove the containsLocalizedBlocks
        removeContainsLocalizedBlocks = true;
      }
    }

    const newExperience = Object.entries(newContent).reduce(
      (acc, [key, value]) => {
        if (Array.isArray(value)) {
          return { ...acc, [key]: value.map(removeVariations) };
        }

        if (typeof value === 'object' && value !== null) {
          const newValue = removeVariations(value);
          return { ...acc, [key]: newValue };
        }

        return { ...acc, [key]: value };
      },
      {}
    );

    // if the object has steps, it means we're in the experience level
    // and we should remove the containsLocalizedBlocks
    if (newExperience.steps && removeContainsLocalizedBlocks) {
      newExperience.containsLocalizedBlocks = false;
    }

    return newExperience;
  };

  return removeVariations(experience);
}
