import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'ext/components/ui';
import { BlockContentShape } from 'entities/block';
import { BLOCK_LABELS } from 'lib/block';
import { Content, AlignmentSpacing } from 'components/SideBarSettings/Shared';
import { updateRequiredLabel } from 'components/SideBarSettings/Survey';
import RatingStyle from './RatingStyle';
import RatingBehavior from './RatingBehavior';

export function RatingSettings({ content, contentWithVariations, onChange }) {
  const {
    id,
    ratingType,
    displayFormat,
    options = [],
    style: ratingStyle,
  } = content ?? {};

  const { label } = contentWithVariations;

  const handleChange = behavior => {
    onChange({ blockId: id, contentChunk: behavior, useOriginalId: true });
  };

  const handleRequiredChange = required => {
    const contentChunk = {
      required: !!required,
      label: updateRequiredLabel(label, required),
      minSelections: required ? 1 : 0,
    };

    onChange({ blockId: id, contentChunk, useOriginalId: true });
  };

  const handleErrorLabelChange = errorLabel => {
    onChange({ blockId: errorLabel.id, contentChunk: errorLabel });
  };

  return (
    <Content>
      <Accordion.Root
        collapsible="true"
        defaultValue={['style']}
        type="multiple"
      >
        <RatingStyle
          id={id}
          ratingType={ratingType}
          displayFormat={displayFormat}
          options={options}
          onChange={handleChange}
        />
        <RatingBehavior
          id={id}
          ratingType={ratingType}
          displayFormat={displayFormat}
          options={options}
          onChange={handleChange}
          onRequiredChange={handleRequiredChange}
          onErrorLabelChange={handleErrorLabelChange}
        />
        <AlignmentSpacing
          id={id}
          style={ratingStyle}
          spacingLabel={BLOCK_LABELS[ratingType]}
          onChange={handleChange}
          hasMargin
        />
      </Accordion.Root>
    </Content>
  );
}

RatingSettings.propTypes = {
  content: BlockContentShape,
  contentWithVariations: BlockContentShape,
  onChange: PropTypes.func,
};

export default RatingSettings;
