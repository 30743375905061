import React from 'react';
import PropTypes from 'prop-types';
import { ContentShape } from 'entities/step-children';
import { THEMES } from 'lib/user-preferences';
import {
  BLOCK_MODES,
  OPTION_SELECT,
  RATING,
  RATING_STAR,
  RATING_EMOJI,
  RATING_NUMBER,
} from 'lib/block';
import { SelectContainer, RatingContainer } from 'components/Editor/Containers';

const SELECT_TYPES = BLOCK_MODES[OPTION_SELECT];
const RATING_TYPES = [RATING, RATING_STAR, RATING_EMOJI, RATING_NUMBER];

/* eslint-disable @appcues/jsx-props-no-spreading */
const SelectType = ({ id, theme, ratingType, selectMode, ...props }) => {
  if (RATING_TYPES.includes(ratingType)) {
    return (
      <RatingContainer
        id={id}
        theme={theme}
        ratingType={ratingType}
        {...props}
      />
    );
  }

  if (SELECT_TYPES.includes(selectMode)) {
    return (
      <SelectContainer
        id={id}
        theme={theme}
        selectMode={selectMode}
        {...props}
      />
    );
  }

  return null;
};
/* eslint-enable @appcues/jsx-props-no-spreading */

SelectType.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.oneOf(THEMES),
  content: ContentShape,
  ratingType: PropTypes.oneOf(RATING_TYPES),
  selectMode: PropTypes.oneOf(SELECT_TYPES),
};

export default SelectType;
