import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import keyBy from 'lodash.keyby';
import isEqual from 'lodash.isequal';
import { selectExperience } from 'entities/experiences';
import { patterns as patternsScreen } from './actions';
import { update, remove, reject, resolve, patterns } from './summary-actions';
import { selectScreensSummary } from './summary-reducer';

export function* fetchScreensSummary() {
  const logger = yield getContext('logger');
  try {
    const { appId } = yield select(selectExperience);
    const screensSummary = yield select(selectScreensSummary);
    const api = yield getContext('api');
    const response = yield call(api.getScreensSummary, appId);

    // If the screens summary is already up to date, do nothing
    if (
      screensSummary &&
      isEqual(Object.keys(screensSummary), Object.keys(keyBy(response, 'id')))
    ) {
      return;
    }

    yield put(resolve(keyBy(response, 'id')));
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
  }
}

function* updateScreensSummary({ payload }) {
  const { id: screenId, delta } = payload;
  const logger = yield getContext('logger');
  try {
    yield put(update(screenId, delta));
  } catch (error) {
    logger.error(error);
  }
}

function* removeScreensSummary({ payload }) {
  const { id: screenId } = payload;
  const logger = yield getContext('logger');
  try {
    yield put(remove(screenId));
  } catch (error) {
    logger.error(error);
  }
}

export default function* screensSummarySaga() {
  yield takeEvery(patterns.focus, fetchScreensSummary);
  yield takeEvery(patternsScreen.update, updateScreensSummary);
  yield takeEvery(patternsScreen.remove, removeScreensSummary);
}
