import { v4 as uuid } from 'uuid';
import { BLOCK, TEXT, TEXT_INPUT } from 'lib/block';

const textInputTemplate = ({ blockId, elementId, content }) => {
  const {
    label,
    placeholder,
    textFieldStyle,
    style: contentStyle,
    ...contentRest
  } = content ?? {};

  return {
    type: BLOCK,
    blockType: TEXT_INPUT,
    id: blockId,
    content: {
      type: TEXT_INPUT,
      id: elementId,
      label: {
        type: TEXT,
        id: uuid(),
        text: 'Question label',
        style: {
          textAlignment: 'leading',
          marginBottom: 12,
          foregroundColor: { light: '#000000' },
          horizontalAlignment: 'leading',
        },
        ...label,
      },
      placeholder: {
        type: TEXT,
        id: uuid(),
        text: 'Answer',
        style: {
          foregroundColor: { light: '#627293' },
        },
        ...placeholder,
      },
      errorLabel: {
        type: TEXT,
        id: uuid(),
        text: 'Required field',
        style: {
          foregroundColor: { light: '#D6216B' },
          horizontalAlignment: 'leading',
          textAlignment: 'leading',
          fontSize: 14,
          marginTop: 10,
        },
      },
      textFieldStyle: {
        foregroundColor: { light: '#000000' },
        borderColor: { light: '#627293' },
        borderWidth: 0.5,
        cornerRadius: 4,
        ...textFieldStyle,
      },
      style: {
        horizontalAlignment: 'leading',
        marginTop: 12,
        marginLeading: 24,
        marginTrailing: 24,
        marginBottom: 12,
        ...contentStyle,
      },
      dataType: 'text',
      numberOfLines: 1,
      maxLength: 100000,
      cursorColor: { light: '#007AFF' },
      ...contentRest,
    },
  };
};

export default textInputTemplate;
