/* global APPCUES_ENV, DEBUG_LOGGER, RELEASE, SENTRY_DSN  */
// eslint-disable-next-line import/no-namespace
import * as Sentry from '@sentry/react';
import {
  selectAccountId,
  selectEmail,
  selectUserId,
} from 'ext/entities/account';

const ignored = [/^Non-Error (promise rejection|exception) captured/];

const log =
  prefix =>
  (...args) =>
    // eslint-disable-next-line no-console
    console.log(`%c[sentry:${prefix}]`, 'color: #ff5290', ...args);

// If the `SENTRY_DSN` is `undefined` such as during local development with no
// specific builder facet used, use this stub client that will output to the
// console
const stub = {
  captureException: log('error'),
  captureMessage: log('info'),
};

/**
 * Create enriched Sentry client
 *
 * @param {State} state - Redux state
 * @return {object} Sentry client enriched with user
 */
export const create = state => {
  if (!DEBUG_LOGGER && typeof SENTRY_DSN !== 'undefined') {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: RELEASE,
      environment: APPCUES_ENV,
      ignoreErrors: ignored,
    });

    // Set user context on initialization
    Sentry.setUser({
      accountId: selectAccountId(state),
      email: selectEmail(state),
      id: selectUserId(state),
    });
  }

  const client = typeof SENTRY_DSN !== 'undefined' ? Sentry : stub;

  return {
    error: (error, tags = {}) => {
      if (error?.name === 'HTTPError') {
        // client.configure scope will only
        // run in production, since this is an specific method from sentry
        if (client.configureScope) {
          client.configureScope(scope => {
            scope.setExtra('error', JSON.stringify(error.response));
          });
        }

        client.captureMessage('HTTPError');
        return;
      }

      client.captureException(error, { tags });
    },
    info: (message, tags = {}) => {
      client.captureMessage(message, { tags });
    },
  };
};
