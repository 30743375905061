import PropTypes from 'prop-types';
import { LAYOUT_TRAITS } from 'lib/trait';
import { PREVIEW_TYPES } from 'lib/user-interface';
import { PRESENTATIONS_TYPES } from 'lib/presentation';

export const AddStepShape = PropTypes.shape({
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  presentation: PropTypes.oneOf(PRESENTATIONS_TYPES),
  stepType: PropTypes.oneOf(['group', 'child']),
});

export const InsertComponentPositionShape = PropTypes.shape({
  rowId: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom', 'right']),
});

export default PropTypes.shape({
  preview: PropTypes.shape({
    id: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(PREVIEW_TYPES),
        show: PropTypes.bool,
      })
    ),
  }),
  targetPlacement: PropTypes.bool,
  selectedSideBarTab: PropTypes.shape({
    stepGroup: PropTypes.string,
  }),
  floatingScreen: PropTypes.bool,
  addStepOfType: AddStepShape,
  insertComponentPosition: InsertComponentPositionShape,
});
