import { v4 as uuid } from 'uuid';
import {
  BLOCK,
  OPTION_SELECT,
  TEXT,
  RATING,
  RATING_NUMBER,
  BLOCK_MODES,
} from 'lib/block';

const [SINGLE] = BLOCK_MODES[OPTION_SELECT];
const [HORIZONTAL] = BLOCK_MODES[RATING_NUMBER];

const ratingNumberTemplate = ({ blockId, elementId }) => ({
  type: BLOCK,
  blockType: RATING,
  id: blockId,
  content: {
    type: OPTION_SELECT,
    ratingType: RATING_NUMBER,
    id: elementId,
    label: {
      type: TEXT,
      id: uuid(),
      text: 'Question label',
      style: {
        fontName: 'System Default Regular',
        foregroundColor: { light: '#000000' },
        marginBottom: 12,
        horizontalAlignment: 'leading',
        textAlignment: 'leading',
      },
    },
    selectMode: SINGLE,
    options: [
      {
        value: '1',
        content: {
          id: uuid(),
          type: TEXT,
          text: '1',
          style: {
            width: 42,
            height: 42,
            foregroundColor: { light: '#627293' },
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 24,
            backgroundColor: { light: '#FFFFFF' },
            borderColor: { light: '#627293' },
            borderWidth: 1,
          },
        },
        selectedContent: {
          id: uuid(),
          type: TEXT,
          text: '1',
          style: {
            width: 42,
            height: 42,
            foregroundColor: { light: '#FFFFFF' },
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 24,
            backgroundColor: { light: '#000000' },
            borderColor: { light: '#627293' },
            borderWidth: 1,
          },
        },
      },
      {
        value: '2',
        content: {
          id: uuid(),
          type: TEXT,
          text: '2',
          style: {
            width: 42,
            height: 42,
            foregroundColor: { light: '#627293' },
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 24,
            backgroundColor: { light: '#FFFFFF' },
            borderColor: { light: '#627293' },
            borderWidth: 1,
          },
        },
        selectedContent: {
          id: uuid(),
          type: TEXT,
          text: '2',
          style: {
            width: 42,
            height: 42,
            foregroundColor: { light: '#FFFFFF' },
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 24,
            backgroundColor: { light: '#000000' },
            borderColor: { light: '#000000' },
            borderWidth: 1,
          },
        },
      },
      {
        value: '3',
        content: {
          id: uuid(),
          type: TEXT,
          text: '3',
          style: {
            width: 42,
            height: 42,
            foregroundColor: { light: '#627293' },
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 24,
            backgroundColor: { light: '#FFFFFF' },
            borderColor: { light: '#627293' },
            borderWidth: 1,
          },
        },
        selectedContent: {
          id: uuid(),
          type: TEXT,
          text: '3',
          style: {
            width: 42,
            height: 42,
            foregroundColor: { light: '#FFFFFF' },
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 24,
            backgroundColor: { light: '#000000' },
            borderColor: { light: '#000000' },
            borderWidth: 1,
          },
        },
      },
      {
        value: '4',
        content: {
          id: uuid(),
          type: TEXT,
          text: '4',
          style: {
            width: 42,
            height: 42,
            foregroundColor: { light: '#627293' },
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 24,
            backgroundColor: { light: '#FFFFFF' },
            borderColor: { light: '#627293' },
            borderWidth: 1,
          },
        },
        selectedContent: {
          id: uuid(),
          type: TEXT,
          text: '4',
          style: {
            width: 42,
            height: 42,
            foregroundColor: { light: '#FFFFFF' },
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 24,
            backgroundColor: { light: '#000000' },
            borderColor: { light: '#000000' },
            borderWidth: 1,
          },
        },
      },
      {
        value: '5',
        content: {
          id: uuid(),
          type: TEXT,
          text: '5',
          style: {
            width: 42,
            height: 42,
            foregroundColor: { light: '#627293' },
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 24,
            backgroundColor: { light: '#FFFFFF' },
            borderColor: { light: '#627293' },
            borderWidth: 1,
          },
        },
        selectedContent: {
          id: uuid(),
          type: TEXT,
          text: '5',
          style: {
            width: 42,
            height: 42,
            foregroundColor: { light: '#FFFFFF' },
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 24,
            backgroundColor: { light: '#000000' },
            borderColor: { light: '#000000' },
            borderWidth: 1,
          },
        },
      },
    ],
    controlPosition: 'hidden',
    displayFormat: HORIZONTAL,
    style: {
      horizontalAlignment: 'center',
      marginTop: 12,
      marginBottom: 12,
      marginLeading: 12,
      marginTrailing: 12,
      width: -1,
    },
    errorLabel: {
      type: TEXT,
      id: uuid(),
      text: 'Required field',
      style: {
        foregroundColor: { light: '#D6216B' },
        horizontalAlignment: 'leading',
        textAlignment: 'leading',
        fontSize: 14,
        marginTop: 10,
      },
    },
  },
});

export default ratingNumberTemplate;
