import PropTypes from 'prop-types';
import { PLATFORMS } from 'lib/platform';

export default PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  platform: PropTypes.oneOf(PLATFORMS),
  state: PropTypes.oneOf(['enabled', 'disabled']),
  createdAt: PropTypes.number,
  createdBy: PropTypes.string,
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.string,
});
