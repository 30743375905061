import { v4 as uuid } from 'uuid';
import { STACK } from 'lib/block';

/**
 * Create an empty embed step
 *
 * @param {string} previewStepId - id to preview the step template before creation
 * @return {Step} Canned step group
 */
export const generateEmbedEmptyStep = ({ previewStepId }) => {
  return {
    ...(previewStepId && { id: previewStepId }),
    type: 'embed',
    contentType: 'application/json',
    content: {
      id: uuid(),
      type: STACK,
      orientation: 'vertical',
      style: {},
      items: [],
    },
    actions: {},
  };
};
