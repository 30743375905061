import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Text = styled.span`
  color: var(--text-color);
  font-size: var(--medium-large);
`;

const Tab = styled.div`
  align-item: center;
  background: var(--background-light);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  padding: 8px;
  width: 100%;

  &:hover ${Text} {
    color: var(--text-color);
  }

  ${({ selected }) =>
    selected &&
    `
      background: var(--primary-light);

      && ${Text} {
        color: var(--text-color);
      }
    `}
`;

const Container = styled.div`
  border-bottom: 2px solid var(--white);
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0 16px;
`;

export default function Tabs({ onClick, selected, tabs }) {
  return (
    <Container role="tablist">
      {tabs.map(({ label, value }) => (
        <Tab
          key={value}
          aria-selected={selected === value}
          onClick={() => onClick(value)}
          role="tab"
          selected={selected === value}
        >
          <Text>{label}</Text>
        </Tab>
      ))}
    </Container>
  );
}

Tabs.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};
