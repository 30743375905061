import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import isEmpty from 'lodash.isempty';
import { selectExperience } from 'entities/experiences';
import { selectSelected } from 'entities/selected';
import { selectStepGroup, EditorShape } from 'entities/step-groups';
import { selectStepChild, update, ContentShape } from 'entities/step-children';
import { Shape as TraitsShape } from 'entities/trait';
import {
  selectScreen,
  selectScreensSummary,
  ScreensListShape,
  ScreenLayoutShape,
} from 'entities/screens';
import { focus } from 'entities/block';
import {
  selectUserPreferences,
  Shape as UserPreferencesShape,
} from 'entities/user-preferences';
import {
  selectUserInterface,
  updateTargetPlacement,
} from 'entities/user-interface';
import { PLATFORMS } from 'lib/platform';
import { updateTraits } from 'lib/trait';
import {
  getElement,
  getRowAndBlockIndex,
  getLastRowIndex,
  handleRemoveStickyRow,
} from 'lib/block';
import { Wrapper as DropZoneWrapper } from './DropZone';
import Device from './Device';
import Embed from './Embed';
import {
  moveRow,
  moveInsideRow,
  moveBetweenRows,
  moveAndCreateRow,
} from './dnd-handler';

export const EditorPane = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  padding-bottom: var(--bottom-bar-height);
  padding-right: var(--side-bar-width);
  position: relative;
  width: 100vw;

  // This allow us to display possible places to
  // drop a block inside the Editor
  &:has([data-dragging='true']) {
    ${DropZoneWrapper} {
      pointer-events: all;
    }
  }

  ${({ isImageService }) =>
    isImageService &&
    `
    padding: 0;
  `}
`;

function Editor({
  experienceId,
  content,
  editor,
  screensList,
  selectedScreenLayout,
  traits,
  userPreferences,
  platform,
  stepId,
  targetPlacement,
  isImageService = false,
  onClick,
  onUpdate,
  onTargetPlacementUpdate,
}) {
  const $content = useRef();
  const $editorPane = useRef();

  if (!content) {
    return null;
  }

  // Unfocus content block if clicked outside content
  const handleClick = ({ target }) => {
    if (!$content.current.contains(target)) {
      onClick(null);
    }
  };

  const handleTraitsUpdate = (updatedTraits, traitTypeToDelete) => {
    const updatedTraitsList = updateTraits({
      traits,
      updatedTraits,
      traitTypeToDelete,
    });

    onUpdate(stepId, { traits: updatedTraitsList });
  };

  const handleDrop = ({
    draggingId,
    droppingId,
    isLastInRow = false,
    isRow = false,
    newRow = false,
    isLast = false,
  }) => {
    let updatedContent = content;
    const [dragRowIndex, dragBlockIndex] = getRowAndBlockIndex(
      content,
      draggingId
    );

    const [dropRowIndex, dropBlockIndex] = getRowAndBlockIndex(
      content,
      droppingId
    );

    const isSameRow = dragRowIndex === dropRowIndex;
    const indexToInsert = isLastInRow
      ? content.items[dropRowIndex].items.length
      : dropBlockIndex;

    if (isSameRow) {
      updatedContent = moveInsideRow(
        content,
        dragRowIndex,
        dragBlockIndex,
        indexToInsert
      );
    }

    if (!isSameRow) {
      updatedContent = moveBetweenRows(
        content,
        dropRowIndex,
        draggingId,
        indexToInsert
      );
    }

    if (newRow) {
      // If it's creating a new row (it's always at the beginning or end)
      const rowIndexToRemoveSticky = isLast ? getLastRowIndex(content) : 0;

      // Remove sticky row
      updatedContent = handleRemoveStickyRow({
        content,
        rowId: content.items[rowIndexToRemoveSticky].id,
        rowIndex: rowIndexToRemoveSticky,
        isDnd: true,
      });

      // Create new row
      updatedContent = moveAndCreateRow(
        updatedContent,
        draggingId,
        isLast ? getLastRowIndex(content) + 1 : 0
      );
    }

    if (isRow) {
      updatedContent = moveRow(content, dragRowIndex, dropRowIndex);

      const { sticky: dragSticky } = getElement(content, draggingId) ?? {};
      const { sticky: dropSticky } = getElement(content, droppingId) ?? {};

      // Delete sticky property if it's either moving
      // a sticky row or moving a row into a sticky row
      if (dragSticky || dropSticky) {
        const stickyRowId = dragSticky ? draggingId : droppingId;
        const stickyRowIndex = dragSticky ? dragRowIndex : dropRowIndex;

        updatedContent = handleRemoveStickyRow({
          content: updatedContent,
          rowId: stickyRowId,
          rowIndex: stickyRowIndex,
          isDnd: true,
        });
      }
    }

    return onUpdate(stepId, { content: updatedContent });
  };

  return (
    <EditorPane
      onClick={handleClick}
      isImageService={isImageService}
      ref={$editorPane}
    >
      {editor.presentation?.includes('embed') ? (
        <Embed
          ref={$content}
          editorPane={$editorPane}
          experienceId={experienceId}
          content={content}
          editor={editor}
          userPreferences={userPreferences}
          onDrop={handleDrop}
        />
      ) : (
        <Device
          ref={$content}
          editorPane={$editorPane}
          platform={platform}
          experienceId={experienceId}
          content={content}
          editor={editor}
          screensList={screensList}
          selectedScreenLayout={selectedScreenLayout}
          traits={traits}
          userPreferences={userPreferences}
          targetPlacement={targetPlacement}
          isImageService={isImageService}
          onTargetPlacementUpdate={onTargetPlacementUpdate}
          handleTraitsUpdate={handleTraitsUpdate}
          onDrop={handleDrop}
        />
      )}
    </EditorPane>
  );
}

Editor.propTypes = {
  experienceId: PropTypes.string,
  content: ContentShape,
  traits: TraitsShape,
  editor: EditorShape,
  screensList: ScreensListShape,
  selectedScreenLayout: ScreenLayoutShape,
  stepId: PropTypes.string,
  platform: PropTypes.oneOf(PLATFORMS),
  userPreferences: UserPreferencesShape,
  targetPlacement: PropTypes.bool,
  isImageService: PropTypes.bool,
  onClick: PropTypes.func,
  onUpdate: PropTypes.func,
  onTargetPlacementUpdate: PropTypes.func,
};

const mapStateToProps = state => {
  const flow = selectExperience(state);

  // If the experience is not yet in the store, return nothing
  if (!flow) return {};

  // If there's a flow but it's not fully loaded with steps yet, return nothing
  if (isEmpty(flow?.steps)) return {};

  // Else return selected step child and the traits from selected step group
  const { id, platform } = flow;
  const { stepGroup: stepGroupId, stepChild: stepId } =
    selectSelected(state) ?? {};
  const { editor } = selectStepGroup(state, stepGroupId) ?? {};
  const { content, traits } = selectStepChild(state, stepId) ?? {};
  const userPreferences = selectUserPreferences(state);
  const { targetPlacement } = selectUserInterface(state);
  const screensList = selectScreensSummary(state);
  const { layout: selectedScreenLayout } = selectScreen(state) ?? {};

  return {
    experienceId: id,
    platform,
    content,
    traits,
    stepId,
    editor,
    screensList,
    selectedScreenLayout,
    userPreferences,
    targetPlacement,
  };
};

const mapDispatchToProps = {
  onClick: focus,
  onUpdate: update,
  onTargetPlacementUpdate: updateTargetPlacement,
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
