import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { THEMES } from 'lib/user-preferences';
import { focus } from 'entities/block';
import { selectSelected } from 'entities/selected';
import {
  selectStepChild,
  ContentShape,
  StyleShape,
} from 'entities/step-children';
import { IMAGE_WITH_TEXT, getParentBlock } from 'lib/block';
import { transformStyles } from 'components/Editor/Primitives';

const Frame = styled.div`
  display: flex;
  justify-content: center;
  ${({ customStyle }) => customStyle}

  ${({ isImageWithText }) => !isImageWithText && 'overflow: hidden;'}

  // SDK doesn't support horizontal alignment when fill mode is set
  // so we force to be always at center
  ${({ contentMode, isCroppedImage }) =>
    contentMode === 'fill' && isCroppedImage && 'justify-content: center;'}
`;

const Picture = styled.img`
  width: 100%; // Fit to container
  align-self: center;

  &[data-selected='true'] {
    outline: 2px solid var(--color-blue-500);
  }

  ${({ contentMode }) => {
    switch (contentMode) {
      case 'fit':
        return 'object-fit: contain';
      case 'fill':
        return `
          object-fit: cover;
          height: 100%;
        `;
      default:
        return '';
    }
  }}
`;

function Image({
  accessibilityLabel,
  contentMode,
  imageUrl,
  id,
  content,
  style,
  theme,
  selectedBlock,
  onClick,
}) {
  const $frame = useRef();
  const $picture = useRef();
  const [isCroppedImage, setIsCroppedImage] = useState(false);

  // We check if the image is bigger than the frame
  // if it's bigger it means it's a cropped image
  useEffect(() => {
    if ($picture.current?.clientWidth > $frame.current?.clientWidth) {
      setIsCroppedImage(true);
    } else {
      setIsCroppedImage(false);
    }
  }, [style]);

  const { blockType } = getParentBlock(content, id) ?? {};

  const handleOnClick = () => {
    if (selectedBlock !== id) {
      onClick(id);
    }
  };

  // The margin on iOS shrinks the image, behaving more like padding on Web
  // so we need to remap the margin to padding so it looks similar on preview.
  // We break those styles and pass to the frame, so the border stays on image
  // but the padding happens outside
  const {
    justifyContent,
    marginTop: paddingTop,
    marginBottom: paddingBottom,
    marginInlineStart: paddingLeft,
    marginInlineEnd: paddingRight,
    ...styles
  } = transformStyles(style, theme);

  const pictureStyles = {
    ...(blockType === IMAGE_WITH_TEXT && {
      alignSelf: 'flex-start',
      maxWidth: 'inherit',
    }),
    ...styles,
  };

  return (
    <Frame
      ref={$frame}
      onClick={handleOnClick}
      customStyle={{
        justifyContent,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
      }}
      isImageWithText={blockType === IMAGE_WITH_TEXT}
      contentMode={contentMode}
      isCroppedImage={isCroppedImage}
    >
      <Picture
        ref={$picture}
        id={id}
        src={imageUrl}
        contentMode={contentMode}
        alt={accessibilityLabel}
        style={pictureStyles}
        data-testid={id}
        data-selected={selectedBlock === id}
      />
    </Frame>
  );
}

Image.propTypes = {
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  contentMode: PropTypes.oneOf(['fill', 'fit']),
  intrinsicSize: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  accessibilityLabel: PropTypes.string,
  style: StyleShape,
  theme: PropTypes.oneOf(THEMES),
  selectedBlock: PropTypes.string,
  content: ContentShape,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  const { stepChild: stepId, block: selectedBlock } =
    selectSelected(state) ?? {};
  const { content } = selectStepChild(state, stepId) ?? {};
  return { content, selectedBlock };
};

const mapDispatchToProps = {
  onClick: focus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Image);
