import { REQUEST_RESOLVED, ITEM_INSERTED } from './action-types';

/**
 * a higher-order reducer that allows loading slices from an action while delegating
 * all other changes to another reducer
 *
 * @param {String} type - the collection type to pluck from the payload
 * @return {Function} Reducer decorator to delegate normal operations to
 */
export default function resolveSiblings(reducer, type) {
  return (state, action) => {
    if (action.payload && action.payload[type]) {
      const items = action.payload[type];
      if (action.type === REQUEST_RESOLVED) {
        return items;
      }
      if (action.type === ITEM_INSERTED) {
        return { ...state, ...items };
      }
    }

    return reducer(state, action);
  };
}
