import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { assign } from 'object-path-immutable';
import useToggle from 'ext/lib/hooks/use-toggle';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import { ScreenInsetsShape } from 'entities/screens';
import { handleTargetAreaShape } from 'lib/target-area-shape-handler';
import {
  TargetArea,
  RelativePositionInformation,
  TargetPositionWrapper,
  TargetLastPosition,
  LastPosition,
  CrossIcon,
} from './styled';

const truncateNumber = number => Number(number.toFixed(2));

const formatNumber = number => `${truncateNumber(number * 100)}%`;

const POSITION_OFFSET_INFORMATION = -50;

const handleMousePosition = ({ target, clientX, clientY }) => {
  const { top, left, width, height } = target.getBoundingClientRect();
  const x = clientX - left;
  const y = clientY - top;

  const relativeX = truncateNumber(x / width);
  const relativeY = truncateNumber(y / height);

  return { x, y, relativeX, relativeY };
};

export function TargetPosition({
  targetRectangle,
  deviceSize,
  insets,
  handleTraitsUpdate,
  onTargetPlacementChange,
}) {
  const $targetArea = useRef();
  const $relativePositionInformation = useRef();
  const [mousePosition, setMousePosition] = useState({ x: '0%', y: '0%' });
  const [showMousePosition, setShowMousePosition] = useToggle();

  useClickOutside([$targetArea], onTargetPlacementChange);

  const handleTargetPosition = ({ target, clientX, clientY }) => {
    const { relativeX, relativeY } = handleMousePosition({
      target,
      clientX,
      clientY,
    });

    const updatedTargetRectangle = assign(targetRectangle, 'config', {
      relativeX,
      relativeY,
    });

    handleTraitsUpdate(updatedTargetRectangle);
    onTargetPlacementChange();
  };

  const handleMouseMove = ({ target, clientX, clientY }) => {
    if (!showMousePosition) {
      setShowMousePosition();
      return;
    }

    const { relativeX, relativeY } = handleMousePosition({
      target,
      clientX,
      clientY,
    });

    const relativeMouseX = formatNumber(relativeX);
    const relativeMouseY = formatNumber(relativeY);

    setMousePosition({ x: relativeMouseX, y: relativeMouseY });

    const left = `${clientX}px`;
    const top = `${clientY + POSITION_OFFSET_INFORMATION}px`;

    $relativePositionInformation.current.style.left = left;
    $relativePositionInformation.current.style.top = top;
  };

  const { xCenter, yCenter } = handleTargetAreaShape({
    targetArea: targetRectangle,
    deviceSize,
    insets,
  });

  return (
    <>
      <TargetArea
        ref={$targetArea}
        insets={insets}
        onClick={handleTargetPosition}
        onMouseMove={handleMouseMove}
        onMouseEnter={setShowMousePosition}
        onMouseLeave={setShowMousePosition}
      />
      {showMousePosition &&
        createPortal(
          <RelativePositionInformation ref={$relativePositionInformation}>
            {mousePosition.x} <br />
            {mousePosition.y}
          </RelativePositionInformation>,
          document.body
        )}
      <TargetPositionWrapper top={yCenter} left={xCenter} isLastPosition>
        <TargetLastPosition>
          <CrossIcon />
          <LastPosition>(last position)</LastPosition>
        </TargetLastPosition>
      </TargetPositionWrapper>
    </>
  );
}

TargetPosition.propTypes = {
  targetRectangle: PropTypes.shape({
    config: PropTypes.shape({
      relativeX: PropTypes.number,
      relativeY: PropTypes.number,
    }),
  }),
  deviceSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  insets: ScreenInsetsShape,
  handleTraitsUpdate: PropTypes.func,
  onTargetPlacementChange: PropTypes.func,
};
