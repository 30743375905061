import { getUserPreferences } from 'lib/user-preferences';
import { TYPE, USER_PREFERENCES_UPDATED } from './actions';

export default function userPreferences(state = getUserPreferences(), action) {
  const { type, payload } = action ?? {};
  const { preference, value } = payload ?? {};

  switch (type) {
    case USER_PREFERENCES_UPDATED:
      return {
        ...state,
        [preference]: value,
      };
    default:
      return state;
  }
}

export const selectUserPreferences = state => state[TYPE];
