import {
  REQUEST_RESOLVED,
  ITEM_INSERTED,
  ITEM_UPDATED,
  ITEM_REPLACED,
  ITEM_REMOVED,
  ITEM_FOCUSED,
  ITEM_FLUSHED,
} from './action-types';
import { DELETED } from './attributes';

/**
 * NOTE: Most of the collection reducer here mirrors the implementation in
 *       Studio. However, there are a few notiable differences:
 *         - Entity state defaults to `null` rather than an empty object
 *         - Optimistic creation for entity types
 *         - Optimistic soft deletion for collections
 *
 *       Eventually we should have both implementations be the same but for
 *       backwards compatibility purposes, they may differ slightly.
 */

/**
 * Create a reducer for an entity type. Entities typically represent a single
 * data model such as feature flags or the current account.
 *
 * @param {string} entity - The type of entity
 * @return {function} Entity scoped reducer
 */
export function createEntityReducer(entity) {
  return (state = null, { type, payload, meta }) => {
    if (meta && meta.type === entity) {
      switch (type) {
        case REQUEST_RESOLVED:
        case ITEM_INSERTED:
        case ITEM_REPLACED:
          return payload;

        // Optimistic update
        case ITEM_UPDATED:
          return {
            ...state,
            ...payload,
          };

        case ITEM_FLUSHED:
          return {
            ...state,
            ...payload.delta,
          };

        case ITEM_FOCUSED:
          return payload ? state : {};

        default:
          return state;
      }
    }

    return state;
  };
}

/**
 * Create a reducer for a collection type. Collections typically represent
 * groups of entities such as users or flows.
 *
 * @param {string} entity - The type of entity for the collection
 * @return {function} Entity scoped reducer
 */
export function createCollectionReducer(entity) {
  return (state = {}, { type, payload, meta }) => {
    if (meta && meta.type === entity) {
      switch (type) {
        case REQUEST_RESOLVED:
          if (meta.partial) {
            return {
              ...state,
              ...payload,
            };
          }
          return payload;

        case ITEM_INSERTED:
        case ITEM_REPLACED:
          return {
            ...state,
            [payload.id]: payload,
          };

        // Optimistic update
        case ITEM_UPDATED:
        case ITEM_FLUSHED:
          return {
            ...state,
            [payload.id]: {
              ...state[payload.id],
              ...payload.delta,
            },
          };

        // Optimistic soft delete
        case ITEM_REMOVED: {
          return {
            ...state,
            [payload.id]: {
              ...state[payload.id],
              [DELETED]: true,
            },
          };
        }

        default:
          return state;
      }
    }

    return state;
  };
}
