import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyleShape } from 'entities/step-children';
import { transformStyles } from 'components/Editor/Primitives';

const Placeholder = styled.div`
  background: var(--noreaster);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  font-family: monospace !important;
  word-break: break-all;
`;

function CustomComponent({ style, identifier, config }) {
  const styles = transformStyles(style);
  const formattedConfig = Object.entries(config).reduce(
    (acc, [key, value]) =>
      `${acc} ${key}=${typeof value === 'string' ? `"${value}"` : value}`,
    ''
  );

  return (
    <Placeholder style={styles} dir="ltr">
      &lt;{!identifier ? 'custom component' : identifier + formattedConfig}{' '}
      /&gt;
    </Placeholder>
  );
}

CustomComponent.propTypes = {
  style: StyleShape,
  identifier: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  config: PropTypes.object,
};

export default CustomComponent;
