import { getContext, call, put, takeEvery, select } from 'redux-saga/effects';
import { INITIALIZE } from 'ext/root/root-actions';
import { completeInteraction, FETCH_ACCOUNTS } from 'ext/lib/track';
import { selectUserId } from 'ext/entities/account';
import { resolve, reject } from './actions';

export function* fetchUserAccounts() {
  try {
    const api = yield getContext('api');
    const userId = yield select(selectUserId);
    const response = yield call(api.getUserAccounts, userId);
    yield put(resolve(response.accounts));
    yield put(completeInteraction(FETCH_ACCOUNTS));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* accountsSaga() {
  yield takeEvery(INITIALIZE, fetchUserAccounts);
}
