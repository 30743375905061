/**
 * Normalized collection filter
 *
 * @param {object[]} collection - List of objects to filter
 * @param {string} property - Property of item to search
 * @param {string} query - Search query
 * @return {object[]} Filtered collection
 */
export default (collection, property, query) => {
  // If neither property nor query is provided, return short-circuit filtering and
  // return original collection
  if (!(property && query)) {
    return collection;
  }

  // Lowercase the query to normalize
  const normalized = query.toLowerCase();

  return collection.filter(item => {
    // Check that the property exists in the item. If it does, check that
    // normalized query is part of the item's property
    return item[property] && item[property].toLowerCase().includes(normalized);
  });
};
