import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheetManager, createGlobalStyle } from 'styled-components';
import { dom } from '@fortawesome/fontawesome-svg-core';
import emoji from 'emoji-mart/css/emoji-mart.css';
import sonar from '@appcues/sonar-tokens/dist/variables.css';
import sonarDark from '@appcues/sonar-tokens/dist/variables.dark.css';
import variables from 'ext/styles/variables.css';
import mobileVariables from './variables.css';
import css from './app.css';

const GlobalStyle = createGlobalStyle`
  ${dom.css()}
  ${emoji.toString()}
  ${variables.toString()}
  ${css.toString()}
  ${sonar.toString()}
  ${sonarDark.toString()}
  ${mobileVariables.toString()}
`;

export default function StyleProvider({ children }) {
  return (
    <StyleSheetManager>
      <>
        <GlobalStyle />
        {children}
      </>
    </StyleSheetManager>
  );
}

StyleProvider.propTypes = {
  children: PropTypes.node,
};
