import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import ContentActions from 'components/SideBarSettings/Content/ContentActions';
import { EMOJI } from 'lib/block';
import { Content, SizeSpacing } from 'components/SideBarSettings/Shared';

export function EmojiSettings({ content, onChange }) {
  const { id } = content ?? {};

  return (
    <Content>
      <Accordion.Root
        collapsible="true"
        defaultValue={['size-spacing']}
        type="multiple"
      >
        <SizeSpacing
          content={{ ...content, parentType: EMOJI }}
          onChange={onChange}
          hasMargin
        />
        <ContentActions blockId={id} context={EMOJI} />
      </Accordion.Root>
    </Content>
  );
}

EmojiSettings.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
};

export default EmojiSettings;
