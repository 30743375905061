import { insert, push } from 'object-path-immutable';
import { generateElementTemplate } from 'components/Editor/Templates';
import {
  getElement,
  getRowAndBlockIndex,
  handleRemoveStickyRow,
} from 'lib/block';

/**
 * Add element in content tree
 *
 * @param {object} content - root content of an step children
 * @param {string} template - template of content block to be inserted
 * @param {object} customComponentModel - template of custom content block to be inserted
 * @param {string} position - position of related block in items array
 * @param {string} newBlockId - new block id to be the wrapper of element created
 * @param {string} newElementId - element id to be created
 * @param {string} rowId - row id to be inserted
 * @param {boolean} newRow - if true, create a new row in content tree
 * @param {array<string>} locales - locales ids to be added in the block element variations
 * @return {object} Updated content tree with updated element
 */
export const addElement = ({
  content,
  template,
  customComponentModel,
  position,
  newBlockId,
  newElementId,
  rowId,
  newRow,
  layoutTrait,
  locales,
}) => {
  const generatedTemplate = generateElementTemplate({
    template,
    customComponentModel,
    blockId: newBlockId,
    elementId: newElementId,
    newRow,
    layoutTrait,
    locales,
  });

  if (!rowId) {
    const updatedContent = push(content, 'items', generatedTemplate);
    return { content: updatedContent };
  }

  const [rowIndex] = getRowAndBlockIndex(content, rowId);
  const indexToInsert = position === 'bottom' ? rowIndex + 1 : rowIndex;

  // If we are adding side-by-side, we push the new item to the row,
  // if it is a new row, we insert as a new item in the content
  let updatedContent =
    position === 'right'
      ? push(content, ['items', rowIndex, 'items'], generatedTemplate)
      : insert(content, 'items', generatedTemplate, indexToInsert);

  const { sticky } = getElement(content, rowId) ?? {};
  if (sticky)
    updatedContent = handleRemoveStickyRow({
      content: updatedContent,
      rowId,
      rowIndex: indexToInsert,
      insertionPosition: position,
    });

  return { content: updatedContent };
};
