/* globals SHADOW_BYPASS, STANDALONE */

/**
 * Get the builder's shadow root. For tests and stories where shadow is
 * bypassed, the window's document is returned instead
 */
export const getBuilderRoot = () => {
  const builderTagName = `appcues-builder${STANDALONE ? '-standalone' : ''}`;
  const $builder = document.querySelector(builderTagName);
  return SHADOW_BYPASS ? document : $builder.shadowRoot;
};

export const getBuilderElement = () => {
  const builderTagName = `appcues-builder${STANDALONE ? '-standalone' : ''}`;
  const $builder = document.querySelector(builderTagName);
  return SHADOW_BYPASS ? document : $builder;
};

/**
 * Get the portal's root element within the builder
 */
export const getPortalRoot = ($builder = getBuilderRoot()) => {
  return $builder.querySelector('#portal-root');
};

/**
 * Get the portal's root element within the builder for light theme
 */
export const getPortalRootLight = ($builder = getBuilderRoot()) => {
  return $builder.querySelector('#portal-root-light');
};

/**
 * Get the editor's document object nested within shadow roots and iframes
 */
export const getEditorDocument = ($builder = getBuilderRoot()) => {
  if (!$builder) {
    return null;
  }

  const $editor = $builder.querySelector('#editor');
  if (!($editor && $editor.shadowRoot)) {
    return null;
  }

  const $frame = $editor.shadowRoot.querySelector('iframe');
  if (!$frame) {
    return null;
  }

  return $frame.contentWindow.document;
};
