import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Mark = styled.span`
  border: 2px solid var(--sharkbait-ooh-la-la);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: 2px 12px 1px 1px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--blurple);
    opacity: 0;
  }
`;

const Input = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;

  &:checked + ${Mark} {
    border-color: var(--blurple);

    &::after {
      opacity: 1;
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px 5px 0;
  position: relative;
  font-size: var(--regular);
  font-weight: var(--bold);
  color: var(--background);
  margin-left: 6px;
  ${({ disabled }) =>
    disabled &&
    `
        cursor: not-allowed;
        opacity: 0.4;
    `}
`;

const Radio = ({ name, value, children, checked, onChange, disabled }) => (
  <Label disabled={disabled}>
    <Input
      checked={checked}
      name={name}
      onChange={onChange}
      type="radio"
      value={value}
      disabled={disabled}
    />
    <Mark aria-checked={checked} aria-label={value} />
    {children}
  </Label>
);

Radio.propTypes = {
  name: PropTypes.string,
  children: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Radio;
