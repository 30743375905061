import React from 'react';
import { Button, Icon, Popover } from '@appcues/sonar';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { WarningIconButton } from './styled';

const handleClick = () =>
  window.open(
    'https://docs.appcues.com/en_US/mobile-building-experiences/styling-mobile-tooltips#troubleshooting-13',
    '_blank'
  );

const SelectorWarningPopover = () => (
  <Popover.Root trigger={<WarningIconButton />} placement="left">
    <Popover.Close>
      <Icon icon={faXmark} />
    </Popover.Close>
    <Popover.Description>
      The selected target ID is not unique <br />
      on this screen captureEvent, which <br />
      could result in this step failing.
    </Popover.Description>
    <Popover.Action>
      <Button onClick={handleClick} variant="secondary" type="button">
        Learn more
        <Icon icon={faExternalLinkAlt} />
      </Button>
    </Popover.Action>
  </Popover.Root>
);

export default SelectorWarningPopover;
