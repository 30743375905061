import styled from 'styled-components';

/**
 * BOX ui primitive
 *
 * container of components laid out on top of one another in an order on the z-axis
 */

export default styled.div.attrs(({ id }) => ({
  'data-apc-type': 'box',
  'data-testid': id,
}))``;
