/**
 * Preview types
 *
 * @constant
 */
export const ERROR_COLOR = 'error-color';
export const ERROR_MESSAGE = 'error-message';
export const INPUT_COLOR = 'input-color';
export const SELECTED_STATE = 'selected-state';

/**
 * Preview types options
 *
 * @constant
 */
export const PREVIEW_TYPES = [
  ERROR_COLOR,
  ERROR_MESSAGE,
  INPUT_COLOR,
  SELECTED_STATE,
];

/**
 * Tabs types
 *
 * @constant
 */
export const STYLE = 'style';
export const TARGET = 'target';
export const NAVIGATION = 'navigation';
