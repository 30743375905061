import { MODAL, CAROUSEL, SKIPPABLE_OPTIONS } from 'lib/trait';
import { SLIDEOUT, STANDARD } from 'lib/presentation';
import { dropShadowTemplate } from './drop-shadow';
import { skippableTemplate } from './skippable';
import { backdropTemplate } from './backdrop';
import { pagingDotsTemplate } from './paging-dots';

/**
 * Slideout has the same structure as modal and uses the presentation
 * style STANDARD. This ensures backward compatibility with previous
 * SDK versions to display a centered modal in those cases.
 *
 * We added an extra slideout property to identify when
 * standard modal should behaves like a slideout.
 */
export const slideoutTemplate = {
  presentationStyle: STANDARD,
  transition: 'slide',
  slideout: true,
  style: {
    verticalAlignment: 'bottom',
    horizontalAlignment: 'leading',
    width: 250,
  },
};

export const modalTemplate = presentation => {
  const isSlideout = presentation === SLIDEOUT;
  const skippableType = isSlideout ? SKIPPABLE_OPTIONS.MINIMAL : '';
  const { style: slideoutStyle, ...slideoutDetails } = slideoutTemplate;

  return [
    {
      type: MODAL,
      config: {
        presentationStyle: presentation,
        ...(presentation === STANDARD && { transition: 'fade' }),
        slideout: false,
        ...(isSlideout && slideoutDetails),
        style: {
          cornerRadius: 8,
          backgroundColor: { light: '#FFFFFF' },
          paddingBottom: 16,
          shadow: dropShadowTemplate,
          ...(isSlideout && slideoutStyle),
        },
      },
    },
    { type: CAROUSEL },
    skippableTemplate(skippableType),
    backdropTemplate,
    pagingDotsTemplate,
  ];
};
