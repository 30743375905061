import React from 'react';
import PropTypes from 'prop-types';
import { Button, DropdownMenu, Icon } from '@appcues/sonar';
import { faClone } from '@fortawesome/free-solid-svg-icons/faClone';
import useToggle from 'ext/lib/hooks/use-toggle';

export function HeaderCloneMenu({ onStepClone, onStepGroupClone }) {
  const [openCloneDropdown, toggleOpenCloneDropdown] = useToggle();

  const handleClone = () => {
    toggleOpenCloneDropdown();
    onStepClone();
  };

  const handleGroupClone = () => {
    toggleOpenCloneDropdown();
    onStepGroupClone();
  };

  return (
    <DropdownMenu.Root open={openCloneDropdown}>
      <DropdownMenu.Trigger asChild onClick={toggleOpenCloneDropdown}>
        <Button iconOnly variant="tertiary" aria-label="Clone">
          <Icon icon={faClone} />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          onInteractOutside={toggleOpenCloneDropdown}
        >
          <DropdownMenu.Item onClick={handleClone}>
            Clone in group
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={handleGroupClone}>
            Clone to new group
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

HeaderCloneMenu.propTypes = {
  onStepClone: PropTypes.func,
  onStepGroupClone: PropTypes.func,
};
