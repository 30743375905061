import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'ext/components/ui';
import { BlockContentShape } from 'entities/block';
import { TEXT_INPUT, SURVEY_BLOCK_LABELS } from 'lib/block';
import { Content, AlignmentSpacing } from 'components/SideBarSettings/Shared';
import TextStyle from './TextStyle';
import BoxStyle from './BoxStyle';

export function TextFieldSettings({ content, onChange }) {
  const { id, textFieldStyle } = content ?? {};

  const handleChange = ({ blockId, style, isPlaceholder = false }) => {
    onChange({
      blockId: blockId || id,
      contentChunk: isPlaceholder ? { style } : { textFieldStyle: style },
      useOriginalId: !isPlaceholder,
    });
  };

  return (
    <Content>
      <Accordion.Root
        collapsible="true"
        defaultValue={['text-style']}
        type="multiple"
      >
        <TextStyle content={content} onChange={handleChange} />
        <BoxStyle style={textFieldStyle} onChange={handleChange} />
        <AlignmentSpacing
          id={id}
          style={textFieldStyle}
          spacingLabel={SURVEY_BLOCK_LABELS[TEXT_INPUT]}
          onChange={handleChange}
          hasPadding
          hasMargin
        />
      </Accordion.Root>
    </Content>
  );
}

TextFieldSettings.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
};

export default TextFieldSettings;
