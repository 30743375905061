import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { assign, del } from 'object-path-immutable';
import {
  Accordion,
  FieldSet,
  Label,
  FontIcon,
  RadioButton,
  RadioButtonGroup,
} from 'ext/components/ui';
import { TARGET_RECTANGLE, TARGET_ELEMENT } from 'lib/trait';
import { capitalize } from 'utils/strings-handler';
import InputWithValidation from 'components/InputWithValidation';
import {
  Controls,
  HelpLabel,
  RadioButtonLabel,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';
import { TooltipIcon } from './styled';

const POSITIONS = {
  AUTO: 'auto',
  TOP: 'top',
  BOTTOM: 'bottom',
};

export default function TooltipPosition({ targetArea, handleTraitsUpdate }) {
  const trackAccordion = useAccordionClick();
  const { config } = targetArea ?? {};
  const {
    contentPreferredPosition,
    contentDistanceFromTarget: distanceFromTarget,
  } = config ?? {};
  const preferredPosition = contentPreferredPosition ?? POSITIONS.AUTO;

  const handleTraitProperty = ({ key, value, traitToDelete }) => {
    const updatedTargetArea = traitToDelete
      ? del(targetArea, `config.${traitToDelete}`)
      : assign(targetArea, 'config', {
          [key]: value,
        });
    handleTraitsUpdate(updatedTargetArea);
  };

  const handleDistance = debounce((key, { target: { valueAsNumber } }) => {
    handleTraitProperty({
      key,
      value: valueAsNumber,
    });
  }, 500);

  const handlePreferredPosition = selectedPreferredPosition => {
    const preferredPositionKey = 'contentPreferredPosition';
    const traitToDelete =
      selectedPreferredPosition === POSITIONS.AUTO && preferredPositionKey;

    handleTraitProperty({
      key: preferredPositionKey,
      value: selectedPreferredPosition,
      traitToDelete,
    });
  };

  return (
    <Accordion.Item value="tooltip-position">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion('Tooltip', 'Tooltip Position')}
        >
          Tooltip position
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Label>
              Placement{' '}
              <HelpLabel>
                Tooltips will be shown in this position <br />
                as long as there is adequate space.
              </HelpLabel>
            </Label>
            <RadioButtonGroup>
              {Object.values(POSITIONS).map(position => (
                <RadioButton
                  key={position}
                  onClick={() => handlePreferredPosition(position)}
                  selected={preferredPosition === position}
                >
                  <RadioButtonLabel isBottom={position === POSITIONS.BOTTOM}>
                    {position !== POSITIONS.AUTO && TooltipIcon}
                    {capitalize(position)}
                  </RadioButtonLabel>
                </RadioButton>
              ))}
            </RadioButtonGroup>
          </FieldSet>

          <FieldSet>
            <Label htmlFor="distance-from-target">Distance from target</Label>
            <InputWithValidation
              id="distance-from-target"
              value={distanceFromTarget ?? 0}
              onChange={event =>
                handleDistance('contentDistanceFromTarget', event)
              }
              type="number"
              placeholder="0"
            />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

TooltipPosition.propTypes = {
  targetArea: PropTypes.shape({
    type: PropTypes.oneOf([TARGET_RECTANGLE, TARGET_ELEMENT]),
    config: PropTypes.shape({
      contentPreferredPosition: PropTypes.oneOf([
        POSITIONS.TOP,
        POSITIONS.BOTTOM,
      ]),
      contentDistanceFromTarget: PropTypes.number,
    }),
  }),
  handleTraitsUpdate: PropTypes.func,
};
