import styled, { css } from 'styled-components';
import {
  Button as SonarButton,
  DropdownMenu as SonarDropdownMenu,
  Icon as SonarIcon,
  Label as SonarLabel,
  FormField as SonarFormField,
} from '@appcues/sonar';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: var(--spacing-regular);

  flex: 1;
  overflow: hidden;
  padding: var(--spacing-regular) var(--spacing-regular) 0;
`;

export const TemplateWrapper = styled.div`
  overflow: auto;
  padding: var(--spacing-small);
  border: 1px solid var(--border-default);
  border-radius: var(--border-radius-small);

  flex: 1;
  margin-bottom: var(--spacing-regular);

  > * {
    margin-bottom: var(--spacing-regular);
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ActionsGroup = styled.div`
  padding: var(--spacing-regular);
  gap: var(--spacing-regular);
  box-shadow: var(--level-2);
`;

const commonButtonStyles = css`
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 12px;
`;

export const Label = styled(SonarLabel)`
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DropdownMenuTriggerButton = styled(SonarButton)`
  ${commonButtonStyles}

  border: 4px solid transparent;
`;

export const DropdownMenuContent = styled(SonarDropdownMenu.Content)`
  z-index: var(--z-index-300);
`;

export const DropdownMenu = {
  ...SonarDropdownMenu,
  Content: DropdownMenuContent,
};

const TemplateCardInfo = styled.div`
  min-height: 75px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-x-small);
  justify-content: space-between;

  ${SonarIcon} {
    margin-right: var(--spacing-x-small);
  }
`;

const TemplateCardDescription = styled.p`
  margin: 0;
  font-size: var(--font-size-regular);
  color: var(--foreground-secondary);
`;

const TemplateCardActions = styled.div`
  align-self: flex-start;
`;

const TemplateCardOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-neutral-200);
  padding: var(--spacing-small) var(--spacing-small) var(--spacing-small)
    var(--spacing-regular);
`;

const TemplateCardRoot = styled.article`
  position: relative;
  display: flex;
  border: 1px solid var(--border-input-default);
  border-radius: var(--border-radius-small);
  overflow: hidden;

  align-items: center;
  justify-content: space-between;
  background-color: var(--color-neutral-200);
  padding: var(--spacing-small) var(--spacing-small) var(--spacing-small)
    var(--spacing-regular);

  &:hover {
    outline: 1px solid var(--border-input-hover);
    cursor: pointer;
  }
`;

export const TemplateCard = {
  Root: TemplateCardRoot,
  Info: TemplateCardInfo,
  Description: TemplateCardDescription,
  Actions: TemplateCardActions,
  Overlay: TemplateCardOverlay,
};

export const ActionsWrapper = styled.div`
  display: flex;
  margin-left: var(--spacing-x-small);

  ${SonarButton} {
    ${commonButtonStyles}
  }
`;

// Flyout

export const OptionalText = styled.span`
  font-weight: var(--font-weight-regular);
  margin: 0 var(--spacing-x-small);
`;

export const PropertyContainer = styled.div`
  position: relative;
  background: var(--color-neutral-50);
  margin-bottom: calc(var(--spacing-large) + var(--spacing-small));
  padding: var(--spacing-regular);
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--border-radius-small);

  > h3 {
    font-size: var(--font-size-medium);
    font-weight: 600;
    margin-top: 0;
    color: var(--foreground-secondary);
  }
`;

export const ButtonDelete = styled(SonarButton)`
  position: absolute;
  top: var(--spacing-regular);
  right: var(--spacing-regular);
  border: none;
  background: none;
  cursor: pointer;
  padding: var(--spacing-small);
`;

export const SideBySide = styled.div`
  display: flex;
  gap: 16px;

  > ${SonarFormField} {
    flex: 1;
    padding-bottom: 0;
  }
`;
