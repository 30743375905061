import { login, isSpoofing } from 'lib/auth';
import { create as createApiClient } from 'lib/api-client';

const initialize = async () => {
  // Authenticate user
  const auth = await login();

  // Check if user is spoofing
  const spoofing = await isSpoofing();

  // Create API client to get important data from our user
  const { getUser, getAccount } = createApiClient(auth);

  // Get user data
  const user = await getUser(auth.userId);

  // Get account data
  const account = await getAccount();

  // Set user signature for Appcues usage
  if (window.AppcuesSettings) {
    window.AppcuesSettings.userIdSignature = user.signature;
  } else {
    window.AppcuesSettings = { userIdSignature: user.signature };
  }

  return {
    auth,
    user: { ...user, isSpoofing: spoofing },
    account,
  };
};

export default initialize;
