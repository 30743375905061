import { useEffect } from 'react';
import debounce from 'lodash.debounce';

/**
 * A custom React hook that adds a debounced window resize event listener.
 * @param {function} callback - The function to be called when the window is resized.
 * @param {number} delay - The delay in milliseconds to debounce the callback.
 * @returns {void}
 */
export default function useWindowResize(callback, delay = 200) {
  const debouncedCallback = debounce(callback, delay);

  useEffect(() => {
    window.addEventListener('resize', debouncedCallback);
    return () => window.removeEventListener('resize', debouncedCallback);
  }, [debouncedCallback]);
}
