export const INITIALIZE = '@root/INITIALIZE';
export const SDK_LOADED = '@root/SDK_LOADED';
export const SDK_INJECTED = '@root/SDK_INJECTED';
export const LOCATION_CHANGED = '@root/LOCATION_CHANGED';

export const initialize = (auth = {}) => ({
  type: INITIALIZE,
  payload: auth,
});

export const loadSDK = () => ({
  type: SDK_LOADED,
});

export const injectSDK = () => ({
  type: SDK_INJECTED,
});

export const changeLocation = () => ({
  type: LOCATION_CHANGED,
});
