import { v4 as uuid } from 'uuid';
import { BLOCK, BUTTON, TEXT } from 'lib/block';

const buttonTemplate = ({ blockId, elementId, content, style }) => {
  const { style: contentStyle, ...contentRest } = content ?? {};

  return {
    type: BLOCK,
    blockType: BUTTON,
    id: blockId,
    content: {
      type: BUTTON,
      id: elementId,
      content: {
        type: TEXT,
        id: uuid(),
        text: 'Next',
        ...contentRest,
        style: {
          fontSize: 17,
          foregroundColor: { light: '#FFFFFF' },
          ...contentStyle,
        },
      },
      style: {
        marginTop: 8,
        marginLeading: 16,
        marginTrailing: 16,
        marginBottom: 16,
        paddingTop: 12,
        paddingLeading: 24,
        paddingTrailing: 24,
        paddingBottom: 12,
        backgroundColor: { light: '#5C5CFF' },
        cornerRadius: 6,
        ...style,
      },
    },
  };
};

export default buttonTemplate;
