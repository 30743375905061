import styled, { css } from 'styled-components';
import { FontIcon } from 'ext/components/ui';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Label = styled.label`
  font-weight: var(--bold);
  font-size: var(--regular);
  color: var(--background);
  margin-right: 10px;
`;

export const AlignmentButton = styled.button`
  background: none;
  border: 1px solid transparent;
  border-radius: 6px;
  color: var(--sharkbait-ooh-la-la);
  cursor: pointer;
  height: 40px;
  width: 40px;

  &:focus {
    outline: 2px solid var(--blurple);
    outline-offset: -2px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: var(--luna);
      border: 1px solid var(--blurple);
      color: var(--blurple);

      &:focus {
        outline: none;
      }
    `}
`;

export const TextAlignmentIcon = styled(FontIcon)``;
