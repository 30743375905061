import React, { Children, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tether from 'ext/components/Tether';

const CARET_WIDTH = 6;

// Offset to center tooltip on the target's tether point based on placement
const tooltipOffset = ({ placement }) => {
  const [primary] = placement.split('-');

  switch (primary) {
    case 'top':
      return `
        transform: translateY(-${CARET_WIDTH}px);

        &::after {
          top: 100%;
          left: calc(50% - ${CARET_WIDTH}px);
          border-top-color: inherit;
        }
      `;
    case 'right':
      return `
        transform: translateX(${CARET_WIDTH}px);

        &::after {
          right: 100%;
          top: calc(50% - ${CARET_WIDTH}px);
          border-right-color: inherit;
        }
      `;
    case 'bottom':
      return `
        transform: translateY(${CARET_WIDTH}px);

        &::after {
          bottom: 100%;
          left: calc(50% - ${CARET_WIDTH}px);
          border-bottom-color: inherit;
        }
      `;
    case 'left':
      return `
        transform: translateX(-${CARET_WIDTH}px);

        &::after {
          left: 100%;
          top: calc(50% - ${CARET_WIDTH}px);
          border-left-color: inherit;
        }
      `;
    default:
      return '';
  }
};

const Target = styled.div``;

const Tip = styled.div`
  align-items: center;
  background: var(--background-light);
  border-color: var(--background-light);
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  color: var(--white);
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  pointer-events: none;
  white-space: nowrap;

  &::after {
    border: solid transparent;
    border-width: ${CARET_WIDTH}px;
    content: '';
    display: inline-block;
    position: absolute;
  }

  ${tooltipOffset};
`;

const Tooltip = ({
  children,
  className,
  delay = 0,
  disabled = false,
  distance = 0,
  label,
  onMouseOut,
  onMouseOver,
  placement: preferred = 'top',
  timeout = 200,
  visible: forced,
  wrapped = false,
}) => {
  const [open, setOpen] = useState(false);

  // If an explicit `visible` prop is provided, assume the tooltip is
  // managed by the parent and use that value over local state
  const visible = forced != null ? forced : open;

  // If the managing component needs to conditionally apply this tooltip, using
  // this `disabled` prop can handle the conditional wrapping of child component
  // rather than having the managing component do that which is usually more
  // cumbersome
  if (disabled) {
    return children;
  }

  const handleMouseOver = () => {
    if (onMouseOver) onMouseOver();
    setOpen(true);
  };

  const handleMouseOut = () => {
    if (onMouseOut) onMouseOut();
    setOpen(false);
  };

  const renderTarget = () => {
    // If the wrapped component already has ref or the mouse handler defined,
    // rather than cloning the element we can wrap the target and have the
    // wrapper bound with the ref and mouse handlers e.g. StepChild
    if (wrapped) {
      return (
        <Target
          data-tooltip="wrapped"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {children}
        </Target>
      );
    }

    // Assert that a single element is wrapped by the tooltip e.g. no fragments
    const child = Children.only(children);

    // To ensure that structural styles are preserved e.g. flex childs, clone the
    // wrapped component and merge the hover handlers and ref prop
    return cloneElement(child, {
      'data-tooltip': 'target',
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
    });
  };

  const attachment = ({ tether, ref }) => (
    <Tip
      ref={ref}
      className={className}
      placement={tether.placement}
      role="tooltip"
    >
      {typeof label === 'function' ? label() : label}
    </Tip>
  );

  return (
    <Tether
      attachment={attachment}
      delay={delay}
      distance={distance}
      placement={preferred}
      timeout={timeout}
      visible={visible}
    >
      {renderTarget()}
    </Tether>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onMouseOut: PropTypes.func,
  onMouseOver: PropTypes.func,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  wrapped: PropTypes.bool,

  // Inherited fade props
  delay: PropTypes.number,
  distance: PropTypes.number,
  timeout: PropTypes.number,
  visible: PropTypes.bool,
};

export default styled(Tooltip)``;
