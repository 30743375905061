import { del, set } from 'object-path-immutable';
import { ROW } from './block';
import { getElement } from './block-handler';
import { getNodePath } from './get-node-path';

/**
 * Get content row and block indexes
 * @param {object} content - root content of an step children
 * @param {string} nodeId - nodeId (can be a stack or block)
 * @return {[number, number]} Row and block indexes
 */
export const getRowAndBlockIndex = (content, nodeId) => {
  const nodePath = getNodePath(content, nodeId);
  if (!nodePath) return [undefined, undefined];

  const [rowIndex, blockIndex] = nodePath.filter(item =>
    Number.isInteger(item)
  );

  return [rowIndex, blockIndex];
};

/**
 * Get last row index available in content tree
 * @param {object} content - root content of an step children
 * @return {number} Last row index
 */
export const getLastRowIndex = content => {
  const items = content?.items ?? [];
  const rowsLength = items.filter(({ role }) => role === ROW)?.length;
  let lastRowIndex;

  // Indexes starts with zero
  if (rowsLength >= 1) lastRowIndex = rowsLength - 1;

  return lastRowIndex;
};

/**
 * Update sticky property in row element
 * @param {object} content - root content of an step children
 * @param {string} rowId - row id
 * @param {string} stickyPosition - sticky position (top, bottom or undefined)
 * @return {object} Updated content tree with updated sticky row property
 */
export const updateStickyRow = ({ content, rowId, stickyPosition }) => {
  const row = getElement(content, rowId);
  const [rowIndex] = getRowAndBlockIndex(content, rowId);
  const updatedRow =
    !row.sticky && stickyPosition
      ? set(row, 'sticky', stickyPosition)
      : del(row, 'sticky');

  const updatedContent = set(content, ['items', rowIndex], updatedRow);

  return { content: updatedContent };
};

/**
 * Remove sticky property from the current row on user interactions
 * @param {object} content - root content of an step children
 * @param {string} rowId - row id
 * @param {string} stickyPosition - sticky position (top, bottom or undefined)
 * @return {object} Updated content tree with updated sticky row property
 */
export const handleRemoveStickyRow = ({
  content,
  rowId,
  rowIndex,
  insertionPosition = 'bottom',
  isDnd,
}) => {
  // If a new row is added above first row or below last row;
  // clone operations are also included in the last case
  const insertionRules =
    (insertionPosition === 'top' && rowIndex === 0) ||
    (insertionPosition === 'bottom' && rowIndex === getLastRowIndex(content));

  if (insertionRules || isDnd) {
    const { content: updatedContent } = updateStickyRow({ content, rowId });
    return updatedContent;
  }

  return content;
};
