import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import FontIcon from './FontIcon';

const theme = `
  --checkbox-background: var(--bludacris);
  --checkbox-border: var(--white);
  --checkbox-border-hover: var(--white);
  --checkbox-color: var(--oh-hi-dark);
  --checkbox-label-color: var(--white);
  --checkbox-border-disabled: var(--sharkbait-ooh-la-la);
  --checkbox-color-disabled: var(--sharkbait-ooh-la-la);

  [data-theme="light"] & {
    --checkbox-background: var(--blurple);
    --checkbox-border: var(--sharkbait-ooh-la-la);
    --checkbox-border-hover: var(--pleather);
    --checkbox-color: var(--white);
    --checkbox-label-color: var(--pleather);
    --checkbox-border-disabled: var(--dirty-water);
    --checkbox-color-disabled: var(--dirty-water);
  }
`;

const Box = styled.div`
  align-items: center;
  border-color: var(--checkbox-border);
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  color: var(--checkbox-color);
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 16px;
  justify-content: center;
  width: 16px;

  &:hover,
  &:focus {
    border-color: var(--checkbox-border-hover);
  }

  ${FontIcon} {
    height: 12px;
    width: 12px;
  }

  &[aria-checked='true'] {
    background: var(--checkbox-background);
    border: none;
  }
`;

const Label = styled.span`
  color: var(--checkbox-label-color);
  cursor: pointer;
  font-size: var(--regular);
  font-weight: var(--normal);
  margin-left: 8px;
`;

const Container = styled.div`
  ${theme}

  align-items: center;
  display: flex;

  ${({ disabled }) =>
    disabled &&
    css`
      ${Box}, ${Label} {
        border-color: var(--checkbox-border-disabled);
        color: var(--checkbox-color-disabled);
        cursor: default;
      }
    `}
`;

function Checkbox({
  checked: initialValue,
  children,
  className,
  disabled,
  label,
  onChange,
}) {
  const [checked, setChecked] = useState(initialValue);

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  const handleClick = event => {
    if (disabled) return;
    if (onChange) {
      onChange(!checked, event);
    }
    setChecked(!checked);
  };

  return (
    <Container className={className} onClick={handleClick} disabled={disabled}>
      <Box
        aria-checked={checked}
        aria-disabled={disabled}
        aria-label={label}
        role="checkbox"
        tabIndex="0"
      >
        {checked && <FontIcon icon="check" />}
      </Box>

      {(children || label) && <Label>{children || label}</Label>}
    </Container>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default styled(Checkbox)``;
