import styled, { css } from 'styled-components';
import { STICKY_OPTIONS } from 'lib/block';
import { DividerWrapper } from 'components/BlockDivider';

const [, BOTTOM] = STICKY_OPTIONS;

export const DragHandle = styled.div`
  z-index: 2;
  position: absolute;
  display: none;
  height: 100%;
  width: 25px;
  background: var(--color-blue-500);
  cursor: grab;

  &::after {
    content: url('data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIwIDMyIiBmaWxsPSIjZmZmIj48cGF0aCBkPSJNOCA0YTQgNCAwIDEgMS00LTQgNCA0IDAgMCAxIDQgNFptOC00YTQgNCAwIDEgMCA0IDQgNCA0IDAgMCAwLTQtNFpNNCAxMmE0IDQgMCAxIDAgNCA0IDQgNCAwIDAgMC00LTRabTEyIDBhNCA0IDAgMSAwIDQgNCA0IDQgMCAwIDAtNC00Wk00IDI0YTQgNCAwIDEgMCA0IDQgNCA0IDAgMCAwLTQtNFptMTIgMGE0IDQgMCAxIDAgNCA0IDQgNCAwIDAgMC00LTRaIi8+PC9zdmc+');
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 7px;
  }
`;

export const RowSelection = styled.div`
  position: relative;
  transform: translate(0);

  &:hover {
    ${DividerWrapper} {
      display: flex;
    }
  }

  &:not(:focus-within) {
    &:hover {
      background: var(--color-blue-100-opacity-50);

      ${DragHandle} {
        display: block;
      }
    }
  }

  &:not(:has([data-dragging='true'])) {
    ${({ isDragging }) =>
      !isDragging &&
      `
        &:hover {
          ${DividerWrapper} {
            opacity: 1;
          }
        }
      `}
  }

  ${({ sticky }) => css`
    ${sticky &&
    css`
      z-index: 3;
      position: sticky;
      ${sticky}: 0;
      ${sticky === BOTTOM && 'margin-top: auto;'}
    `}
  `}
`;

export const StickyOverlay = styled.div`
  &[data-overlay='true'] {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    outline: 1px dashed var(--color-blue-500);
    outline-offset: -1px;
    background: var(--color-blue-100-opacity-50);
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const BlockOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  background-color: var(--myst);
  z-index: 1;

  ${({ height, width }) => `
    height: ${height}px;
    width: ${width}px;
  `}
`;
