import React from 'react';
import styled from 'styled-components';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { Text, Link } from '@appcues/sonar';

const ErrorText = styled(Text)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: var(--color-pink-600);
  text-align: center;
`;

const Error = () => (
  <ErrorText>
    Unable to find selectors on this screen.{' '}
    <Link
      href="https://docs.appcues.com/en_US/mobile-building-experiences/styling-mobile-tooltips#troubleshooting-13"
      icon={faExternalLinkAlt}
      inline
    >
      Learn more
    </Link>
  </ErrorText>
);

export default Error;
