import styled from 'styled-components';

export const Link = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noreferrer noopener',
}))`
  color: var(--primary-light);
  text-decoration: none;
  font-weight: var(--bold);
`;

export const Truncated = styled.span`
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const OptionTitle = styled.h3`
  color: var(--white);
  display: block;
  font-size: var(--medium-large);
  font-weight: var(--bold);
  margin: 0 0 4px 0;
  text-transform: capitalize;
`;

export const OptionDesc = styled.p`
  color: var(--text-color);
  font-weight: var(--light);
  margin: 5px 0 0;
`;

export const Text = styled.span`
  font-weight: ${({ bold }) => bold && 'bold'};
  text-transform: ${({ capitalize }) => capitalize && 'capitalize'};
`;

export const P = styled.p`
  font-size: var(--regular);
`;

export const Heading = styled.h2`
  font-size: var(--medium-large);
  font-weight: var(--bold);
  margin: 8px 0 16px;
`;

export const WarningMessage = styled.div`
  color: var(--warning-light);
  display: flex;
  align-items: baseline;
  margin: 10px 0;

  &:last-child {
    margin-bottom: 0;
  }

  ${Text} {
    font-size: var(--regular);
    margin-left: 6px;
  }
`;

export const ErrorMessage = styled(WarningMessage)`
  color: var(--error-light);
`;

/**
 * @deprecated - Use `<Text bold />` instead
 */
export const Bold = styled.strong``;
