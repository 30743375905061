import styled from 'styled-components';
import Input from './Input';
import FontIcon from './FontIcon';

const Search = styled.div`
  align-items: center;
  display: flex;

  ${({ wrapped }) =>
    wrapped &&
    `
    background-color: var(--background-light);
    border-radius: 6px;
    padding: 0 12px;
  `}

  ${Input} {
    --input-active: none;
    --input-border: none;

    background: inherit;
    font-size: var(--regular);
  }

  ${FontIcon} {
    color: var(--light-text-color);
    font-size: var(--regular);
    margin-right: 4px;
  }
`;

export default Search;
