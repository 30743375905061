import { createLifecycleFor } from 'ext/lib/collections';

export const TYPE = 'auth';

export const { resolve, update } = createLifecycleFor(TYPE);

export const ACCOUNT_CHANGED = 'ACCOUNT_CHANGED';
export const change = id => ({
  type: ACCOUNT_CHANGED,
  payload: id,
});

export const ACCOUNT_SPOOFED = 'ACCOUNT_SPOOFED';
export const spoof = id => ({
  type: ACCOUNT_SPOOFED,
  payload: id,
});

export const ACCOUNT_DESPOOFED = 'ACCOUNT_DESPOOFED';
export const despoof = () => ({
  type: ACCOUNT_DESPOOFED,
});
