/* globals CUSTOMER_API_URL, AUTH_APP_URL */

import createAuthClient from '@appcues/auth';
import PropTypes from 'prop-types';

/**
 * Authenticated user interface
 *
 * @typedef {object} Auth
 * @property {string} jwt - JSON web token for API
 * @property {string} accountId - Account ID
 * @property {string} userId - User ID
 */
export const Shape = PropTypes.shape({
  jwt: PropTypes.string,
  accountId: PropTypes.string,
  userId: PropTypes.string,
});

let authClient;

const getAuthClient = () => {
  if (!authClient) {
    authClient = createAuthClient(CUSTOMER_API_URL, AUTH_APP_URL);
  }
  return authClient;
};

const withAuthClient =
  fn =>
  (...args) => {
    const auth = getAuthClient();
    return fn(auth, ...args);
  };

/**
 * Verify logged in user or redirect back to login page.
 *
 * @return {Promise<Auth>} Promise holding logged in user
 */
export const login = withAuthClient(async auth => {
  const [jwt, accountId, userId] = await Promise.all([
    auth.getToken(),
    auth.getAccountId(),
    auth.getUserId(),
  ]);

  return { jwt, accountId, userId };
});

/**
 * Logout user
 *
 * @return {Promise<void>}
 */
export const logout = withAuthClient(auth => {
  return auth.logout();
});

/**
 * Spoof into another account
 *
 * @param {string} id - Account ID to spoof
 * @return {Promise<void>}
 */
export const spoof = withAuthClient((auth, id) => {
  return auth.spoof(id);
});

/**
 * Despoof from current account
 *
 * @return {Promise<void>}
 */
export const despoof = withAuthClient(auth => {
  return auth.despoof();
});

/**
 * Whether user is currently spoofing
 *
 * @return {Promise<boolean>}
 */
export const isSpoofing = withAuthClient(auth => {
  return auth.isSpoofing();
});

/**
 * Switch account
 *
 * @param {string} id - Account ID
 * @return {Promise<void>}
 */
export const switchAccount = withAuthClient((auth, id) => {
  return auth.switchAccount(id);
});
