import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import handleErrorStyle from './error-style-handler';

const Message = styled.p`
  margin: 0px;
`;

const ErrorMessage = ({ message, color, transformedStyle }) => (
  <Message style={handleErrorStyle({ style: transformedStyle, color })}>
    {message}
  </Message>
);

ErrorMessage.propTypes = {
  message: PropTypes.string,
  color: PropTypes.string,
  transformedStyle: PropTypes.shape({
    fontSize: PropTypes.string,
    textAlign: PropTypes.string,
    justifyContent: PropTypes.string,
    marginTop: PropTypes.string,
  }),
};

export default ErrorMessage;
