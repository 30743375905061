import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Input } from '@appcues/sonar';
import { RangeInput } from 'ext/components/ui';
import { Validation, Error } from './styled';

const InputWithValidation = ({
  value,
  max,
  min,
  range,
  onChange,
  ...props
}) => {
  const isRangeInput = range;

  const [currentValue, setCurrentValue] = useState(value);
  const [error, setError] = useState(false);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleError = debounce(setError, 500);

  const handleChange = (event, rangeEvent) => {
    const { value: inputValue, valueAsNumber } =
      event.target || rangeEvent.target;

    // If the user tries to clear the input
    // let them to clear it and wait until they delete something again
    if (inputValue === '') {
      setCurrentValue('');
      handleError('Please insert a number type');
      return;
    }

    if (Number.isNaN(valueAsNumber)) {
      setCurrentValue(Number(min) || 0);
      if (max && (min || min === 0)) {
        handleError(`Expected a ${min}-${max} number`);
        return;
      }

      handleError('Please insert a number type');
      return;
    }

    setCurrentValue(valueAsNumber);

    if (valueAsNumber > Number(max)) {
      handleError(`Should be smaller than ${max}`);
      // We need to cancel any debounce function that might be running
      onChange.cancel?.();
      return;
    }

    if (valueAsNumber < Number(min)) {
      handleError(`Should be greater than ${min}`);
      return;
    }

    handleError(false);
    onChange(event);
  };

  const InputComponent = isRangeInput ? RangeInput : Input;

  const maxMin = {
    max: isRangeInput ? max : `${max}`,
    min: isRangeInput ? min : `${min}`,
  };

  const inputProps = { ...props, error, ...maxMin, onChange: handleChange };

  return (
    <Validation error={isRangeInput && error}>
      {/* eslint-disable-next-line @appcues/jsx-props-no-spreading */}
      <InputComponent value={currentValue} {...inputProps} />
      {error && <Error isRangeInput={isRangeInput}>{error}</Error>}
    </Validation>
  );
};

InputWithValidation.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  range: PropTypes.bool,
  onChange: PropTypes.func,
};

export default InputWithValidation;
