import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '@appcues/sonar';
import selectScreenIcon from './select-screen-icon.png';

const Wrapper = styled.div`
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 40px;

  > img {
    align-self: center;
  }
`;

const Empty = ({ onToggleFloatingScreen }) => (
  <Wrapper onClick={onToggleFloatingScreen}>
    <img src={selectScreenIcon} alt="Select a screen" />
    <Text>Use a captured screen to target specific elements from your app</Text>
  </Wrapper>
);

Empty.propTypes = {
  onToggleFloatingScreen: PropTypes.func,
};

export default Empty;
