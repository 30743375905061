import { PAGING_DOTS } from 'lib/trait';

export const pagingDotsStyleTemplate = {
  style: {
    verticalAlignment: 'bottom',
    horizontalAlignment: 'center',
    foregroundColor: { light: '#999999' },
    backgroundColor: { light: '#CCCCCC' },
  },
};

export const pagingDotsTemplate = {
  type: PAGING_DOTS,
  config: pagingDotsStyleTemplate,
};
