import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Switch } from '@appcues/sonar';
import { FontIcon, Tooltip } from 'ext/components/ui';

const Label = styled.span`
  text-align: center;
`;

const FontIconWrapper = styled.span`
  padding: 5px;

  ${Switch.Label} > & {
    display: inline;
  }

  ${FontIcon} {
    color: var(--blurple);
  }
`;

export default function HelpLabel({ children, placement }) {
  const tooltipLabel = <Label>{children}</Label>;

  return (
    <Tooltip label={tooltipLabel} placement={placement}>
      <FontIconWrapper>
        <FontIcon icon="question-circle" />
      </FontIconWrapper>
    </Tooltip>
  );
}

HelpLabel.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
};
