import styled, { css } from 'styled-components';
import { BottomBarMenu, Button, FontIcon } from 'ext/components/ui';
import StepIcon from './Icons';

const centeredStyles = css`
  align-items: stretch;
  display: inline-flex;
  justify-content: center;
`;

const iconButtonStyles = css`
  ${centeredStyles}

  background: var(--background-x-light);
  cursor: pointer;
  transition: background 200ms ease-in-out;

  :hover {
    background: var(--background-x-light);
  }

  ${FontIcon}, ${StepIcon} {
    color: var(--white);
    fill: var(--white);
    height: 100%;
    width: 100%;
  }
`;

export const Centered = styled.div`
  ${centeredStyles}
`;

export const PageGroup = styled.div`
  ${centeredStyles}

  &:not(:last-of-type)::after {
    --caret-width: 5px;

    align-self: center;
    border: solid transparent;
    border-left-color: var(--text-color);
    border-width: var(--caret-width);
    content: '';
    display: inline-block;
    height: var(--caret-width);
    margin-left: 6px;
    width: var(--caret-width);
  }
`;

export const AddWrapper = styled.div`
  ${centeredStyles}

  ${Button} {
    color: var(--white);
    font-weight: var(--bold);
    padding: 8px 16px;
  }

  ${FontIcon} {
    margin-right: 6px;
  }

  ${({ centered }) =>
    centered &&
    `
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, 0, 0);

      ${Button} {
        width: 160px;
      }

      ${BottomBarMenu} {
        bottom: 54px;
      }
    `}
`;

export const StepGroups = styled.div`
  ${centeredStyles}

  background: var(--background);
  border-radius: 8px;
  padding: 4px;

  ${AddWrapper} {
    &::before {
      content: '';
      align-self: center;
      border: 1px solid var(--text-color);
      height: 10px;
      margin-right: 6px;
      width: 1px;
    }
  }
`;

export const DragHandle = styled.div`
  ${iconButtonStyles}

  height: 100%;
  padding: 10px 3px;
  width: 14px;

  ${FontIcon} {
    color: var(--text-color);
  }
`;

export const NavigationIcon = styled(FontIcon).attrs({
  icon: 'share',
  'aria-label': 'Navigation icon',
})``;

export const WarningIcon = styled(FontIcon).attrs({
  icon: 'exclamation-triangle',
  'aria-label': 'Warning icon',
})``;

export const StepChildWrapper = styled.div`
  ${iconButtonStyles}

  padding: 10px;
  width: 40px;

  ${StepIcon} {
    height: 20px;
    width: 20px;
  }

  ${FontIcon} {
    position: absolute;
    color: var(--color-yellow-300);
    height: 12px;
    bottom: 3px;
    font-size: var(--large);
    margin-left: 20px;
    width: 12px;
  }

  ${NavigationIcon} {
    top: 2px;
    color: var(--text-color);
    margin-left: -20px;
  }

  ${({ selected }) =>
    selected &&
    `
      background: var(--secondary);
      transition: background 0s;

      :hover {
        background: var(--secondary) !important;
      }
  `}

  ${({ dragging, droppable }) =>
    dragging &&
    `
      background: ${
        droppable ? 'var(--secondary)' : 'var(--background-x-light)'
      };
      transition: background 0s;

      :hover {
        background: ${
          droppable ? 'var(--secondary)' : 'var(--background-x-light)'
        } !important;
      }

      ${StepIcon} {
        opacity: 0;
      }
  `}
`;

export const StepChildren = styled.div`
  ${centeredStyles}

  ${StepChildWrapper}, ${DragHandle} {
    --border-radius: 6px;

    margin-right: 2px;

    &:first-of-type {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }

    &:last-of-type {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      margin-right: 0;
    }
  }
`;

export const StepGroupWrapper = styled.div`
  ${centeredStyles}

  background: transparent;
  transform: translate3d(0, 0, 0);

  margin-left: 6px;
  margin-right: 6px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ dragging, droppable }) =>
    dragging &&
    `
    ${StepChildren} {
        background: ${
          droppable ? 'var(--secondary)' : 'var(--background-x-light)'
        };
        border-radius: 6px;
      }

      ${StepChildWrapper}, ${DragHandle} {
        opacity: 0;
        pointer-events: none;
      }
  `}
`;

export const Label = styled.span`
  text-align: center;
  white-space: pre;
`;

export const Container = styled.div`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 16px;
  overflow-x: hidden;

  > ${Centered} {
    margin: 0 auto;
  }

  ${({ scrollable }) =>
    scrollable &&
    `
      margin: 0;

      ${PageGroup} {
        &:first-of-type {
          ${StepGroups} {
            &:first-of-type {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }

        &:last-of-type {
          ${StepGroups} {
            &:last-of-type {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
  `}

  ${({ dragging }) =>
    dragging &&
    `
      overflow-x: scroll;

      ::-webkit-scrollbar {
        display: none;
      }

      ${DragHandle}, ${StepChildWrapper} {
        :hover {
          background: var(--background-x-light);
        }
      }
  `}
`;
