import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@appcues/sonar';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { Accordion, FontIcon } from 'ext/components/ui';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { EditorShape } from 'entities/step-groups';
import { Controls, useAccordionClick } from 'components/SideBarSettings/Shared';
import { GridContainer, GridItem } from './styled';

const arrows = {
  'top leading': faArrowRight,
  'top center': faArrowDown,
  'top trailing': faArrowLeft,
  'center leading': faArrowRight,
  'center center': faArrowUp,
  'center trailing': faArrowLeft,
  'bottom leading': faArrowRight,
  'bottom center': faArrowUp,
  'bottom trailing': faArrowLeft,
};

export default function Position({ editor, onChange }) {
  const { track } = useAnalytics();
  const trackAccordion = useAccordionClick();
  const { style } = editor ?? {};
  const { verticalAlignment, horizontalAlignment } = style ?? {};

  const handleTrack = useCallback(
    name => {
      track('Mobile Builder interaction', {
        name,
        component: 'ModalSettings - Position',
      });
    },
    [track]
  );

  const handleAlignmentUpdate = useCallback(
    value => {
      const [verticalAlignmentValue, horizontalAlignmentValue] =
        value.split(' ');

      if (
        verticalAlignment === verticalAlignmentValue &&
        horizontalAlignment === horizontalAlignmentValue
      ) {
        return;
      }

      handleTrack(`Updated Position ${value}`);

      onChange({
        style: {
          ...style,
          verticalAlignment: verticalAlignmentValue,
          horizontalAlignment: horizontalAlignmentValue,
        },
      });
    },
    [horizontalAlignment, verticalAlignment, style, onChange, handleTrack]
  );

  return (
    <Accordion.Item value="position">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Modal', 'Position')}>
          Position
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <GridContainer aria-label="Slideout position">
            {Object.keys(arrows).map(option => (
              <GridItem
                key={option}
                aria-label={`Slideout ${option} position`}
                active={
                  `${verticalAlignment} ${horizontalAlignment}` === option
                }
                onClick={() => handleAlignmentUpdate(option)}
              >
                <Icon icon={arrows[option]} />
              </GridItem>
            ))}
          </GridContainer>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Position.propTypes = {
  editor: EditorShape,
  onChange: PropTypes.func,
};
