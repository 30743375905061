import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { INITIALIZE } from 'ext/root/root-actions';
import { reject, resolve } from './actions';

function* fetchUser({ payload: { userId } }) {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getUser, userId);
    yield put(resolve(response));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery(INITIALIZE, fetchUser);
}
