import isEmpty from 'lodash.isempty';
import { set } from 'object-path-immutable';
import { TARGET_ELEMENT } from './trait';

/**
 * Handles updating the traits array
 *
 * @param {Object} traits - The traits array to update
 * @param {Object[]} updatedTraits - The updated traits to add to the traits array
 * @param {string} traitTypeToDelete - The trait type to delete from the traits array
 * @returns {Object[]} The updated traits array
 */
export const updateTraits = ({
  traits = [],
  updatedTraits = [],
  traitTypeToDelete,
}) => {
  // If there is a traitTypeToDelete, remove it from the traits array
  let updatedTraitsArray = traits.filter(
    trait => trait.type !== traitTypeToDelete
  );

  // If the updatedTraits is an object, convert it to an array
  const traitsToUpdate = Array.isArray(updatedTraits)
    ? updatedTraits
    : [updatedTraits];

  traitsToUpdate.forEach(updatedTrait => {
    const traitIndex = updatedTraitsArray.findIndex(
      trait => trait.type === updatedTrait.type
    );

    // If the trait already exists, update it, otherwise add it to the array
    updatedTraitsArray =
      traitIndex !== -1
        ? set(updatedTraitsArray, traitIndex, updatedTrait)
        : [...updatedTraitsArray, updatedTrait];
  });

  // prevents empty objects from being saved
  const filteredTraits = updatedTraitsArray.filter(trait => !isEmpty(trait));

  return filteredTraits;
};

/**
 * Gets target element trait selector
 *
 * @param {Object} traits - The traits array to get the target element trait
 * @returns {Object} Selector object if found, otherwise null
 */
export const getTargetElementSelector = traits => {
  const targetElement = traits?.find(({ type }) => type === TARGET_ELEMENT);
  const targetElementConfig = targetElement?.config;

  return targetElementConfig?.selector ? targetElementConfig : null;
};
