import { create as createSentryClient } from './sentry';

/**
 * Create Sentry client
 *
 * @param {State} state - Redux state
 * @return {object} Sentry client
 */
export const create = state => {
  const { error, info } = createSentryClient(state);
  return { error, info };
};
