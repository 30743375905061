import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SendToLeft = (
  <g id="sidebar-move-left" fillRule="nonzero" fill="currentColor">
    <path
      d="M22.142872,8.9553654 C22.142872,8.6517936 22.0268004,8.3571504 21.8125145,8.151793 L16.0000075,2.3392861 C15.7857216,2.1250001 15.4910784,2 15.1875066,2 C14.8839348,2 14.5982202,2.1250001 14.3839342,2.3392861 L13.7142905,3.0089298 C13.5000046,3.2142872 13.3750044,3.5089304 13.3750044,3.8125022 C13.3750044,4.116074 13.5000046,4.4107172 13.7142905,4.6160745 L16.3303651,7.2410777 L10.0446433,7.2410777 C9.4017854,7.2410777 8.9999992,7.7767926 8.9999992,8.3839362 L8.9999992,9.5267947 C8.9999992,10.1339383 9.4017854,10.6696532 10.0446433,10.6696532 L16.3303651,10.6696532 L13.7142905,13.2857277 C13.5000046,13.5000137 13.3750044,13.7946569 13.3750044,14.0982287 C13.3750044,14.4018005 13.5000046,14.6964437 13.7142905,14.9107297 L14.3839342,15.5803733 C14.5982202,15.7857307 14.8839348,15.9107309 15.1875066,15.9107309 C15.4910784,15.9107309 15.7857216,15.7857307 16.0000075,15.5803733 L21.8125145,9.7678664 C22.0268004,9.5535804 22.142872,9.2589372 22.142872,8.9553654 Z"
      id="Text"
      transform="translate(15.571436, 8.955365) scale(-1, 1) translate(-15.571436, -8.955365) "
    />
    <path
      d="M0,0 L4.0020869,0 C5.1055038,0 6,0.8926228 6,1.99508929 L6,16.0049107 C6,17.1067681 5.1017394,18 4.0020869,18 L0,18 L0,0 Z"
      id="Rectangle-15"
      transform="translate(3.000000, 9.000000) scale(-1, 1) translate(-3.000000, -9.000000) "
    />
  </g>
);

const SendToRight = (
  <g id="sidebar-move-right" fillRule="nonzero" fill="currentColor">
    <path
      d="M13.1428728,8.9553654 C13.1428728,8.6517936 13.0268012,8.3571504 12.8125153,8.151793 L7.0000083,2.3392861 C6.7857224,2.1250001 6.4910792,2 6.1875074,2 C5.8839356,2 5.598221,2.1250001 5.383935,2.3392861 L4.7142913,3.0089298 C4.5000054,3.2142872 4.3750052,3.5089304 4.3750052,3.8125022 C4.3750052,4.116074 4.5000054,4.4107172 4.7142913,4.6160745 L7.3303659,7.2410777 L1.0446441,7.2410777 C0.40178619,7.2410777 0,7.7767926 0,8.3839362 L0,9.5267947 C0,10.1339383 0.40178619,10.6696532 1.0446441,10.6696532 L7.3303659,10.6696532 L4.7142913,13.2857277 C4.5000054,13.5000137 4.3750052,13.7946569 4.3750052,14.0982287 C4.3750052,14.4018005 4.5000054,14.6964437 4.7142913,14.9107297 L5.383935,15.5803733 C5.598221,15.7857307 5.8839356,15.9107309 6.1875074,15.9107309 C6.4910792,15.9107309 6.7857224,15.7857307 7.0000083,15.5803733 L12.8125153,9.7678664 C13.0268012,9.5535804 13.1428728,9.2589372 13.1428728,8.9553654 Z"
      id="Text"
    />
    <path
      d="M16,0 L20.0020869,0 C21.1055038,0 22,0.8926228 22,1.99508929 L22,16.0049107 C22,17.1067681 21.1017394,18 20.0020869,18 L16,18 L16,0 Z"
      id="Rectangle-15"
    />
  </g>
);

const icons = {
  left: SendToRight,
  right: SendToLeft,
};

const SideBarIcon = ({ className, position }) => (
  <svg
    className={className}
    role="img"
    viewBox="0 0 23 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      {icons[position]}
    </g>
  </svg>
);

SideBarIcon.propTypes = {
  className: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
};

export default styled(SideBarIcon)`
  color: rgba(255, 255, 255, 0.8);
  height: 18px;
  width: 23px;
  &:hover {
    color: var(--white);
  }
`;
