/* eslint-disable no-param-reassign */
import { IOS, ANDROID } from 'lib/platform';
import { POINTER_EDGE } from './variables';

const { TOP, BOTTOM, LEFT, RIGHT } = POINTER_EDGE;

const DEFAULT_MAX_WIDTH = 400;
const MIN_CONTENT_HEIGHT = 46;

const SAFE_AREA = {
  [IOS]: {
    top: 59,
    bottom: 34,
    left: 16,
    right: 16,
  },
  [ANDROID]: {
    top: 24,
    bottom: 24,
    left: 16,
    right: 16,
  },
  default: {
    top: 24,
    bottom: 24,
    left: 16,
    right: 16,
  },
};

/**
 * Returns a number whose value is limited to the given range
 * @param {number} num - number to be evaluated
 * @param {number} min - minimum value range
 * @param {number} max - maximum value range
 * @returns {number}
 */
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const resetContentSize = contentNode => {
  contentNode.style.height = null;
  contentNode.style.width = null;
};

const applyInitialSizes = (contentNode, tooltipNode, tooltipConfig, device) => {
  contentNode.style.width = `${Math.min(
    tooltipConfig.style?.width ?? DEFAULT_MAX_WIDTH,
    device.width
  )}px`;

  tooltipNode.style.width = `${Math.min(
    tooltipConfig.style?.width ?? DEFAULT_MAX_WIDTH,
    device.width
  )}px`;

  tooltipNode.style.borderWidth = null;
  contentNode.style.marginLeft = `${tooltipConfig.style?.borderWidth ?? 0}px`;
  contentNode.style.marginRight = `${tooltipConfig.style?.borderWidth ?? 0}px`;
};

/**
 * Calculate the position of the tooltip given the screen size, and
 * the size of the tooltips contents. These calculations are used
 * on the drawTooltip and calculateContentPosition methods
 * @param {Object} contentNode - DOM node that represents only the content
 * @param {Object} tooltipNode - DOM node that represents the whole tooltip (content and pointer)
 * @param {Object} targetConfig - configuration for the target as size and position
 * @param {Object} tooltipConfig - configuration for the tooltip, including styles
 * @param {Object} device - device height and width
 * @returns {tooltipFrame, actualPointerEdge, offsetFromCenter}
 */
export function calculateFramePosition({
  contentNode,
  tooltipNode,
  targetConfig,
  tooltipConfig,
  device,
  isAnchored,
}) {
  const deviceSafeArea = SAFE_AREA[device.insets.platform] ?? SAFE_AREA.default;

  // We need to account top/bottom insets to calculate the correct tooltip position
  // Anchored tooltip configs already account for the insets
  const additionalTop = isAnchored ? 0 : device.insets?.top;
  const additionalBottom = isAnchored ? 0 : device.insets?.bottom;

  resetContentSize(contentNode);

  const distanceFromTarget = targetConfig?.contentDistanceFromTarget ?? 0;

  const targetRect = {
    xOrigin:
      (targetConfig?.x ?? 0) + device.width * (targetConfig?.relativeX ?? 0),
    yOrigin:
      additionalTop +
      (targetConfig?.y ?? 0) +
      (device.height - additionalTop - additionalBottom) *
        (targetConfig?.relativeY ?? 0),
    width:
      (targetConfig?.width ?? 0) +
      device.width * (targetConfig?.relativeWidth ?? 0),
    height:
      (targetConfig?.height ?? 0) +
      device.height * (targetConfig?.relativeHeight ?? 0),
  };

  applyInitialSizes(contentNode, tooltipNode, tooltipConfig, device);

  let xOrigin = 0;
  let yOrigin = 0;
  let width = Math.min(
    tooltipConfig.style?.width ?? DEFAULT_MAX_WIDTH,
    device.width
  );
  let height = Math.max(contentNode.offsetHeight, MIN_CONTENT_HEIGHT);

  // Account for border size and safe area space allocated to the pointer
  const additionalBorderHeight = (tooltipConfig.style?.borderWidth ?? 0) * 2;

  const additionalPointerLength =
    tooltipConfig.hidePointer !== true ? tooltipConfig.pointerLength ?? 0 : 0;

  // We only want to add additionalPointerHeight once we know the pointer is top/bottom
  height += additionalBorderHeight;

  // Cap size to not exceed safe screen size
  width = Math.min(
    width,
    device.width - deviceSafeArea.left - deviceSafeArea.right
  );
  height = Math.min(
    height,
    device.height - deviceSafeArea.top - deviceSafeArea.bottom
  );

  // Determine available space for tooltipFrame
  const safeSpaceAbove =
    clamp(
      targetRect.yOrigin - distanceFromTarget,
      deviceSafeArea.top,
      device.height - deviceSafeArea.bottom
    ) - deviceSafeArea.top;

  const safeSpaceBelow =
    device.height -
    deviceSafeArea.bottom -
    clamp(
      targetRect.yOrigin + targetRect.height + distanceFromTarget,
      deviceSafeArea.top,
      device.height - deviceSafeArea.bottom
    );

  const safeSpaceBefore =
    clamp(
      targetRect.xOrigin - distanceFromTarget,
      deviceSafeArea.left,
      device.width - deviceSafeArea.right
    ) - deviceSafeArea.left;

  const safeSpaceAfter =
    device.width -
    deviceSafeArea.right -
    clamp(
      targetRect.xOrigin + targetRect.width + distanceFromTarget,
      deviceSafeArea.left,
      device.width - deviceSafeArea.right
    );

  const excessSpaceAbove = safeSpaceAbove - (height + additionalPointerLength);
  const excessSpaceBelow = safeSpaceBelow - (height + additionalPointerLength);
  const excessSpaceBefore = safeSpaceBefore - (width + additionalPointerLength);
  const excessSpaceAfter = safeSpaceAfter - (width + additionalPointerLength);

  const verticalSpaceIsAvailable = excessSpaceAbove > 0 || excessSpaceBelow > 0;
  const horizontalSpaceIsAvailable =
    excessSpaceBefore > 0 || excessSpaceAfter > 0;

  function finalizeTooltipFrame(contentPosition) {
    const minContentSize =
      MIN_CONTENT_HEIGHT + additionalBorderHeight + additionalPointerLength;

    let offsetFromCenter = 0;
    let actualPointerEdge;

    switch (contentPosition) {
      case TOP:
        actualPointerEdge = BOTTOM;
        height += additionalPointerLength;
        // Position tooltip above the target rectangle (and within the safe area)
        if (height <= safeSpaceAbove) {
          // `min` in case `targetRect` is outside the bottom edge of the safe area
          yOrigin =
            Math.min(
              targetRect.yOrigin - distanceFromTarget,
              device.height - deviceSafeArea.bottom
            ) - height;
        } else {
          // Shrink tooltip height if too tall to fit.
          height = Math.max(safeSpaceAbove, minContentSize);
          yOrigin = deviceSafeArea.top;
        }
        break;
      case BOTTOM:
        actualPointerEdge = TOP;
        height += additionalPointerLength;
        // Position tooltip below the target rectangle (and within the safe area)
        if (height <= safeSpaceBelow) {
          // `max` in case `targetRect` is outside the top edge of the safe area
          yOrigin = Math.max(
            targetRect.yOrigin + targetRect.height + distanceFromTarget,
            deviceSafeArea.top
          );
        } else {
          // Shrink height if too tall to fit
          height = Math.max(safeSpaceBelow, minContentSize);
          yOrigin = device.height - deviceSafeArea.bottom - height;
        }
        break;
      case LEFT:
        actualPointerEdge = RIGHT;
        width += additionalPointerLength;
        // Position tooltip before the target rectangle and within the safe area
        if (width <= safeSpaceBefore) {
          // `min` in case `targetRect` is outside the trailing edge of the safe area
          xOrigin =
            Math.min(
              targetRect.xOrigin - distanceFromTarget,
              device.width - deviceSafeArea.right
            ) - width;
        } else {
          // Shrink width if too wide to fit
          width = Math.max(safeSpaceBefore, minContentSize);
          xOrigin = deviceSafeArea.left;
        }
        break;
      case RIGHT:
        actualPointerEdge = LEFT;
        width += additionalPointerLength;
        // Position tooltip after the target rectangle and within the safe area.
        if (width <= safeSpaceAfter) {
          // `max` in case `targetRect` is outside the leading edge of the safe area.
          xOrigin = Math.max(
            targetRect.xOrigin + targetRect.width + distanceFromTarget,
            deviceSafeArea.left
          );
        } else {
          // Shrink width if too wide to fit
          width = Math.max(safeSpaceAfter, minContentSize);
          xOrigin = device.width - deviceSafeArea.right - width;
        }
        break;
      default:
        break;
    }

    // Determine positioning orthogonal to the preferred axis
    if (contentPosition === TOP || contentPosition === BOTTOM) {
      const preferredOriginX =
        targetRect.xOrigin + targetRect.width / 2 - width / 2;

      // Ideally be centered on the target rectangle, but clamp to the safe edges
      xOrigin = clamp(
        preferredOriginX,
        deviceSafeArea.left,
        device.width - deviceSafeArea.right - width
      );

      // Save the offset so we can draw the pointer to still be pointing at the target
      offsetFromCenter = preferredOriginX - xOrigin;
    } else if (contentPosition === LEFT || contentPosition === RIGHT) {
      const preferredOriginY =
        targetRect.yOrigin + targetRect.height / 2 - height / 2;

      // Ideally be centered on the target rectangle, but clamp to the safe edges
      yOrigin = clamp(
        preferredOriginY,
        deviceSafeArea.top,
        device.height - deviceSafeArea.bottom - height
      );

      // Save the offset so we can draw the pointer to still be pointing at the target
      offsetFromCenter = preferredOriginY - yOrigin;
    }

    const tooltipFrame = {
      xOrigin,
      yOrigin,
      width,
      height,
    };

    return { tooltipFrame, actualPointerEdge, offsetFromCenter };
  }

  const preferredPosition = targetConfig?.contentPreferredPosition;
  const preferredVerticalSpace =
    excessSpaceAbove > excessSpaceBelow ? TOP : BOTTOM;
  const preferredHorizontalSpace =
    excessSpaceBefore > excessSpaceAfter ? LEFT : RIGHT;

  const availablePosition = () => {
    switch (true) {
      case preferredPosition === TOP && excessSpaceAbove > 0:
        return TOP;
      case preferredPosition === BOTTOM && excessSpaceBelow > 0:
        return BOTTOM;
      case preferredPosition === LEFT && excessSpaceBefore > 0:
        return LEFT;
      case preferredPosition === RIGHT && excessSpaceAfter > 0:
        return RIGHT;
      case verticalSpaceIsAvailable:
        return preferredVerticalSpace;
      case horizontalSpaceIsAvailable:
        return preferredHorizontalSpace;
      default:
        return preferredVerticalSpace;
    }
  };

  return finalizeTooltipFrame(availablePosition());
}
