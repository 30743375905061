import { TOOLTIP } from 'lib/trait';
import {
  STICKY_OPTIONS,
  getRowAndBlockIndex,
  getLastRowIndex,
} from 'lib/block';

/**
 * Get row index, element and the position if the row is able to sticky
 * @param {string} layoutTrait - layout trait (modal or tooltip)
 * @param {object} content - root content of an step children
 * @param {string} blockId - selected block id to retrieve the row index
 * @return {string} Top for index zero, bottom for last index or null otherwise
 */
export const getStickyRow = ({ layoutTrait, content, blockId }) => {
  // We don't support sticky rows for tooltips
  if (layoutTrait === TOOLTIP) return {};

  const [rowIndex] = getRowAndBlockIndex(content, blockId);
  const lastRowIndex = getLastRowIndex(content);
  const { id: rowId, sticky } = content?.items?.[rowIndex] ?? {};
  let allowedToStickyPosition;

  if (rowIndex >= 0 && lastRowIndex >= 0) {
    // Builder currently supports only the first and last rows to be sticky
    const [TOP, BOTTOM] = STICKY_OPTIONS;
    const availableStickyPositions = {
      0: TOP,
      [lastRowIndex]: BOTTOM,
    };

    allowedToStickyPosition = availableStickyPositions[rowIndex];
  }

  return { rowId, allowedToStickyPosition, isSticky: !!sticky };
};
