import styled from 'styled-components';
import { Button, PlatformIcon } from 'ext/components/ui';

export const Container = styled.div`
  position: absolute;
  bottom: 65px;
`;

export const Menu = styled.div`
  background: var(--background-dark);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 355px;
  padding: 16px 24px;

  ${Button} {
    margin-bottom: 12px;
    width: 100%;
  }
`;

export const Section = styled.div`
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  height: 100%;
  justify-content: ${({ justify = 'center' }) => justify};
  width: 100%;
`;

export const Heading = styled.h2`
  color: var(--white);
  font-size: var(--large);
  font-weight: var(--medium);
  margin: 0 0 8px 0;
`;

export const ListWrapper = styled.div`
  position: relative;

  ${PlatformIcon} {
    width: 12px;
    margin-right: 10px;
  }

  &::before,
  &::after {
    box-sizing: border-box;
    content: '';
    display: block;
    height: 12px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &::before {
    background: linear-gradient(
      to bottom,
      var(--background-x-dark),
      transparent
    );
    height: 12px;
    top: 0;
  }

  &::after {
    background: linear-gradient(to top, var(--background-x-dark), transparent);
    bottom: 0;
    height: 12px;
  }
`;

export const Loading = styled.div`
  height: 250px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const List = styled.ul`
  height: 250px;
  list-style: none;
  margin: 0 -12px 8px;
  overflow-y: auto;
  padding: 12px 0;
`;

export const ListItemWrapper = styled.li`
  align-items: center;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0 12px;
  opacity: 0.6;
  padding: 8px 12px;
  position: relative;

  &:hover {
    background: var(--background);
    opacity: 1;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background: var(--background);
    opacity: 1;
  `}
`;

export const FlowName = styled.span`
  flex-grow: 1;
  font-size: var(--regular);
  overflow: hidden;
  padding-right: 20px;
  white-space: nowrap;
  width: 200px;
`;

export const StateIndicator = styled.span`
  background: var(--white);
  border-radius: 3px;
  flex-shrink: 0;
  height: 6px;
  width: 6px;

  ${({ published }) =>
    published &&
    `
    background: var(--success-light);
  `}
`;

export const CurrentBadge = styled.div`
  background: var(--success);
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  font-size: var(--xx-small);
  font-weight: var(--bold);
  padding: 2px 8px;
  position: absolute;
  right: -8px;
  text-transform: uppercase;
  top: -8px;
`;

export const StatusMessageWrapper = styled.div`
  color: var(--light-text-color);
  height: 250px;
  margin-bottom: 8px;
`;

export const Message = styled.p`
  color: var(--light-text-color);
  margin: 0 0 16px;
`;
