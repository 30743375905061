import { useDrag, useDrop } from 'react-dnd';

export const STEP_GROUP = 'STEP_GROUP';
export const STEP_CHILD = 'STEP_CHILD';

export const useDnd = (type, { id, index, onDrag, onDrop }) => {
  const [{ droppable }, drop] = useDrop({
    accept: type,
    hover: item => {
      if (item.target === index) {
        return;
      }
      onDrag(item.target, index);
      item.target = index; // eslint-disable-line no-param-reassign
    },
    collect: monitor => ({
      droppable: monitor.isOver({ shallow: true }),
    }),
  });

  const [{ dragging, draggable }, drag, preview] = useDrag({
    type,
    item: { type, target: index, id, index },
    isDragging: monitor => monitor.getItem().id === id,
    end: (item, monitor) => {
      const result = monitor.getDropResult();
      const commit = result && item.target !== item.index;
      onDrop(item, commit);
    },
    collect: monitor => ({
      draggable: monitor.canDrag(),
      dragging: monitor.isDragging(),
    }),
  });

  return [
    { dragging, draggable, droppable },
    { drag, drop, preview },
  ];
};
