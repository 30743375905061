import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ModalIcon = (
  <path
    fill="currentColor"
    d="M1.93730471,10 L1.93730471,17.235556 L18.0626953,17.235556 L18.0626953,10 L1.93730471,10 Z M-9.99200722e-16,2.21581981 C-9.99200722e-16,1.33136973 0.700083561,0.614381271 1.56519886,0.614381271 L18.4348011,0.614381271 C19.2992366,0.614381271 20,1.33158717 20,2.21581981 L20,17.7841801 C20,18.6686302 19.2999164,19.3856187 18.4348011,19.3856187 L1.56519886,19.3856187 C0.7007634,19.3856187 -9.99200722e-16,18.6684128 -9.99200722e-16,17.7841801 L-9.99200722e-16,2.21581981 Z"
  />
);

const TooltipIcon = (
  <path
    fill="currentColor"
    d="M7.66128658,14.5220334 C7.85119239,14.3148046 8.2302473,14.1468126 8.49623907,14.1468126 L18.7827579,14.1468126 C19.4546758,14.1468126 20,13.6018557 20,12.9296174 L20,7.01618171 C20,6.34349053 19.4550222,5.79898649 18.7827579,5.79898649 L1.21724211,5.79898649 C0.545324199,5.79898649 -5.32907052e-15,6.34394335 -5.32907052e-15,7.01618171 L-5.32907052e-15,12.9296174 C-5.32907052e-15,13.6023085 0.544977857,14.1468126 1.21724211,14.1468126 L3.33469027,14.1468126 C3.61402812,14.1468126 3.99023242,14.3102293 4.18433108,14.5220334 L5.57895395,16.0438715 C5.76885976,16.2511003 6.07256506,16.2556756 6.26666372,16.0438715 L7.66128658,14.5220334 Z"
  />
);

const HotspotIcon = (
  <path
    fill="currentColor"
    d="M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M10,17.2 C13.9764502,17.2 17.2,13.9764502 17.2,10 C17.2,6.0235498 13.9764502,2.8 10,2.8 C6.0235498,2.8 2.8,6.0235498 2.8,10 C2.8,13.9764502 6.0235498,17.2 10,17.2 Z M10,14.05 C7.76324676,14.05 5.95,12.2367533 5.95,10 C5.95,7.76324673 7.76324676,5.95 10,5.95 C12.2367533,5.95 14.05,7.76324673 14.05,10 C14.05,12.2367533 12.2367533,14.05 10,14.05 Z"
  />
);

const SlideoutIcon = (
  <path
    fill="currentColor"
    d="M-9.99200722e-16,2.21581981 C-9.99200722e-16,1.33136973 0.700083561,0.614381271 1.56519886,0.614381271 L18.4348011,0.614381271 C19.2992366,0.614381271 20,1.33158717 20,2.21581981 L20,17.7841801 C20,18.6686302 19.2999164,19.3856187 18.4348011,19.3856187 L1.56519886,19.3856187 C0.7007634,19.3856187 -9.99200722e-16,18.6684128 -9.99200722e-16,17.7841801 L-9.99200722e-16,2.21581981 Z M1.93730471,17.235556 L18.0626953,17.235556 L18.0626953,2.76444396 L1.93730471,2.76444396 L1.93730471,17.235556 Z M8.22259332,9.55658889 C8.22259332,9.00593776 8.67097173,8.55954683 9.22026649,8.55954683 L18.0626953,8.55954683 L18.0626953,14.8519 L9.22026649,14.8519 C8.66926681,14.8519 8.22259332,14.3993695 8.22259332,13.8548579 L8.22259332,9.55658889 Z"
  />
);

// TODO: we should reference these by constants, but the step-group entity
// shouldn't be imported into anything in `src/ext`. Really this component
// shouldn't be in here, but it's referenced in the Menu component. So we'll
// just use the strings for each step type here.
const icons = {
  modal: ModalIcon,
  'tooltip-group': TooltipIcon,
  'hotspot-group': HotspotIcon,
  tooltip: HotspotIcon,
  shorty: SlideoutIcon,
};

const StepIcon = ({ className, type }) => (
  <svg
    aria-label={type}
    className={className}
    role="img"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    {icons[type]}
  </svg>
);

StepIcon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(icons)),
};

export default styled(StepIcon)``;
