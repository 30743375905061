import PropTypes from 'prop-types';
import { StyleShape } from 'entities/step-children';
import { LAYOUT_TRAITS, SKIPPABLE_OPTIONS } from 'lib/trait';
import { PRESENTATIONS_TYPES } from 'lib/presentation';

export const SkippableShape = PropTypes.oneOfType([
  PropTypes.shape({
    ignoreBackdropTap: PropTypes.bool,
    buttonAppearance: PropTypes.oneOf(Object.values(SKIPPABLE_OPTIONS)),
    buttonStyle: StyleShape,
  }),
  PropTypes.bool,
]);

// UI-only editor props
export const EditorShape = PropTypes.shape({
  animation: PropTypes.bool,
  carousel: PropTypes.bool,
  transition: PropTypes.string,
  frameID: PropTypes.string,
  pagingDots: PropTypes.shape({
    style: StyleShape,
  }),
  presentation: PropTypes.oneOf(PRESENTATIONS_TYPES),
  skippable: SkippableShape,
  style: StyleShape,
  type: PropTypes.oneOf(LAYOUT_TRAITS),
});

export default PropTypes.shape({
  editor: EditorShape,
});
