import React from 'react';
import styled from 'styled-components';
import { Icon } from '@appcues/sonar';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';

const StyledIcon = styled(Icon)`
  color: var(--color-neutral-500);
`;

const Spinner = () => (
  <StyledIcon aria-label="loading" icon={faSpinnerThird} size="x-large" spin />
);

export default Spinner;
