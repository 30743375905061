import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@appcues/sonar';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { DebouncedInput, Label } from 'ext/components/ui';
import { Controls, Text } from 'components/SideBarSettings/Shared';
import { Validation, Error } from './styled';

export default function FrameTarget({ frameID, onChange }) {
  const [currentFrameID, setCurrentFrameID] = useState('');
  const [error, setError] = useState(false);

  if (frameID !== '' && frameID !== currentFrameID) {
    setCurrentFrameID(frameID);
    setError(false);
  }

  const handleFrameID = ({ target: { value } }) => {
    setCurrentFrameID(value);

    // If the value is empty or only whitespace, we set an error and return
    setError(value.trim() === '');
    const hasOnlyWhitespace = value.length > 0 && !/\S/.test(value);
    if (value === frameID || value === '' || hasOnlyWhitespace) return;

    onChange({ frameID: value });
  };

  return (
    <Controls>
      <Text>
        Appcues can display this content in a frame within your app. Some code
        required.{' '}
        <Link
          href="https://docs.appcues.com/en_US/mobile-embeds"
          icon={faExternalLinkAlt}
          inline
        >
          Learn more
        </Link>
      </Text>
      <Label htmlFor="frame-id">Frame ID</Label>
      <Validation error={error}>
        <DebouncedInput
          id="frame-id"
          defaultValue={currentFrameID}
          onChange={handleFrameID}
          placeholder="e.g. homeBanner"
        />
        {error && <Error>Frame ID should not be empty</Error>}
      </Validation>
    </Controls>
  );
}

FrameTarget.propTypes = {
  frameID: PropTypes.string,
  onChange: PropTypes.func,
};
