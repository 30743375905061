import { normalize, denormalize } from 'normalizr';
import {
  revert as denormalizeStepGroup,
  StepGroupSchema,
} from 'entities/step-groups';

export const ExperienceSchema = { steps: [StepGroupSchema] };

/**
 * @param {Experience} canonicalExperience - Canonical experience object from the server
 * @return {object} - Normalized experience and steps
 */
export function transform(canonicalExperience) {
  const { entities, result } = normalize(canonicalExperience, ExperienceSchema);

  return {
    experience: result,
    ...entities,
  };
}

/**
 * @param {Experience} experience - Normalized experience
 * @param {object} entities - Collection of steps
 * @return {Experience} - Reconstituted canonical experience
 */
export function revert(experience, { stepGroups, stepChildren }) {
  const groups = Object.entries(stepGroups).reduce(
    (acc, [id, group]) => ({ ...acc, [id]: denormalizeStepGroup(group) }),
    {}
  );
  const entities = { stepGroups: groups, stepChildren };
  return denormalize(experience, ExperienceSchema, entities);
}
