import { useEffect } from 'react';

export default function useEscape(onCancel) {
  useEffect(() => {
    function onKeyDown(e) {
      if (/esc(ape)?/i.test(e.key) && onCancel) {
        e.preventDefault();
        e.stopPropagation();
        onCancel();
      }
    }
    document.addEventListener('keydown', onKeyDown, true);
    return () => {
      document.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onCancel]);
}
