import {
  linkType,
  continueType,
  closeType,
  launchExperienceType,
  noOpType,
} from 'lib/actions';

export const ACTION_TYPES = {
  URL: linkType,
  NEXT_STEP: `${continueType}/next`,
  PREVIOUS_STEP: `${continueType}/prev`,
  CUSTOM_STEP: `${continueType}/custom`,
  DISMISS_FLOW: closeType,
  TRIGGER_FLOW: launchExperienceType,
  NO_OP: noOpType,
};

export const ACTION_TYPES_LIST = [
  {
    label: 'Next step',
    value: ACTION_TYPES.NEXT_STEP,
  },
  {
    label: 'Previous step',
    value: ACTION_TYPES.PREVIOUS_STEP,
  },
  {
    label: 'Go to link',
    value: ACTION_TYPES.URL,
  },
  {
    label: 'Trigger Flow',
    value: ACTION_TYPES.TRIGGER_FLOW,
  },
  {
    label: 'Dismiss Flow',
    value: ACTION_TYPES.DISMISS_FLOW,
  },
  {
    label: 'Go to custom step',
    value: ACTION_TYPES.CUSTOM_STEP,
  },
  {
    label: 'Do nothing',
    value: ACTION_TYPES.NO_OP,
  },
];
