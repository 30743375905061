import isEmpty from 'lodash.isempty';
/**
 * Parse text from OpenBuilder SDK structure to SlateJS structure
 *
 * @param {string|array<{ text: string }>} content - Text or array of text objects
 * @returns {array<{ children: array<{ text: string }>}>} - SlateJS structure
 */
export const parseToSlate = content => [
  {
    type: 'paragraph',
    children: Array.isArray(content)
      ? content.map(({ text, style }) => ({ text, ...style }))
      : [{ text: content }],
  },
];

/**
 * Parse text from SlateJS structure to OpenBuilder SDK structure
 *
 * @param {array<{ children: array<{ text: string }>}>} content - SlateJS structure
 * @returns {{ text: string, spans: array<{ text: string }> }} - OpenBuilder SDK structure
 */
export const parseFromSlate = (content = []) => {
  let text = '';
  const spans = [];

  content.forEach((node, i) => {
    if (node.type === 'paragraph') {
      node.children.forEach(({ text: spanText, ...style }) => {
        text += spanText;
        spans.push({
          text: spanText,
          ...(!isEmpty(style) ? { style } : {}),
        });
      });

      // Add a new line if it's not the last paragraph
      if (i < content.length - 1) {
        text += '\n';
        spans.push({ text: '\n' });
      }
    }
  });

  return { text, spans };
};
