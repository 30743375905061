import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'ext/components/ui';
import { BlockContentShape } from 'entities/block';
import { TEXT_INPUT, BLOCK_LABELS } from 'lib/block';
import { Content, AlignmentSpacing } from 'components/SideBarSettings/Shared';
import { updateRequiredLabel } from 'components/SideBarSettings/Survey';
import TextInputBehavior from './TextInputBehavior';

export function TextInputSettings({
  content,
  contentWithVariations,
  onChange,
}) {
  const {
    id,
    dataType: type,
    numberOfLines,
    maxLength,
    style: textInputStyle,
  } = content ?? {};

  const { label } = contentWithVariations;

  const handleChange = behavior => {
    onChange({ blockId: id, contentChunk: behavior, useOriginalId: true });
  };

  const handleRequiredChange = required => {
    const contentChunk = {
      required: !!required,
      label: updateRequiredLabel(label, required),
      minSelections: required ? 1 : 0,
    };

    onChange({ blockId: id, contentChunk, useOriginalId: true });
  };

  const handleErrorLabelChange = errorLabel => {
    onChange({ blockId: errorLabel.id, contentChunk: errorLabel });
  };

  return (
    <Content>
      <Accordion.Root
        collapsible="true"
        defaultValue={['behavior']}
        type="multiple"
      >
        <TextInputBehavior
          type={type}
          numberOfLines={numberOfLines}
          maxLength={maxLength}
          onChange={handleChange}
          onRequiredChange={handleRequiredChange}
          onErrorLabelChange={handleErrorLabelChange}
        />
        <AlignmentSpacing
          style={textInputStyle}
          spacingLabel={BLOCK_LABELS[TEXT_INPUT]}
          onChange={handleChange}
          hasMargin
        />
      </Accordion.Root>
    </Content>
  );
}

TextInputSettings.propTypes = {
  content: BlockContentShape,
  contentWithVariations: BlockContentShape,
  onChange: PropTypes.func,
};

export default TextInputSettings;
