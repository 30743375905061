import { BLOCK, CUSTOM_COMPONENT } from 'lib/block';

const customComponentTemplate = ({
  blockId,
  elementId,
  customComponentModel,
}) => {
  const { id: componentId, identifier, properties = [] } = customComponentModel;

  const config = Object.fromEntries(
    properties.map(propSchema => [propSchema.name, propSchema.default])
  );

  return {
    type: BLOCK,
    blockType: CUSTOM_COMPONENT,
    id: blockId,
    content: {
      type: CUSTOM_COMPONENT,
      id: elementId,
      componentId,
      identifier,
      config,
    },
  };
};

export default customComponentTemplate;
