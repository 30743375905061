import { createActionConfig } from './create-action-config';

export const type = '@appcues/link';
export const getLink = ({ url, openExternally = false, on = 'tap' }) =>
  createActionConfig({
    on,
    type,
    config: {
      url,
      ...(on !== 'navigate' && { openExternally }),
    },
  });
