import styled from 'styled-components';
import PropTypes from 'prop-types';
import { easing } from 'ext/lib/style';

const theme = `
  --button-background: var(--diel-gray);
  --button-color: var(--myst);
  --button-hover-color: var(--white);

  --button-disabled-background: var(--diel-gray);
  --button-disabled-color: var(--myst);

  --button-tertiary-border: 1px solid var(--white);
  --button-tertiary-color: var(--myst);
  --button-tertiary-hover: var(--white);

  --button-light-color: var(--blurple);
  --button-light-hover: var(--blurple);

  [data-theme='light'] & {
    --button-tertiary-border: 1px solid var(--sharkbait-ooh-la-la);
    --button-tertiary-color: var(--pleather);
    --button-tertiary-hover: var(--oh-hi-dark);
  }
`;

const Button = styled.button`
  ${theme}

  align-items: center;
  background: var(--button-background);
  border: none;
  color: var(--button-color);
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: var(--regular);
  font-weight: var(--semi-bold);
  line-height: 1.5;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transition: ${easing('background', 'color')};
  padding: 8px 16px;
  border-radius: 6px;

  &:hover:not([disabled]) {
    color: var(--button-hover-color);
  }

  ${({ kind }) => {
    switch (kind) {
      case 'primary':
        return `
          background: linear-gradient(90deg, var(--secondary) 0%, #8960ff 100%);
        `;
      case 'secondary':
        return `
          background: linear-gradient(90deg, #0B818B 0%, var(--success) 100%);
        `;
      case 'danger':
        return `
          background: linear-gradient(90deg, var(--warning) 0%, #F1656C 100%);
        `;
      case 'tertiary':
        return `
          background: transparent;
          border: var(--button-tertiary-border);
          color: var(--button-tertiary-color);

          &:hover:not([disabled]) {
            color: var(--button-tertiary-hover);
          }
        `;
      default:
        return '';
    }
  }}

  &[disabled] {
    background: var(--button-disabled-background);
    color: var(--button-disabled-color);
    transition: background 0ms;
    cursor: not-allowed;
  }

  &[aria-pressed='true'] {
    background: var(--color-blurple-200);
    border: 1px solid var(--color-blurple-700);
    box-shadow: 0px 0px 0px 4px var(--color-neutral-300);
  }
`;

Button.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'danger']),
};

export default Button;

export const SquareButton = styled(Button)`
  padding: 8px;
  width: 40px;
  height: 40px;
`;

export const ButtonGroup = styled.div`
  align-items: stretch;
  display: flex;

  ${({ right }) => right && `justify-content: flex-end;`}

  > ${Button} {
    margin-left: 8px;
  }

  > :first-child {
    margin-left: 0;
  }
`;

export const LightButton = styled(Button)`
  padding: 8px;
  background: transparent;
  border: none;
  color: var(--button-light-color);

  &:hover:not([disabled]) {
    color: var(--button-light-hover);
  }
`;
