import { createCollectionReducer, DELETED } from 'ext/lib/collections';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectCustomComponents = state =>
  Object.fromEntries(
    Object.entries(state[TYPE]).filter(([, component]) => !component[DELETED])
  );

export const selectCustomComponentsForApp = (state, appId) =>
  Object.fromEntries(
    Object.entries(selectCustomComponents(state)).filter(
      ([, component]) => component.sourceAppId === appId
    )
  );

export const selectCustomComponent = (state, id) =>
  selectCustomComponents(state)[id];
