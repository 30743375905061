import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { StyleShape } from 'entities/step-children';
import { SkippableShape } from 'entities/step-groups';
import { MODAL, PREVIEW_MODAL_ID, IMAGE_SERVICE_SELECTOR } from 'lib/trait';
import {
  STANDARD,
  HALF_SCREEN,
  FULL_SCREEN,
  COVER_SHEET,
  SLIDEOUT,
  PRESENTATIONS,
} from 'lib/presentation';
import { THEMES, ORIENTATIONS } from 'lib/user-preferences';
import {
  CloseX,
  PagingDots,
  transformStyles,
} from 'components/Editor/Primitives';
import { handleStandardStyles } from './standard-styles';
import { handleSlideoutStyles } from './slideout-styles';
import { GAPS } from './variables';

const [PORTRAIT, LANDSCAPE] = ORIENTATIONS;

const TABLET_MAX_WIDTH = '644px';

const unsetBorderBottomRadius = `
 border-bottom-left-radius: unset;
 border-bottom-right-radius: unset;
`;

const presentationStyle = {
  [STANDARD]: {
    base: css`
      ${({ orientation, verticalAlignment }) =>
        handleStandardStyles({
          orientation,
          verticalAlignment,
        })}
    `,
  },
  [SLIDEOUT]: {
    base: css`
      ${({ orientation, verticalAlignment, horizontalAlignment }) =>
        handleSlideoutStyles({
          orientation,
          verticalAlignment,
          horizontalAlignment,
        })}
    `,
  },
  [HALF_SCREEN]: {
    base: css`
      height: 50%;
      min-height: 240px;
      align-self: end;
    `,
    border: `
      ${unsetBorderBottomRadius}
  `,
  },
  [FULL_SCREEN]: {
    base: `height: 100%;`,
    border: `border-radius: unset;`,
  },
  [COVER_SHEET]: {
    base: css`
      height: calc(100% - ${GAPS[COVER_SHEET][PORTRAIT].vertical});
      align-self: end;
    `,
    border: `${unsetBorderBottomRadius}`,
  },
};

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  // This allows fixed elements to be relative to the modal and not the viewport
  transform: translate(0);

  ${({ presentation, orientation, device: { isTablet }, width }) => {
    const baseStyle =
      presentation !== STANDARD &&
      presentation !== SLIDEOUT &&
      orientation === LANDSCAPE &&
      !isTablet
        ? presentationStyle[FULL_SCREEN].base
        : presentationStyle[presentation].base;

    return css`
      ${baseStyle}
      ${width && `width: ${width}px;`}
      ${isTablet && { maxWidth: TABLET_MAX_WIDTH }}
    `;
  }}
`;

const PreviewModal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  border-width: 0px;
  width: 100%;
  z-index: 0;

  ${({ customStyle }) => customStyle}

  ${({ presentation }) => presentationStyle[presentation]?.border};

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const Modal = ({
  id,
  presentation,
  theme,
  orientation,
  device,
  deviceSize,
  skippable,
  pagingDots,
  backgroundContent,
  style,
  children,
  handleClick,
}) => {
  let styles = { ...style };

  if (backgroundContent) {
    const {
      type,
      style: backgroundColor,
      ...backgroundImage
    } = backgroundContent;

    styles = {
      ...styles,
      backgroundImage,
      ...backgroundColor,
    };
  }

  const { alignSelf, ...previewStyle } = transformStyles(styles, theme);
  const {
    width: preferredWidth,
    verticalAlignment,
    horizontalAlignment,
  } = styles ?? {};
  // Device width minus horizontal margin on each side. Assumes the gap value is a percentage.
  const horizontalGapPercentage =
    (Number.parseFloat(GAPS[STANDARD][orientation].horizontal) / 100) * 2;
  const maxWidth = deviceSize.width * (1 - horizontalGapPercentage);
  const width = preferredWidth ? Math.min(preferredWidth, maxWidth) : undefined;

  return (
    <ModalContainer
      key={`${id}-${verticalAlignment}-${horizontalAlignment}`}
      id={IMAGE_SERVICE_SELECTOR}
      orientation={orientation}
      presentation={presentation}
      device={device}
      verticalAlignment={verticalAlignment}
      horizontalAlignment={horizontalAlignment}
      width={width}
    >
      <PreviewModal
        id={id}
        customStyle={previewStyle}
        presentation={presentation}
        onClick={handleClick}
      >
        <CloseX skippable={skippable} theme={theme} />
        {children}
      </PreviewModal>
      <PagingDots style={pagingDots?.style} theme={theme} />
    </ModalContainer>
  );
};

Modal.propTypes = {
  id: PropTypes.oneOf([PREVIEW_MODAL_ID]),
  presentation: PropTypes.oneOf(Object.keys(PRESENTATIONS[MODAL])),
  theme: PropTypes.oneOf(THEMES),
  orientation: PropTypes.oneOf(ORIENTATIONS),
  device: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    isDefault: PropTypes.bool,
    isTablet: PropTypes.bool,
  }),
  deviceSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  skippable: SkippableShape,
  pagingDots: PropTypes.shape({
    style: StyleShape,
  }),
  backgroundContent: PropTypes.shape({
    type: PropTypes.string,
    style: StyleShape,
  }),
  style: StyleShape,
  children: PropTypes.node,
  handleClick: PropTypes.func,
};

export default Modal;
