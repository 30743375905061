import {
  launchExperienceType,
  trackEventType,
  userPropertiesType,
  submitFormType,
  removeSubmitFormAction,
  addSubmitFormAction,
} from 'lib/actions';

// In order to work better for the SDK
// we put the launch-experience action to the last position
const sortActions = actions =>
  actions?.sort((a, b) => {
    if (a.type === launchExperienceType) return 1;
    if (b.type === launchExperienceType) return -1;
    return 0;
  });

function actionsMapper({ prevActions, newActions, handleSubmitForm = false }) {
  const prevActionsMapped = {
    actions: [],
    events: [],
    userProperties: [],
    submitForm: [],
  };

  // We need to map submit form action only if the step has survey block
  const updatedNewActions = handleSubmitForm
    ? addSubmitFormAction(newActions)
    : newActions;

  const updatedPrevActions = handleSubmitForm
    ? removeSubmitFormAction({ actions: prevActions }).actions
    : prevActions;

  // We need to map the actions to their category (actions, events, userProperties, submitForm)
  // so we can determine if we need to overwrite or append
  updatedPrevActions.forEach(action => {
    if (action.type === trackEventType) {
      prevActionsMapped.events.push(action);
    }
    if (action.type === userPropertiesType) {
      prevActionsMapped.userProperties.push(action);
    }
    if (action.type === submitFormType) {
      prevActionsMapped.submitForm.push(action);
    }
    if (
      action.type !== trackEventType &&
      action.type !== userPropertiesType &&
      action.type !== submitFormType
    ) {
      prevActionsMapped.actions.push(action);
    }
  });

  // Then we just merge the objects together
  // and it overwrites the previous actions when the new action is the same category
  const updated = {
    ...prevActionsMapped,
    ...updatedNewActions,
  };

  // Then we flat all the actions in a single array of objects
  return [
    ...updated.submitForm,
    ...sortActions(updated.actions),
    ...updated.events,
    ...updated.userProperties,
  ];
}

export default actionsMapper;
