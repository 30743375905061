export const sortList = (list, selectedItem) => {
  return [...list].sort((a, b) => {
    if (a.id === selectedItem || b.id === selectedItem) {
      return a.id === selectedItem ? -1 : b.id === selectedItem ? 1 : 0;
    }

    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    // Use natural sorting
    return nameA.localeCompare(nameB, undefined, { numeric: true });
  });
};
