import styled from 'styled-components';

export const StatusMessage = styled.div`
  align-items: center;
  color: var(--background);
  display: flex;
  font-size: var(--small);
  height: 32px;
  justify-content: center;
  width: 100%;

  & + && {
    border-top-width: 1px;
    border-top-style: solid;
  }
  ${({ kind }) => {
    switch (kind) {
      case 'success':
        return `
          background-color: var(--success-x-light);
          border-top-color: var(--success);
        `;
      case 'error':
        return `
          background-color: var(--error-light);
          border-top-color: var(--error);
        `;
      case 'info':
      default:
        return `
          background-color: var(--warning-x-light);
          border-top-color: var(--warning-light);
        `;
    }
  }}
`;

export const MoreInfo = styled.span.attrs(() => ({
  role: 'button',
}))`
  border-bottom: 1px solid currentcolor;
  cursor: pointer;
  font-weight: var(--bold);
  line-height: 1;
  margin-left: 0.25em;
  margin-top: 1px;
`;
