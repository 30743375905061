import { useContext, useEffect } from 'react';
import { getEditorDocument } from 'ext/lib/document';
import RootContext from 'ext/components/RootContext';

/**
 * Hook to handle clicks that occur outside of provided references
 *
 * @param {React.RefObject[]} refs - References to non-outside elements
 * @param {function} onClickOutside - Callback to execute when clicked outside
 * @return {void}
 */
export default function useClickOutside(refs, onClickOutside) {
  const $builder = useContext(RootContext);

  useEffect(() => {
    // If no refs are provided, exit early and do not bind handlers
    if (!refs || refs.length === 0) {
      return;
    }

    const handler = event => {
      // Since the builder is masked via ShadowDOM, the only way to extract the
      // underlying element within the ShadowDOM is to pluck the first element
      // in the list of composed elements
      const [target] = event.composedPath();

      // Determine if the click occurred within the allowed list of elements
      // provided by their references.
      const inside = refs
        .filter(({ current }) => current)
        .some(({ current }) => target === current || current.contains(target));

      // If the click did not occur within the allowed list of elements, trigger
      // the provided handler
      if (!inside && typeof onClickOutside === 'function') {
        onClickOutside(event);
      }
    };

    // In addition to the top-level document, also get the deeply nested
    // editor's document to attach the click handlers
    const editor = getEditorDocument($builder);

    document.addEventListener('click', handler, true);

    if (editor) {
      editor.addEventListener('click', handler, true);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('click', handler, true);

      if (editor) {
        editor.removeEventListener('click', handler, true);
      }
    };
  }, [refs, onClickOutside, $builder]);
}
