import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import keyBy from 'lodash.keyby';
import { INITIALIZE } from 'ext/root/root-actions';
import { reject, resolve } from './actions';

function* fetchApps() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getApps);
    const filteredResponse = yield response.filter(
      ({ state }) => state === 'enabled'
    );
    yield put(resolve(keyBy(filteredResponse, 'id')));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery(INITIALIZE, fetchApps);
}
