import { v4 as uuid } from 'uuid';
import { TARGET_INTERACTION } from 'lib/trait';
import { getContinue, getNoOp } from 'lib/actions';
import { ANCHORED } from 'lib/presentation';
import { TEXT, BUTTON, STACK } from 'lib/block';
import generateElementTemplate from 'components/Editor/Templates/generate-element-template';
import {
  backdropCircleTemplate,
  backdropRectangleTemplate,
} from './backdrop-keyhole';
import { targetRectangleTemplate } from './target-rectangle';

/**
 * Create canned tooltip step
 *
 * @param {string} presentation - tooltip presentation display (anchored or floating)
 * @param {string} previewStepId - id to preview the step template before creation
 * @return {Step} Canned step group
 */
export const generateTooltipStep = ({ presentation, previewStepId }) => {
  const buttonElementId = uuid();

  return {
    ...(previewStepId && { id: previewStepId }),
    type: 'tooltip',
    contentType: 'application/json',
    content: {
      id: uuid(),
      type: STACK,
      orientation: 'vertical',
      style: {},
      items: [
        generateElementTemplate({
          template: TEXT,
          blockId: uuid(),
          elementId: uuid(),
          newRow: true,
          content: {
            text: `Here's a helpful hint`,
            style: {
              fontSize: 15,
              fontName: 'System Default Bold',
              textAlignment: 'leading',
              horizontalAlignment: 'leading',
              marginTop: 16,
            },
          },
        }),
        generateElementTemplate({
          template: TEXT,
          blockId: uuid(),
          elementId: uuid(),
          newRow: true,
          content: {
            text: 'Take a look at one of our newest features!',
            style: {
              fontSize: 15,
              textAlignment: 'leading',
              horizontalAlignment: 'leading',
            },
          },
        }),
        generateElementTemplate({
          template: BUTTON,
          blockId: uuid(),
          elementId: buttonElementId,
          newRow: true,
        }),
      ],
    },
    actions: {
      [buttonElementId]: [getContinue()],
    },
    traits: [
      ...(presentation !== ANCHORED ? [targetRectangleTemplate] : []),
      presentation === ANCHORED
        ? backdropRectangleTemplate
        : backdropCircleTemplate,
      {
        type: TARGET_INTERACTION,
        config: {
          actions: [getNoOp()],
        },
      },
    ],
  };
};
