import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'ext/components/ui';
import { BlockContentShape } from 'entities/block';
import { SINGLE_SELECT, MULTI_SELECT, BLOCK_LABELS } from 'lib/block';
import { Content, AlignmentSpacing } from 'components/SideBarSettings/Shared';
import { updateRequiredLabel } from 'components/SideBarSettings/Survey';
import SelectStyle from './SelectStyle';
import SelectBehavior from './SelectBehavior';

export function SelectSettings({
  blockType,
  content,
  contentWithVariations,
  onChange,
}) {
  const {
    id,
    selectedColor,
    unselectedColor,
    options = [],
    minSelections,
    maxSelections,
    required,
    style: selectStyle,
  } = content ?? {};

  const { label } = contentWithVariations;

  const handleChange = behavior => {
    onChange({ blockId: id, contentChunk: behavior, useOriginalId: true });
  };

  const handleRequiredChange = updatedRequired => {
    const contentChunk = {
      required: !!updatedRequired,
      label: updateRequiredLabel(label, updatedRequired),
      minSelections: updatedRequired ? 1 : 0,
    };

    onChange({ blockId: id, contentChunk, useOriginalId: true });
  };

  const handleErrorLabelChange = errorLabel => {
    onChange({ blockId: errorLabel.id, contentChunk: errorLabel });
  };

  return (
    <Content>
      <Accordion.Root
        collapsible="true"
        defaultValue={['style']}
        type="multiple"
      >
        <SelectStyle
          id={id}
          blockType={blockType}
          selectedColor={selectedColor}
          unselectedColor={unselectedColor}
          onChange={handleChange}
        />
        <SelectBehavior
          blockType={blockType}
          options={options}
          minSelections={minSelections}
          maxSelections={maxSelections}
          required={required}
          onChange={handleChange}
          onRequiredChange={handleRequiredChange}
          onErrorLabelChange={handleErrorLabelChange}
        />
        <AlignmentSpacing
          id={id}
          style={selectStyle}
          spacingLabel={BLOCK_LABELS[blockType]}
          onChange={handleChange}
          hasMargin
        />
      </Accordion.Root>
    </Content>
  );
}

SelectSettings.propTypes = {
  blockType: PropTypes.oneOf([SINGLE_SELECT, MULTI_SELECT]),
  content: BlockContentShape,
  contentWithVariations: BlockContentShape,
  onChange: PropTypes.func,
};

export default SelectSettings;
