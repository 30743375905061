import pick from 'lodash.pick';
import { get } from 'ext/lib/local-storage';
import summary from '__fixtures__/get-experiences-summary.json';
import experience from '__fixtures__/get-experience.json';
import tags from '__fixtures__/get-tags.json';

/**
 * Check local storage to determine if fixtures should be used
 * To mock all services presented in fixtures options set fixtures to true
 * e.g. set('appcues:builder:fixtures', true)
 *
 * or you can select with fixture you want to use creating an array of fixture keys
 * e.g set('appcues:builder:fixtures', ['getExperiencesSummary', 'getExperiences'])
 */

const selectedFixtures = get('appcues:builder:fixtures');

const fixtureOptions = {
  getExperiencesSummary: () => Promise.resolve(summary),
  getExperience: () => Promise.resolve(experience),
  getTags: () => Promise.resolve(tags),
  updateExperience: () => Promise.resolve({ ok: true }),
  createStep: () => Promise.resolve({}),
  addStep: () => Promise.resolve({}),
  updateStep: () => Promise.resolve({ ok: true }),
  moveStep: () => Promise.resolve(''),
};

// Override API methods with fixtures methods selected
export default selectedFixtures === true
  ? fixtureOptions
  : pick(fixtureOptions, selectedFixtures || []);
