import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Accordion } from 'ext/components/ui';
import { CUSTOM_COMPONENT, BLOCK_LABELS } from 'lib/block';
import { Shape as BlockContentShape } from 'entities/block';
import {
  Shape as CustomComponentShape,
  selectCustomComponent,
} from 'entities/custom-components';
import { Content, AlignmentSpacing } from 'components/SideBarSettings/Shared';
import ContentActions from 'components/SideBarSettings/Content/ContentActions';
import CustomComponent from './CustomComponent';

export function CustomComponentSettings({ content, component, onChange }) {
  const { id, style } = content ?? {};

  const handleChange = updatedContent => {
    onChange({ blockId: id, contentChunk: updatedContent });
  };

  return (
    <Content>
      <Accordion.Root
        collapsible="true"
        defaultValue={['configuration', 'alignment-spacing']}
        type="multiple"
      >
        <CustomComponent
          content={content}
          component={component}
          onChange={onChange}
        />
        <AlignmentSpacing
          style={style}
          content={content}
          spacingLabel={BLOCK_LABELS[CUSTOM_COMPONENT]}
          onChange={handleChange}
          hasMargin
        />
        <ContentActions blockId={id} context={CUSTOM_COMPONENT} />
      </Accordion.Root>
    </Content>
  );
}

CustomComponentSettings.propTypes = {
  content: BlockContentShape,
  component: CustomComponentShape,
  onChange: PropTypes.func,
};

const mapStateToProps = (state, { content }) => ({
  component: selectCustomComponent(state, content.componentId),
});

export default connect(mapStateToProps)(CustomComponentSettings);
