import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from '@appcues/sonar';
import { Heading, Input, Modal, ModalHeader } from 'ext/components/ui';
import { spoof } from 'entities/auth';
import { Body } from './styled';

export function SpoofingModal({ onClose, onSpoof, visible }) {
  const [value, setValue] = useState('');
  const [pending, setPending] = useState(false);

  const handleChange = useCallback(event => {
    setValue(event.target.value);
  }, []);

  const handleSpoof = useCallback(
    event => {
      event.preventDefault();

      if (!pending) {
        setPending(true);
        onSpoof(value);
      }
    },
    [onSpoof, pending, value]
  );

  return (
    <Modal onClose={onClose} visible={visible} theme="light">
      <ModalHeader>
        <Heading>Spoof Account</Heading>
        <ModalHeader.Close onClose={onClose} />
      </ModalHeader>

      <Body as="form" onSubmit={handleSpoof}>
        <Input
          onChange={handleChange}
          placeholder="Enter account ID to spoof"
          value={value}
        />

        <ButtonGroup>
          <Button variant="tertiary" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button
            disabled={!value || pending}
            variant="primary"
            onClick={handleSpoof}
            onSubmit={handleSpoof}
            type="button"
          >
            Spoof
          </Button>
        </ButtonGroup>
      </Body>
    </Modal>
  );
}

SpoofingModal.propTypes = {
  onClose: PropTypes.func,
  onSpoof: PropTypes.func,
  visible: PropTypes.bool,
};

const mapDispatchToProps = {
  onSpoof: spoof,
};

export default connect(null, mapDispatchToProps)(SpoofingModal);
