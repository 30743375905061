import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const shape = PropTypes.shape({});

const OverridesContext = createContext({});

export default OverridesContext;

function save(value) {
  localStorage.setItem('appcues:devtools:preview-sha', value);
  window.location.reload();
}

const revert = () => save(null);

export function useOverrides() {
  const overrides = useContext(OverridesContext);

  return { overrides, save, revert };
}
