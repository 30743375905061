import PropTypes from 'prop-types';

export const SlateEditorShape = PropTypes.shape({
  editor: PropTypes.shape({
    addMark: PropTypes.func,
  }),
});

export default PropTypes.shape({
  id: PropTypes.string,
  editor: SlateEditorShape,
});
