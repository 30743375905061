import { transform } from 'entities/step-groups';
import { generateStep } from 'entities/step-children';
import { MODAL, TOOLTIP, EMBED } from 'lib/trait';
import { modalTemplate } from './modal';
import { tooltipTemplate } from './tooltip';
import { embedTemplate } from './embed';

/**
 * Generate step group based on layout trait and presentation type
 *
 * @param {string} layoutTrait - layout trait (modal, tooltip or embed)
 * @param {string} presentation - presentation
 * @param {string} previewStepId - id to preview the step template before creation
 * @return {Step} Canned step group
 */
export const generateTraitPresentation = ({
  layoutTrait,
  presentation,
  locales,
  previewStepId,
}) => {
  const step = generateStep({ presentation, locales, previewStepId });
  const steps = Array.isArray(step) ? step : [step];

  const templates = {
    [MODAL]: {
      children: steps,
      traits: modalTemplate(presentation),
    },
    [TOOLTIP]: {
      children: steps,
      traits: tooltipTemplate(presentation),
    },
    [EMBED]: {
      children: steps,
      traits: embedTemplate(presentation),
    },
  };

  const { children, traits } = templates[layoutTrait];

  return {
    actions: {},
    type: 'group',
    children,
    traits,
  };
};

/**
 * Generate canned sample presentation step based on layout
 * trait and presentation type to be displayed on preview on
 * the creation of the flow first step
 *
 * @param {string} layoutTrait - layout trait (modal, tooltip or embed)
 * @param {string} presentation - presentation
 * @return {object} Editor and content samples
 */
export const generatePresentationStep = ({ layoutTrait, presentation }) => {
  const PREVIEW_STEP_GROUP_ID = Symbol('preview-step-group-id');
  const PREVIEW_STEP_CHILDREN_ID = Symbol('preview-step-id');

  const presentationTemplate = generateTraitPresentation({
    layoutTrait,
    presentation,
    previewStepId: PREVIEW_STEP_CHILDREN_ID,
  });

  const { stepGroups, stepChildren } = transform({
    id: PREVIEW_STEP_GROUP_ID,
    ...presentationTemplate,
  });

  const { editor } = stepGroups?.[PREVIEW_STEP_GROUP_ID] ?? {};
  const { content, traits } = stepChildren?.[PREVIEW_STEP_CHILDREN_ID] ?? {};

  return { editor, content, traits };
};
