import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@appcues/sonar';
import { faCloudCheck } from '@fortawesome/pro-regular-svg-icons/faCloudCheck';
import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons/faCloudArrowUp';
import { faCloudExclamation } from '@fortawesome/pro-regular-svg-icons/faCloudExclamation';
import { capitalize } from 'ext/lib/strings';
import Tooltip from './Tooltip';

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 40px;
`;

export function StatusIndicator({ status }) {
  return status ? (
    <Tooltip label={capitalize(status)}>
      <Container>
        {status === 'saving' && (
          <Icon
            aria-label="saving"
            colorToken="foreground-disabled"
            icon={faCloudArrowUp}
          />
        )}

        {status === 'saved' && (
          <Icon
            aria-label="saved"
            colorToken="foreground-success"
            icon={faCloudCheck}
          />
        )}

        {status === 'error' && (
          <Icon
            aria-label="error"
            colorToken="foreground-error"
            icon={faCloudExclamation}
          />
        )}
      </Container>
    </Tooltip>
  ) : null;
}

StatusIndicator.propTypes = {
  status: PropTypes.oneOf(['saving', 'saved', 'error']),
};

export default StatusIndicator;
