import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { del } from 'object-path-immutable';
import { selectExperience, stepsUpdate } from 'entities/experiences';
import {
  update as updateStepGroup,
  selectStepGroup,
  EditorShape,
} from 'entities/step-groups';
import {
  update as updateStepChildren,
  selectStepChild,
  ContentShape,
} from 'entities/step-children';
import { Shape as TraitsShape } from 'entities/trait';
import { selectUserPreferences } from 'entities/user-preferences';
import { selectScreen, Shape as ScreenShape } from 'entities/screens';
import {
  selectUserInterface,
  updateTargetPlacement,
  updateSideBarTab,
  selectSideBarTab,
} from 'entities/user-interface';
import {
  updateTraits,
  MODAL,
  TOOLTIP,
  EMBED,
  PAGING_DOTS,
  SKIPPABLE,
  LAYOUT_TRAITS,
} from 'lib/trait';
import { delayType, Shape as ActionsShape } from 'lib/actions';
import { PLATFORMS } from 'lib/platform';
import { THEMES } from 'lib/user-preferences';
import ModalSettings from './Modal';
import TooltipSettings from './Tooltip';
import EmbedSettings from './Embed';
import PagingDotsSettings from './PagingDots';
import SkippableSettings from './Skippable';
import HeaderActions from './HeaderActions';

export function TraitSettings({
  selectedStepGroup,
  stepChildrenIds,
  selectedStepChild,
  selectedTrait,
  selectedScreen,
  platform,
  layoutTrait,
  traits,
  editor = {},
  actions,
  content,
  isStepGroupFirstChild,
  targetPlacement,
  selectedSideBarTab,
  theme,
  onGroupUpdate,
  onChildrenUpdate,
  onStepsUpdate,
  onTargetPlacementUpdate,
  onSideBarTabUpdate,
}) {
  const handleChange = (patch, traitToDelete) => {
    const editorUpdated = traitToDelete
      ? del(editor, traitToDelete)
      : { ...editor, ...patch };

    onGroupUpdate(selectedStepGroup, { editor: editorUpdated });
  };

  const handleActionsUpdate = (updatedAction, actionTypeToDelete) => {
    const currentActions =
      actions[selectedStepGroup]?.filter(
        ({ type }) =>
          type !== updatedAction?.type && type !== actionTypeToDelete
      ) || [];

    let updatedActions = updatedAction?.type
      ? [...currentActions, updatedAction]
      : currentActions;

    // If there are no actions left, delete the group from actions array
    if (updatedActions.length === 0) {
      const otherActions = del(actions, selectedStepGroup);
      onGroupUpdate(selectedStepGroup, { actions: otherActions });
      return;
    }

    // If there are multiple actions, including a delay action, the delay action should always be last
    const delayAction = updatedActions.find(({ type }) => type === delayType);
    if (delayAction) {
      updatedActions = updatedActions.filter(({ type }) => type !== delayType);
      updatedActions.push(delayAction);
    }

    onGroupUpdate(selectedStepGroup, {
      actions: { [selectedStepGroup]: updatedActions },
    });
  };

  const handleTraitsUpdate = (updatedTraits, traitTypeToDelete) => {
    const updatedTraitsList = updateTraits({
      traits,
      updatedTraits,
      traitTypeToDelete,
    });

    onChildrenUpdate(selectedStepChild, { traits: updatedTraitsList });
  };

  const pagingDots = (
    <PagingDotsSettings editor={editor} theme={theme} onChange={handleChange} />
  );

  const skippable = (
    <SkippableSettings editor={editor} theme={theme} onChange={handleChange} />
  );

  const modal = (
    <>
      <HeaderActions trait={layoutTrait} />
      <ModalSettings
        selectedStepGroup={selectedStepGroup}
        isStepGroupFirstChild={isStepGroupFirstChild}
        stepChildrenIds={stepChildrenIds}
        editor={editor}
        content={content}
        actions={actions}
        theme={theme}
        handleActionsUpdate={handleActionsUpdate}
        onChange={handleChange}
      />
    </>
  );

  const tooltip = (
    <>
      <HeaderActions trait={layoutTrait} />
      <TooltipSettings
        selectedStepGroup={selectedStepGroup}
        selectedStepChild={selectedStepChild}
        selectedScreen={selectedScreen}
        selectedSideBarTab={selectedSideBarTab}
        isStepGroupFirstChild={isStepGroupFirstChild}
        targetPlacement={targetPlacement}
        platform={platform}
        editor={editor}
        actions={actions}
        traits={traits}
        theme={theme}
        handleActionsUpdate={handleActionsUpdate}
        handleTraitsUpdate={handleTraitsUpdate}
        onChange={handleChange}
        onStepsUpdate={onStepsUpdate}
        onSideBarTabUpdate={onSideBarTabUpdate}
        onTargetPlacementUpdate={onTargetPlacementUpdate}
      />
    </>
  );

  const embed = (
    <>
      <HeaderActions trait={layoutTrait} />
      <EmbedSettings
        selectedStepChild={selectedStepChild}
        isStepGroupFirstChild={isStepGroupFirstChild}
        stepChildrenIds={stepChildrenIds}
        editor={editor}
        content={content}
        theme={theme}
        onChange={handleChange}
      />
    </>
  );

  const traitsSettings = {
    [PAGING_DOTS]: pagingDots,
    [SKIPPABLE]: skippable,
    [MODAL]: modal,
    [TOOLTIP]: tooltip,
    [EMBED]: embed,
  };

  return traitsSettings[selectedTrait || layoutTrait] ?? null;
}

TraitSettings.propTypes = {
  selectedStepGroup: PropTypes.string,
  stepChildrenIds: PropTypes.arrayOf(PropTypes.string),
  selectedStepChild: PropTypes.string,
  selectedTrait: PropTypes.string,
  selectedScreen: ScreenShape,
  platform: PropTypes.oneOf(PLATFORMS),
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  traits: TraitsShape,
  editor: EditorShape,
  actions: ActionsShape,
  content: ContentShape,
  isStepGroupFirstChild: PropTypes.bool,
  targetPlacement: PropTypes.bool,
  selectedSideBarTab: PropTypes.string,
  theme: PropTypes.oneOf(THEMES),
  onGroupUpdate: PropTypes.func,
  onChildrenUpdate: PropTypes.func,
  onStepsUpdate: PropTypes.func,
  onTargetPlacementUpdate: PropTypes.func,
  onSideBarTabUpdate: PropTypes.func,
};

const mapStateToProps = (state, { selectedStepGroup, selectedStepChild }) => {
  const platform = selectExperience(state)?.platform;
  const { children: stepChildrenIds, actions } = selectStepGroup(
    state,
    selectedStepGroup
  );
  const { content, traits } = selectStepChild(state, selectedStepChild) ?? {};
  const isStepGroupFirstChild =
    stepChildrenIds?.indexOf(selectedStepChild) === 0;
  const selectedSideBarTab = selectSideBarTab(state, selectedStepGroup);
  const { theme } = selectUserPreferences(state);
  const { targetPlacement } = selectUserInterface(state);
  const selectedScreen = selectScreen(state);

  return {
    platform,
    stepChildrenIds,
    actions,
    content,
    traits,
    isStepGroupFirstChild,
    selectedSideBarTab,
    targetPlacement,
    selectedScreen,
    theme,
  };
};

const mapDispatchToProps = {
  onGroupUpdate: updateStepGroup,
  onChildrenUpdate: updateStepChildren,
  onStepsUpdate: stepsUpdate,
  onTargetPlacementUpdate: updateTargetPlacement,
  onSideBarTabUpdate: updateSideBarTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(TraitSettings);
