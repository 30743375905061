import { ALLOWED_ANCHORED } from 'lib/platform';
import { ANCHORED, PRESENTATIONS } from './presentation';

/**
 * Get the list of allowed presentations for a given layout trait and platform selected
 *
 * @param {string} layoutTrait - One of layout trait options
 * @param {string} platform - One of platform options
 * @returns {array<string>} - Allowed presentations list
 */
export const getAllowedPresentations = (layoutTrait, platform) =>
  Object.entries(PRESENTATIONS[layoutTrait]).filter(
    ([presentation]) =>
      presentation !== ANCHORED || ALLOWED_ANCHORED.includes(platform)
  );
