import React from 'react';
import { SURVEY, BLOCK_CATEGORIES, BLOCK_LABELS } from 'lib/block';

const [TEXT_INPUT, SINGLE_SELECT, MULTI_SELECT, RATING] =
  BLOCK_CATEGORIES[SURVEY];

const textInputIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.8375 0.0743946C13.7449 -0.0248419 13.5949 -0.0247918 13.5024 0.0745066L6.93111 7.12663C6.88666 7.17433 6.86168 7.23904 6.86168 7.3065V9.55121C6.86168 9.6917 6.96781 9.80559 7.09872 9.80559H9.19037C9.25324 9.80559 9.31353 9.77879 9.35798 9.73108L15.9306 2.67757C15.975 2.62985 16 2.56511 16 2.49762C16 2.43012 15.975 2.3654 15.9305 2.31771L13.8375 0.0743946ZM0.173566 3.29157C0.284699 3.17231 0.435427 3.1053 0.592592 3.1053H5.46623C5.79351 3.1053 6.05882 3.39003 6.05882 3.74126C6.05882 4.09249 5.79351 4.37721 5.46623 4.37721H1.18518V14.7281H12.9964V9.55265C12.9964 9.20142 13.2617 8.9167 13.589 8.9167C13.9162 8.9167 14.1815 9.20142 14.1815 9.55265V15.364C14.1815 15.7153 13.9162 16 13.589 16H0.592592C0.265312 16 0 15.7153 0 15.364V3.74126C0 3.57259 0.0624337 3.41084 0.173566 3.29157Z"
    fill="currentColor"
  />
);

const singleSelectIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5 8C5 6.31563 6.31563 5 8 5C9.65625 5 11 6.31563 11 8C11 9.65625 9.65625 11 8 11C6.31563 11 5 9.65625 5 8ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1.5C4.40938 1.5 1.5 4.40938 1.5 8C1.5 11.5906 4.40938 14.5 8 14.5C11.5906 14.5 14.5 11.5906 14.5 8C14.5 4.40938 11.5906 1.5 8 1.5Z"
    fill="currentColor"
  />
);

const multiSelectIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.7143 0C14.975 0 16 1.02321 16 2.28571V13.7143C16 14.975 14.975 16 13.7143 16H2.28571C1.02321 16 0 14.975 0 13.7143V2.28571C0 1.02321 1.02321 0 2.28571 0H13.7143ZM12.1357 6.42143C12.525 6.03214 12.525 5.39643 12.1357 5.00714C11.7464 4.61786 11.1107 4.61786 10.7214 5.00714L6.85714 8.87143L5.27857 7.29286C4.88929 6.90357 4.25357 6.90357 3.86429 7.29286C3.47393 7.68214 3.47393 8.31786 3.86429 8.70714L6.15 10.9929C6.53929 11.3821 7.175 11.3821 7.56429 10.9929L12.1357 6.42143Z"
    fill="currentColor"
  />
);

const ratingIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.99888 0.242004C8.27773 0.242004 8.53234 0.401133 8.65358 0.651799L10.7329 4.93403L15.3794 5.62207C15.6522 5.66147 15.8765 5.85243 15.9644 6.11309C16.0493 6.37679 15.9796 6.66474 15.7856 6.85569L12.4151 10.1959L13.2122 14.9091C13.2577 15.1819 13.1456 15.4608 12.9182 15.6214C12.6939 15.7821 12.3696 15.8033 12.1514 15.6729L7.99888 13.4542L3.82213 15.6729C3.6039 15.8033 3.30686 15.7821 3.07953 15.6214C2.85523 15.4608 2.74309 15.1819 2.76127 14.9091L3.58268 10.1959L0.215517 6.85569C0.019713 6.66474 -0.0496973 6.37679 0.0363837 6.11309C0.122162 5.85243 0.348579 5.66147 0.621068 5.62207L5.2649 4.93403L7.34418 0.651799C7.46845 0.401103 7.72002 0.242004 7.99888 0.242004ZM7.99888 2.635L6.40759 5.91608C6.3015 6.13128 6.0954 6.28283 5.85898 6.3192L2.27267 6.8466L4.87692 9.426C5.04363 9.59271 5.11941 9.83216 5.08 10.0655L4.46774 13.6906L7.65637 11.9872C7.87157 11.872 8.12921 11.872 8.34138 11.9872L11.53 13.6906L10.9178 10.0655C10.8783 9.83216 10.9572 9.59271 11.1239 9.426L13.7275 6.8466L10.1418 6.3192C9.90236 6.28283 9.69625 6.13128 9.59319 5.91608L7.99888 2.635Z"
    fill="currentColor"
  />
);

const icons = {
  [TEXT_INPUT]: textInputIcon,
  [SINGLE_SELECT]: singleSelectIcon,
  [MULTI_SELECT]: multiSelectIcon,
  [RATING]: ratingIcon,
};

const surveyIcon = icon => (
  <svg role="img" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    {icons[icon]}
  </svg>
);

export const surveyOptions = [
  TEXT_INPUT,
  SINGLE_SELECT,
  MULTI_SELECT,
  RATING,
].reduce(
  (acc, surveyBlock) => [
    ...acc,
    {
      icon: surveyIcon(surveyBlock),
      label: BLOCK_LABELS[surveyBlock],
      value: surveyBlock,
    },
  ],
  []
);

export default surveyOptions;
