export const handleFilterSearch = (item, filterString, filterProperties) => {
  if (!filterString) {
    return true;
  }

  return filterProperties.some(property => {
    const value = item.metadata[property] || item[property];
    return (
      typeof value === 'string' &&
      value.toLowerCase().includes(filterString.toLowerCase())
    );
  });
};

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
};

export const handleDate = date =>
  new Intl.DateTimeFormat('en-US', dateOptions).format(date);
