import styled from 'styled-components';

export const EmbedViewport = styled.div`
  *,
  *::before,
  *::after {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  }

  ${({ scale }) => `transform: scale(${scale});`}

  ${({ width }) => `width: ${width}px;`}

  transition: all 0.5s ease-in-out;
`;
