import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from '@appcues/sonar';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { ScreenLayoutShape, TYPE as SCREEN_TYPE } from 'entities/screens';
import { toggleFloatingScreen } from 'entities/user-interface';
import { LOADING, LOADED, selectStatusByEntity } from 'entities/status';
import Spinner from 'components/Spinner';
import Empty from './Empty';
import Error from './Error';
import SelectorWarningPopover from './SelectorWarningPopover';
import { Wrapper, Container, Loading, Item } from './styled';

const MAX_DISPLAY_NAME_LENGTH = 34;

const filterSelectors = (selectors, searchFilter) =>
  selectors.filter(selector =>
    selector?.displayName.toLowerCase().includes(searchFilter.toLowerCase())
  );

export const SelectorsList = ({
  currentSelectorId,
  selectedScreenId,
  selectors,
  status,
  onSelectorClick,
  onToggleFloatingScreen,
}) => {
  const [searchFilter, setSearchFilter] = useState('');

  const hasNoScreen = !selectedScreenId;
  const hasNoSelectors = selectedScreenId && selectors.length === 0;
  const filteredSelectors = filterSelectors(selectors, searchFilter);

  const handleSearch = ({ target }) => {
    setSearchFilter(target.value);
  };

  return (
    <Wrapper>
      <Input
        icon={faMagnifyingGlass}
        placeholder="Search..."
        onChange={handleSearch}
      />
      <Container>
        {status === LOADING && (
          <Loading>
            <Spinner />
          </Loading>
        )}

        {status === LOADED && hasNoScreen && (
          <Empty onToggleFloatingScreen={onToggleFloatingScreen} />
        )}

        {hasNoSelectors && <Error />}

        {status === LOADED &&
          filteredSelectors.map(selector => {
            const isSelected = selector.id === currentSelectorId;

            return (
              <Item
                key={selector.id}
                aria-selected={isSelected}
                onClick={() => onSelectorClick(selector)}
                title={
                  selector.displayName.length >= MAX_DISPLAY_NAME_LENGTH
                    ? selector.displayName
                    : null
                }
              >
                {selector.displayName}
                {isSelected && selector.isDuplicated && (
                  <SelectorWarningPopover />
                )}
              </Item>
            );
          })}
      </Container>
    </Wrapper>
  );
};

SelectorsList.propTypes = {
  currentSelectorId: PropTypes.string,
  selectedScreenId: PropTypes.string,
  selectors: PropTypes.arrayOf(ScreenLayoutShape),
  status: PropTypes.oneOf([LOADING, LOADED]),
  onSelectorClick: PropTypes.func,
  onToggleFloatingScreen: PropTypes.func,
};

const mapStateToProps = state => ({
  status: selectStatusByEntity(state, SCREEN_TYPE),
});

const mapDispatchToProps = {
  onToggleFloatingScreen: toggleFloatingScreen,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectorsList);
