import React from 'react';
import PropTypes from 'prop-types';
import { STICKY_OPTIONS } from 'lib/block';
import RowContainer from 'components/RowContainer';

const Row = ({ id, children, sticky, onDrop }) => (
  <RowContainer id={id} sticky={sticky} onDrop={onDrop}>
    {children}
  </RowContainer>
);

Row.propTypes = {
  id: PropTypes.string,
  sticky: PropTypes.oneOf(STICKY_OPTIONS),
  children: PropTypes.node,
  onDrop: PropTypes.func,
};

export default Row;
