import { set, get } from 'object-path-immutable';
import { merge } from 'lodash';

/**
 * A method to merge properties to an object
 * It will only merge the properties to the keys provided
 *
 * @param {object} obj - Object to be merged
 * @param {array<string>} keys - Keys to be manipulated and merged
 * @param {object} properties - Properties to be merged to the original object
 * @returns
 */
export function mergeProperties(obj, keys, properties) {
  return keys.reduce((acc, key) => {
    const oldProperties = get(acc, key) || {};
    const newProperties = merge({}, oldProperties, properties);
    return set(acc, key, newProperties);
  }, obj);
}
