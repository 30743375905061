import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, FontIcon } from 'ext/components/ui';

const BookEnd = styled(Button).attrs(({ side = 'right' }) => ({
  children: <FontIcon icon={`angle-${side}`} />,
}))`
  background-color: var(--background);
  height: 48px;
  padding: 8px 4px;

  ${({ side }) => {
    switch (side) {
      case 'left':
        return `
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          margin-left: 16px;
        `;
      case 'right':
      default:
        return `
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-right: 16px;
        `;
    }
  }}
`;

export const ScrollControl = ({
  disabled = false,
  interval = 120,
  side = 'right',
  steps = 48,
  target,
}) => {
  const timer = useRef();

  const scroll = useCallback(() => {
    const { current: $target } = target;
    const step = (side === 'right' ? 1 : -1) * steps;
    // NOTE: Because we are using setInterval with Element#scrollBy, the scroll
    //       animation can be a little jumpy. This shouldn't be an issue for
    //       most, but if we ever wanted to tighten up the animation, we can
    //       consider some other options that use APIs like
    //       requestAnimationFrame or existing smooth scroll libraries.
    $target.scrollBy({ left: step, behavior: 'smooth' });
  }, [side, steps, target]);

  const handleMouseDown = () => {
    timer.current = setInterval(scroll, interval);
    scroll();
  };

  const handleClearTimer = () => {
    clearInterval(timer.current);
  };

  return (
    <BookEnd
      disabled={disabled}
      onMouseDown={handleMouseDown}
      onMouseUp={handleClearTimer}
      // NOTE: Because it is possible to start the scroll then mouseout without
      //       mouseup, the timer is stopped immediately on mouseout as well.
      //       This does mean that if the user re-enters the target with
      //       mousedown the scroll has halted, but that seems safer than
      //       continuously triggering the scroll
      onMouseOut={handleClearTimer}
      side={side}
    />
  );
};

ScrollControl.propTypes = {
  disabled: PropTypes.bool,
  interval: PropTypes.number,
  side: PropTypes.oneOf(['right', 'left']),
  steps: PropTypes.number,
  // NOTE: Due to how we render the builder in a Shadow root via script, the
  //       expected PropTypes.instanceOf(Element) will not work since the
  //       constructor of Element here will not match the one used during
  //       runtime. Therefore, PropTypes.object is used to validate the prop to
  //       the best of our abilities.
  // eslint-disable-next-line react/forbid-prop-types
  target: PropTypes.shape({ current: PropTypes.object }),
};
