import styled from 'styled-components';
import { Icon } from '@appcues/sonar';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 45px);
  grid-template-rows: repeat(3, 35px);
  gap: 8px;
  width: fit-content;
  background: var(--color-neutral-50);
  margin: auto;
  padding: 12px;
  border-radius: 6px;
`;

const activeStyles = {
  background: 'var(--color-blurple-200)',
  color: 'var(--color-blurple-700)',
};

export const GridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-600);
  background: var(--color-neutral-0);
  border-radius: 6px;
  cursor: pointer;

  ${({ active }) => active && activeStyles}

  &:hover {
    ${activeStyles}
  }

  ${Icon} {
    font-size: var(--font-size-small);
  }
`;
