import React from 'react';
import PropTypes from 'prop-types';
import { DragIndicator as StyledDragIndicator } from './styled';

const CaretIndicator = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219 10">
    <path stroke="currentColor" strokeWidth="2" d="M.799 5H218.6" />
    <path
      d="M.5.5h12.533l2.4 4.5-2.4 4.5H.5v-9ZM218.5.5h-12.533l-2.4 4.5 2.4 4.5H218.5v-9Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

const DragIndicator = ({ isAbove }) => {
  return (
    <StyledDragIndicator isAbove={isAbove}>
      <CaretIndicator />
    </StyledDragIndicator>
  );
};

DragIndicator.propTypes = {
  isAbove: PropTypes.bool,
};

export default DragIndicator;
