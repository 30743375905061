import React from 'react';
import { Message, Section, StatusMessageWrapper } from './styled';

export const FlowListEmpty = () => (
  <StatusMessageWrapper aria-label="empty">
    <Section direction="column">
      <Message>Nothing here yet!</Message>
      <Message>Create a flow to get started.</Message>
    </Section>
  </StatusMessageWrapper>
);

export const NoFlowsFound = () => (
  <StatusMessageWrapper aria-label="no flows">
    <Section direction="column">
      <Message>No flows found.</Message>
    </Section>
  </StatusMessageWrapper>
);
