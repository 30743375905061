import { del } from 'object-path-immutable';
import {
  continueType,
  closeType,
  launchExperienceType,
  linkType,
  submitFormType,
  getSubmitForm,
} from './types';

const ignoredTypes = new Set([submitFormType, linkType, launchExperienceType]);

/**
 * Check if one of the ignored types are in actions
 * @param {object} actions - current actions from a element
 * @returns {boolean} True if one of the ignored types is in actions
 */
const hasIgnoredTypes = actions =>
  actions.some(
    ({ type, config }) => ignoredTypes.has(type) || config?.offset === -1
  );

/**
 * Update actions object with submit-form based on their types
 * If the action is continue (Next or Custom Step), close (Dismiss) we should add submit-form action
 * If the action is submit-form, link, launch-experience or continue but with negative offset (previous)
 * we don't need to add submit-form action
 * @param {object} actions - current actions from step
 * @returns {object} Updated actions with submit-form action
 */
export const addSubmitFormAction = actions => {
  const submitFormAction = getSubmitForm();
  const submitFormWithSkip = getSubmitForm({ skipValidation: true });

  return Object.keys(actions).reduce((acc, key) => {
    const isClose = actions[key].some(({ type }) => type === closeType);
    const shouldUpdate = !hasIgnoredTypes(actions[key]);

    const submitActionObj = isClose ? submitFormWithSkip : submitFormAction;
    const updatedKeyActions = shouldUpdate
      ? [submitActionObj, ...actions[key]]
      : actions[key];

    return {
      ...acc,
      [key]: updatedKeyActions,
    };
  }, {});
};

/**
 * Delete all submit-form actions from actions object
 * @param {object} actions - current actions from step
 * @returns {object} Updated actions without submit-form action
 */
export const removeSubmitFormAction = actions =>
  Object.keys(actions).reduce((acc, key) => {
    acc[key] = actions[key].filter(({ type }) => type !== submitFormType);
    return acc;
  }, {});

/**
 * Check if actions contains previous step action
 * @param {object} actions - current actions from step
 * @returns {boolean} True if contains previous step action
 */
export const hasPreviousAction = actions =>
  Object.values(actions).some(elementAction =>
    elementAction.some(
      ({ type, config }) => type === continueType && config?.offset === -1
    )
  );

/**
 * Remove config property from continueType actions transforming previous actions into next
 * @param {object} actions - current actions from step
 * @returns {object} Updated actions without previous action
 */
export const transformPreviousActionIntoNext = actions =>
  Object.keys(actions).reduce(
    (acc, elementId) => ({
      ...acc,
      [elementId]: actions[elementId].reduce(
        (blockActions, blockAction) => [
          ...blockActions,
          {
            ...(blockAction.type === continueType &&
            blockAction.config?.offset === -1
              ? del(blockAction, 'config')
              : blockAction),
          },
        ],
        []
      ),
    }),
    {}
  );
