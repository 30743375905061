import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  hasTranslations: PropTypes.bool,
  hasPublishedTranslations: PropTypes.bool,
  conditions: PropTypes.shape({
    and: PropTypes.arrayOf(
      PropTypes.shape({
        properties: PropTypes.shape({
          property: PropTypes.string,
          operator: PropTypes.string,
          value: PropTypes.string,
        }),
      })
    ),
  }),
});

export const LocalesTableShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    property: PropTypes.string,
    targetCriteria: PropTypes.arrayOf(PropTypes.string),
  })
);
