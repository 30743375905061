import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDragLayer } from 'react-dnd';
import useOverflowable from 'ext/lib/hooks/use-overflowable';
import useToggle from 'ext/lib/hooks/use-toggle';
import move from 'ext/lib/move';
import { ScrollControl } from 'ext/components/ScrollControl';
import { moveStepGroup, selectExperience } from 'entities/experiences';
import { Shape as SelectedShape } from 'entities/selected';
import {
  moveStepChild,
  selectStepGroups,
  Shape as StepGroupShape,
} from 'entities/step-groups';
import { focus as focusChild } from 'entities/step-children';
import { focus as focusBlock } from 'entities/block';
import { updateSlate } from 'entities/slate';
import { STEP_GROUP, STEP_CHILD } from './use-dnd';
import AddManager from './AddManager';
import StepGroup from './StepGroup';
import { Centered, Container, StepGroups } from './styled';

/**
 * NOTE: Currently there is a bug(?) in `react-dnd` where unmounting drag/drop
 *       zones while a drag is in progress will throw an invariant violation
 *       re: being unable to find a valid element. Despite the warning, the
 *       functionality continues to work because the issue seems to be the
 *       internal redux store in `react-dnd` continue to dispatch actions on
 *       unmounted components. This is why we are currently using the `key`
 *       index as the `PageGroup` key, but should use something more unique once
 *       the underlying issue is resolved.
 */

export const Steps = ({
  groups,
  groupOrder,
  selected,
  onSelect,
  onClick,
  onChildMove,
  onGroupMove,
  onSlateUpdate,
}) => {
  const $overflow = useRef();

  const [order, setOrder] = useState(groupOrder ?? []);
  const [isMenuOpen, toggleIsMenuOpen] = useToggle();

  const { dragging } = useDragLayer(monitor => ({
    dragging: monitor.isDragging(),
  }));

  const [overflowing, scroll] = useOverflowable($overflow);

  useEffect(() => {
    setOrder(groupOrder);
  }, [groupOrder]);

  const handleClick = (child, parent) => {
    const { stepChild } = selected ?? {};

    if (child !== stepChild) {
      onSelect(child, parent);
    }

    // If the user clicks on a step, we want to deselect any block
    // and clear the slate editor
    onClick();
    onSlateUpdate({ id: null, editor: {} });
  };

  const handleDrag = useCallback(
    (from, to) => {
      setOrder(current => move(current, from, to));
    },
    [setOrder]
  );

  const handleDrop = useCallback(
    (item, commit) => {
      if (commit) {
        const {
          id: stepGroupId,
          stepChildId,
          type,
          index: from,
          target: to,
        } = item;

        if (type === STEP_GROUP) {
          onGroupMove(stepGroupId, {
            from,
            to,
            targetStepId: groupOrder[to],
          });
        } else if (type === STEP_CHILD) {
          const { id, children: stepGroupChildren } = groups[stepGroupId];

          onChildMove(stepChildId, {
            from,
            to,
            parent: id,
            targetStepId: stepGroupChildren[to],
          });
        }
      } else {
        setOrder(groupOrder);
      }
    },
    [groups, groupOrder, onGroupMove, onChildMove, setOrder]
  );

  if (groupOrder == null || groupOrder.length === 0) {
    return null;
  }

  const { editor: { type, presentation } = {} } =
    groups[selected?.stepGroup] ?? {};

  return (
    <>
      {overflowing && (
        <ScrollControl disabled={scroll.start} side="left" target={$overflow} />
      )}

      <Container dragging={dragging} ref={$overflow} scrollable={overflowing}>
        <Centered>
          <StepGroups role="group">
            {order.map((id, index) => {
              const {
                children: steps,
                editor: {
                  type: layoutTrait,
                  presentation: stepPresentation,
                  carousel: isSwappable,
                },
                actions,
              } = groups[id] ?? {};

              return (
                <StepGroup
                  key={id}
                  id={id}
                  index={index}
                  selected={selected}
                  steps={steps}
                  actions={actions}
                  layoutTrait={layoutTrait}
                  presentation={stepPresentation}
                  isSwappable={isSwappable}
                  isMenuOpen={isMenuOpen}
                  onClick={handleClick}
                  onDrag={handleDrag}
                  onDrop={handleDrop}
                />
              );
            })}

            <AddManager
              selectedTrait={type}
              selectedPresentation={presentation}
              isMenuOpen={isMenuOpen}
              toggleIsMenuOpen={toggleIsMenuOpen}
            />
          </StepGroups>
        </Centered>
      </Container>

      {overflowing && (
        <ScrollControl disabled={scroll.end} side="right" target={$overflow} />
      )}
    </>
  );
};

Steps.propTypes = {
  groups: PropTypes.objectOf(StepGroupShape),
  groupOrder: PropTypes.arrayOf(PropTypes.string),
  selected: SelectedShape,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  onChildMove: PropTypes.func,
  onGroupMove: PropTypes.func,
  onSlateUpdate: PropTypes.func,
};

const mapStateToProps = state => ({
  groups: selectStepGroups(state),
  groupOrder: selectExperience(state)?.steps ?? [],
});

const mapDispatchToProps = {
  onSelect: focusChild,
  onClick: focusBlock,
  onGroupMove: moveStepGroup,
  onChildMove: moveStepChild,
  onSlateUpdate: updateSlate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
