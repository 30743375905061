import { createActionConfig } from './create-action-config';

export const type = '@appcues/track';
export const getTrackEvent = eventName =>
  createActionConfig({
    type,
    config: {
      eventName,
      attributes: {
        _builderButtonEvent: true,
      },
    },
  });
