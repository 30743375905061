import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FieldSet, Select } from 'ext/components/ui';
import {
  selectStepGroups,
  Shape as StepGroupShape,
} from 'entities/step-groups';
import { selectExperience } from 'entities/experiences';
import { selectSelected, Shape as SelectedShape } from 'entities/selected';
import { getContinue } from 'lib/actions';

export function CustomStepOption({
  stepGroups,
  selected,
  groupOrder,
  onChange,
  config,
}) {
  const stepChildID = config?.stepID;

  const stepsList = useMemo(() => {
    const { stepChild } = selected;

    // We need to order the stepGroups
    // because the correct order is done on the experience.steps
    const orderedStepGroups = groupOrder.map(order => stepGroups[order]);

    return orderedStepGroups
      .flatMap((group, groupIndex) =>
        group.children.map((childId, stepIndex) => ({
          value: childId,
          label: `Group ${groupIndex + 1} - Step ${stepIndex + 1}`,
        }))
      )
      .filter(({ value }) => value !== stepChild);
  }, [groupOrder, stepGroups, selected]);

  const handleCustomStepChange = ({ value }) => {
    onChange({ actions: [getContinue({ stepID: value })] });
  };

  return (
    <FieldSet>
      <Select
        id="trigger-custom-step-select"
        options={stepsList}
        value={stepsList.filter(({ value }) => value === stepChildID)}
        placeholder="Select Step"
        onChange={handleCustomStepChange}
        portal
      />
    </FieldSet>
  );
}

CustomStepOption.propTypes = {
  stepGroups: StepGroupShape,
  selected: SelectedShape,
  groupOrder: PropTypes.arrayOf(PropTypes.string),
  config: PropTypes.shape({
    stepID: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
  groupOrder: selectExperience(state)?.steps ?? [],
  stepGroups: selectStepGroups(state),
  selected: selectSelected(state),
});

export default connect(mapStateToProps, null)(CustomStepOption);
