import { useEffect, useState } from 'react';

/**
 * Hook to open preview modal on load
 *
 * @param {object} selected - Selected state
 * @param {string} selected.experience - Transient selected experience field
 * @return {boolean} Whether to open the preview on load
 */
export default function useOpenPreview(selected) {
  const [preview, setPreview] = useState();

  useEffect(() => {
    const { experience } = selected ?? {};

    const params = new URLSearchParams(window.location.search);
    const exists = params.has('preview');

    // Remove the preview search param upon load if it exists
    if (exists) {
      params.delete('preview');
      window.history.replaceState({}, null, `?${params.toString()}`);
    }

    // If the transient selected experience field and the preview search param
    // both exist, then assume the page was opened from studio and open the
    // preview on page load.
    setPreview(experience != null && exists);
  }, [selected]);

  return preview;
}
