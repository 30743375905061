import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Accordion, FieldSet } from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import {
  Content,
  Controls,
  Style,
  SizeSpacing,
} from 'components/SideBarSettings/Shared';
import ContentActions from 'components/SideBarSettings/Content/ContentActions';
import { IMAGE } from 'lib/block';
import ImageUploader from 'components/ImageUploader';
import { DEFAULT_IMAGE_URL } from 'components/Editor/Templates/Content';
import Accessibility from './Accessibility';

export function ImageSettings({ content, onChange }) {
  const { id: blockId, imageUrl } = content ?? {};
  const showImageInUploadBox = imageUrl !== DEFAULT_IMAGE_URL;

  const handleImage = useCallback(
    params => {
      // If no params (the image has been deleted), reset to the default image
      const { url, intrinsicSize } = params ?? {
        url: DEFAULT_IMAGE_URL,
        intrinsicSize: { width: 1920, height: 1080 },
      };

      onChange({
        blockId,
        contentChunk: { imageUrl: url, blurHash: '', intrinsicSize },
      });
    },
    [blockId, onChange]
  );

  return (
    <Content>
      <Controls>
        <FieldSet>
          <ImageUploader
            description="Upload image or .svg"
            currentImage={showImageInUploadBox ? imageUrl : undefined}
            onUpload={handleImage}
          />
        </FieldSet>
      </Controls>
      <Accordion.Root
        collapsible="true"
        defaultValue={['style', 'size-spacing', 'accessibility']}
        type="multiple"
      >
        <Style content={content} onChange={onChange} />
        <SizeSpacing
          content={content}
          onChange={onChange}
          hasMargin
          horizontalAlignmentOnly
        />
        <Accessibility content={content} onChange={onChange} />
        <ContentActions blockId={blockId} context={IMAGE} />
      </Accordion.Root>
    </Content>
  );
}

ImageSettings.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
};

export default ImageSettings;
