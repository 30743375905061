/* global DEBUG_LOGGER, SEGMENT_WRITE_KEY, APPCUES_ENV */

import Analytics from 'analytics-node';

const log =
  (prefix, client) =>
  (...args) =>
    // eslint-disable-next-line no-console
    console.log(`%c[${client}:${prefix}]`, 'color: #5c5cff', ...args);

// If the `SEGMENT_WRITE_KEY` is `undefined` such as during local development
// with no specific builder facet used, use this stub client that will output to
// the console
const segmentStub = {
  identify: log('identify', 'segment'),
  page: log('page', 'segment'),
  track: log('track', 'segment'),
};

const getSegmentInstance = ({ isSpoofing }, clientMode) => {
  if (DEBUG_LOGGER || typeof SEGMENT_WRITE_KEY === 'undefined' || isSpoofing) {
    return segmentStub;
  }
  if (clientMode) {
    return window?.analytics;
  }

  return new Analytics(SEGMENT_WRITE_KEY, { flushAt: 1 });
};

export const createSegmentAnalyticsClient = (
  user,
  baseProperties = {},
  clientMode = false
) => {
  const { userId, accountId, email } = user;
  const segmentClient = getSegmentInstance(user, clientMode);

  const baseUserTraits = {
    env: APPCUES_ENV,
    accountId,
    ...(email && { email }),
  };

  return {
    analyticsProvider: segmentClient,
    identify: ({ traits = {}, context = {}, identifyUserId }) => {
      const identifyProps = {
        userId: identifyUserId || userId,
        traits: {
          ...baseUserTraits,
          ...traits,
        },
        context,
      };

      // Immediately identify user on initialization
      if (clientMode) {
        segmentClient.identify(userId, identifyProps);
      } else {
        segmentClient.identify(identifyProps);
      }
    },
    page: () => {
      segmentClient.page({
        userId,
        traits: {
          ...baseUserTraits,
        },
      });
    },
    track: (properties = {}) => {
      segmentClient.track({
        userId,
        event: properties.name,
        properties: {
          ...baseUserTraits,
          ...baseProperties,
          ...properties,
        },
      });
    },
  };
};
