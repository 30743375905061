import PropTypes from 'prop-types';
import { StyleShape, ContentShape } from 'entities/step-children';
import { BLOCK_LABELS } from 'lib/block';

export const BlockContentShape = PropTypes.shape({
  id: PropTypes.string,
  style: StyleShape,
  text: PropTypes.string,
  content: ContentShape,
  type: PropTypes.oneOf(Object.keys(BLOCK_LABELS)),
});

export default PropTypes.shape({
  blockType: PropTypes.oneOf(Object.keys(BLOCK_LABELS)),
  content: BlockContentShape,
  id: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(BLOCK_LABELS)),
});
