import isEqual from 'lodash.isequal';

/**
 * Method that parses the layout object from screen and returns an array of selectors
 *
 * @param {object} layout - an object from screen that has children with selectors
 * @returns {array} Array of selectors following the order from the outside to the inside
 */
export function parseScreenSelectors(layout) {
  const selectors = [];

  if (!layout || Object.keys(layout).length === 0) {
    return [];
  }

  function parseObjectWithSelector(item) {
    if (item.selector) {
      const { type, children, ...selectorProps } = item;

      // If all of the selector targets are empty,
      // we won't include it as a valid selector option
      if (Object.values(selectorProps.selector).every(value => value === '')) {
        return;
      }

      // If all the targets are duplicated, i.e. the selector object is the same,
      // we should identify the non unique selectors as duplicated
      selectors.forEach((selector, index) => {
        if (isEqual(selector.selector, selectorProps.selector)) {
          selectorProps.isDuplicated = true;
          selectors[index] = { ...selector, isDuplicated: true };
        }
      });

      selectors.push(selectorProps);
    }

    if (item.children) {
      item.children?.forEach(child => parseObjectWithSelector(child));
    }
  }

  layout.children?.forEach(child => parseObjectWithSelector(child));

  return selectors;
}
