import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const theme = `
  --logo-text-color: var(--white);

  [data-theme="light"] & {
    --logo-text-color: var(--oh-hi-dark);
  }
`;

const Wrapper = styled.div`
  ${theme};

  width: 160px;
`;

const Icon = styled.svg`
  path {
    fill: #5757f5;
  }
`;

const Text = styled.svg`
  path {
    fill: var(--logo-text-color);
  }
`;

function Logo({ className }) {
  return (
    <Wrapper className={className}>
      <svg viewBox="0 0 581.1 123.08">
        <Icon>
          <path d="M43.13,67.18l34.47,54a4.28,4.28,0,0,0,3.76,1.92H94.13a4.33,4.33,0,0,0,4.38-4.37V4.37a4.42,4.42,0,0,0-7.87-2.72L43.13,61.93A4.76,4.76,0,0,0,43.13,67.18Z" />
          <path d="M4.38,123.08H27.12a4.33,4.33,0,0,0,4.38-4.37v-29A4.43,4.43,0,0,0,23.62,87L.88,116A4.44,4.44,0,0,0,4.38,123.08Z" />
        </Icon>

        <Text>
          <path d="M410.54,57.88a8,8,0,0,0-.85-.89l-.47-.4-.24-.21A20.6,20.6,0,0,0,405.47,54a23,23,0,0,0-4.28-2,28.69,28.69,0,0,0-4.69-1.24,27.56,27.56,0,0,0-12.38.63,30.09,30.09,0,0,0-6.88,2.91,28.94,28.94,0,0,0-5.83,4.5,32.68,32.68,0,0,0-4.57,5.81,26.17,26.17,0,0,0-3,6.82,27.72,27.72,0,0,0,0,15.22,27.41,27.41,0,0,0,3,6.81,32.68,32.68,0,0,0,4.57,5.81,29.28,29.28,0,0,0,5.85,4.51,33.7,33.7,0,0,0,6.85,2.9,26.73,26.73,0,0,0,12.72.63,21.74,21.74,0,0,0,4.74-1.35,31.47,31.47,0,0,0,4.23-2.11,37.92,37.92,0,0,0,3.88-2.65,10.37,10.37,0,0,0,1-1l.16-.18a3.56,3.56,0,0,0,.66-1.2l.06-.36a3,3,0,0,0-.39-1.65l-.14-.22a3.59,3.59,0,0,0-.37-.5l-.37-.5c-.74-1-1.44-2-2.19-2.92a3.16,3.16,0,0,0-1.16-.84,2.14,2.14,0,0,0-1.37,0A5.78,5.78,0,0,0,403.86,93l-.66.56c-.65.54-1,.89-1.91,1.53a14.2,14.2,0,0,1-2.64,1.59,12.67,12.67,0,0,1-3.19,1.12,17.47,17.47,0,0,1-10.86-1.14,19.62,19.62,0,0,1-5.87-4.07,19.4,19.4,0,0,1-4.09-6.06,17.84,17.84,0,0,1,0-14.59,20.2,20.2,0,0,1,4.08-6.12,21.52,21.52,0,0,1,5.89-4.18,16,16,0,0,1,10.16-1.2,22,22,0,0,1,3.36,1.06,21.67,21.67,0,0,1,3,1.56c2,1.18,4.26,4.56,6.45,1.88l3.17-4.26a1.88,1.88,0,0,0,.33-1.43A2.82,2.82,0,0,0,410.54,57.88Z" />
          <path d="M215.54,103.21,180.86,24.3a3.17,3.17,0,0,0-2.92-2h-5.56a3.14,3.14,0,0,0-2.91,1.93l-34.75,78.89a3.06,3.06,0,0,0,.2,3.06,3.13,3.13,0,0,0,2.69,1.48h5.13a3.08,3.08,0,0,0,2.92-1.93L154,86.26h42.15l8.29,19.47a3.17,3.17,0,0,0,2.93,2h5a3.12,3.12,0,0,0,2.7-1.25A3.23,3.23,0,0,0,215.54,103.21Zm-57.1-27,.14-.31,16.71-38.89,16.76,39.2Z" />
          <path d="M533.07,70.23a29.46,29.46,0,0,0-4-8.57h0a28.72,28.72,0,0,0-4.52-4.79,20.33,20.33,0,0,0-5.37-3.44,29.49,29.49,0,0,0-6-2.22,27.33,27.33,0,0,0-14.08.27,26.64,26.64,0,0,0-6.7,2.9,31.35,31.35,0,0,0-5.69,4.54,25.28,25.28,0,0,0-4.24,5.82,29.21,29.21,0,0,0-2.73,6.89,27,27,0,0,0-.62,11.76,28.91,28.91,0,0,0,.88,4.53,29.46,29.46,0,0,0,4,8.59,28.63,28.63,0,0,0,4.52,4.78,20.52,20.52,0,0,0,5.38,3.45,28.83,28.83,0,0,0,6,2.21,27.55,27.55,0,0,0,20.77-3.17,31.39,31.39,0,0,0,5.69-4.53,23.4,23.4,0,0,0,4.09-5.62l.09-.2a1.82,1.82,0,0,0,.16-1.91,1.5,1.5,0,0,0-1.27-.78h-8.29a2.28,2.28,0,0,0-1.83.85,17.27,17.27,0,0,1-3.62,3.3A17.71,17.71,0,0,1,511,97a18.35,18.35,0,0,1-11.21-.32,20.34,20.34,0,0,1-5.36-2.92,15.17,15.17,0,0,1-3.87-4.59,24.75,24.75,0,0,1-1.79-6l-.05-.26H489l42.6-.09c1.75,0,2.74-1.08,2.74-3A29.07,29.07,0,0,0,533.07,70.23ZM524,74l-34.47.16.09-.29a20.45,20.45,0,0,1,2.55-5.49,19.22,19.22,0,0,1,5.1-5.11A17.71,17.71,0,0,1,502,61.14a18.35,18.35,0,0,1,11.21.32,20.34,20.34,0,0,1,5.36,2.92A15.13,15.13,0,0,1,522.45,69a16,16,0,0,1,1.79,4.73l.06.27Z" />
          <path d="M276.74,99.2a31.76,31.76,0,0,0,4.49-5.81,29.9,29.9,0,0,0,2.91-6.88,27.87,27.87,0,0,0,0-15.21,29.9,29.9,0,0,0-2.91-6.88,29.1,29.1,0,0,0-10.34-10.34,30.82,30.82,0,0,0-6.79-2.91,27.47,27.47,0,0,0-18.51,1.16,29.78,29.78,0,0,0-6.83,4.31l-.37.31V53.87a2.55,2.55,0,0,0-2.83-2.82H231.2a2.61,2.61,0,0,0-2.91,2.82v66.38a2.74,2.74,0,0,0,2.74,2.83h4.45a3,3,0,0,0,2.12-.79,2.73,2.73,0,0,0,.79-2v-19.4l.37.31a28.56,28.56,0,0,0,6.75,4.23,27.45,27.45,0,0,0,18.5,1.16,29.9,29.9,0,0,0,6.88-2.91A28.5,28.5,0,0,0,276.74,99.2Zm-13.23-2.78a16.76,16.76,0,0,1-14.14,0,18,18,0,0,1-5.87-4.24,20.19,20.19,0,0,1-4-6.11,18.72,18.72,0,0,1,0-14.47,20.34,20.34,0,0,1,4-6,19.47,19.47,0,0,1,5.87-4.16,16.78,16.78,0,0,1,14.13,0,19.73,19.73,0,0,1,5.79,4.15,20.3,20.3,0,0,1,3.92,6,18.6,18.6,0,0,1,0,14.46,22,22,0,0,1-3.93,6.14A18.56,18.56,0,0,1,263.51,96.42Z" />
          <path d="M304.21,51.13h-4.36A2.61,2.61,0,0,0,296.94,54v66.29a2.76,2.76,0,0,0,.79,2,2.71,2.71,0,0,0,1.95.79h4.44a3,3,0,0,0,2.12-.79,2.69,2.69,0,0,0,.79-2v-19.4l.37.31a28.42,28.42,0,0,0,6.76,4.23,27.44,27.44,0,0,0,18.5,1.16,30.09,30.09,0,0,0,6.88-2.91,29.21,29.21,0,0,0,5.85-4.53,31.68,31.68,0,0,0,4.48-5.81,29.9,29.9,0,0,0,2.91-6.88,27.69,27.69,0,0,0,0-15.21,29.71,29.71,0,0,0-2.91-6.88,29,29,0,0,0-10.33-10.34,31.11,31.11,0,0,0-6.8-2.91,27.48,27.48,0,0,0-18.51,1.16,29.78,29.78,0,0,0-6.83,4.31L307,57V54A2.55,2.55,0,0,0,304.21,51.13Zm3.94,20.54a20.44,20.44,0,0,1,4-6A19.52,19.52,0,0,1,318,61.47a16.78,16.78,0,0,1,14.13,0A19.84,19.84,0,0,1,338,65.63a20.31,20.31,0,0,1,3.92,6,18.56,18.56,0,0,1,0,14.46,21.45,21.45,0,0,1-3.93,6.15,18.64,18.64,0,0,1-5.77,4.23,16.82,16.82,0,0,1-14.15,0,18.1,18.1,0,0,1-5.86-4.24,20.07,20.07,0,0,1-4-6.11A18.74,18.74,0,0,1,308.15,71.67Z" />
          <path d="M468,53.18a2.58,2.58,0,0,0-2.91-2.82H460.7a2.55,2.55,0,0,0-2.83,2.82V85a11.84,11.84,0,0,1-1.18,5.39,11.6,11.6,0,0,1-3,4,14.54,14.54,0,0,1-4.17,2.41A13.33,13.33,0,0,1,439,96.26a11.23,11.23,0,0,1-3.9-3.34,11.83,11.83,0,0,1-2.13-4.5,22,22,0,0,1-.66-5.08V54a5.09,5.09,0,0,0-.65-2.75,3.37,3.37,0,0,0-2.77-1h-3.16a3.46,3.46,0,0,0-2.55,1,3.94,3.94,0,0,0-1,2.89V83.43a29.77,29.77,0,0,0,1.42,9.43,23.3,23.3,0,0,0,4.29,7.76,19.51,19.51,0,0,0,7,5.2,25.82,25.82,0,0,0,14.8,1.41,22.57,22.57,0,0,0,3.91-1.33,13.27,13.27,0,0,0,3.23-2.12l.69-.64.38-.37V105a2.44,2.44,0,0,0,.7,1.78,2.69,2.69,0,0,0,2.21,1h4.44a2.69,2.69,0,0,0,1.95-.78A2.4,2.4,0,0,0,468,105Z" />
          <path d="M579.63,84.32a14.65,14.65,0,0,0-3.92-5,23.86,23.86,0,0,0-6-3.57,42.18,42.18,0,0,0-7.58-2.34,27,27,0,0,1-3.72-1.13,14.37,14.37,0,0,1-3.1-1.58,7,7,0,0,1-2.88-3.39,5.26,5.26,0,0,1,0-3.19c.35-1.17,1.83-4.39,7.55-4.75,5.47-.34,8.22,2.53,9.17,3.81.14.18,1.48,1.91,2.44,3h0a2.83,2.83,0,0,0,.57.4,2.52,2.52,0,0,0,2.39-.31l3.34-2.48a2.44,2.44,0,0,0,1.19-2.1l0-.1a5.72,5.72,0,0,0-.76-1.81,44.5,44.5,0,0,0-3.2-4.06,15.8,15.8,0,0,0-3.54-3.09,16.33,16.33,0,0,0-4.44-1.91,23.94,23.94,0,0,0-13.52.52,16.69,16.69,0,0,0-6,3.25,14.57,14.57,0,0,0-3.93,5.12,15.7,15.7,0,0,0-.18,12.65A13.59,13.59,0,0,0,547.06,77a26.87,26.87,0,0,0,5.35,3.57,41,41,0,0,0,7,2.54c.71.16,1.42.35,2.16.55s1.46.39,2.19.56a17,17,0,0,1,3.73,1.38,7.78,7.78,0,0,1,2.59,2,5.37,5.37,0,0,1-.11,6.19,9.46,9.46,0,0,1-2.64,2.32A13,13,0,0,1,564,97.47a13.64,13.64,0,0,1-6,0,15.37,15.37,0,0,1-3-1.12,12.89,12.89,0,0,1-2.69-1.8,14.61,14.61,0,0,1-1.93-2.72,4.3,4.3,0,0,0-1.11-1.52A2.53,2.53,0,0,0,546.5,90l-4,1.89a3,3,0,0,0-.91.6,2.54,2.54,0,0,0-.52.75,2.19,2.19,0,0,0-.1,1.25,4.13,4.13,0,0,0,.31,1.22,17.9,17.9,0,0,0,2.91,5,16.79,16.79,0,0,0,4.92,4,19.1,19.1,0,0,0,5.66,2.25,26.58,26.58,0,0,0,13.88-.44,18.76,18.76,0,0,0,6.55-3.32,14.62,14.62,0,0,0,4.39-5.38A16,16,0,0,0,579.63,84.32Z" />
        </Text>
      </svg>
    </Wrapper>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
};

export default styled(Logo)``;
