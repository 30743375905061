import styled from 'styled-components';
import Button from './Button';

const MiniButton = styled(Button)`
  background-color: inherit;
  font-size: ${({ size = 18 }) => `${size}px;`}
  transition: none;
  &:hover {
    color: var(--white);
  }
`;

export default MiniButton;

export const ControlGroup = styled.div`
  display: flex;
`;
