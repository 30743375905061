import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@appcues/sonar';
import { faBoxArchive } from '@fortawesome/pro-solid-svg-icons/faBoxArchive';
import { SCREEN_STATE } from 'entities/screens';
import { ORIENTATIONS } from 'lib/user-preferences';
import { ItemImageWrapper, ItemImage, ImageOverlay } from './styled';

const { ARCHIVED } = SCREEN_STATE;

const ScreenImageWrapper = ({ src, alt, state, orientation }) => {
  const [loading, setLoading] = useState(true);

  return (
    <ItemImageWrapper>
      <ItemImage
        src={src}
        alt={alt}
        orientation={orientation}
        isLoading={loading}
        onLoad={() => setLoading(false)}
      />

      {state === ARCHIVED && (
        <ImageOverlay>
          <Icon icon={faBoxArchive} size="large" aria-label="Archived" />
        </ImageOverlay>
      )}
    </ItemImageWrapper>
  );
};

export default ScreenImageWrapper;

ScreenImageWrapper.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string,
  state: PropTypes.oneOf(Object.values(SCREEN_STATE)),
  orientation: PropTypes.oneOf(ORIENTATIONS),
};
