import styled from 'styled-components';
import { Logo, P, Text } from 'ext/components/ui';

const centered = `
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  ${centered}

  height: 100vh;
  width: 100vw;
`;

export const Dialog = styled.div`
  ${centered}

  background: var(--white);
  border-radius: 6px;
  box-shadow: var(--level-3);
  flex-direction: column;
  width: 335px;
  padding: 42px 25px;

  ${Logo} {
    width: 200px;
    margin-bottom: 32px;
  }

  ${P} {
    font-size: var(--medium-large);
    font-weight: var(--normal);
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 24px;
    text-align: center;
  }

  ${P}:last-of-type {
    margin-bottom: 0px;
  }
`;

export const Title = styled(Text)`
  margin-bottom: 24px;
`;

// Inherited from studio's UILink component
export const ExternalLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  color: var(--blurple);
  cursor: pointer;
  font-size: inherit;
  font-weight: var(--bold);
  line-height: 1.5;
  text-decoration: none;

  &:hover,
  &:active {
    color: var(--deep-work);
    text-decoration: none;
  }

  &:focus-visible {
    border-radius: 6px;
    box-shadow: 0 0 0 2px var(--blues-cues);
    outline: none;
  }

  &:visited {
    color: var(--deep-work);
  }
`;
