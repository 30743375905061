import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@appcues/sonar';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import Button from './Button';
import { Truncated } from './Text';

const Label = styled(Truncated)`
  max-width: 200px;
  padding-right: 10px;
`;

const DropdownButton = forwardRef(({ children, onClick }, ref) => (
  <Button onClick={onClick} ref={ref}>
    <Label>{children}</Label>
    <Icon icon={faChevronDown} />
  </Button>
));

DropdownButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default DropdownButton;
