/**
 * Capitalize the first letter of a string
 *
 * @param {String} string
 * @returns {String} capitalized string
 */
export const capitalize = string =>
  `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;

/**
 * Truncate a string in the middle with an ellipsis by default
 *
 * @param {String} fullString
 * @param {Number} stringLength
 * @param {String} separator
 * @returns {String} truncated string with the separator is the middle
 */
export const truncateMiddle = (fullString, stringLength, separator = '...') => {
  if (fullString.length <= stringLength) return fullString;

  const separatorLength = separator.length;
  const charsToShow = stringLength - separatorLength;
  const startChars = Math.ceil(charsToShow / 2);
  const endChars = Math.floor(charsToShow / 2);

  return `${fullString.slice(
    0,
    Math.max(0, startChars)
  )}${separator}${fullString.slice(fullString.length - endChars)}`;
};
