import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { INITIALIZE } from 'ext/root/root-actions';
import { switchAccount, spoof, despoof, isSpoofing } from 'lib/auth';
import { isPreviewBuild } from 'lib/location';
import {
  resolve,
  update,
  ACCOUNT_CHANGED,
  ACCOUNT_SPOOFED,
  ACCOUNT_DESPOOFED,
} from './actions';

/**
 * Account related query params
 */
const SPOOFING_KEY = 'view_as';
const SWITCHING_KEY = 'account';

/**
 * Environment aware navigate for use in sagas
 *
 * @param {string} path - Path to navigate to or nullish for reload
 * @return {void}
 */
function* navigate(path) {
  const history = yield getContext('history');
  const { pathname } = history.location;

  const preview = isPreviewBuild(pathname);
  const prefixed = pathname.startsWith('/mobile');

  if (path != null) {
    if (preview) {
      window.location.hash = path;
    } else {
      history.push(prefixed ? `/mobile${path}` : path);
    }
  }

  window.location.reload();
}

/**
 * Consume query param
 *
 * @param {string} key - Query param to consume
 * @return {string} Query param value if it exists
 */
function* scrape(key) {
  const history = yield getContext('history');
  const { search } = history.location;

  const params = new URLSearchParams(search);
  const exists = params.has(key);

  if (!exists) {
    return null;
  }

  const value = params.get(key);

  params.delete(key);
  window.history.replaceState({}, null, `?${params.toString()}`);

  return value;
}

function* changeAccount({ payload: id }) {
  try {
    yield call(switchAccount, id);
    yield call(navigate, '/');
  } catch {
    // TODO: Handle error properly e.g. show toast
  }
}

function* startSpoof({ payload: id }) {
  try {
    yield call(spoof, id);
    yield call(navigate, '/');
  } catch {
    // TODO: Handle error properly e.g. show toast
  }
}

function* stopSpoof() {
  try {
    yield call(despoof);
    yield call(navigate, '/');
  } catch {
    // TODO: Handle error properly e.g. show toast
  }
}

function* initialize({ payload: auth }) {
  try {
    const { accountId } = auth;

    const spoofId = yield call(scrape, SPOOFING_KEY);
    const switchId = yield call(scrape, SWITCHING_KEY);
    const spoofing = yield call(isSpoofing);

    const shouldSpoof = spoofId != null && spoofId !== accountId;
    const shouldSwitch = switchId != null && switchId !== accountId;

    const redirecting = shouldSpoof || shouldSwitch;

    yield put(resolve({ spoofing, redirecting }));

    if (shouldSpoof) {
      if (spoofing) {
        yield call(despoof);
      }
      yield call(spoof, spoofId);
      yield call(navigate);
    } else if (shouldSwitch) {
      yield call(switchAccount, switchId);
      yield call(navigate);
    }
  } catch {
    yield put(update({ redirecting: false }));
  }
}

export default function* saga() {
  yield takeLatest(ACCOUNT_CHANGED, changeAccount);
  yield takeLatest(ACCOUNT_SPOOFED, startSpoof);
  yield takeLatest(ACCOUNT_DESPOOFED, stopSpoof);
  yield takeLatest(INITIALIZE, initialize);
}
