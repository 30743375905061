import React, { useState, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import { Switch } from '@appcues/sonar';
import { FieldSet, Select, DebouncedInput as Input } from 'ext/components/ui';
import { getUserProperties } from 'lib/actions';
import { GroupedFieldSet, GroupedField, CloseIcon } from './styled';

export default function UserPropertiesOptions({
  userPropertiesList,
  config,
  onChange,
}) {
  const [clearInputKey, setClearInputKey] = useState(uuid());
  const [userPropertyKey, setUserPropertyKey] = useState('');
  const [hasUserProperties, setHasUserProperties] = useState(
    !!config?.userProperties || false
  );

  const initialUserProperties = config?.userProperties || {};
  const [userProperties, setUserProperties] = useState(initialUserProperties);

  if (!isEqual(initialUserProperties, userProperties)) {
    setUserProperties(initialUserProperties);
    setHasUserProperties(!isEmpty(config?.userProperties));
  }

  const userPropertiesOptions = useMemo(() => {
    return userPropertiesList.map(({ value, name }) => ({
      value,
      label: name,
    }));
  }, [userPropertiesList]);

  const remainingUserPropertiesOptions = useMemo(() => {
    return userPropertiesOptions.filter(({ value }) => !userProperties[value]);
  }, [userPropertiesOptions, userProperties]);

  const handleSwitchChange = () => {
    setHasUserProperties(!hasUserProperties);

    // If there was a user properties action, remove it
    if (!isEmpty(userProperties)) {
      onChange({ userProperties: [] });
    }

    setUserProperties({});
  };

  const handleUserPropertySelect = ({ value }) => {
    setUserPropertyKey(value);
  };

  const handleUserPropertyInput = (event, key = userPropertyKey) => {
    const { value } = event.target;

    const updatedUserProperties = {
      ...userProperties,
      [key]: value,
    };

    if (key) {
      onChange({ userProperties: [getUserProperties(updatedUserProperties)] });
      setUserProperties(updatedUserProperties);

      // Clear select and input
      setUserPropertyKey('');
      setClearInputKey(uuid());
    }
  };

  const handleDeleteUserProperty = key => {
    const updatedUserProperties = {
      ...userProperties,
    };

    delete updatedUserProperties[key];

    onChange({
      userProperties:
        Object.keys(updatedUserProperties).length > 0
          ? [getUserProperties(updatedUserProperties)]
          : [],
    });

    setUserProperties(updatedUserProperties);
  };

  return (
    <>
      <FieldSet>
        <Switch
          id="update-properties"
          checked={hasUserProperties}
          onCheckedChange={handleSwitchChange}
          fullWidth
        >
          <Switch.Label htmlFor="update-properties">
            Update user properties
          </Switch.Label>
        </Switch>
      </FieldSet>

      {hasUserProperties && (
        <>
          {Object.keys(userProperties).map(key => (
            <GroupedFieldSet key={key}>
              <GroupedField>
                <Select
                  options={userPropertiesOptions}
                  value={userPropertiesOptions.filter(
                    ({ value }) => key === value
                  )}
                  placeholder="Choose property"
                  onChange={handleUserPropertySelect}
                  portal
                />

                <Input
                  defaultValue={userProperties[key]}
                  placeholder="Set value"
                  onChange={e => handleUserPropertyInput(e, key)}
                  type="text"
                />

                <CloseIcon
                  aria-label="Delete property"
                  icon="trash"
                  role="button"
                  onClick={() => handleDeleteUserProperty(key)}
                />
              </GroupedField>
            </GroupedFieldSet>
          ))}

          {remainingUserPropertiesOptions.length > 0 && (
            // We need to use a key here to force a re-render
            <GroupedFieldSet key={clearInputKey}>
              <GroupedField>
                <Select
                  options={remainingUserPropertiesOptions}
                  placeholder="Choose property"
                  onChange={handleUserPropertySelect}
                  portal
                />

                <Input
                  defaultValue=""
                  placeholder="Set value"
                  onChange={handleUserPropertyInput}
                  type="text"
                />
              </GroupedField>
            </GroupedFieldSet>
          )}
        </>
      )}
    </>
  );
}

UserPropertiesOptions.propTypes = {
  userPropertiesList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  config: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    userProperties: PropTypes.object,
  }),
  onChange: PropTypes.func,
};
