import React from 'react';
import PropTypes from 'prop-types';
import { FieldSet, Label, Select } from 'ext/components/ui';
import { BlockContentShape } from 'entities/block';
import {
  SINGLE_SELECT,
  MULTI_SELECT,
  OPTION_SELECT,
  TEXT_INPUT,
  RATING,
} from 'lib/block';
import { Controls } from 'components/SideBarSettings/Shared';
import surveyOptions from './SurveyOptions';
import TextInputSettings from './TextInputSettings';
import TextFieldSettings from './TextFieldSettings';
import SelectSettings from './SelectSettings';
import RatingSettings from './RatingSettings';
import TextOptionsSettings from './TextOptionsSettings';
import updateSurveyType from './type-handler';

export function SurveySettings({
  localeId,
  blockProperties,
  blockPropertiesWithVariations,
  handleUpdate,
}) {
  const { primitiveBlock, primitiveType, blockType } = blockProperties ?? {};
  const { primitiveBlock: primitiveBlockWithVariations } =
    blockPropertiesWithVariations ?? {};

  const blockFields = (
    <>
      <Controls>
        <FieldSet>
          <Label htmlFor="survey-type">Type</Label>
          <Select
            id="survey-type"
            aria-label="select-survey-type"
            options={surveyOptions}
            placeholder="Survey input type"
            onChange={({ value }) =>
              value !== blockType &&
              updateSurveyType({
                from: blockType,
                to: value,
                blockProperties: blockPropertiesWithVariations,
                onChange: handleUpdate,
              })
            }
            value={surveyOptions.filter(({ value }) => blockType === value)}
            portal
          />
        </FieldSet>
      </Controls>

      {blockType === TEXT_INPUT && (
        <TextInputSettings
          content={primitiveBlock}
          contentWithVariations={primitiveBlockWithVariations}
          onChange={handleUpdate}
        />
      )}

      {(blockType === SINGLE_SELECT || blockType === MULTI_SELECT) && (
        <SelectSettings
          blockType={blockType}
          content={primitiveBlock}
          contentWithVariations={primitiveBlockWithVariations}
          onChange={handleUpdate}
        />
      )}

      {blockType === RATING && (
        <RatingSettings
          blockType={blockType}
          content={primitiveBlock}
          contentWithVariations={primitiveBlockWithVariations}
          onChange={handleUpdate}
        />
      )}
    </>
  );

  const primitiveFields = (
    <>
      {primitiveType === TEXT_INPUT && (
        <TextFieldSettings
          content={primitiveBlock}
          contentWithVariations={primitiveBlockWithVariations}
          onChange={handleUpdate}
        />
      )}

      {primitiveType === OPTION_SELECT && (
        <TextOptionsSettings
          content={primitiveBlock}
          contentWithVariations={primitiveBlockWithVariations}
          localeId={localeId}
          onChange={handleUpdate}
        />
      )}
    </>
  );

  return blockType ? blockFields : primitiveFields;
}

SurveySettings.propTypes = {
  blockProperties: PropTypes.shape({
    id: PropTypes.string,
    primitiveBlock: BlockContentShape,
    primitiveType: PropTypes.string,
    blockType: PropTypes.string,
  }),
  blockPropertiesWithVariations: BlockContentShape,
  localeId: PropTypes.string,
  handleUpdate: PropTypes.func,
};

export default SurveySettings;
