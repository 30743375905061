import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = (
  <path
    fill="currentColor"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2 0C0.895431 0 0 0.89543 0 2V8C0 9.10457 0.895431 10 2 10H18C19.1046 10 20 9.10457 20 8V2C20 0.895431 19.1046 0 18 0H2ZM4.63672 3.99992C4.08443 3.99992 3.63672 4.44763 3.63672 4.99992C3.63672 5.5522 4.08443 5.99992 4.63672 5.99992H15.364C15.9163 5.99992 16.364 5.5522 16.364 4.99992C16.364 4.44763 15.9163 3.99992 15.364 3.99992H4.63672Z"
  />
);

const icons = {
  button: Button,
};

const ButtonIcon = ({ className, type }) => (
  <svg
    aria-label={type}
    className={className}
    role="img"
    viewBox="0 0 20 10"
    xmlns="http://www.w3.org/2000/svg"
    height="10"
    width="20"
  >
    {icons[type]}
  </svg>
);

ButtonIcon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

export default styled(ButtonIcon)``;
