import { IOS } from 'lib/platform';
import { deviceOptions } from './device';

/**
 * This method returns the devices for the given platform
 * or all options if platform is not listed
 *
 * @param {string} platform - One of platform options
 * @returns {array<object>} - e.g.: [{ label: 'iPhone X/XS', value: '375x812' }, ...]
 */
export const getDeviceOptions = platform => {
  return deviceOptions[platform] ?? Object.values(deviceOptions).flat();
};

/**
 * This method returns the device for the given platform and viewport
 * or the default device for the given platform.
 *
 * @param {string} platform - One of platform options
 * @param {string} viewport - e.g.: '375x812'
 * @returns {object} - e.g.: { label: 'iPhone X/XS', value: '375x812' }
 */
export const getDevice = (platform = IOS, viewport) => {
  const devicePlatformOptions = getDeviceOptions(platform);

  return (
    devicePlatformOptions.find(({ value }) => value === viewport) ||
    devicePlatformOptions.find(({ isDefault }) => isDefault)
  );
};
