import styled, { css } from 'styled-components';
import { Input } from '@appcues/sonar';
import { Error as FormError } from 'components/Form';

export const Validation = styled.div`
  && ${Input} {
    ${({ error }) => error && 'border-color: var(--border-input-error);'}
  }
`;

export const Error = styled(FormError)`
  ${({ isRangeInput }) =>
    isRangeInput &&
    css`
      display: block;
      width: 100px;
      margin: 4px 0 0 auto;
    `};
`;
