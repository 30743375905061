import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontIcon, Text, Tooltip } from 'ext/components/ui';
import move from 'ext/lib/move';
import { Shape as SelectedShape } from 'entities/selected';
import { Shape as ActionsShape } from 'lib/actions';
import { PRESENTATIONS_TYPES, PRESENTATIONS_GROUP } from 'lib/presentation';
import { LAYOUT_TRAITS } from 'lib/trait';
import { useDnd, STEP_GROUP } from './use-dnd';
import { DragHandle, StepGroupWrapper, StepChildren } from './styled';
import StepChild from './StepChild';

const StepGroup = ({
  id,
  index,
  onClick,
  onDrag,
  onDrop,
  selected = {},
  actions,
  steps,
  layoutTrait,
  presentation,
  isSwappable,
  isMenuOpen,
}) => {
  const [order, setOrder] = useState(steps);

  useEffect(() => {
    setOrder(steps);
  }, [steps]);

  const options = { id, index, onDrag, onDrop };
  const [{ dragging, draggable, droppable }, connectors] = useDnd(
    STEP_GROUP,
    options
  );

  const handleClick = useCallback(
    stepChildId => {
      onClick(stepChildId, id);
    },
    [id, onClick]
  );

  const handleDrag = useCallback(
    (from, to) => {
      setOrder(current => move(current, from, to));
    },
    [setOrder]
  );

  const handleDrop = useCallback(
    (item, commit) => {
      if (commit) {
        onDrop({ ...item, id, stepChildId: item.id }, commit);
      } else {
        setOrder(steps);
      }
    },
    [id, onDrop, setOrder, steps]
  );

  const isSelected = stepChildId =>
    id === selected.stepGroup && stepChildId === selected.stepChild;

  const hasNavigation = actions?.[id]?.length > 0;

  const numberOfSteps = steps ? steps.length : 0;
  const isSingleStep = numberOfSteps === 1;
  if (numberOfSteps === 0) return null;

  const label = <Text>{PRESENTATIONS_GROUP[layoutTrait]} group</Text>;

  return (
    <StepGroupWrapper
      ref={connectors.drop}
      dragging={dragging}
      droppable={droppable}
    >
      <StepChildren ref={connectors.preview}>
        {numberOfSteps > 1 && (
          <Tooltip visible={draggable ? null : false} label={label}>
            <DragHandle ref={connectors.drag}>
              <FontIcon
                aria-label="drag-handle"
                icon="grip-lines-vertical"
                role="img"
              />
            </DragHandle>
          </Tooltip>
        )}

        {order.map((child, mark) => (
          <StepChild
            ref={isSingleStep ? connectors.drag : null}
            key={child}
            id={child}
            index={mark}
            selected={isSelected(child)}
            layoutTrait={layoutTrait}
            presentation={presentation}
            isSingleStep={isSingleStep}
            isSwappable={isSwappable}
            isMenuOpen={isMenuOpen}
            hasNavigation={hasNavigation}
            onClick={() => handleClick(child)}
            onDrag={handleDrag}
            onDrop={handleDrop}
          />
        ))}
      </StepChildren>
    </StepGroupWrapper>
  );
};

StepGroup.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  onClick: PropTypes.func,
  onDrag: PropTypes.func,
  onDrop: PropTypes.func,
  selected: SelectedShape,
  steps: PropTypes.arrayOf(PropTypes.string),
  actions: ActionsShape,
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  presentation: PropTypes.oneOf(PRESENTATIONS_TYPES),
  isSwappable: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
};

export default StepGroup;
