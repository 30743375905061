import {
  composeReducers,
  createEntityReducer,
  resolveSiblings,
  hasMany,
  moveChildren,
} from 'ext/lib/collections';

import { revert } from './schema';

const TYPE = 'experience';

const reducer = composeReducers(
  hasMany('steps', 'stepGroups'),
  moveChildren('steps', TYPE),
  createEntityReducer(TYPE)
);

export default resolveSiblings(reducer, TYPE);

export const selectExperience = state => state[TYPE];

export const selectCanonicalExperience = ({
  experience,
  stepGroups,
  stepChildren,
}) => {
  return revert(experience, { stepGroups, stepChildren });
};
