/**
 * Redux state
 * @typedef {any} State
 */

/**
 * Reducer composer
 *
 * @param {...Reducer} reducers - Reducers to compose
 * @return {Reducer} Composed reducer
 */
export default function composeReducers(...reducers) {
  if (reducers.length < 2) {
    throw new Error('No need for composition');
  }

  return reducers.reduce((composed, reducer) => (state, action) => {
    const next = reducer(state, action);
    return composed(next, action);
  });
}
