import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ControlledInput } from 'ext/components/ui';

const InputWrapper = styled.div`
  position: absolute;
`;

const ContainerTheme = `
  --property-inputs-color: #FFFFFF;
  --property-inputs-hover-background: var(--color-neutral-800);
  --property-inputs-hover-color: #FFFFFF;
  --property-inputs-hover-border: 1px solid var(--color-neutral-300);

  [data-theme='light'] & {
    --property-inputs-color: var(--secondary);
    --property-inputs-hover-background: var(--white);
    --property-inputs-hover-color: var(--background);
    --property-inputs-hover-border: none;
  }
`;

const Container = styled.div`
  ${ContainerTheme}
  --horizontal-gap: ${props => props.horizontalGap};

  ${InputWrapper} {
    &:nth-child(1) {
      top: calc(50% - 10px);
      left: 6px;
    }

    &:nth-child(2) {
      left: calc(50% - 13px);
      top: var(--horizontal-gap);
    }

    &:nth-child(3) {
      top: calc(50% - 10px);
      right: 6px;
    }

    &:nth-child(4) {
      left: calc(50% - 13px);
      bottom: var(--horizontal-gap);
    }
  }

  &&& input {
    border-radius: 6px;
    width: 27px;
    height: 20px;
    border: none;
    text-align: center;
    background: transparent;
    color: var(--property-inputs-color);
    font-size: var(--x-small);
    padding: 1px 2px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:hover,
    &:focus {
      background: var(--property-inputs-hover-background);
      color: var(--property-inputs-hover-color);
      border: var(--property-inputs-hover-border);
    }
  }
`;

const positions = ['left', 'top', 'right', 'bottom'];

const handleFocus = event => {
  event.target.select();
};

const handleBlur = event => {
  if (event.target.value === '') {
    event.target.value = 0; // eslint-disable-line no-param-reassign
  }
};

const PropertyInputs = ({
  horizontalGap,
  onUpdate,
  namePrefix,
  ...positionsValue
}) => (
  <Container horizontalGap={horizontalGap}>
    {positions.map(position => (
      <InputWrapper key={`${namePrefix}-${position}`}>
        <ControlledInput
          aria-label={`${namePrefix} ${position}`}
          defaultValue={positionsValue[position]}
          onChange={event => {
            onUpdate(position, event.target.value);
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="number"
        />
      </InputWrapper>
    ))}
  </Container>
);

PropertyInputs.propTypes = {
  horizontalGap: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  namePrefix: PropTypes.string.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  right: PropTypes.number.isRequired,
  bottom: PropTypes.number.isRequired,
};

export default PropertyInputs;
