/**
 * A method that checks the luminance of a color and returns true if the color is light
 * @param {string} color - hexadecimal color
 * @param {number} threshold - luminance threshold
 * @returns {boolean} True if the color is light
 */
export const isColorLight = (color, threshold = 0.5) => {
  if (!color) return false;

  // Parse the hexadecimal string and convert it to the RGB format
  const r = Number.parseInt(color.slice(1, 3), 16);
  const g = Number.parseInt(color.slice(3, 5), 16);
  const b = Number.parseInt(color.slice(5, 7), 16);

  // Calculate the luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Compare the luminance to the threshold
  return luminance > threshold;
};
