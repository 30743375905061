import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@appcues/sonar';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import useToggle from 'ext/lib/hooks/use-toggle';
import { Tooltip } from 'ext/components/ui';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { Shape as ExperienceShape } from 'entities/experiences';
import PreviewMobileFlowModal from './PreviewMobileFlowModal';

export function PreviewMobileFlow({
  flow,
  accountId,
  visible: initial = false,
}) {
  const { track } = useAnalytics();
  const [visible, toggle] = useToggle(initial);

  const handleClick = () => {
    track('Mobile Builder view', {
      name: 'Opened Preview Flow Modal',
      component: 'PreviewMobileFlow',
    });
    toggle();
  };

  return (
    <>
      <Tooltip label="Preview Flow">
        <Button
          aria-label="Preview flow"
          variant="tertiary"
          onClick={handleClick}
        >
          <Icon icon={faEye} />
        </Button>
      </Tooltip>

      <PreviewMobileFlowModal
        flow={flow}
        accountId={accountId}
        onClose={toggle}
        visible={visible}
      />
    </>
  );
}

PreviewMobileFlow.propTypes = {
  flow: ExperienceShape.isRequired,
  accountId: PropTypes.string,
  visible: PropTypes.bool,
};

export default PreviewMobileFlow;
