/**
 * Function that limits the number of times a given callback
 * function is called during an animation frame.
 *
 * Returns A new function that can be called in place of the original
 * function, with a `cancel` method that can be called to cancel any scheduled
 * animation frame callbacks.
 */
function animationThrottle(
  callback: (...args: any[]) => void
): EventListener & { cancel: () => void } {
  let token: number | null = null;
  let lastArgs: any[] | null = null;

  const invoke = () => {
    callback(...(lastArgs ?? []));
    token = null;
  };

  const result = (...args: any[]) => {
    lastArgs = args;

    if (!token) {
      token = requestAnimationFrame(invoke);
    }
  };

  result.cancel = () => {
    if (token) {
      cancelAnimationFrame(token);
    }
  };

  return result;
}

export default animationThrottle;
