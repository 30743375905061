import styled from 'styled-components';
import { Input } from '@appcues/sonar';

export const Container = styled.div`
  width: 380px;

  ${Input} {
    width: 100%;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  gap: 6px;

  button[aria-pressed='true'] {
    color: var(--foreground-button-secondary-active);
    background: var(--background-button-secondary-active);
  }
`;
