import { BACKDROP_KEYHOLE } from 'lib/trait';

export const backdropCircleTemplate = {
  type: BACKDROP_KEYHOLE,
  config: {
    shape: 'circle',
    blurRadius: 50,
  },
};

export const backdropRectangleTemplate = {
  type: BACKDROP_KEYHOLE,
  config: {
    shape: 'rectangle',
    cornerRadius: 0,
  },
};
