import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import RedBarOfDeath from './RedBarOfDeath';

export default function ErrorBoundary({ children, reload }) {
  const fallback = useCallback(
    () => <RedBarOfDeath reload={reload} />,
    [reload]
  );

  return (
    <SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  reload: PropTypes.func,
};
