import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DIRECTIONS } from 'lib/user-preferences';
import {
  selectUserPreferences,
  updateUserPreferences,
} from 'entities/user-preferences';
import { Container, Label, AlignmentButton, TextAlignmentIcon } from './styled';

const [LTR] = DIRECTIONS;

const textAlignmentPositions = ['leading', 'center', 'trailing'];
const textAlignmentIcons = ['align-left', 'align-center', 'align-right'];

const TextAlignmentPicker = ({ alignment, onClick, direction }) => {
  const [selected, setSelected] = useState(alignment);

  const textAlignmentOptions =
    direction === LTR
      ? textAlignmentPositions
      : textAlignmentPositions.toReversed();

  useEffect(() => {
    setSelected(alignment);
  }, [alignment]);

  const handleClick = position => {
    setSelected(position);
    onClick(position);
  };

  return (
    <Container>
      <Label>Align</Label>

      {textAlignmentOptions.map((position, index) => (
        <AlignmentButton
          key={position}
          onClick={() => handleClick(position)}
          selected={position === selected}
          aria-label={position}
          title={position}
        >
          <TextAlignmentIcon icon={textAlignmentIcons[index]} />
        </AlignmentButton>
      ))}
    </Container>
  );
};

TextAlignmentPicker.propTypes = {
  alignment: PropTypes.oneOf(textAlignmentPositions),
  onClick: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.objectOf(PropTypes.func),
  ]),
  direction: PropTypes.oneOf(DIRECTIONS),
};

const mapStateToProps = state => ({
  direction: selectUserPreferences(state).direction,
});

const mapDispatchToProps = {
  onUserPreferencesUpdate: updateUserPreferences,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextAlignmentPicker);
