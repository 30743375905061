import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonGroup, Button } from '@appcues/sonar';
import {
  Heading,
  InputClickToCopy,
  Input,
  Modal,
  ModalHeader,
} from 'ext/components/ui';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { update } from 'entities/experiences';
import { Form, FormGroup, Label } from 'components/Form';

export const FlowDetails = ({ flow, onClose, onSave, visible = true }) => {
  const { track } = useAnalytics();

  const [name, setName] = useState('');

  useEffect(() => {
    setName(flow.name || '');
  }, [flow, setName]);

  const handleNameChange = useCallback(
    ({ target: { value } }) => {
      setName(value);
    },
    [setName]
  );

  const trimmedName = name.trim();
  const disabled = trimmedName.length === 0 || trimmedName === flow.name;

  const handleSave = event => {
    event.preventDefault();

    track('Mobile Builder interaction', {
      name: 'Updated Flow Details',
      component: 'FlowDetails',
    });

    onSave({ id: flow.id, name: trimmedName });
    onClose();
  };

  const handleCancel = () => {
    setName(flow.name || '');
    onClose();
  };

  return (
    <Modal onClose={onClose} visible={visible} theme="light">
      <ModalHeader>
        <Heading>Edit Flow</Heading>
        <ModalHeader.Close onClose={onClose} />
      </ModalHeader>
      <Form disabled={disabled} onSubmit={handleSave} role="form">
        <FormGroup>
          <Label htmlFor="name">Flow name</Label>
          <Input
            id="name"
            onChange={handleNameChange}
            placeholder="Enter flow name..."
            role="textbox"
            value={name}
          />
        </FormGroup>
        <FormGroup>
          <Label>Flow ID</Label>
          <InputClickToCopy text={flow.id} wrap />
        </FormGroup>
        <ButtonGroup right>
          <Button variant="tertiary" onClick={handleCancel} type="button">
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant="primary"
            onClick={handleSave}
            type="submit"
          >
            Save
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
};

FlowDetails.propTypes = {
  flow: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  visible: PropTypes.bool,
};

const mapDispatchToProps = {
  onSave: update,
};

export default connect(null, mapDispatchToProps)(FlowDetails);
