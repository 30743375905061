import { createActionConfig } from './create-action-config';

export const type = '@appcues/delay';
export const getDelay = ({ duration, on = 'tap' }) =>
  createActionConfig({
    on,
    type,
    config: {
      duration,
    },
  });
