import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonGroup, Button, TileButton, TileGroup } from '@appcues/sonar';
import {
  Input,
  ModalHeader,
  Heading,
  Form,
  FieldSet,
  Label,
  PlatformIcon,
  Select,
} from 'ext/components/ui';
import { sortList } from 'ext/lib/sort-list';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { DEFAULT_TYPE, EMBED_TYPE } from 'lib/experiences';
import { IONIC } from 'lib/platform';
import { selectApps, Shape as AppsShape } from 'entities/apps';
import { create } from 'entities/experiences';
import { selectTags, Shape as TagsShape } from 'entities/tags';
import { EmbedsIcon, ModalsTooltipsIcon } from './Icons';
import { Modal } from './styled';

const DEFAULT_APP = null;
const DEFAULT_PLATFORM = '';
const DEFAULT_NAME = '';
const DEFAULT_TAGS = [];

export const FlowCreate = ({
  apps = {},
  onClose,
  onCreate,
  tags = {},
  visible = true,
}) => {
  const { track } = useAnalytics();

  const [app, setApp] = useState(DEFAULT_APP);
  const [platform, setPlatform] = useState(DEFAULT_PLATFORM);
  const [name, setName] = useState(DEFAULT_NAME);
  const [type, setType] = useState(DEFAULT_TYPE);
  const [selectedTags, setSelectedTags] = useState(DEFAULT_TAGS);

  const trimmed = name.trim();
  const disabled = trimmed.length === 0 || app === null;

  const appsOptions = useMemo(
    () =>
      sortList(Object.values(apps)).map(
        ({ id: value, name: label, platform: platformValue }) => ({
          label,
          value,
          platform: platformValue,
          icon: <PlatformIcon type={platformValue} />,
        })
      ),
    [apps]
  );

  const tagsOptions = useMemo(
    () =>
      sortList(Object.values(tags)).map(({ id: value, name: label }) => ({
        label,
        value,
      })),
    [tags]
  );

  const handleClose = () => {
    onClose();
    setApp(DEFAULT_APP);
    setName(DEFAULT_NAME);
    setSelectedTags(DEFAULT_TAGS);
  };

  const handleCreate = event => {
    track('Mobile Builder interaction', {
      name: 'Created Flow',
      component: 'FlowCreate',
    });

    event.preventDefault();

    const flow = {
      name: trimmed,
      appId: app,
      tagIds: selectedTags,
      type,
    };

    onCreate(flow);
    handleClose();
  };

  const handleNameChange = ({ target: { value } }) => {
    setName(value);
  };

  const handleAppChange = selected => {
    setApp(selected?.value);
    setPlatform(selected?.platform);

    // we must reset the type to only modal if the platform is ionic
    if (selected?.platform === IONIC) {
      setType(DEFAULT_TYPE);
    }
  };

  const handleTagsChange = selected => {
    setSelectedTags(selected.map(({ value }) => value));
  };

  return (
    <Modal onClose={handleClose} visible={visible} theme="light">
      <ModalHeader>
        <Heading>Create Flow</Heading>
        <ModalHeader.Close onClose={handleClose} />
      </ModalHeader>

      <Form disabled={disabled} onSubmit={handleCreate} role="form">
        <FieldSet>
          <Label htmlFor="app">App</Label>
          <Select
            id="app"
            onChange={handleAppChange}
            options={appsOptions}
            placeholder="Select app"
            defaultValue={app}
          />
        </FieldSet>

        {platform !== IONIC && (
          <FieldSet>
            <Label htmlFor="type">Flow type</Label>
            <TileGroup type="single" defaultValue={DEFAULT_TYPE}>
              <TileButton
                key="modals-tooltips"
                value={DEFAULT_TYPE}
                label="Modals & Tooltips"
                data-state={type === DEFAULT_TYPE ? 'on' : 'off'}
                onClick={() => setType(DEFAULT_TYPE)}
              >
                <ModalsTooltipsIcon />
              </TileButton>

              <TileButton
                key="embeds"
                label="Embeds"
                value={EMBED_TYPE}
                data-state={type === EMBED_TYPE ? 'on' : 'off'}
                onClick={() => setType(EMBED_TYPE)}
              >
                <EmbedsIcon />
              </TileButton>
            </TileGroup>
          </FieldSet>
        )}

        <FieldSet>
          <Label htmlFor="name">Flow name</Label>
          <Input
            id="name"
            onChange={handleNameChange}
            placeholder="e.g. Flow Name"
            role="textbox"
            value={name}
          />
        </FieldSet>

        <FieldSet>
          <Label htmlFor="tags">Tags</Label>
          <Select
            id="tags"
            isClearable
            isMulti
            name="tags"
            placeholder="Select Tags (Optional)"
            onChange={handleTagsChange}
            options={tagsOptions}
          />
        </FieldSet>

        <ButtonGroup>
          <Button variant="tertiary" onClick={handleClose} type="button">
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant="primary"
            onClick={handleCreate}
            type="submit"
          >
            Create
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
};

FlowCreate.propTypes = {
  apps: AppsShape,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  tags: PropTypes.objectOf(TagsShape),
  visible: PropTypes.bool,
};

const mapStateToProps = state => ({
  apps: selectApps(state),
  tags: selectTags(state),
});

const mapDispatchToProps = {
  onCreate: create,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowCreate);
