import React from 'react';
import { enabled } from 'ext/lib/devtools';
import useToggle from 'ext/lib/hooks/use-toggle';
import Tether from 'ext/components/Tether';
import { FontIcon } from 'ext/components/ui';
import { Shape as BlockShape } from 'entities/block';
import {
  BlockSchemeWrapper,
  BlockCodeWrapper,
  BlockCode,
  Button,
} from './styled';

const BlockScheme = ({ blockProperties }) => {
  const [isBlockSchemeVisible, setIsBlockSchemeVisible] = useToggle(false);

  return enabled() ? (
    <BlockSchemeWrapper>
      <Tether
        attachment={
          <BlockCodeWrapper>
            <BlockCode>{JSON.stringify(blockProperties, null, 2)}</BlockCode>
          </BlockCodeWrapper>
        }
        visible={isBlockSchemeVisible}
      >
        <Button
          onClick={setIsBlockSchemeVisible}
          aria-label="Block scheme button"
          kind="primary"
        >
          <FontIcon icon="code" />
        </Button>
      </Tether>
    </BlockSchemeWrapper>
  ) : null;
};

BlockScheme.propTypes = {
  blockProperties: BlockShape,
};

export default BlockScheme;
