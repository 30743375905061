import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Badge, Button, Icon, Spinner } from '@appcues/sonar';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { faWandSparkles } from '@fortawesome/free-solid-svg-icons/faWandSparkles';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons/faCirclePlus';
import { faGrid2Plus } from '@fortawesome/pro-solid-svg-icons/faGrid2Plus';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons/faLanguage';
import useToggle from 'ext/lib/hooks/use-toggle';
import Portal from 'ext/components/Portal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  updateTranslation,
  autoTranslate,
  selectLocales,
  Shape as LocalesShape,
} from 'ext/entities/locales';
import {
  selectExperience,
  selectCanonicalExperience,
  Shape as ExperienceShape,
} from 'entities/experiences';
import { selectStepChild, ContentShape } from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import { LOADING, LOADED, ERROR, selectStatusByEntity } from 'entities/status';
import { DIRECTIONS } from 'lib/user-preferences';
import { LocaleShape } from 'entities/user-preferences';
import {
  delocalize,
  generateXLIFF,
  addTranslations,
  getLocaleVariations,
  wrapBlocksWithLocalized,
  DEFAULT_LOCALE,
} from 'lib/localization';
import { HelpLabel } from 'components/SideBarSettings/Shared';
import ErrorModal from 'components/ErrorModal';
import LocalesModal from './LocalesModal';
import AiPopover from './AiPopover';
import { saveXLIFF } from './save-xliff';
import {
  DropdownMenu,
  InputFile,
  Overlay,
  Loading,
  Translating,
  ButtonsGroup,
} from './styled';

const [LTR] = DIRECTIONS;

export const LocalesDropdown = ({
  experienceId,
  canonicalExperience,
  content,
  selectedLocale,
  locales = {},
  status,
  onLocaleChange,
  onTranslationUpdate,
  onAutoTranslate,
}) => {
  const { aiTranslate } = useFlags();
  const $inputRef = useRef();
  const [openLocalesDropdown, toggleOpenLocalesDropdown] = useToggle();
  const [openLocalesModal, toggleOpenLocalesModal] = useToggle();
  const [errorOverlay, toggleErrorOverlay] = useState(false);
  const [uploadLocaleId, setUploadLocaleId] = useState();
  const [uploadError, setUploadError] = useState(null);
  const [autoTranslateLocaleId, setAutoTranslateLocaleId] = useState();

  useEffect(() => {
    if (status === ERROR) {
      toggleErrorOverlay(true);
    }
  }, [status]);

  const activeLocales = getLocaleVariations(content);
  const hasActiveLocales = activeLocales.length > 0;

  const activeSelectedLocale =
    selectedLocale?.experienceId === experienceId &&
    activeLocales.includes(selectedLocale?.id) &&
    selectedLocale;

  const { id: selectedLocaleId, name: selectedLocaleName } =
    activeSelectedLocale || DEFAULT_LOCALE;

  const activeLocalesOptions = [];
  const localesOptions = [];
  const sortedLocales = Object.values(locales).sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  sortedLocales.forEach(({ id, name }) =>
    activeLocales.includes(id)
      ? activeLocalesOptions.push({ id, name })
      : localesOptions.push({ id, name })
  );

  const getLocaleValue = localeId => {
    const locale = locales[localeId];
    return locale?.conditions.and[0].properties.value.split('\n')[0];
  };

  const getDirectionValue = localeId => {
    try {
      if (localeId === DEFAULT_LOCALE.id) {
        return LTR;
      }

      const firstLocaleCode =
        locales[localeId]?.conditions.and[0].properties.value.split('\n')[0];
      const locale = new Intl.Locale(firstLocaleCode);
      return locale.textInfo?.direction ?? locale.getTextInfo().direction;
    } catch {
      // Incorrect locale information provided (expected if the locale conditions aren't a locale identifier),
      // so don't automatically update text direction
      return null;
    }
  };

  const handleLocaleSelect = (localeId, addVariation) => {
    if (selectedLocaleId === localeId) {
      return;
    }

    if (addVariation) {
      const experienceWithLocalizedBlocks = wrapBlocksWithLocalized(
        canonicalExperience,
        [localeId]
      );

      onTranslationUpdate(experienceWithLocalizedBlocks);
    }

    toggleOpenLocalesDropdown();

    onLocaleChange(
      {
        id: localeId,
        name: locales[localeId]?.name || DEFAULT_LOCALE.name,
        experienceId,
      },
      getDirectionValue(localeId)
    );
  };

  const onDownloadXliffClick = async localeId => {
    const locale = locales[localeId];
    const localeValue = getLocaleValue(localeId);
    const xliffContents = await generateXLIFF(
      canonicalExperience,
      locale.id,
      localeValue
    );

    saveXLIFF(canonicalExperience.name, locale.name, xliffContents);

    toggleOpenLocalesDropdown();
  };

  const onFileRead = async event => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(file);

    // eslint-disable-next-line unicorn/prefer-add-event-listener
    fileReader.onload = async () => {
      try {
        const xliff = fileReader.result;
        const updatedExperience = await addTranslations(
          xliff,
          canonicalExperience,
          uploadLocaleId
        );

        onTranslationUpdate(updatedExperience);
      } catch (error) {
        setUploadError(error);
      }

      $inputRef.current.value = null;

      toggleOpenLocalesDropdown();
      onLocaleChange(
        {
          id: uploadLocaleId,
          name: locales[uploadLocaleId]?.name || DEFAULT_LOCALE.name,
          experienceId,
        },
        getDirectionValue(uploadLocaleId)
      );
    };
  };

  const onUploadTranslationClick = localeId => {
    // We need this state to get select the locale after the
    // file has been uploaded in the onFileReader method
    setUploadLocaleId(localeId);
    $inputRef.current.click();
  };

  const onAutoTranslateClick = async localeId => {
    const localeValue = getLocaleValue(localeId);
    setAutoTranslateLocaleId(localeId);
    onAutoTranslate({ canonicalExperience, localeValue, localeId });

    if (status !== ERROR) {
      toggleOpenLocalesDropdown();
      onLocaleChange(
        {
          id: localeId,
          name: locales[localeId]?.name,
          experienceId,
        },
        getDirectionValue(localeId)
      );
    }
  };

  const onAddAllTranslationsClick = () => {
    const experienceWithLocalizedBlocks = wrapBlocksWithLocalized(
      canonicalExperience,
      Object.keys(locales)
    );

    onTranslationUpdate(experienceWithLocalizedBlocks);

    toggleOpenLocalesDropdown();
  };

  const onRemoveTranslation = localeId => {
    const experienceWithLocalizedBlocks = delocalize(
      canonicalExperience,
      localeId
    );

    onTranslationUpdate(experienceWithLocalizedBlocks);

    onLocaleChange(
      {
        id: DEFAULT_LOCALE.id,
        name: DEFAULT_LOCALE.name,
        experienceId,
      },
      getDirectionValue(DEFAULT_LOCALE.id)
    );

    toggleOpenLocalesDropdown();
  };

  const onLocalizationSettingsClick = () => {
    toggleOpenLocalesModal();
    toggleOpenLocalesDropdown();
  };

  return (
    <>
      <DropdownMenu.Root open={openLocalesDropdown}>
        <DropdownMenu.Trigger onClick={toggleOpenLocalesDropdown}>
          <Icon icon={faGlobe} />
          <DropdownMenu.TriggerLabel>
            {selectedLocaleName}
          </DropdownMenu.TriggerLabel>
          <Icon icon={faCaretDown} />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="start"
            onInteractOutside={toggleOpenLocalesDropdown}
          >
            <DropdownMenu.Label>Select translation</DropdownMenu.Label>
            <DropdownMenu.ScrollableList>
              <DropdownMenu.Item
                aria-pressed={selectedLocaleId === DEFAULT_LOCALE.id}
                onClick={() => handleLocaleSelect(DEFAULT_LOCALE.id)}
              >
                {DEFAULT_LOCALE.name}
                <DropdownMenu.RightSlot>
                  <HelpLabel placement="right">
                    The default language will display for <br />
                    any languages without a translation.
                  </HelpLabel>
                </DropdownMenu.RightSlot>
              </DropdownMenu.Item>

              {activeLocalesOptions.map(({ id, name }) => (
                <DropdownMenu.Sub key={id}>
                  <DropdownMenu.SubTrigger
                    aria-pressed={selectedLocaleId === id}
                    onClick={() => handleLocaleSelect(id)}
                  >
                    {name}
                    <DropdownMenu.RightSlot>
                      <Icon icon={faCaretRight} />
                    </DropdownMenu.RightSlot>
                  </DropdownMenu.SubTrigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.SubContent>
                      <DropdownMenu.Label>{name}</DropdownMenu.Label>
                      <DropdownMenu.Item
                        onClick={() => onDownloadXliffClick(id)}
                      >
                        <Icon icon={faDownload} /> Download .xliff
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        onClick={() => onUploadTranslationClick(id)}
                      >
                        <Icon icon={faUpload} /> Upload translation file
                      </DropdownMenu.Item>

                      {aiTranslate && (
                        <DropdownMenu.Item
                          onClick={() => onAutoTranslateClick(id)}
                        >
                          <Icon icon={faWandSparkles} /> Auto-translate{' '}
                          <Badge variant="info">Appcues AI</Badge>
                          <AiPopover />
                        </DropdownMenu.Item>
                      )}

                      <DropdownMenu.Separator aria-label="language actions separator" />

                      <DropdownMenu.Item
                        onClick={() => onRemoveTranslation(id)}
                      >
                        <Icon icon={faTrashCan} /> Remove translation
                      </DropdownMenu.Item>
                    </DropdownMenu.SubContent>
                  </DropdownMenu.Portal>
                </DropdownMenu.Sub>
              ))}
            </DropdownMenu.ScrollableList>

            <DropdownMenu.Separator aria-label="languages separator" />

            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>
                <Icon icon={faCirclePlus} /> Add translation
                <DropdownMenu.RightSlot>
                  <Icon icon={faCaretRight} />
                </DropdownMenu.RightSlot>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.Portal>
                <DropdownMenu.SubContent>
                  {localesOptions.length > 0 && (
                    <>
                      <DropdownMenu.Label>Add translation</DropdownMenu.Label>
                      <DropdownMenu.ScrollableList>
                        {localesOptions.map(({ id, name }) => (
                          <DropdownMenu.Item
                            key={id}
                            onClick={() => handleLocaleSelect(id, true)}
                          >
                            {name}
                          </DropdownMenu.Item>
                        ))}
                      </DropdownMenu.ScrollableList>

                      <DropdownMenu.Separator aria-label="add translation separator" />

                      <DropdownMenu.Item onClick={onAddAllTranslationsClick}>
                        <Icon icon={faGrid2Plus} /> Add all
                      </DropdownMenu.Item>
                    </>
                  )}
                  <DropdownMenu.Item onClick={onLocalizationSettingsClick}>
                    <Icon icon={faLanguage} /> Localization settings
                  </DropdownMenu.Item>
                </DropdownMenu.SubContent>
              </DropdownMenu.Portal>
            </DropdownMenu.Sub>

            <DropdownMenu.Item
              disabled={!hasActiveLocales}
              onClick={() =>
                hasActiveLocales ? onRemoveTranslation('all') : null
              }
            >
              <Icon icon={faTrashCan} /> Remove all translations
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      <LocalesModal
        locales={sortedLocales}
        open={openLocalesModal}
        onClose={toggleOpenLocalesModal}
      />

      <ErrorModal
        title="Error processing file"
        description="There was an error processing your file, check the error description for more information:"
        error={uploadError}
        setError={setUploadError}
      />

      <InputFile
        ref={$inputRef}
        type="file"
        accept=".xliff"
        onChange={onFileRead}
      />

      {status === LOADING && (
        <Portal>
          <Overlay>
            <Loading>
              <Spinner />
            </Loading>
          </Overlay>
        </Portal>
      )}

      {status === ERROR && errorOverlay && (
        <Portal>
          <Overlay>
            <Translating>
              An error happened with the auto-translation.
            </Translating>
            <ButtonsGroup>
              <Button
                onClick={() => onAutoTranslateClick(autoTranslateLocaleId)}
              >
                Try again
              </Button>
              <Button
                variant="secondary"
                onClick={() => toggleErrorOverlay(false)}
              >
                Close
              </Button>
            </ButtonsGroup>
          </Overlay>
        </Portal>
      )}
    </>
  );
};

LocalesDropdown.propTypes = {
  experienceId: PropTypes.string,
  canonicalExperience: ExperienceShape,
  content: ContentShape,
  selectedLocale: LocaleShape,
  locales: PropTypes.objectOf(LocalesShape),
  status: PropTypes.oneOf([LOADING, LOADED, ERROR]),
  onLocaleChange: PropTypes.func,
  onTranslationUpdate: PropTypes.func,
  onAutoTranslate: PropTypes.func,
};

const mapStateToProps = state => {
  const { id: experienceId } = selectExperience(state) ?? {};
  const canonicalExperience = selectCanonicalExperience(state) ?? {};
  const { stepChild: stepId } = selectSelected(state) ?? {};
  const { content } = selectStepChild(state, stepId) ?? {};
  const locales = selectLocales(state) ?? {};

  return {
    experienceId,
    canonicalExperience,
    content,
    locales,
    status: selectStatusByEntity(state, 'experience'),
  };
};

const mapDispatchToProps = {
  onTranslationUpdate: updateTranslation,
  onAutoTranslate: autoTranslate,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalesDropdown);
