import React from 'react';
import styled from 'styled-components';

export const Actions = styled.section.attrs({
  role: 'toolbar',
})`
  z-index: 3;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  min-height: 42px;
  padding: 6px 24px;
`;

export const Label = styled.div`
  text-align: center;
`;

export const ScreenCaptureIcon = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 0h-7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm.5 14a.5.5 0 0 1-.5.5h-7A.501.501 0 0 1 4 14V2a.5.5 0 0 1 .5-.5h7c.275 0 .5.224.5.5v12Zm-3-1.5H7a.5.5 0 0 0 0 1h2a.5.5 0 1 0 0-1Z"
      fill="#5C5CFF"
    />
    <path
      d="M8.709 6.927c-.14 0-.27.07-.348.186l-1.185 1.78-.35-.524a.417.417 0 0 0-.695 0L4.736 10.46a.418.418 0 0 0-.02.43.405.405 0 0 0 .34.22h5.858a.418.418 0 0 0 .348-.65L9.031 7.113c-.05-.117-.182-.186-.322-.186Zm-2.61-.12a.956.956 0 1 0 0-1.913.956.956 0 0 0 0 1.913Z"
      fill="#5C5CFF"
    />
  </svg>
);
