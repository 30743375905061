import React from 'react';
import styled from 'styled-components';
import PropTypes, { string } from 'prop-types';

const FontGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FontGroupLabel = styled.span``;

const FontGroupCount = styled.span`
  background-color: var(--noreaster);
  color: var(--sharkbait-ooh-la-la);
  display: inline-block;
  font-size: var(--x-small);
  font-weight: var(--semi-bold);
  text-align: center;
  padding: 0.17em 0.5em;
  border-radius: 2em;
`;

const FontGroupOptions = ({ label, options }) => (
  <FontGroup>
    <FontGroupLabel>{label}</FontGroupLabel>
    <FontGroupCount>{options.length}</FontGroupCount>
  </FontGroup>
);

FontGroupOptions.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(string),
};

export default FontGroupOptions;
