import { v4 as uuid } from 'uuid';
import {
  BLOCK,
  OPTION_SELECT,
  TEXT,
  RATING,
  RATING_NUMBER,
  RATING_EMOJI,
  BLOCK_MODES,
} from 'lib/block';

const [SINGLE] = BLOCK_MODES[OPTION_SELECT];
const [HORIZONTAL] = BLOCK_MODES[RATING_NUMBER];

const ratingEmojiTemplate = ({ blockId, elementId }) => ({
  type: BLOCK,
  blockType: RATING,
  id: blockId,
  content: {
    type: OPTION_SELECT,
    ratingType: RATING_EMOJI,
    id: elementId,
    label: {
      type: TEXT,
      id: uuid(),
      text: 'How satisfied are you with our app?',
      style: {
        fontName: 'System Default Bold',
        fontSize: 17,
        foregroundColor: { light: '#425678' },
        marginBottom: 12,
        horizontalAlignment: 'leading',
        textAlignment: 'leading',
      },
    },
    selectMode: SINGLE,
    options: [
      {
        content: {
          id: uuid(),
          style: {
            fontName: 'System Default Regular',
            width: 48,
            height: 48,
            fontSize: 30,
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 8,
            foregroundColor: {
              light: '#627293',
            },
          },
          text: '😦',
          type: 'text',
        },
        selectedContent: {
          id: uuid(),
          style: {
            fontName: 'System Default Regular',
            width: 48,
            height: 48,
            fontSize: 30,
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 8,
            foregroundColor: {
              light: '#627293',
            },
            backgroundColor: {
              light: '#000000',
            },
          },
          text: '😦',
          type: 'text',
        },
        value: '😦',
      },
      {
        content: {
          id: uuid(),
          style: {
            fontName: 'System Default Regular',
            width: 48,
            height: 48,
            fontSize: 30,
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 8,
            foregroundColor: {
              light: '#627293',
            },
          },
          text: '🙁',
          type: 'text',
        },
        selectedContent: {
          id: uuid(),
          style: {
            fontName: 'System Default Regular',
            width: 48,
            height: 48,
            fontSize: 30,
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 8,
            foregroundColor: {
              light: '#627293',
            },
            backgroundColor: {
              light: '#000000',
            },
          },
          text: '🙁',
          type: 'text',
        },
        value: '🙁',
      },
      {
        content: {
          id: uuid(),
          style: {
            fontName: 'System Default Regular',
            width: 48,
            height: 48,
            fontSize: 30,
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 8,
            foregroundColor: {
              light: '#627293',
            },
          },
          text: '😐',
          type: 'text',
        },
        selectedContent: {
          id: uuid(),
          style: {
            fontName: 'System Default Regular',
            width: 48,
            height: 48,
            fontSize: 30,
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 8,
            foregroundColor: {
              light: '#627293',
            },
            backgroundColor: {
              light: '#000000',
            },
          },
          text: '😐',
          type: 'text',
        },
        value: '😐',
      },
      {
        content: {
          id: uuid(),
          style: {
            fontName: 'System Default Regular',
            width: 48,
            height: 48,
            fontSize: 30,
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 8,
            foregroundColor: {
              light: '#627293',
            },
          },
          text: '🙂',
          type: 'text',
        },
        selectedContent: {
          id: uuid(),
          style: {
            fontName: 'System Default Regular',
            width: 48,
            height: 48,
            fontSize: 30,
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 8,
            foregroundColor: {
              light: '#627293',
            },
            backgroundColor: {
              light: '#000000',
            },
          },
          text: '🙂',
          type: 'text',
        },
        value: '🙂',
      },
      {
        content: {
          id: uuid(),
          style: {
            fontName: 'System Default Regular',
            width: 48,
            height: 48,
            fontSize: 30,
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 8,
            foregroundColor: {
              light: '#627293',
            },
          },
          text: '😀',
          type: 'text',
        },
        selectedContent: {
          id: uuid(),
          style: {
            fontName: 'System Default Regular',
            width: 48,
            height: 48,
            fontSize: 30,
            marginTrailing: 4,
            marginLeading: 4,
            cornerRadius: 8,
            foregroundColor: {
              light: '#627293',
            },
            backgroundColor: {
              light: '#000000',
            },
          },
          text: '😀',
          type: 'text',
        },
        value: '😀',
      },
    ],
    controlPosition: 'hidden',
    displayFormat: HORIZONTAL,
    style: {
      horizontalAlignment: 'center',
      marginTop: 12,
      marginBottom: 12,
      marginLeading: 24,
      marginTrailing: 24,
      width: -1,
    },
    errorLabel: {
      type: TEXT,
      id: uuid(),
      text: 'Required field',
      style: {
        foregroundColor: { light: '#D6216B' },
        horizontalAlignment: 'leading',
        textAlignment: 'leading',
        fontSize: 14,
        marginTop: 10,
      },
    },
  },
});

export default ratingEmojiTemplate;
