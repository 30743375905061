import React from 'react';
import PropTypes from 'prop-types';
import { PlatformIcon } from 'ext/components/ui';
import { Shape as ExperienceShape } from 'entities/experiences';
import { truncateMiddle } from 'utils/strings-handler';
import {
  CurrentBadge,
  FlowName,
  ListItemWrapper,
  StateIndicator,
} from './styled';

const FALLBACK_FLOW_NAME = 'Untitled Flow';

const ListItem = ({ flow, isSelected, onClick }) => {
  const { id, name, platform, published, state } = flow;

  const handleSelect = () => {
    if (!isSelected) {
      onClick(id);
    }
  };

  return (
    <ListItemWrapper
      isSelected={isSelected}
      onClick={handleSelect}
      role="listitem"
    >
      {platform && <PlatformIcon type={platform} />}
      <FlowName title={name}>
        {truncateMiddle(name, 30) || FALLBACK_FLOW_NAME}
      </FlowName>
      <StateIndicator aria-label={state} published={published} />
      {isSelected && <CurrentBadge aria-label="current">Current</CurrentBadge>}
    </ListItemWrapper>
  );
};

ListItem.propTypes = {
  flow: ExperienceShape,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ListItem;
