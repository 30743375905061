import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Accordion, FieldSet } from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import { selectUserPreferences } from 'entities/user-preferences';
import { THEMES } from 'lib/user-preferences';
import { HERO, BLOCK_LABELS } from 'lib/block';
import ImageUploader from 'components/ImageUploader';
import { heroBackgroundTemplate } from 'components/Editor/Templates/Content/hero';
import { Content, Controls } from 'components/SideBarSettings/Shared';
import ContentActions from 'components/SideBarSettings/Content/ContentActions';
import Style from './Style';
import AlignmentSpacing from './AlignmentSpacing';

export function HeroSettings({ content, onChange, theme }) {
  const { id, style } = content ?? {};
  const { imageUrl } = style?.backgroundImage ?? {};

  const handleContentChange = updatedContent => {
    onChange({
      blockId: id,
      contentChunk: updatedContent,
      useOriginalId: true,
    });
  };

  const handleBackgroundImage = useCallback(
    params => {
      const { url, intrinsicSize } = params ?? {};

      if (!params) {
        const { backgroundImage, ...styleRest } = style;
        onChange({
          blockId: id,
          contentChunk: {
            style: styleRest,
          },
          useOriginalId: true,
        });
        return;
      }

      onChange({
        blockId: id,
        contentChunk: {
          style: {
            ...style,
            backgroundImage: {
              ...(style?.backgroundImage ??
                heroBackgroundTemplate.backgroundImage),
              imageUrl: url,
              blurHash: '',
              intrinsicSize,
            },
          },
        },
        useOriginalId: true,
      });
    },
    [id, style, onChange]
  );

  return (
    <Content>
      <Controls>
        <FieldSet>
          <ImageUploader
            currentImage={imageUrl}
            onUpload={handleBackgroundImage}
          />
        </FieldSet>
      </Controls>

      <Accordion.Root
        collapsible="true"
        defaultValue={['style', 'alignment-spacing']}
        type="multiple"
      >
        <Style content={content} onChange={onChange} theme={theme} />

        <AlignmentSpacing
          style={style}
          spacingLabel={BLOCK_LABELS[HERO]}
          onChange={handleContentChange}
        />
        <ContentActions blockId={id} context={HERO} />
      </Accordion.Root>
    </Content>
  );
}

HeroSettings.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
};

const mapStateToProps = state => ({
  theme: selectUserPreferences(state).theme,
});

export default connect(mapStateToProps)(HeroSettings);
