import {
  continueType,
  closeType,
  launchExperienceType,
  linkType,
  trackEventType,
  userPropertiesType,
  submitFormType,
} from 'lib/actions';
import { ACTION_TYPES } from './constants';

const handleContinueType = config => {
  let type = ACTION_TYPES.NEXT_STEP;

  if (config?.offset) {
    type = ACTION_TYPES.PREVIOUS_STEP;
  }

  if (config?.stepID) {
    type = ACTION_TYPES.CUSTOM_STEP;
  }

  return {
    type,
    ...(config ? { config } : {}),
  };
};

const handleLinkType = (config, baseConfig) => {
  if (baseConfig.type === launchExperienceType) {
    return {
      type: ACTION_TYPES.TRIGGER_FLOW,
      config: {
        experienceID: baseConfig.config.experienceID,
        url: config.url,
      },
    };
  }

  if (baseConfig.type === closeType) {
    return {
      type: ACTION_TYPES.URL,
      config: {
        ...config,
        markComplete: true,
      },
    };
  }

  return {
    type: ACTION_TYPES.URL,
    config,
  };
};

const handleCloseType = (config, baseConfig) => {
  // if the action has link already, the dismiss is done with markComplete
  return baseConfig.type === linkType ||
    baseConfig.type === launchExperienceType
    ? {
        type: baseConfig.type,
        config: {
          ...baseConfig.config,
          markComplete: true,
        },
      }
    : {
        type: ACTION_TYPES.DISMISS_FLOW,
        config,
      };
};

const handleLaunchExperienceType = (config, baseConfig) => {
  // if the action has link already, we pass the url down
  return baseConfig.type === linkType
    ? {
        type: ACTION_TYPES.TRIGGER_FLOW,
        config: {
          experienceID: config.experienceID,
          url: baseConfig.config.url,
        },
      }
    : {
        type: ACTION_TYPES.TRIGGER_FLOW,
        config,
      };
};

const handleTrackEventType = (config, baseConfig) => {
  return {
    ...baseConfig,
    config: {
      ...baseConfig.config,
      eventName: config.eventName,
    },
  };
};

const handleUserPropertiesType = (config, baseConfig) => {
  return {
    ...baseConfig,
    config: {
      ...baseConfig.config,
      userProperties: config,
    },
  };
};

const handleSubmitFormType = (config, baseConfig) => {
  return {
    ...baseConfig,
    config: {
      ...baseConfig.config,
      skipValidation: config?.skipValidation,
    },
  };
};

const actionsKeys = {
  [continueType]: handleContinueType,
  [closeType]: handleCloseType,
  [linkType]: handleLinkType,
  [launchExperienceType]: handleLaunchExperienceType,
  [trackEventType]: handleTrackEventType,
  [userPropertiesType]: handleUserPropertiesType,
  [submitFormType]: handleSubmitFormType,
};

/**
 * Get the action type and its properties
 * @param {array} actions - Actions array containing all the set actions
 * @returns {object} Action type and properties grouped in config object
 */
const getActionTypeConfig = (actions = []) => {
  // In order to not overwrite the main actions,
  // we order events/userProperties actions to the end,
  // so we can add them to the main actions
  const actionsOrderedByActionsKeys = [...actions].sort(({ type }) =>
    type === trackEventType || type === userPropertiesType ? 1 : -1
  );

  return actionsOrderedByActionsKeys?.reduce(
    (baseConfig, { type, config }) =>
      actionsKeys[type] ? actionsKeys[type](config, baseConfig) : baseConfig,
    {}
  );
};

export default getActionTypeConfig;
