import { useCallback, useLayoutEffect, useState } from 'react';
import debounce from 'lodash.debounce';

const initial = {
  overflowing: false,
  scroll: { start: false, end: false },
};

/**
 * Check if connected target element is overflowing horizontally
 *
 * @param {React.RefObject} ref - Target ref
 * @return {boolean} result.overflowing - Whether target is overflowing
 * @return {boolean} result.start - Whether scroll is at the very beginning
 * @return {boolean} result.end - Whether scroll is at the very end
 */
export default ref => {
  const [{ overflowing, scroll }, setState] = useState(initial);

  const check = useCallback(() => {
    const { current: $target = {} } = ref;
    const { offsetWidth, scrollLeft, scrollWidth } = $target;
    setState({
      overflowing: offsetWidth < scrollWidth,
      scroll: {
        start: scrollLeft <= 0,
        end: scrollLeft >= scrollWidth - offsetWidth,
      },
    });
  }, [ref]);

  useLayoutEffect(() => {
    const { current: $target } = ref;
    const handler = debounce(check, 500);

    check();

    window.addEventListener('resize', handler);
    if ($target) {
      $target.addEventListener('scroll', handler);
    }

    return () => {
      window.removeEventListener('resize', handler);
      if ($target) {
        $target.removeEventListener('scroll', handler);
      }
    };
  }, [check, ref]);

  return [overflowing, scroll];
};
