import collect from 'lib/path-builder';

/**
 * Get content node item
 *
 * @param {object} content - root content of an step children
 * @param {string} nodeId - nodeId (can be a stack or block)
 * @return {array<string>} Node path array
 */
export const getNodePath = (content, nodeId) => {
  const nodes = collect(content);
  return nodes?.[nodeId];
};
