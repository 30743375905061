import move from 'ext/lib/move';
import { createPatternCreator } from './create-lifecycle';
import { CHILD_MOVED } from './action-types';

export const createMoveActionsFor = entityType => {
  const createPatternFor = createPatternCreator(entityType);

  return {
    /**
     * Move child of an entity/collection
     *
     * @param {string} child - child id
     * @param {object} payload - additional data
     * @param {number} payload.from - source index of child
     * @param {number} payload.to - target index for child
     * @param {string} [payload.parent] - optional parent id for collections
     */
    move: (child, { parent, from, to, targetStepId }) => ({
      type: CHILD_MOVED,
      payload: {
        child,
        parent,
        from,
        to,
        targetStepId,
        action: to > from ? 'after' : 'before',
      },
      meta: { type: entityType },
    }),

    /**
     * Filter functions to match action and entity type
     */
    patterns: {
      move: createPatternFor(CHILD_MOVED),
    },
  };
};

/**
 * Higher-order reducer that updates the order of an entity's/collection's
 * children while delegating all other changes to provided reducer. Works for
 * both single entities and collections based on whether the `parent` value is
 * provided by the action.
 *
 * @param {string} field - field on entity that points to the list of children
 * @param {string} entityType - entity/collection type
 * @return {Function} Reducer decorator to delegate normal operations to
 */
export const moveChildren = (field, entityType) => {
  return (state, action) => {
    const { type, payload, meta = {} } = action;

    if (meta.type === entityType && type === CHILD_MOVED) {
      const { parent, from, to } = payload;

      if (parent) {
        const entity = state[parent];

        if (entity && entity[field]) {
          return {
            ...state,
            [parent]: {
              ...entity,
              [field]: move(entity[field], from, to),
            },
          };
        }

        return state;
      }

      return state[field]
        ? { ...state, [field]: move(state[field], from, to) }
        : state;
    }

    return state;
  };
};
