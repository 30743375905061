import PropTypes from 'prop-types';

/**
 * Screen status types
 *
 * @constant
 */
export const SCREEN_STATE = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export const ScreenInsetsShape = PropTypes.shape({
  top: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  platform: PropTypes.string,
});

export const ScreenLayoutShape = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
});

const ScreenShape = PropTypes.shape({
  id: PropTypes.string,
  displayName: PropTypes.string,
  screenshotImageUrl: PropTypes.string,
  state: PropTypes.oneOf(Object.values(SCREEN_STATE)),
  layout: ScreenLayoutShape,
  metadata: PropTypes.shape({
    appBuild: PropTypes.string,
    appName: PropTypes.string,
    appVersion: PropTypes.string,
    bundlePackageId: PropTypes.string,
    deviceHeight: PropTypes.number,
    deviceModel: PropTypes.string,
    deviceOrientation: PropTypes.string,
    deviceType: PropTypes.string,
    deviceWidth: PropTypes.number,
    insets: ScreenInsetsShape,
    osName: PropTypes.string,
    osVersion: PropTypes.string,
    sdkName: PropTypes.string,
    sdkVersion: PropTypes.string,
  }),
  presignedScreenshotImageUrl: PropTypes.shape({
    presignedUrl: PropTypes.string,
    expiredAt: PropTypes.string,
  }),
});

export default ScreenShape;

export const ScreensListShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.shape(ScreenShape),
});
