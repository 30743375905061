import { css } from 'styled-components';
import { STANDARD } from 'lib/presentation';
import { GAPS } from './variables';

/**
 * Handle with standard styles such as max sizes and position
 * @param {string} orientation - Device orientation (portrait or landscape)
 * @param {string} verticalAlignment - Vertical alignment (top, center or bottom)
 * @returns {css} - Returns the css template literal with the styles
 */
export const handleStandardStyles = ({ orientation, verticalAlignment }) => css`
  max-height: calc(100% - ${GAPS[STANDARD][orientation].vertical} * 2);
  max-width: calc(100% - ${GAPS[STANDARD][orientation].horizontal} * 2);

  ${verticalAlignment === 'top' &&
  css`
    align-self: flex-start;
    margin-top: ${GAPS[STANDARD][orientation].relative};
  `}

  ${verticalAlignment === 'bottom' &&
  css`
    align-self: flex-end;
    margin-bottom: ${GAPS[STANDARD][orientation].relative};
  `}
`;
