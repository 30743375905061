import { createLifecycleFor } from 'ext/lib/collections';

export const TYPE = 'locales';

export const {
  reject,
  resolve,
  send,
  create,
  append,
  flush,
  prune,
  remove,
  update,
  patterns,
} = createLifecycleFor(TYPE);

export const UPDATE_TRANSLATION = '@locales/UPDATE_TRANSLATION';
export const updateTranslation = payload => ({
  type: UPDATE_TRANSLATION,
  payload,
});

export const AUTO_TRANSLATE = '@locales/AUTO_TRANSLATE';
export const autoTranslate = payload => ({
  type: AUTO_TRANSLATE,
  payload,
});
