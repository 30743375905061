import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  FieldSet,
  FontIcon,
  DebouncedInput,
  Label,
} from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import { Controls, useAccordionClick } from 'components/SideBarSettings/Shared';

export default function Accessibility({ content, onChange }) {
  const trackAccordion = useAccordionClick();

  const { id, accessibilityLabel } = content ?? {};

  const handleChange = useCallback(
    ({ target: { value } }) => {
      onChange({ blockId: id, contentChunk: { accessibilityLabel: value } });
    },
    [id, onChange]
  );

  return (
    <Accordion.Item value="accessibility">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion('Image', 'Accessibility')}
        >
          Accessibility
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Label htmlFor="alt-text">Alt text</Label>
            <DebouncedInput
              id="alt-text"
              defaultValue={accessibilityLabel || ''}
              onChange={handleChange}
              placeholder="Optional"
              type="text"
            />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Accessibility.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
};
