/**
 * Return the target area shape, i.e. origin and center points, width and height.
 *
 * @param {String} shape - shape of the targetArea (circle or rectangle)
 * @param {Object} targetArea - target area properties
 * @param {Number} spreadRadius - it works as padding for the shape
 * @param {Object} deviceSize - width and height of the device
 * @param {Object} insets - safe areas for the device
 * @returns {Object} shape of target area
 */
export const handleTargetAreaShape = ({
  shape,
  targetArea,
  spreadRadius = 0,
  deviceSize,
  insets,
  isAnchored = false,
}) => {
  const {
    height = 0,
    width = 0,
    relativeX = 0,
    relativeY = 0,
    x = 0,
    y = 0,
  } = targetArea?.config || {};

  // We need to account top/bottom insets to calculate the correct tooltip position
  // Anchored tooltip configs already account for the insets
  const additionalTop = isAnchored ? 0 : insets?.top ?? 0;
  const additionalBottom = isAnchored ? 0 : insets?.bottom ?? 0;

  const { width: deviceWidth, height: deviceHeight } = deviceSize;

  let xOrigin = x + deviceWidth * relativeX;
  let yOrigin =
    additionalTop +
    y +
    (deviceHeight - additionalTop - additionalBottom) * relativeY;

  let xCenter = xOrigin + width / 2;
  let yCenter = yOrigin + height / 2;

  // When the shape is rectangle, we need to adjust
  // the origin and center points to account for the spread radius.
  if (shape === 'rectangle') {
    xOrigin -= spreadRadius;
    yOrigin -= spreadRadius;
    xCenter += spreadRadius * 2;
    yCenter += spreadRadius * 2;
  }

  const targetAreaShape = {
    xOrigin,
    yOrigin,
    xCenter,
    yCenter,
    width: width + spreadRadius * 2,
    height: height + spreadRadius * 2,
  };

  return targetAreaShape;
};

/**
 * Return the crosshair position inside the backdrop keyhole
 *
 * @param {String} shape - shape of the targetArea (circle or rectangle)
 * @param {Boolean} isAnchored - defines if the tooltip is floating or anchored
 * @param {Boolean} hasScreen - defines if it has a valid screen
 * @param {Boolean} hasSelector - defines if the selector has been defined
 * @param {Object} deviceSize - width and height of the device
 * @param {Number} xCenter - horizontal center of the shape
 * @param {Number} yCenter - vertical center of the shape
 * @param {Number} spreadRadius - it works as padding for the shape
 * @returns {Object} - top and left position for the crosshair icon
 */
export const targetAreaCrosshairPosition = ({
  shape,
  isAnchored,
  hasScreen,
  hasSelector,
  deviceSize,
  xCenter,
  yCenter,
  spreadRadius,
}) => ({
  top:
    isAnchored && hasScreen && !hasSelector
      ? deviceSize.height / 2
      : yCenter - (shape === 'rectangle' ? spreadRadius * 2 : 0),
  left:
    isAnchored && hasScreen && !hasSelector
      ? deviceSize.width / 2
      : xCenter - (shape === 'rectangle' ? spreadRadius * 2 : 0),
});
