/**
 * Platform types
 *
 * @constant
 */
export const ANDROID = 'android';
export const IOS = 'ios';
export const REACT_NATIVE = 'react-native';
export const XAMARIN = 'xamarin';
export const FLUTTER = 'flutter';
export const IONIC = 'ionic';

/**
 * Platform options
 *
 * @constant
 */
export const PLATFORMS = [ANDROID, IOS, REACT_NATIVE, XAMARIN, FLUTTER, IONIC];

/**
 * Platforms that are allowed to use ANCHORED presentation
 *
 * @constant
 */
export const ALLOWED_ANCHORED = [ANDROID, IOS, REACT_NATIVE, FLUTTER];
