/* global REACT_DEVTOOLS, LAUNCHDARKLY_CLIENT_ID */

import React from 'react';
import { render } from 'react-dom';
import history from 'history/browser';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import PreviewProxy from 'components/PreviewProxy';
import PreviewExperience from 'components/PreviewExperience';
import App from 'components/App';
import { Root, initialize } from 'root';

if (REACT_DEVTOOLS) {
  // eslint-disable-next-line global-require
  require('react-devtools');
}

// eslint-disable-next-line unicorn/prefer-top-level-await
(async () => {
  // Root element to mount the builder
  const $root = document.querySelector('#root');

  // Short-circuit if preview proxy detected
  const { pathname, hash, search } = history.location;
  const searchHash = hash.replace('#', '');
  const params = Object.fromEntries(new URLSearchParams(search || searchHash));

  // If it's a route to the image-service
  // we render an specific page without authentication
  // to render only the experience preview
  if (pathname.includes('image-service') || hash.includes('image-service')) {
    render(<PreviewExperience />, $root);
    return;
  }

  // If the url has type param
  // we should render the PreviewProxy which is a non-authenticated page
  if (params?.type || params?.democues) {
    render(<PreviewProxy params={params} />, $root);
    return;
  }

  // Authenticate and get user initial data
  const { auth, user, account } = await initialize();

  // Initialize LaunchDarkly provider
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCHDARKLY_CLIENT_ID,
    context: {
      kind: 'user',
      key: auth.accountId,
      tier: account.tier,
      createdAt: account.createdAt,
    },
  });

  // Render application
  render(
    <LDProvider>
      <Root auth={auth} user={user}>
        <App />
      </Root>
    </LDProvider>,
    $root
  );
})();
