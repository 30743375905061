import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentMenuButton as StyledContentMenuButton,
  ContentMenuButtonElement,
} from './styled';

const ContentMenuButtonIcon = ({ icon }) => (
  <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    {icon}
  </svg>
);

ContentMenuButtonIcon.propTypes = {
  icon: PropTypes.node,
};

const ContentMenuButton = ({ element, text, icon, onClick }) => (
  <StyledContentMenuButton onClick={() => onClick(element)}>
    <ContentMenuButtonElement>
      <ContentMenuButtonIcon icon={icon} />
      {text}
    </ContentMenuButtonElement>
  </StyledContentMenuButton>
);

ContentMenuButton.propTypes = {
  element: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
};

export default ContentMenuButton;
