import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { INITIALIZE } from 'ext/root/root-actions';

import { send, reject, resolve } from './summary-actions';

const VALID_STATES = new Set(['DRAFT', 'PUBLISHED']);

const parse = response =>
  response.reduce((acc, { experience }) => {
    const { id, state } = experience;

    if (id && VALID_STATES.has(state)) {
      acc[id] = experience;
    }

    return acc;
  }, {});

function* fetchExperiences() {
  const logger = yield getContext('logger');
  try {
    const { getExperiencesSummary } = yield getContext('api');

    // start loading
    yield put(send());

    const response = yield call(getExperiencesSummary);
    yield put(resolve(parse(response)));
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
  }
}

export default function* experiencesSaga() {
  yield takeLatest(INITIALIZE, fetchExperiences);
}
