import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { INITIALIZE } from 'ext/root/root-actions';
import { reject, resolve } from './sdk-token-actions';

function* fetchSDKToken() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getSDKToken);
    yield put(resolve(response));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* sdkTokenSaga() {
  yield takeEvery(INITIALIZE, fetchSDKToken);
}
