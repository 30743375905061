import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Accordion, FieldSet, FontIcon, Label } from 'ext/components/ui';
import { EditorShape } from 'entities/step-groups';
import { THEMES } from 'lib/user-preferences';
import {
  STANDARD,
  SLIDEOUT,
  FULL_SCREEN,
  ANCHORED,
  FLOATING,
  EMBED_PRESENTATION,
} from 'lib/presentation';
import { TRAIT_LABELS } from 'lib/trait';
import ColorInput from 'components/ColorInput';
import InputWithValidation from 'components/InputWithValidation';
import useStyleSettings from './use-style-settings';
import useAccordionClick from './use-accordion-click';
import Background from './Background';
import HelpLabel from './HelpLabel';
import { Controls, GroupedField, GroupedFieldSet } from './styled';

const hasCustomizableBorder = presentation =>
  [STANDARD, SLIDEOUT, FLOATING, ANCHORED].includes(presentation) ||
  presentation?.includes(EMBED_PRESENTATION);

const hasCustomizableWidth = presentation =>
  [SLIDEOUT, FLOATING, ANCHORED].includes(presentation);

const handleHelpLabel = presentation =>
  [FLOATING, ANCHORED].includes(presentation) ? 'tooltip' : SLIDEOUT;

export default function Container({ editor, onChange, theme = 'light' }) {
  const trackAccordion = useAccordionClick();
  const { presentation, style = {}, type } = editor || {};
  const traitLabel = TRAIT_LABELS[type];

  const handleChange = useCallback(
    patch => {
      onChange({ style: patch });
    },
    [onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
    theme,
  });

  return (
    <Accordion.Item value="container">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion(traitLabel, 'Container')}
        >
          Container
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <Background
            editor={editor}
            valueFor={valueFor}
            handleChangeFor={handleChangeFor}
          />

          {hasCustomizableBorder(presentation) && (
            <GroupedFieldSet>
              <GroupedField>
                <Label htmlFor="trait-border-color">Border color</Label>
                <ColorInput
                  id="trait-border-color"
                  color={valueFor.borderColor}
                  onChange={handleChangeFor.borderColor}
                />
              </GroupedField>

              <GroupedField>
                <Label htmlFor="trait-thickness">Thickness</Label>
                <InputWithValidation
                  id="trait-thickness"
                  value={valueFor.borderWidth}
                  onChange={handleChangeFor.borderWidth}
                  type="number"
                  placeholder="0"
                  max="100"
                  min="0"
                />
              </GroupedField>
            </GroupedFieldSet>
          )}

          {presentation !== FULL_SCREEN && (
            <FieldSet>
              <InputWithValidation
                label="Corner radius"
                value={valueFor.cornerRadius}
                onChange={handleChangeFor.cornerRadius}
                max={100}
                min={0}
                range
              />
            </FieldSet>
          )}

          {hasCustomizableWidth(presentation) && (
            <FieldSet>
              <Label htmlFor="trait-width">
                Width
                <HelpLabel>
                  The {handleHelpLabel(presentation)} will display at this width{' '}
                  <br />
                  where possible, but may shrink if there is <br />
                  insufficient space.
                </HelpLabel>
              </Label>
              <InputWithValidation
                id="trait-width"
                value={valueFor.width}
                onChange={handleChangeFor.width}
                type="number"
                placeholder="0"
                min="48"
              />
            </FieldSet>
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Container.propTypes = {
  editor: EditorShape,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
};
