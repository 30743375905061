/**
 * Move element in a list and reorder
 *
 * @param {array} list - original list of elements
 * @param {number} from - element source index
 * @param {number} to - element destination index
 * @return {array} reordered list with moved element
 */
export default function move(list, from, to) {
  const result = [...list.slice(0, from), ...list.slice(from + 1)];
  result.splice(to, 0, list[from]);
  return result;
}
