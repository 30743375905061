import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Accordion, FieldSet, FontIcon } from 'ext/components/ui';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { Switch } from '@appcues/sonar';
import { EditorShape, pagingDotsStyleTemplate } from 'entities/step-groups';
import { SKIPPABLE_OPTIONS } from 'lib/trait';
import {
  Controls,
  HelpLabel,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';
import { SurveyCarouselWarning } from 'components/SideBarSettings/Trait/SurveyCarouselWarning';

const TRANSITION_OPTIONS = {
  FADE: 'fade',
  NONE: 'none',
};

export default function Details({
  editor,
  isSingleStep,
  hasSurveyBlock,
  onChange,
}) {
  const { track } = useAnalytics();
  const trackAccordion = useAccordionClick();
  const { carousel, skippable, pagingDots, transition } = editor ?? {};

  const handleTrack = useCallback(
    name => {
      track('Mobile Builder interaction', {
        name,
        component: 'EmbedSettings - Details',
      });
    },
    [track]
  );

  const handleSkippableUpdate = useCallback(
    value => {
      const { MINIMAL, HIDDEN } = SKIPPABLE_OPTIONS;
      const buttonAppearance = value ? MINIMAL : HIDDEN;

      handleTrack(`${value ? 'Added' : 'Removed'} Modal close`);
      onChange({ skippable: { ...skippable, buttonAppearance } });
    },
    [onChange, skippable, handleTrack]
  );

  // Manage fade or none as valid values for embed animation value
  const handleEmbedTransitionUpdate = useCallback(() => {
    const { FADE, NONE } = TRANSITION_OPTIONS;
    const updatedTransition = transition === FADE ? NONE : FADE;

    handleTrack(
      `${!transition === NONE ? 'Added' : 'Removed'} Embed animation`
    );
    onChange({
      transition: updatedTransition,
    });
  }, [onChange, transition, handleTrack]);

  // Manage style object when adding or remove the entire pagingDots trait otherwise
  const handlePagingDotsUpdate = useCallback(() => {
    handleTrack(`${!pagingDots ? 'Added' : 'Removed'} Step indicator`);

    if (pagingDots) {
      onChange(null, 'pagingDots');
    } else {
      onChange({
        pagingDots: pagingDotsStyleTemplate,
      });
    }
  }, [onChange, pagingDots, handleTrack]);

  const handleCarouselUpdate = useCallback(
    value => {
      handleTrack(`${value ? 'Added' : 'Removed'} Swipe for next step`);
      onChange({ carousel: value });
    },
    [onChange, handleTrack]
  );

  return (
    <Accordion.Item value="details">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Embed', 'Details')}>
          Details
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Switch
              id="close-x"
              checked={skippable?.buttonAppearance !== SKIPPABLE_OPTIONS.HIDDEN}
              onCheckedChange={handleSkippableUpdate}
              fullWidth
            >
              <Switch.Label htmlFor="close-x">Close X</Switch.Label>
            </Switch>
          </FieldSet>

          <FieldSet>
            <Switch
              id="animation"
              checked={transition === TRANSITION_OPTIONS.FADE}
              onCheckedChange={handleEmbedTransitionUpdate}
              fullWidth
            >
              <Switch.Label htmlFor="animation">
                Animation
                <HelpLabel>
                  The frame will fade in and out <br />
                  for a smoother experience.
                </HelpLabel>
              </Switch.Label>
            </Switch>
          </FieldSet>

          {!isSingleStep && (
            <>
              <FieldSet>
                <Switch
                  id="step-indicator"
                  checked={!!pagingDots}
                  onCheckedChange={handlePagingDotsUpdate}
                  fullWidth
                >
                  <Switch.Label htmlFor="step-indicator">
                    Step indicator
                  </Switch.Label>
                </Switch>
              </FieldSet>

              <FieldSet>
                <Switch
                  id="swipe"
                  checked={!!carousel}
                  onCheckedChange={handleCarouselUpdate}
                  fullWidth
                >
                  <Switch.Label htmlFor="swipe">
                    Swipe for next step
                  </Switch.Label>
                </Switch>
              </FieldSet>
              {hasSurveyBlock && !!carousel && <SurveyCarouselWarning />}
            </>
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Details.propTypes = {
  editor: EditorShape,
  isSingleStep: PropTypes.bool,
  hasSurveyBlock: PropTypes.bool,
  onChange: PropTypes.func,
};
