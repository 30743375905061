import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch } from '@appcues/sonar';
import { isValidInAppUrl } from 'ext/lib/url';
import { FieldSet, Select } from 'ext/components/ui';
import {
  selectExperience,
  selectExperiencesSummary,
  Shape as ExperienceShape,
} from 'entities/experiences';
import Radio from 'components/Radio';
import { getClose, getLaunchExperience, getLink } from 'lib/actions';
import { InputUrl } from 'components/SideBarSettings/Shared';

const Dot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ published }) =>
    published ? 'var(--color-green-300)' : 'var(--color-neutral-300)'};
`;

export function TriggerFlowOption({
  flows = {},
  appId = '',
  config,
  onChange,
}) {
  const { experienceID, url, markComplete = false } = config || {};
  const [currentUrl, setCurrentUrl] = useState('');
  const [isInAppValidUrl, setIsInAppValidUrl] = useState(true);
  const [anotherScreen, setAnotherScreen] = useState(!!url || false);

  useEffect(() => {
    setCurrentUrl(url);
    setIsInAppValidUrl(isValidInAppUrl(url));
    setAnotherScreen(!!url);
  }, [url]);

  const flowsList = useMemo(() => {
    const appFlows = Object.values(flows).filter(flow => flow.appId === appId);

    return appFlows.map(({ id, name, published }) => ({
      value: id,
      label: name,
      icon: <Dot published={published} />,
    }));
  }, [flows, appId]);

  const handleTriggerFlowChange = ({ id, link, markCompleteValue }) => {
    const actions = [getLaunchExperience(id)];

    if (link) {
      actions.unshift(getLink({ url: link }));
    }

    // We always put the close at first position
    // so the SDK won't have issues with race conditions
    if (markCompleteValue) {
      actions.unshift(getClose({ markComplete: true }));
    }

    onChange({ actions });
  };

  const handleRadioChange = value => {
    if (!value) {
      handleTriggerFlowChange({
        id: experienceID,
        markCompleteValue: markComplete,
      });
      setCurrentUrl('');
    }

    setAnotherScreen(value);
  };

  const handleUrlChange = ({ target: { value } }) => {
    setCurrentUrl(value);

    const isValidInAppUrlLink = isValidInAppUrl(value);
    setIsInAppValidUrl(isValidInAppUrlLink);

    if (isValidInAppUrlLink) {
      handleTriggerFlowChange({
        id: experienceID,
        link: value,
        markCompleteValue: markComplete,
      });
    }
  };

  const handleSelectChange = ({ value }) => {
    if (value !== experienceID) {
      handleTriggerFlowChange({
        id: value,
        link: url,
        markCompleteValue: markComplete,
      });
    }
  };

  const handleMarkCompleteChange = checked => {
    handleTriggerFlowChange({
      id: experienceID,
      link: url,
      markCompleteValue: checked,
    });
  };

  return (
    <>
      <FieldSet>
        <Select
          id="trigger-flow-select"
          options={flowsList}
          value={flowsList.filter(({ value }) => value === experienceID)}
          onChange={handleSelectChange}
          placeholder="Select Flow"
          portal
        />
      </FieldSet>

      {experienceID && (
        <>
          <FieldSet>
            <Radio
              name="On this screen"
              checked={!anotherScreen}
              onChange={() => handleRadioChange(false)}
            >
              On this screen
            </Radio>
            <Radio
              name="On another screen"
              checked={anotherScreen}
              onChange={() => handleRadioChange(true)}
            >
              On another screen
            </Radio>
          </FieldSet>

          {anotherScreen && (
            <InputUrl
              url={currentUrl}
              onChange={handleUrlChange}
              isValidUrl={isInAppValidUrl}
            />
          )}
        </>
      )}

      <FieldSet>
        <Switch
          id="mark-complete"
          checked={markComplete}
          onCheckedChange={handleMarkCompleteChange}
          fullWidth
        >
          <Switch.Label htmlFor="mark-complete">
            Mark flow complete
          </Switch.Label>
        </Switch>
      </FieldSet>
    </>
  );
}

TriggerFlowOption.propTypes = {
  flows: ExperienceShape,
  appId: PropTypes.string,
  config: PropTypes.shape({
    experienceID: PropTypes.string,
    url: PropTypes.string,
    markComplete: PropTypes.bool,
  }),
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
  flows: selectExperiencesSummary(state),
  appId: selectExperience(state)?.appId,
});

export default connect(mapStateToProps, null)(TriggerFlowOption);
