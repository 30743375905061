import { v4 as uuid } from 'uuid';
import { getContinue } from 'lib/actions';
import { TEXT, IMAGE, BUTTON, STACK } from 'lib/block';
import generateElementTemplate from 'components/Editor/Templates/generate-element-template';

/**
 * Create canned slideout step
 *
 * @param {string} previewStepId - id to preview the step template before creation
 * @return {Step} Canned step group
 */
export const generateSlideoutStep = ({ previewStepId }) => {
  const buttonElementId = uuid();

  return {
    ...(previewStepId && { id: previewStepId }),
    type: 'modal',
    contentType: 'application/json',
    content: {
      type: STACK,
      orientation: 'vertical',
      id: uuid(),
      style: {},
      items: [
        generateElementTemplate({
          template: IMAGE,
          blockId: uuid(),
          elementId: uuid(),
          newRow: true,
        }),
        generateElementTemplate({
          template: TEXT,
          blockId: uuid(),
          elementId: uuid(),
          newRow: true,
          content: {
            text: 'Take a few moments to learn how to best use our features.',
            style: {
              marginLeading: 8,
              marginTrailing: 8,
            },
          },
        }),
        generateElementTemplate({
          template: BUTTON,
          blockId: uuid(),
          elementId: buttonElementId,
          newRow: true,
          style: {
            marginLeading: 8,
            marginTrailing: 8,
            marginBottom: 8,
            width: -1,
          },
        }),
      ],
    },
    actions: {
      [buttonElementId]: [getContinue()],
    },
    traits: [],
  };
};
