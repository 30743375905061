import styled from 'styled-components';
import { Button as SharedButton } from 'ext/components/ui';

export const BlockSchemeWrapper = styled.div`
  position: absolute;
  bottom: 2%;
  right: 7%;
`;

export const BlockCodeWrapper = styled.div`
  max-height: 65vh;
  overflow: auto;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--background-dark);
`;

export const BlockCode = styled.code`
  color: var(--text-color);
  font-size: var(--x-small);
  white-space: pre;
`;

export const Button = styled(SharedButton)`
  height: 40px;
  width: 40px;
  font-size: 16px;
  border-radius: 50%;
`;
