export const TYPE = 'userPreferences';

export const USER_PREFERENCES_UPDATED = 'USER_PREFERENCES_UPDATED';
export const updateUserPreferences = (preference, value) => ({
  type: USER_PREFERENCES_UPDATED,
  payload: {
    preference,
    value,
  },
});
