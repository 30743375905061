import {
  composeReducers,
  createCollectionReducer,
  resolveSiblings,
  moveChildren,
  hasMany,
} from 'ext/lib/collections';

const TYPE = 'stepGroups';

const reducer = composeReducers(
  hasMany('children', 'stepChildren'),
  moveChildren('children', TYPE),
  createCollectionReducer(TYPE)
);

export default resolveSiblings(reducer, TYPE);

export const selectStepGroups = state => state[TYPE];
export const selectStepGroup = (state, id) => selectStepGroups(state)[id];
