import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'ext/lib/hooks/use-toggle';
import { Button, Icon, Input, Text } from '@appcues/sonar';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCode } from '@fortawesome/pro-solid-svg-icons/faCode';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import { Shape as CustomComponentShape } from 'entities/custom-components';
import CreatedBy from 'components/TemplateList/CreatedBy';
import VersionPopover from './VersionPopover';
import CustomComponentFlyout from './CustomComponentFlyout';
import {
  ActionsGroup,
  Wrapper,
  TemplateWrapper,
  TemplateCard,
  Label,
  DropdownMenu,
  DropdownMenuTriggerButton,
  ActionsWrapper,
} from './styled';

export function CustomComponentList({
  customComponents,
  handleClick,
  handleCreateComponent,
  handleUpdateComponent,
  handleRemoveComponent,
  handleCancel,
}) {
  const $dropdownMenu = useRef(null);
  const $wrapper = useRef(null);

  const [isPopoverOpen, toggleIsPopoverOpen] = useToggle(false);
  const [openCustomComponentFlyout, toggleCustomComponentFlyout] = useToggle();
  const [editingComponentId, setEditingComponentId] = useState(null);

  const editingComponentTemplate = customComponents.find(
    ({ id }) => id === editingComponentId
  );

  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(null);
  const [deletingTemplate, setDeletingTemplate] = useState(null);
  const [searchFilter, setSearchFilter] = useState(null);

  const filteredTemplates = customComponents
    .filter(({ name, userCreatedBy }) => {
      if (!searchFilter) return true;

      const createdBy = userCreatedBy?.fullName;
      return [name, createdBy].some(property =>
        property?.toLowerCase().includes(searchFilter.toLowerCase())
      );
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const onClean = event => {
    setDeletingTemplate(null);
    setSearchFilter(null);
    event?.stopPropagation();
  };

  useClickOutside([$wrapper, $dropdownMenu], () => {
    if (!openCustomComponentFlyout && !dropdownMenuOpen && !isPopoverOpen) {
      handleCancel();
    }
  });

  useClickOutside([$dropdownMenu], () => setDropdownMenuOpen(null));

  const onConfirmDelete = async (event, id) => {
    handleRemoveComponent(id);
    onClean(event);
  };

  const handleComponentEdit = (event, id) => {
    setDropdownMenuOpen(null);
    setEditingComponentId(id);
    toggleCustomComponentFlyout();
    event.stopPropagation();
  };

  const handleComponentDelete = (event, id) => {
    setDropdownMenuOpen(null);
    setDeletingTemplate(id);
    event.stopPropagation();
  };

  const handleTemplateDeletion = id => (
    <TemplateCard.Overlay>
      <Text>Permanently delete?</Text>
      <ActionsWrapper>
        <Button
          variant="tertiary"
          onClick={event => onConfirmDelete(event, id)}
        >
          <Icon icon={faCheck} />
        </Button>
        <Button variant="tertiary" onClick={event => onClean(event)}>
          <Icon icon={faXmark} />
        </Button>
      </ActionsWrapper>
    </TemplateCard.Overlay>
  );

  const handleTemplateMenu = id => (
    <DropdownMenu.Root open={dropdownMenuOpen === id} modal>
      <DropdownMenu.Trigger asChild>
        <DropdownMenuTriggerButton
          iconOnly
          variant="tertiary"
          title="Component options"
          onClick={event => {
            setDropdownMenuOpen(id);
            event.stopPropagation();
          }}
        >
          <Icon icon={faEllipsisVertical} />
        </DropdownMenuTriggerButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content ref={$dropdownMenu} side="bottom">
          <DropdownMenu.Item onClick={event => handleComponentEdit(event, id)}>
            <Icon icon={faPenToSquare} /> Edit
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onClick={event => handleComponentDelete(event, id)}
          >
            <Icon icon={faTrashCan} /> Delete
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );

  const handleTemplateContent = ({
    id,
    name,
    description,
    userCreatedBy,
    createdAt,
    updatedAt,
  }) => {
    const { avatarUrl, fullName } = userCreatedBy ?? {};

    return (
      <>
        <TemplateCard.Info>
          <Label>
            <Icon icon={faCode} size="small" /> {name}
          </Label>
          <TemplateCard.Description>{description}</TemplateCard.Description>
          <CreatedBy
            avatarUrl={avatarUrl}
            fullName={fullName}
            createdAt={createdAt}
            updatedAt={updatedAt}
          />
        </TemplateCard.Info>
        <TemplateCard.Actions>
          {handleTemplateMenu(id, name)}
        </TemplateCard.Actions>
        {deletingTemplate === id && handleTemplateDeletion(id)}
      </>
    );
  };

  return (
    <>
      <Wrapper ref={$wrapper}>
        <VersionPopover
          isOpen={isPopoverOpen}
          toggleOpen={toggleIsPopoverOpen}
        />
        <Input
          icon={faMagnifyingGlass}
          placeholder="Search by component name..."
          onChange={event => setSearchFilter(event.target.value)}
        />
        <Button variant="secondary" onClick={toggleCustomComponentFlyout}>
          Create new component
        </Button>
        <TemplateWrapper>
          {filteredTemplates.map(
            ({
              id,
              name,
              content: componentDefinition,
              userCreatedBy,
              createdAt,
              updatedAt,
            }) => (
              <TemplateCard.Root
                key={id}
                onClick={() => handleClick({ id, componentDefinition })}
              >
                {handleTemplateContent({
                  id,
                  name,
                  description: componentDefinition.description,
                  userCreatedBy,
                  createdAt,
                  updatedAt,
                })}
              </TemplateCard.Root>
            )
          )}
        </TemplateWrapper>
      </Wrapper>
      <ActionsGroup>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </ActionsGroup>

      <CustomComponentFlyout
        editingComponentTemplate={editingComponentTemplate}
        open={openCustomComponentFlyout}
        onOpenChange={() => {
          setEditingComponentId(null);
          toggleCustomComponentFlyout();
        }}
        handleCreateComponent={handleCreateComponent}
        handleUpdateComponent={handleUpdateComponent}
      />
    </>
  );
}

CustomComponentList.propTypes = {
  customComponents: PropTypes.arrayOf(CustomComponentShape),
  handleClick: PropTypes.func,
  handleCreateComponent: PropTypes.func,
  handleUpdateComponent: PropTypes.func,
  handleRemoveComponent: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default CustomComponentList;
