import PropTypes from 'prop-types';

export const CUSTOM_COMPONENT_TYPE = 'custom_component';
export const CUSTOM_COMPONENT_CONTENT_TYPE = 'mobile-component-definition';

export default PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf([CUSTOM_COMPONENT_TYPE]),
  contentType: PropTypes.oneOf([CUSTOM_COMPONENT_CONTENT_TYPE]),
  content: PropTypes.shape({
    identifier: PropTypes.string,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.oneOf(['string', 'number', 'boolean']),
        default: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      })
    ),
  }),
  createdAt: PropTypes.number,
  createdBy: PropTypes.string,
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.string,
});
