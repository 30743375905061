import PropTypes from 'prop-types';
import { PLATFORMS } from 'lib/platform';

export default PropTypes.shape({
  appId: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  platform: PropTypes.oneOf(PLATFORMS),
  state: PropTypes.oneOf(['DRAFT', 'PUBLISHED']),
  steps: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object, // Original
      PropTypes.string, // Normalized
    ])
  ),
});
