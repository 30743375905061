import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Menu, List, ListItem, ListBody, MenuHeading } from 'ext/components/ui';
import { MODAL, TOOLTIP, LAYOUT_TRAITS, TRAIT_LABELS } from 'lib/trait';
import { PLATFORMS } from 'lib/platform';
import {
  STANDARD,
  PRESENTATIONS,
  PRESENTATIONS_TYPES,
  getAllowedPresentations,
} from 'lib/presentation';
import { capitalize } from 'utils/strings-handler';
import Icons from './Icons';

const StepIcon = styled(Icons)`
  height: 20px;
  margin-right: 15px;
  min-width: 30px;
  padding: 0px 7px;
`;

const handleLabel = (layoutTrait, presentation) => {
  const presentationLabel = PRESENTATIONS[layoutTrait][presentation];
  const traitLabel =
    presentation === STANDARD || layoutTrait === TOOLTIP
      ? ` ${TRAIT_LABELS[layoutTrait]}`
      : '';

  return capitalize(`${presentationLabel}${traitLabel}`);
};

export const AddMenu = forwardRef(
  (
    {
      selectedTrait,
      selectedPresentation,
      platform,
      onClick,
      handleAddGroup,
      handleAddStep,
    },
    ref
  ) => {
    const traitType = TRAIT_LABELS[selectedTrait];

    return (
      <Menu onClick={onClick} ref={ref}>
        <MenuHeading>Add step</MenuHeading>
        <List aria-label="Add modal step group" role="list">
          {[MODAL, TOOLTIP].map(layoutTrait =>
            getAllowedPresentations(layoutTrait, platform).map(
              ([presentation]) => (
                <ListItem
                  key={presentation}
                  onClick={() => handleAddGroup({ layoutTrait, presentation })}
                >
                  <StepIcon
                    layoutTrait={layoutTrait}
                    presentation={presentation}
                  />
                  <ListBody>{handleLabel(layoutTrait, presentation)}</ListBody>
                </ListItem>
              )
            )
          )}
        </List>

        {selectedTrait && selectedPresentation && (
          <>
            <MenuHeading>Add to {traitType} group</MenuHeading>
            <List aria-label={`Add step to group ${traitType}`} role="list">
              <ListItem onClick={handleAddStep}>
                <StepIcon
                  layoutTrait={selectedTrait}
                  presentation={selectedPresentation}
                />
                <ListBody>
                  {handleLabel(selectedTrait, selectedPresentation)}
                </ListBody>
              </ListItem>
            </List>
          </>
        )}
      </Menu>
    );
  }
);

AddMenu.propTypes = {
  selectedTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  selectedPresentation: PropTypes.oneOf(PRESENTATIONS_TYPES),
  platform: PropTypes.oneOf(PLATFORMS),
  onClick: PropTypes.func,
  handleAddGroup: PropTypes.func,
  handleAddStep: PropTypes.func,
};

export default AddMenu;
