const safe = callback => {
  try {
    return callback();
  } catch {
    return null;
  }
};

export const handleStorageFactory = storageType => {
  /**
   * Safely call function without throwing error
   *
   * @param {Function} callback - Throwable callback
   * @return {any} Return value of callback
   */

  /**
   * Safely get and parse value from local storage or return fallback
   *
   * @param {string} key - Local storage key
   * @param {string} fallback - Fallback value
   * @return {string} Localstorage value or fallback
   */
  const get = (key, fallback) => {
    const data = safe(() => JSON.parse(storageType.getItem(key)));
    return data || fallback;
  };

  /**
   * Safely set and stringify value to local storage
   *
   * @param {string} key - Local storage key
   * @param {string} value - Value to set
   * @return {void}
   */
  const set = (key, value) => {
    safe(() => storageType.setItem(key, JSON.stringify(value)));
  };

  /**
   * Safely remove value from local storage
   *
   * @param {string} key - Local storage key
   * @return {void}
   */
  const remove = key => {
    safe(() => storageType.removeItem(key));
  };

  return {
    get,
    set,
    remove,
  };
};
