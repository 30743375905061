import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  FieldSet,
  FontIcon,
  Label,
  Select,
  DebouncedInput as Input,
  RadioButton,
  RadioButtonGroup,
} from 'ext/components/ui';
import { capitalize } from 'utils/strings-handler';
import {
  Controls,
  HelpLabel,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';
import { RequiredField } from 'components/SideBarSettings/Survey';

const TYPES = ['text', 'number', 'email', 'phone', 'name', 'address', 'url'];

const typeOptions = TYPES.map(type => ({
  label: type === 'url' ? type.toUpperCase() : capitalize(type),
  value: type,
}));

const NUMBER_OF_LINES = {
  short: 1,
  long: 3,
};

const MAX_LENGTH = {
  min: 1,
  max: 100000,
};

export default function TextInputBehavior({
  type,
  numberOfLines,
  maxLength,
  onChange,
  onRequiredChange,
  onErrorLabelChange,
}) {
  const trackAccordion = useAccordionClick();

  const handleTypeChange = ({ value }) => {
    onChange({ dataType: value });
  };

  const handleMaxLengthChange = e => {
    const { valueAsNumber } = e.target;

    const invalidValue = Number.isNaN(valueAsNumber);
    const invalidRange =
      valueAsNumber < MAX_LENGTH.min || valueAsNumber > MAX_LENGTH.max;

    const value = invalidValue || invalidRange ? MAX_LENGTH.max : valueAsNumber;
    if (value === MAX_LENGTH.max) e.target.value = '';

    onChange({ maxLength: value });
  };

  return (
    <Accordion.Item value="behavior">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion('TextInput', 'Behavior')}
        >
          Behavior
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Label htmlFor="text-input-type">
              Input Type
              <HelpLabel>
                Input type controls keyboard <br />
                layout and autofill suggestions
              </HelpLabel>
            </Label>
            <Select
              id="text-input-type"
              options={typeOptions}
              placeholder="Select input type"
              onChange={handleTypeChange}
              value={typeOptions.filter(({ value }) => type === value)}
              portal
            />
          </FieldSet>
          <FieldSet>
            <RadioButtonGroup>
              <RadioButton
                onClick={() =>
                  onChange({
                    numberOfLines: NUMBER_OF_LINES.short,
                  })
                }
                selected={numberOfLines === NUMBER_OF_LINES.short}
              >
                Short answer
              </RadioButton>
              <RadioButton
                onClick={() =>
                  onChange({
                    numberOfLines: NUMBER_OF_LINES.long,
                  })
                }
                selected={numberOfLines === NUMBER_OF_LINES.long}
              >
                Long answer
              </RadioButton>
            </RadioButtonGroup>
          </FieldSet>

          <FieldSet>
            <Label htmlFor="text-input-max-length">Maximum characters</Label>
            <Input
              id="text-input-max-length"
              defaultValue={maxLength === MAX_LENGTH.max ? '' : maxLength}
              onChange={handleMaxLengthChange}
              type="number"
              placeholder="None (Unlimited)"
              min={MAX_LENGTH.min}
              max={MAX_LENGTH.max}
            />
          </FieldSet>
          <RequiredField
            onRequiredChange={onRequiredChange}
            onErrorLabelChange={onErrorLabelChange}
          />
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

TextInputBehavior.propTypes = {
  type: PropTypes.oneOf(TYPES),
  numberOfLines: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onRequiredChange: PropTypes.func,
  onErrorLabelChange: PropTypes.func,
};
