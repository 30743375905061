import styled, { css } from 'styled-components';

const xsmall = css`
  margin: var(--spacing-x-small);
`;

const small = css`
  margin: var(--spacing-x-small) var(--spacing-small);
`;

const regular = css`
  margin: var(--spacing-x-small) var(--spacing-regular);
`;

export const Divider = styled.hr`
  border: 0;
  border-right: 1px solid var(--border-default);

  ${props => props.$size === 'x-small' && xsmall}
  ${props => props.$size === 'small' && small}
  ${props => props.$size === 'regular' && regular}
`;
