import React from 'react';
import PropTypes from 'prop-types';
import {
  FormField,
  Label,
  Input,
  Select,
  SelectOption,
  InlineMessage,
  Icon,
} from '@appcues/sonar';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { capitalize } from 'utils/strings-handler';
import { CUSTOM_COMPONENT_PROPERTY_TYPE as PROPERTY_TYPE } from 'lib/block';
import {
  ButtonDelete,
  OptionalText,
  PropertyContainer,
  SideBySide,
} from './styled';

const parseNumericInput = value => {
  if (value === '') return '';
  const parsed = Number.parseFloat(value);
  return Number.isNaN(parsed) ? 0 : parsed;
};

export function PropertyForm({
  property,
  index,
  error,
  onPropertyChange,
  onRemoveProperty,
}) {
  const handleDefaultInputChange = ({ target }) => {
    const isNumericProperty = property.type === PROPERTY_TYPE.NUMBER;
    const newValue = isNumericProperty
      ? parseNumericInput(target.value)
      : target.value;

    onPropertyChange(index, 'default', newValue);
  };

  return (
    <PropertyContainer>
      <h3>Property</h3>

      <FormField>
        <Label>Property name</Label>
        <Input
          id="property-name"
          value={property.name}
          placeholder="propertyName"
          onChange={({ target }) =>
            onPropertyChange(index, 'name', target.value)
          }
        />
        {error[`${index}-name`] && (
          <InlineMessage variant="error">
            {error[`${index}-name`]}
          </InlineMessage>
        )}
      </FormField>

      <FormField>
        <Label>
          Description<OptionalText>(optional)</OptionalText>
        </Label>
        <Input
          id="description"
          value={property.description}
          placeholder="Description of the property's functionality"
          onChange={({ target }) =>
            onPropertyChange(index, 'description', target.value)
          }
        />
      </FormField>

      <SideBySide>
        <FormField>
          <Label>Type</Label>
          <Select
            value={property.type}
            onValueChange={value => onPropertyChange(index, 'type', value)}
            width="full-width"
          >
            {Object.values(PROPERTY_TYPE).map(option => (
              <SelectOption
                key={option}
                value={option}
                label={capitalize(option)}
              />
            ))}
          </Select>
        </FormField>

        <FormField>
          <Label>Default value</Label>
          {property.type === PROPERTY_TYPE.BOOLEAN ? (
            <Select
              onValueChange={value =>
                onPropertyChange(index, 'default', value === 'true')
              }
              value={property.default.toString()}
              width="full-width"
            >
              {['true', 'false'].map(option => (
                <SelectOption key={option} value={option} label={option} />
              ))}
            </Select>
          ) : (
            <Input
              id="default-value"
              value={property.default}
              onChange={handleDefaultInputChange}
            />
          )}
        </FormField>
      </SideBySide>

      <ButtonDelete
        variant="tertiary"
        onClick={event => onRemoveProperty(event, index)}
      >
        <Icon size="regular" icon={faXmark} />
      </ButtonDelete>
    </PropertyContainer>
  );
}

PropertyForm.propTypes = {
  index: PropTypes.number.isRequired,
  onPropertyChange: PropTypes.func.isRequired,
  onRemoveProperty: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.string),
  property: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.oneOf(Object.values(PROPERTY_TYPE)),
    default: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
  }),
};
