import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { Button, Icon } from '@appcues/sonar';
import { faArrowTurnDownLeft } from '@fortawesome/pro-solid-svg-icons/faArrowTurnDownLeft';
import { faArrowTurnDownRight } from '@fortawesome/pro-solid-svg-icons/faArrowTurnDownRight';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { Tooltip } from 'ext/components/ui';
import { undoAction, redoAction, selectHistoryLog } from 'entities/history-log';
import { ButtonGroup } from './styled';

const UndoRedo = ({ undo, redo, canUndo, canRedo }) => {
  const { track } = useAnalytics();

  const [undoVisibleTooltip, setUndoVisibleTooltip] = useState(false);
  const [redoVisibleTooltip, setRedoVisibleTooltip] = useState(false);

  useEffect(() => {
    const handleShortcuts = debounce(({ ctrlKey, metaKey, shiftKey, key }) => {
      const undoShortcuts = (ctrlKey || metaKey) && !shiftKey && key === 'z';
      if (canUndo && undoShortcuts) undo();

      const redoShortcuts =
        (ctrlKey && key === 'y') || (metaKey && shiftKey && key === 'z');
      if (canRedo && redoShortcuts) redo();
    }, 250);

    if (canUndo || canRedo) {
      window.addEventListener('keydown', handleShortcuts);
    } else {
      window.removeEventListener('keydown', handleShortcuts);
    }
    return () => window.removeEventListener('keydown', handleShortcuts);
  }, [canUndo, canRedo, undo, redo]);

  const buttons = [
    {
      label: 'Undo',
      icon: faArrowTurnDownLeft,
      visibleTooltip: undoVisibleTooltip,
      setVisibleTooltip: setUndoVisibleTooltip,
      disabled: !canUndo,
      action: undo,
    },
    {
      label: 'Redo',
      icon: faArrowTurnDownRight,
      visibleTooltip: redoVisibleTooltip,
      setVisibleTooltip: setRedoVisibleTooltip,
      disabled: !canRedo,
      action: redo,
    },
  ];

  const handleButtonClick = (label, action) => {
    track('Mobile Builder interaction', {
      name: `Clicked ${label} Button`,
      component: 'UndoRedo',
    });
    action();
  };

  const handleButton = ({
    label,
    icon,
    visibleTooltip,
    setVisibleTooltip,
    disabled,
    action,
  }) => (
    <Tooltip
      key={label}
      label={label}
      onMouseOut={() => setVisibleTooltip(false)}
      onMouseOver={() => setVisibleTooltip(true)}
      visible={visibleTooltip}
    >
      <Button
        aria-label={label}
        disabled={disabled}
        variant="tertiary"
        onClick={() => handleButtonClick(label, action)}
      >
        <Icon icon={icon} flip="vertical" />
      </Button>
    </Tooltip>
  );

  return (
    <ButtonGroup>{buttons.map(button => handleButton(button))}</ButtonGroup>
  );
};

UndoRedo.propTypes = {
  undo: PropTypes.func,
  redo: PropTypes.func,
  canRedo: PropTypes.bool,
  canUndo: PropTypes.bool,
};

const mapStateToProps = state => {
  const { undos, redos } = selectHistoryLog(state);
  return {
    canUndo: undos?.length > 0,
    canRedo: redos?.length > 0,
  };
};

const mapDispatchToProps = {
  undo: undoAction,
  redo: redoAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UndoRedo);
