import { useEffect, useRef, useState } from 'react';

/**
 * A custom hook that checks if all images in a DOM node have loaded
 * @param {DOMNode} children - The content rendered that will be checked for images
 * @returns {Array} [$contentNode, isLoaded] - The DOM node where you  and a boolean indicating if all images have loaded
 */
export const useImageLoad = children => {
  const $contentNode = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    const images = $contentNode.current.querySelectorAll('img');
    const unloadedImages = [...images].filter(img => !img.complete);
    setIsLoaded(unloadedImages.length === 0);
  };

  useEffect(() => {
    const contentNode = $contentNode.current;
    handleImageLoad();

    const images = contentNode.querySelectorAll('img');

    images.forEach(img => {
      img.addEventListener('load', handleImageLoad);
    });

    return () => {
      images.forEach(img => {
        img.removeEventListener('load', handleImageLoad);
      });
    };
  }, [children]);

  return [$contentNode, isLoaded];
};
