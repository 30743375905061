import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@appcues/sonar';
import { FieldSet } from 'ext/components/ui';
import {
  getClose,
  getRequestReview,
  requestReviewType,
  Shape as ActionsShape,
} from 'lib/actions';

export default function DismissFlowOption({ actions, config, onChange }) {
  const dismissFlow = config?.markComplete ?? true;

  const handleDismissFlowChange = markComplete => {
    const hasRequestReview = actions?.some(
      action => action.type === requestReviewType
    );

    onChange({
      actions: [
        getClose({ markComplete }),
        ...(hasRequestReview ? [getRequestReview()] : []),
      ],
    });
  };

  return (
    <FieldSet>
      <Switch
        id="mark-complete"
        checked={dismissFlow}
        onCheckedChange={handleDismissFlowChange}
        fullWidth
      >
        <Switch.Label htmlFor="mark-complete">Mark Flow complete</Switch.Label>
      </Switch>
    </FieldSet>
  );
}

DismissFlowOption.propTypes = {
  actions: PropTypes.arrayOf(ActionsShape),
  config: PropTypes.shape({
    markComplete: PropTypes.bool,
  }),
  onChange: PropTypes.func,
};
