import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import keyBy from 'lodash.keyby';
import { INITIALIZE } from 'ext/root/root-actions';
import { reject, resolve } from './actions';

function* fetchTags() {
  const logger = yield getContext('logger');
  try {
    const api = yield getContext('api');
    const response = yield call(api.getTags);

    yield put(resolve(keyBy(response, 'id')));
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
  }
}

export default function* saga() {
  yield takeEvery(INITIALIZE, fetchTags);
}
