import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import StyleProvider from 'ext/components/StyleProvider';

/**
 * NOTE: Most interactions with this component should Just Work. However,
 *       internally the component will render a branch new tree within the
 *       iframe so it's possible that we run into timing issues and race
 *       conditions. Luckily the component provides a few lifecycle hooks for
 *       when the content of the iframe has mounted and updated -
 *       `contentDidMount` and `contentDidUpdate` - if we require finer control
 */

const Wrapper = styled(Frame)`
  color-scheme: none;
  border: none;
  height: ${({ $fullscreen }) =>
    $fullscreen ? '100%' : 'calc(100% - var(--bottom-bar-height))'};
  left: 0;
  margin: 0;
  outline: none;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

const IFrame = ({ children, onLoad, onUpdate, ...props }) => (
  <Wrapper
    src="about:blank"
    contentDidMount={onLoad}
    contentDidUpdate={onUpdate}
    // eslint-disable-next-line @appcues/jsx-props-no-spreading
    {...props}
  >
    <FrameContextConsumer>
      {context => (
        <StyleProvider global={false} target={context.document.body}>
          {typeof children === 'function' ? children(context) : children}
        </StyleProvider>
      )}
    </FrameContextConsumer>
  </Wrapper>
);

IFrame.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  id: PropTypes.string,
  onLoad: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default IFrame;
