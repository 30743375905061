import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { Badge, Button, Icon, Spinner, usePagination } from '@appcues/sonar';
import { LocalesTableShape } from 'ext/entities/locales';
import { LOADING, LOADED } from 'entities/status';
import EmptyLocales from './EmptyLocales';
import {
  Table,
  Pagination,
  TargetCriteria,
  ActionButtons,
  Loading,
} from './styled';

const PAGE_SIZE = 5;

export const LocalesTable = ({
  locales = [],
  status,
  isEditingLanguage,
  setIsEditingLanguage,
  onRemoveLocale,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState();
  const { pageIndex, pageSize, setPagination } = usePagination({
    customPageSize: PAGE_SIZE,
  });

  const filteredEditingLocales = locales.filter(
    ({ id }) => id !== isEditingLanguage?.id
  );

  const totalResults = filteredEditingLocales.length ?? 0;
  const totalPages = Math.ceil(totalResults / pageSize);
  let startIndex = pageIndex * pageSize;
  const endIndex = startIndex + pageSize;

  // Adjust index if the current page is empty
  if (endIndex > totalResults) {
    const lastPageIndex = totalPages - 1;
    startIndex = Math.max(0, lastPageIndex * pageSize);
  }

  const paginatedLocales = filteredEditingLocales.slice(startIndex, endIndex);

  const onDeleteClick = id => {
    setShowDeleteConfirmation(id);
  };

  const onConfirmClick = id => {
    onRemoveLocale(id);
    setShowDeleteConfirmation();
  };

  const onCancelClick = () => {
    setShowDeleteConfirmation();
  };

  const handleActionButtons = locale => {
    const actionButton = ({ ariaLabel, onClick, dataAction, icon }) => (
      <Button
        aria-label={ariaLabel}
        onClick={onClick}
        variant="tertiary"
        data-action={dataAction}
      >
        <Icon icon={icon} />
      </Button>
    );

    return showDeleteConfirmation === locale.id ? (
      <>
        {actionButton({
          ariaLabel: 'Confirm language deletion',
          onClick: () => onConfirmClick(locale.id),
          dataAction: 'confirm',
          icon: faCheck,
        })}
        {actionButton({
          ariaLabel: 'Cancel language deletion',
          onClick: onCancelClick,
          dataAction: 'cancel',
          icon: faXmark,
        })}
      </>
    ) : (
      <>
        {actionButton({
          ariaLabel: 'Edit language',
          onClick: () => setIsEditingLanguage(locale),
          icon: faPencil,
        })}
        {actionButton({
          ariaLabel: 'Delete language',
          onClick: () => onDeleteClick(locale.id),
          icon: faTrashCan,
        })}
      </>
    );
  };

  return (
    <>
      <Table.Root aria-label="Locales table" isLoading={status === LOADING}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Language</Table.Th>
            <Table.Th>Property</Table.Th>
            <Table.Th>Target Criteria</Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {paginatedLocales?.map(({ id, name, property, targetCriteria }) => (
            <Table.Tr key={id}>
              <Table.Td>{name}</Table.Td>
              <Table.Td>{property}</Table.Td>
              <Table.Td>
                <TargetCriteria>
                  {targetCriteria.map(targetCriteriaCode => (
                    <Badge key={targetCriteriaCode}>{targetCriteriaCode}</Badge>
                  ))}
                </TargetCriteria>
              </Table.Td>
              <Table.Td>
                <ActionButtons>
                  {handleActionButtons({
                    id,
                    name,
                    property,
                    targetCriteria,
                  })}
                </ActionButtons>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table.Root>
      <Pagination
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalResults={totalResults}
        setPagination={setPagination}
      />
      {status === LOADING && (
        <Loading>
          <Spinner />
        </Loading>
      )}
      {locales.length === 0 && <EmptyLocales />}
    </>
  );
};

LocalesTable.propTypes = {
  locales: LocalesTableShape,
  status: PropTypes.oneOf([LOADING, LOADED]),
  isEditingLanguage: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    property: PropTypes.string,
    targetCriteria: PropTypes.arrayOf(PropTypes.string),
  }),
  setIsEditingLanguage: PropTypes.func,
  onRemoveLocale: PropTypes.func,
};
