import { POINTER_EDGE } from './variables';

const { TOP, BOTTOM, LEFT, RIGHT } = POINTER_EDGE;

// Helper to calculate the max allowable pointer corner radius.
export function maxPointerCornerRadius(pointerSize) {
  const point1 = { x: 0, y: pointerSize.length };
  const point2 = { x: pointerSize.base / 2, y: 0 };

  const angle = Math.atan2(point1.y, point2.x);
  const radius =
    (point1.y - point2.y) /
    (1 + 2 * Math.sin(angle - Math.PI / 2) + 1 / Math.cos(angle));

  return Number.isNaN(radius) ? 0 : radius;
}

// Helper to project an (x,y) point.
export function project(point, distance, angle) {
  return {
    x: point.x + Math.cos(angle) * distance,
    y: point.y + Math.sin(angle) * distance,
  };
}

// Helper to determine the center points for the circles needed
// to draw a rounded pointer.
export function roundedPointerCenters(pointer, mainRect) {
  const { base, length, offset, cornerRadius, edge } = pointer;
  const { width, height, xOrigin, yOrigin } = mainRect;

  const angle = Math.atan2(length, base / 2);

  const outerOffset = Math.tan(angle / 2) * cornerRadius;
  const topOffset = cornerRadius / Math.cos(angle);

  switch (edge) {
    case TOP: {
      return {
        circle1: {
          x: xOrigin + width / 2 + offset - (base / 2 + outerOffset),
          y: yOrigin - cornerRadius,
        },
        circle2: {
          x: xOrigin + width / 2 + offset,
          y: yOrigin - length + topOffset,
        },
        circle3: {
          x: xOrigin + width / 2 + offset + (base / 2 + outerOffset),
          y: yOrigin - cornerRadius,
        },
      };
    }
    case BOTTOM: {
      return {
        circle1: {
          x: xOrigin + width / 2 + offset + (base / 2 + outerOffset),
          y: yOrigin + height + cornerRadius,
        },
        circle2: {
          x: xOrigin + width / 2 + offset,
          y: yOrigin + height + length - topOffset,
        },
        circle3: {
          x: xOrigin + width / 2 + offset - (base / 2 + outerOffset),
          y: yOrigin + height + cornerRadius,
        },
      };
    }
    case LEFT: {
      return {
        circle1: {
          x: xOrigin - cornerRadius,
          y: yOrigin + height / 2 + offset + (base / 2 + outerOffset),
        },
        circle2: {
          x: xOrigin - length + topOffset,
          y: yOrigin + height / 2 + offset,
        },
        circle3: {
          x: xOrigin - cornerRadius,
          y: yOrigin + height / 2 + offset - (base / 2 + outerOffset),
        },
      };
    }
    case RIGHT: {
      return {
        circle1: {
          x: xOrigin + width + cornerRadius,
          y: yOrigin + height / 2 + offset - (base / 2 + outerOffset),
        },
        circle2: {
          x: xOrigin + width + length - topOffset,
          y: yOrigin + height / 2 + offset,
        },
        circle3: {
          x: xOrigin + width + cornerRadius,
          y: yOrigin + height / 2 + offset + (base / 2 + outerOffset),
        },
      };
    }
    default:
      return {};
  }
}

// Helper to calculate the path of pointer. Uses absolute coordinates for simplicity.
export function pointerEdgePath(pointer, mainRect) {
  const { cornerRadius, edge } = pointer;
  const angle = Math.atan2(pointer.length, pointer.base / 2);
  const { circle1, circle2, circle3 } = roundedPointerCenters(
    pointer,
    mainRect
  );

  let angleAdjust = 0;

  switch (edge) {
    case TOP:
      angleAdjust = 0;
      break;
    case RIGHT:
      angleAdjust = Math.PI / 2;
      break;
    case BOTTOM:
      angleAdjust = Math.PI;
      break;
    case LEFT:
      angleAdjust = (3 * Math.PI) / 2;
      break;
    default:
      break;
  }

  circle1.arcStart = project(circle1, cornerRadius, Math.PI / 2 + angleAdjust);
  circle1.arcEnd = project(
    circle1,
    cornerRadius,
    -angle + Math.PI / 2 + angleAdjust
  );

  circle2.arcStart = project(
    circle2,
    cornerRadius,
    -angle - Math.PI / 2 + angleAdjust
  );
  circle2.arcEnd = project(
    circle2,
    cornerRadius,
    angle - Math.PI / 2 + angleAdjust
  );

  circle3.arcStart = project(
    circle3,
    cornerRadius,
    angle + Math.PI / 2 + angleAdjust
  );
  circle3.arcEnd = project(circle3, cornerRadius, Math.PI / 2 + angleAdjust);

  return `
    L${circle1.arcStart.x},${circle1.arcStart.y} 
    A${cornerRadius},${cornerRadius} 0 0 0 ${circle1.arcEnd.x},${circle1.arcEnd.y}
    L${circle2.arcStart.x},${circle2.arcStart.y} 
    A${cornerRadius},${cornerRadius} 0 0 1 ${circle2.arcEnd.x},${circle2.arcEnd.y}
    L${circle3.arcStart.x},${circle3.arcStart.y} 
    A${cornerRadius},${cornerRadius} 0 0 0 ${circle3.arcEnd.x},${circle3.arcEnd.y}
  `;
}

// Helper for drawTooltip that handles the path calculations for drawing the tooltip pointer.
export function getPointerPathInfo(pointer, mainRect, boxCornerRadius) {
  const { base, length, offset, edge } = pointer;
  const { width, height, xOrigin, yOrigin } = mainRect;

  const path = [];
  const pointerPathInfo = {
    overridesTopLeftCorner: false,
    overridesTopRightCorner: false,
    overridesBottomRightCorner: false,
    overridesBottomLeftCorner: false,
    path: '',
  };

  const originX = width / 2 - base / 2 + offset;
  const originY = height / 2 - base / 2 + offset;

  switch (edge) {
    case TOP: {
      if (originX < 0) {
        // Check for collisions with left corner where we should
        // draw an asymmetrical triangle right at the edge
        pointerPathInfo.overridesTopLeftCorner = true;
        path.push(
          `l0,${-length} ${base},${length}`,
          `h${width - base - boxCornerRadius}`
        );
      } else if (originX < boxCornerRadius) {
        // Check for collisions with left corner where we should
        // draw an asymmetrical triangle next to the rounded corner
        path.push(
          `l0,${-length} ${base},${length}`,
          `h${width - boxCornerRadius - base - boxCornerRadius}`
        );
      } else if (originX > width - base) {
        // Check for collisions with right corner where we should
        // draw an asymmetrical triangle right at the edge
        pointerPathInfo.overridesTopRightCorner = true;
        path.push(
          `h${width - base - boxCornerRadius}`,
          `l${base},${-length} 0,${length}`
        );
      } else if (originX > width - base - boxCornerRadius) {
        // Check for collisions with right corner where we should
        // draw an asymmetrical triangle next to the rounded corner
        path.push(
          `h${width - boxCornerRadius - base - boxCornerRadius}`,
          `l${base},${-length} 0,${length}`
        );
      } else {
        // Regular centered pointer
        path.push(
          pointerEdgePath(pointer, mainRect),
          `L${xOrigin + width - boxCornerRadius},${yOrigin}`
        );
      }

      break;
    }
    case BOTTOM: {
      if (originX < 0) {
        // Check for collisions with left corner where we should
        // draw an asymmetrical triangle right at the edge
        pointerPathInfo.overridesBottomLeftCorner = true;
        path.push(
          `h${-(width - base - boxCornerRadius)}`,
          `l${-base},${length} 0,${-length}`
        );
      } else if (originX < boxCornerRadius) {
        // Check for collisions with left corner where we should
        // draw an asymmetrical triangle next to the rounded corner
        path.push(
          `h${-(width - boxCornerRadius - base - boxCornerRadius)}`,
          `l${-base},${length} 0,${-length}`
        );
      } else if (originX > width - base) {
        // Check for collisions with right corner where we should
        // draw an asymmetrical triangle right at the edge
        pointerPathInfo.overridesBottomRightCorner = true;
        path.push(
          `l0,${length} ${-base},${-length}`,
          `h${-(width - base - boxCornerRadius)}`
        );
      } else if (originX > width - base - boxCornerRadius) {
        // Check for collisions with right corner where we should
        // draw an asymmetrical triangle next to the rounded corner
        path.push(
          `l0,${length} ${-base},${-length}`,
          `h${-(width - boxCornerRadius - base - boxCornerRadius)}`
        );
      } else {
        // Regular centered pointer
        path.push(
          pointerEdgePath(pointer, mainRect),
          `L${xOrigin + boxCornerRadius},${yOrigin + height}`
        );
      }

      break;
    }
    case LEFT: {
      if (originY < 0) {
        // Check for collisions with top corner where we should
        // draw an asymmetrical triangle right at the edge
        pointerPathInfo.overridesTopLeftCorner = true;
        path.push(
          `v${-(height - base - boxCornerRadius)}`,
          `l${-length},${-base} ${length},0`
        );
      } else if (originY < boxCornerRadius) {
        // Check for collisions with top corner where we should
        // draw an asymmetrical triangle next to the rounded corner
        path.push(
          `v${-(height - boxCornerRadius - base - boxCornerRadius)}`,
          `l${-length},${-base} ${length},0`
        );
      } else if (originY > height - base) {
        // Check for collisions with bottom corner where we should
        // draw an asymmetrical triangle right at the edge
        pointerPathInfo.overridesBottomLeftCorner = true;
        path.push(
          `l${-length},0 ${length},${-base}`,
          `v${-(height - base - boxCornerRadius)}`
        );
      } else if (originY > height - base - boxCornerRadius) {
        // Check for collisions with bottom corner where we should
        // draw an asymmetrical triangle next to the rounded corner
        path.push(
          `l${-length},0 ${length},${-base}`,
          `v${-(height - boxCornerRadius - base - boxCornerRadius)}`
        );
      } else {
        // Regular centered pointer
        path.push(
          pointerEdgePath(pointer, mainRect),
          `L${xOrigin},${yOrigin + boxCornerRadius}`
        );
      }

      break;
    }
    case RIGHT: {
      if (originY < 0) {
        // Check for collisions with top corner where we should
        // draw an asymmetrical triangle right at the edge
        pointerPathInfo.overridesTopRightCorner = true;
        path.push(
          `l${length},0 ${-length},${base}`,
          `v${height - base - boxCornerRadius}`
        );
      } else if (originY < boxCornerRadius) {
        // Check for collisions with top corner where we should
        // draw an asymmetrical triangle next to the rounded corner
        path.push(
          `l${length},0 ${-length},${base}`,
          `v${height - boxCornerRadius - base - boxCornerRadius}`
        );
      } else if (originY > height - base) {
        // Check for collisions with bottom corner where we should
        // draw an asymmetrical triangle right at the edge
        pointerPathInfo.overridesBottomRightCorner = true;
        path.push(
          `v${height - base - boxCornerRadius}`,
          `l${length},${base} ${-length},0`
        );
      } else if (originY > height - base - boxCornerRadius) {
        // Check for collisions with bottom corner where we should
        // draw an asymmetrical triangle next to the rounded corner
        path.push(
          `v${height - boxCornerRadius - base - boxCornerRadius}`,
          `l${length},${base} ${-length},0`
        );
      } else {
        // Regular centered pointer
        path.push(
          pointerEdgePath(pointer, mainRect),
          `L${xOrigin + width},${yOrigin + height - boxCornerRadius}`
        );
      }

      break;
    }
    default:
      break;
  }

  pointerPathInfo.path = path.join(' ');
  return pointerPathInfo;
}

// Calculate the main rect of the tooltip, excluding the pointer
export function calculateMainRect(tooltipFrame, pointer, borderWidth) {
  const { length, edge } = pointer;

  const mainRect = {
    xOrigin: 0 + (edge === LEFT ? length : 0) + borderWidth / 2,
    yOrigin: 0 + (edge === TOP ? length : 0) + borderWidth / 2,
    width: tooltipFrame.width - borderWidth,
    height: tooltipFrame.height - borderWidth,
  };

  if (edge === TOP || edge === BOTTOM) {
    mainRect.height -= length;
  } else if (edge === LEFT || edge === RIGHT) {
    mainRect.width -= length;
  }

  return mainRect;
}

// Calculate the corner radius of the tooltip box
export function calculateBoxCornerRadius(cornerRadius, maxHeight, maxWidth) {
  return Math.min(cornerRadius, maxHeight, maxWidth);
}

// Build the path of the tooltip box
export function buildPath(pointerPathInfo, pointer, mainRect, boxCornerRadius) {
  const path = [];

  const corner = cornerEdge => {
    const cornerOffsets = [
      [boxCornerRadius, boxCornerRadius],
      [-boxCornerRadius, boxCornerRadius],
      [-boxCornerRadius, -boxCornerRadius],
      [boxCornerRadius, -boxCornerRadius],
    ];

    const [xOffset, yOffset] = cornerOffsets[cornerEdge];
    return `a${boxCornerRadius},${boxCornerRadius} 0 0 1 ${xOffset},${yOffset}`;
  };

  const moveTo = (x, y) => {
    path.push(`M${x},${y}`);
  };

  const horizontalLineTo = length => {
    path.push(`h${length}`);
  };

  const verticalLineTo = length => {
    path.push(`v${length}`);
  };

  const pushPath = () => {
    path.push(pointerPathInfo.path);
  };

  if (!pointerPathInfo.overridesTopLeftCorner) {
    moveTo(mainRect.xOrigin + boxCornerRadius, mainRect.yOrigin);
  } else if (pointer.edge === TOP) {
    moveTo(mainRect.xOrigin, mainRect.yOrigin);
  } else if (pointer.edge === LEFT) {
    moveTo(mainRect.xOrigin + boxCornerRadius, mainRect.yOrigin);
  }

  if (pointer.edge === TOP) {
    pushPath();
  } else {
    horizontalLineTo(mainRect.width - boxCornerRadius * 2);
  }

  if (!pointerPathInfo.overridesTopRightCorner) {
    path.push(corner(0));
  } else if (pointer.edge === TOP) {
    verticalLineTo(boxCornerRadius);
  } else if (pointer.edge === RIGHT) {
    horizontalLineTo(boxCornerRadius);
  }

  if (pointer.edge === RIGHT) {
    pushPath();
  } else {
    verticalLineTo(mainRect.height - boxCornerRadius * 2);
  }

  if (!pointerPathInfo.overridesBottomRightCorner) {
    path.push(corner(1));
  } else if (pointer.edge === BOTTOM) {
    verticalLineTo(boxCornerRadius);
  } else if (pointer.edge === RIGHT) {
    horizontalLineTo(-boxCornerRadius);
  }

  if (pointer.edge === BOTTOM) {
    pushPath();
  } else {
    horizontalLineTo(-(mainRect.width - boxCornerRadius * 2));
  }

  if (!pointerPathInfo.overridesBottomLeftCorner) {
    path.push(corner(2));
  } else if (pointer.edge === BOTTOM) {
    verticalLineTo(-boxCornerRadius);
  } else if (pointer.edge === LEFT) {
    horizontalLineTo(-boxCornerRadius);
  }

  if (pointer.edge === LEFT) {
    pushPath();
  } else {
    verticalLineTo(-(mainRect.height - boxCornerRadius * 2));
  }

  if (!pointerPathInfo.overridesTopLeftCorner) {
    path.push(corner(3));
  }

  // Closing the path
  path.push('z');

  return path;
}
