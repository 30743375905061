/* eslint-disable @appcues/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseSelect, { components } from 'react-select';
import Icon from './FontIcon';

/**
 * Styles
 */

const theme = `
  --select-bg: var(--diel-gray);
  --select-text: var(--white);
  --select-border: var(--diel-gray);
  --select-active: var(--sharkbait-ooh-la-la);
  --select-inactive: var(--white);
  --select-disabled: var(--sharkbait-ooh-la-la);
  --select-disabled-text: var(--dirty-water);
  --select-placeholder: var(--myst);
  --select-menu-shadow: var(--level-3);
  --select-option-bg: var(--sharkbait-ooh-la-la);
  --select-option-selected: var(--bludacris);
  --select-error: var(--pink-power-ranger);

  [data-theme='light'] & {
    --select-bg: var(--white);
    --select-text: var(--pleather);
    --select-border: var(--sharkbait-ooh-la-la);
    --select-active: var(--oh-hi-dark);
    --select-inactive: var(--sharkbait-ooh-la-la);
    --select-disabled: var(--noreaster);
    --select-disabled-text: var(--dirty-water);
    --select-placeholder: var(--sharkbait-ooh-la-la);
    --select-menu-shadow: var(--level-3);
    --select-option-bg: var(--noreaster);
    --select-option-selected: var(--blurple);
    --select-error: var(--motorola-razr);
  }
`;

/**
 * Helper to extend existing Select styles
 *
 * @param {object<object|function>} overrides - Style overrides
 * @return {object} Extended styles
 */
const extend = overrides =>
  Object.entries(overrides).reduce((acc, [key, value]) => {
    acc[key] = (provided, state) => ({
      ...provided,
      ...(typeof value === 'function' ? value(state) : value),
    });
    return acc;
  }, {});

/**
 * Base styles
 */
const base = extend({
  // Icon for clearing all multi selected values
  clearIndicator: {
    alignItems: 'center',
    color: 'var(--select-inactive)',
    display: 'flex',
    height: '16px',
    marginRight: '8px',
    padding: '0px',
    transition: 'color 200ms ease-in-out',
    width: '16px',
    ':hover': {
      color: 'var(--select-inactive)',
    },
  },

  // Parent container for select
  container: {
    fontSize: 'var(--regular)',
    maxWidth: '400px',
    minWidth: '256px',
  },

  // Inner container for select
  control: ({ isDisabled, isFocused, selectProps: { error } }) => ({
    background: 'var(--select-bg)',
    borderColor: 'var(--select-border)',
    borderRadius: '6px',
    boxShadow: 'none',
    cursor: 'pointer',
    minHeight: '42px',
    transition: 'border-color 200ms ease-in-out',
    ':hover': {
      borderColor: 'var(--select-active)',
    },

    ...(isFocused && {
      borderColor: 'var(--select-active)',
      ':hover': {
        borderColor: 'var(--select-active)',
      },
    }),

    ...(isDisabled && {
      background: 'var(--select-disabled)',
      borderColor: 'var(--select-disabled)',
      color: 'var(--select-disabled-text)',
      ':hover': {
        borderColor: 'var(--select-disabled)',
      },
    }),

    ...(error && {
      borderColor: 'var(--select-error)',
      ':hover': {
        borderColor: 'var(--select-error)',
      },
    }),
  }),

  // Caret icon indicating whether container options is open
  dropdownIndicator: ({ isDisabled, selectProps: { menuIsOpen } }) => ({
    alignItems: 'center',
    color: 'var(--select-inactive)',
    display: 'flex',
    height: '14px',
    marginRight: '12px',
    padding: '0px',
    width: '14px',
    ':hover': {
      color: 'var(--select-inactive)',
    },

    ...(isDisabled && {
      color: 'var(--select-disabled-text)',
    }),

    svg: {
      ...(menuIsOpen && {
        transform: 'rotate(-180deg)',
      }),
    },
  }),

  // Separator between input and icon/indicators
  indicatorSeparator: {
    display: 'none',
  },

  // Select text input
  input: {
    color: 'var(--select-text)',
  },

  // Select menu container
  menu: {
    backgroundColor: 'var(--select-bg)',
    borderRadius: '6px',
    boxShadow: 'var(--select-menu-shadow)',
    color: 'var(--select-text)',
    marginTop: '6px',
    padding: '8px',
  },

  // Multi value tags
  multiValue: {
    borderRadius: '4px',
    background: 'var(--select-option-bg)',
    '&& > *': {
      color: 'var(--select-text)',
    },
  },

  // Multi value remove icon
  multiValueRemove: {
    color: 'var(--select-inactive)',
    ':hover': {
      color: 'var(--select-inactive)',
    },
  },

  // Select menu options
  option: ({ isFocused, isSelected }) => ({
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: 'var(--regular)',
    display: 'flex',

    ...(isFocused && {
      background: 'var(--select-option-bg)',
    }),

    ...(isSelected && {
      background: 'none',
      color: 'var(--select-option-selected)',
      fontWeight: 'var(--bold)',
      ':hover': {
        background: 'var(--select-option-bg)',
      },
    }),
  }),

  // Placeholder text when no value selected
  placeholder: {
    color: 'var(--select-placeholder)',
  },

  // Selected text for single value
  singleValue: {
    color: 'var(--select-text)',
  },
});

/**
 * Custom sub-components
 */

const Control = ({ children, ...props }) => (
  <components.Control {...props}>{children}</components.Control>
);

const IconWrapper = styled.div`
  display: flex;
  align-self: center;
  width: 15px;
  margin-right: 12px;
`;

// We let option to add and icon from FontAwesome or a custom icon
const OptionIcon = ({ icon }) => (
  <IconWrapper>
    {typeof icon === 'string' ? (
      <Icon size="xs" icon={icon} aria-label={icon} />
    ) : (
      icon
    )}
  </IconWrapper>
);

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const handleLabel = ({ data, children }) => (
  <LabelWrapper>
    {data.icon && <OptionIcon icon={data.icon} />}
    {children}
  </LabelWrapper>
);

/**
 * Listed option
 */
const Option = ({
  children,
  data,
  innerProps,
  isSelected,
  isDisabled,
  ...props
}) => (
  <components.Option
    {...props}
    data={data}
    innerProps={{
      ...innerProps,
      role: 'option',
    }}
    isSelected={isSelected}
    isDisabled={isDisabled}
  >
    {handleLabel({ data, children })}
  </components.Option>
);

/**
 * Selected option
 */
const SingleValue = ({ children, data, ...props }) => (
  <components.SingleValue {...props} data={data}>
    {handleLabel({ data, children })}
  </components.SingleValue>
);

/**
 * Clear indicator with aria label
 */
const ClearIndicator = ({ innerProps, ...props }) => (
  <components.ClearIndicator
    {...props}
    innerProps={{
      ...innerProps,
      'aria-label': 'Clear options',
    }}
  />
);

/**
 * Caret icon for select indicator with aria label
 * The function name is DropdownIndicator because internally
 * react-select names select as dropdown
 */
const DropdownIndicator = ({ innerProps, ...props }) => {
  return (
    <components.DropdownIndicator
      {...props}
      innerProps={{
        ...innerProps,
        'aria-label': 'Open select options',
        'aria-hidden': 'false',
      }}
    >
      <Icon
        icon="caret-down"
        title="carret down"
        label="Open select options icon"
        aria-hidden="false"
      />
    </components.DropdownIndicator>
  );
};

/**
 * Menu with theme variables
 */
const StyledMenu = styled(components.Menu)`
  ${theme};
`;

const Menu = ({ children, selectProps: { menuButton }, ...props }) => (
  <StyledMenu {...props}>
    {children}
    {menuButton}
  </StyledMenu>
);

/**
 * Multi value remove with aria label
 */
const MultiValueRemove = ({ innerProps, ...props }) => (
  <components.MultiValueRemove
    {...props}
    innerProps={{
      ...innerProps,
      'aria-label': 'Clear option',
    }}
  />
);

/**
 * Prop type interfaces
 */
const shapes = {
  OPTION: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

/**
 * Create select component for base component
 *
 * @param {BaseSelect} Component - Base component to style
 * @return {React.Component} Styled react-select component
 *
 */
const createSelect = Component => {
  const Base = ({
    components: replacements,
    inputValue: initialInputValue,
    placeholder,
    isMulti,
    isClearable,
    isDisabled,
    onChange,
    options: initialOptions,
    portal = false,
    value: initialValue,
    styles,
    ...props
  }) => {
    const [options, setOptions] = useState(initialOptions);
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setOptions(initialOptions);
    }, [initialOptions]);
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const handleChange = (selected, ...rest) => {
      onChange?.(selected, ...rest);
    };

    return (
      <Component
        components={{
          ClearIndicator,
          Control,
          DropdownIndicator,
          Menu,
          MultiValueRemove,
          Option,
          SingleValue,
          ...replacements,
        }}
        styles={{
          ...base,
          ...styles,
        }}
        // Render the select dropdown menu in a Portal to ensure rendering is not
        // hindered by things like parent overflow styles. By default the menu
        // will NOT render through the portal due to some open issues regarding
        // positioning with scroll, but useful when rendered within things like
        // modals.
        menuPortalTarget={portal && document.querySelector('#portal-root')}
        // Use fixed positioning when rendering within a context that will not
        // scroll such as the content of a modal via a portal. This ensures that
        // scrolls from the parent elements do not offset the menu accidentally.
        // Lifewise, use absolute positioning when the select context may be
        // scrollable to ensure scroll offsets appropriately reposition the menu
        // as well.
        menuPosition={portal ? 'fixed' : 'absolute'}
        // Managed props
        placeholder={placeholder}
        aria-label={isMulti ? 'multi-select' : 'select'}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={isDisabled}
        onChange={handleChange}
        options={options}
        value={value}
        // Since this component is just a styled wrapper around `react-select`, we
        // want to forward any props we do not need to intercept. This ensures the
        // full functionality of the library is kept intact.
        {...props}
      />
    );
  };

  Base.propTypes = {
    components: PropTypes.objectOf(PropTypes.elementType),
    error: PropTypes.bool,
    styles: PropTypes.objectOf(PropTypes.func),
    // Managed props
    inputValue: PropTypes.string,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(shapes.OPTION),
    // Convenience prop to render menu within the portal root
    portal: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(shapes.OPTION),
      shapes.OPTION,
    ]),
  };

  return styled(Base)`
    ${theme}
  `;
};

/**
 * TODO: Need to create a CacheProvider to react-select render
 * the styles properly into the shadow DOM:
 * https://github.com/JedWatson/react-select/issues/3680#issuecomment-924248517
 */

const Select = createSelect(BaseSelect);

export default Select;
