export const DEFAULT_ASPECT_RATIO = { width: 16, height: 9 };

/**
 *
 * @param {String} embed - The embed code of the video
 * @returns {String} - The embed code of the video with the width and height defined as 100% and other attributes removed
 */
export function parseVideo(embed) {
  const iframeRegex = /<iframe[^>]*src=["'][^"']*["'][^>]*><\/iframe>/;
  const match = embed.match(iframeRegex);

  if (!match) return embed;

  return match[0]
    .replace(/(width|height)=["'][^"']*["']|style=["'][^"']*["']/g, '') // Remove width, height, and style attributes
    .replace(/<iframe\s*/, '<iframe width="100%" height="100%" ');
}

/**
 *
 * @param {String} embed - The embed code of the video
 * @returns {Object} - intrinsic dimensions of the video or the default 16x9 dimensions
 */
export function getVideoIntrinsicSize(iframeString) {
  const paddingBottomMatch = iframeString.match(
    /padding-bottom:\s*(?:calc\()?\s*(\d+(\.\d+)?)%\s*(?:\+[^)]+)?\)?;/
  );

  if (paddingBottomMatch) {
    const height = Math.trunc(Number.parseFloat(paddingBottomMatch[1]));
    const width = 100; // The width is always 100% based on the padding-bottom method
    return { width, height };
  }

  const widthMatch = iframeString.match(/width="(\d+(\.\d+)?)"/);
  const heightMatch = iframeString.match(/height="(\d+(\.\d+)?)"/);

  if (widthMatch && heightMatch) {
    const intrinsicWidth = Number.parseFloat(widthMatch[1]);
    const intrinsicHeight = Number.parseFloat(heightMatch[1]);
    return { width: intrinsicWidth, height: intrinsicHeight };
  }

  return DEFAULT_ASPECT_RATIO;
}
