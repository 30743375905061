/**
 * Block types
 *
 * @constant
 */
export const BLOCK = 'block';
export const BOX = 'box';
export const BUTTON = 'button';
export const EMOJI = 'emoji';
export const HERO = 'hero';
export const IMAGE = 'image';
export const IMAGE_WITH_TEXT = 'imageWithText';
export const LABEL = 'label';
export const ERROR_LABEL = 'errorLabel';
export const LOCALIZED = 'localized';
export const MULTI_SELECT = 'multiSelect';
export const OPTION_SELECT = 'optionSelect';
export const PLACEHOLDER = 'placeholder';
export const RATING = 'rating';
export const RATING_EMOJI = 'ratingEmoji';
export const RATING_NUMBER = 'ratingNumber';
export const RATING_STAR = 'ratingStar';
export const ROW = 'row';
export const SINGLE_SELECT = 'singleSelect';
export const SPACER = 'spacer';
export const STACK = 'stack';
export const TEXT = 'text';
export const TEXT_INPUT = 'textInput';
export const VIDEO = 'embed';
export const CUSTOM_COMPONENT = 'customComponent';

export const BLOCK_TYPES = [
  BLOCK,
  BOX,
  BUTTON,
  EMOJI,
  HERO,
  IMAGE,
  IMAGE_WITH_TEXT,
  LABEL,
  LOCALIZED,
  MULTI_SELECT,
  OPTION_SELECT,
  PLACEHOLDER,
  RATING,
  RATING_EMOJI,
  RATING_NUMBER,
  RATING_STAR,
  ROW,
  SINGLE_SELECT,
  SPACER,
  STACK,
  TEXT,
  TEXT_INPUT,
  VIDEO,
  CUSTOM_COMPONENT,
];

/**
 * Block labels
 *
 * @constant
 */
export const BLOCK_LABELS = {
  [BLOCK]: 'Block',
  [BOX]: 'Box',
  [BUTTON]: 'Button',
  [EMOJI]: 'Emoji',
  [HERO]: 'Hero / Header',
  [IMAGE]: 'Image / GIF',
  [IMAGE_WITH_TEXT]: 'Icon w/ text',
  [LABEL]: 'Label',
  [MULTI_SELECT]: 'Multi-select',
  [OPTION_SELECT]: 'Option-select',
  [PLACEHOLDER]: 'Placeholder',
  [RATING]: 'Rating',
  [ROW]: 'Row',
  [SINGLE_SELECT]: 'Single-select',
  [SPACER]: 'Spacer',
  [STACK]: 'Stack',
  [TEXT]: 'Text',
  [TEXT_INPUT]: 'Text input',
  [VIDEO]: 'Video',
  [CUSTOM_COMPONENT]: 'Custom',
};

/**
 * Block categories
 *
 * @constant
 */
export const CONTENT = 'content';
export const SURVEY = 'survey';
export const BLOCK_CATEGORIES = {
  [CONTENT]: [
    BUTTON,
    IMAGE,
    EMOJI,
    TEXT,
    VIDEO,
    HERO,
    IMAGE_WITH_TEXT,
    CUSTOM_COMPONENT,
  ],
  [SURVEY]: [TEXT_INPUT, SINGLE_SELECT, MULTI_SELECT, RATING, OPTION_SELECT],
};

/**
 * Survey nested block labels
 *
 * @constant
 */
export const SURVEY_BLOCK_LABELS = {
  [TEXT_INPUT]: 'Input field',
  [BLOCK]: 'Survey question',
  [OPTION_SELECT]: 'Text',
};

/**
 * Composed blocks
 *
 * Composed are blocks created using more than one primitive blocks.
 * They do not follow a regular structure, and can be composed of more than one primitive within
 * a layout structure (HERO) or contain specific behavior properties (TEXT_INPUT).
 *
 * @constant
 */
export const COMPOSED_BLOCKS = [
  BUTTON,
  HERO,
  IMAGE_WITH_TEXT,
  ...BLOCK_CATEGORIES[SURVEY],
];

/**
 * Block modes
 *
 * @constant
 */
export const BLOCK_MODES = {
  [OPTION_SELECT]: ['single', 'multi'],
  [RATING_NUMBER]: ['horizontalList', 'verticalList', 'nps'],
};

/**
 * Sticky options
 *
 * @constant
 */
export const STICKY_OPTIONS = ['top', 'bottom'];

/**
 * Custom component property types
 *
 * @constant
 */
export const CUSTOM_COMPONENT_PROPERTY_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
};
