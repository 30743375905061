import { BLOCK, TEXT } from 'lib/block';

const textTemplate = ({ blockId, elementId, content }) => {
  const { style: contentStyle, ...contentRest } = content ?? {};

  return {
    type: BLOCK,
    blockType: TEXT,
    id: blockId,
    content: {
      type: TEXT,
      id: elementId,
      text: 'Take a few moments to learn how to best use our features.',
      ...contentRest,
      style: {
        fontSize: 17,
        textAlignment: 'center',
        horizontalAlignment: 'center',
        foregroundColor: { light: '#000000' },
        marginTop: 8,
        marginBottom: 8,
        marginLeading: 16,
        marginTrailing: 16,
        ...contentStyle,
      },
    },
  };
};

export default textTemplate;
