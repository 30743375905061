import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Link } from '@appcues/sonar';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { FieldSet, Label } from 'ext/components/ui';
import { isValidURl, isValidInAppUrl } from 'ext/lib/url';
import {
  getLink,
  linkType,
  getDelay,
  delayType,
  Shape as ActionsShape,
} from 'lib/actions';
import InputWithValidation from 'components/InputWithValidation';
import InputUrl from './InputUrl';
import { Controls, Text } from './styled';
import HelpLabel from './HelpLabel';

const validateUrl = url => isValidInAppUrl(url) || isValidURl(url);
export default function Navigation({
  stepGroup,
  actions,
  handleActionsUpdate,
}) {
  const linkAction =
    actions?.[stepGroup]?.find(({ type }) => type === linkType) || {};
  const url = linkAction?.config?.url || '';

  const delayAction =
    actions?.[stepGroup]?.find(({ type }) => type === delayType) || {};
  const delayDuration = delayAction?.config?.duration || 0;

  const [currentUrl, setCurrentUrl] = useState('');
  const [currentDelayDuration, setCurrentDelayDuration] = useState(0);
  const [isValidUrl, setIsValidUrl] = useState(true);

  useEffect(() => {
    setCurrentUrl(url);
    setIsValidUrl(validateUrl(url));
  }, [url]);

  useEffect(() => {
    setCurrentDelayDuration(delayDuration);
  }, [delayDuration]);

  const handleUrlChange = ({ target: { value } }) => {
    if (value === currentUrl) return;

    setCurrentUrl(value);

    const isValidUrlLink = validateUrl(value);
    setIsValidUrl(isValidUrlLink);

    if (value === '') {
      handleActionsUpdate(null, linkType);
      return;
    }

    if (isValidUrlLink) {
      const navigateAction = getLink({ url: value, on: 'navigate' });
      handleActionsUpdate(navigateAction);
    }
  };

  const handleDelayChange = ({ target: { valueAsNumber } }) => {
    if (valueAsNumber === currentDelayDuration) return;

    setCurrentDelayDuration(valueAsNumber);

    if (valueAsNumber === 0) {
      handleActionsUpdate(null, delayType);
      return;
    }

    const navigateAction = getDelay({
      duration: valueAsNumber,
      on: 'navigate',
    });
    handleActionsUpdate(navigateAction);
  };

  return (
    <Controls>
      <Text>
        Before showing this step group, Appcues can redirect your users to a
        particular app screen using a deep link.{' '}
        <Link
          href="https://docs.appcues.com/en_US/build-a-mobile-flow-across-screens"
          icon={faExternalLinkAlt}
          inline
        >
          Learn more
        </Link>
      </Text>
      <Label htmlFor="go-to-url-input">Screen deep link URL (optional)</Label>
      <InputUrl
        url={currentUrl}
        onChange={handleUrlChange}
        isValidUrl={isValidUrl}
      />

      <FieldSet>
        <Label htmlFor="delay">
          Presentation delay
          <HelpLabel>
            Time (in milliseconds) to wait after <br />
            navigation is triggered before <br />
            presenting this step group.
            <br />
            Requires SDK versions ≥ 3.2.0
          </HelpLabel>
        </Label>
        <InputWithValidation
          id="delay"
          value={currentDelayDuration}
          onChange={debounce(handleDelayChange, 500)}
          type="number"
          min="0"
          max="2000"
          suffix="ms"
        />
      </FieldSet>
    </Controls>
  );
}

Navigation.propTypes = {
  stepGroup: PropTypes.string,
  handleActionsUpdate: PropTypes.func,
  actions: ActionsShape,
};
