import React from 'react';
import PropTypes from 'prop-types';
import { CustomPicker } from 'react-color';
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { CurrentColor } from 'ext/components/ui';
import { generateColor } from 'ext/lib/color';
import Fields from './Fields';
import QuickColorPicker from './QuickColorPicker';

const ColorPickerTop = styled.div`
  position: relative;
  height: 144px;
  min-width: 280px;
  margin: 0px;
  overflow: hidden;
`;

const ColorPickerBottom = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 16px;
`;

const HueAndAlphaContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-left: 16px;

  > div {
    position: relative;
    height: 12px;
    width: 100%;
    margin: 4px 0;
    display: inline-block;

    div {
      border-radius: 12px;
    }
  }
`;

const theme = `
  --cp-bg: #323F58; 
  --cp-quick-option-selected-border: #D0D7E4;
  --cp-quick-option-border: #FFF;
  --cp-color-type-btn-bg: #FFF;
  --cp-color-type-label-color: #FFF;
  
  [data-theme="light"] & {
    --cp-bg: var(--white);
    --cp-quick-option-selected-border: #425678;
    --cp-quick-option-border: #fff;
    --cp-color-type-btn-bg: #425678;
    --cp-color-type-label-color: #425678;
  }
`;

const ColorPickerWrapper = styled.div`
  ${theme}
  background: var(--cp-bg);
`;

// Needed to update size of color picker pointer (i.e. circle)
// See https://github.com/casesandberg/react-color/blob/master/src/components/common/Saturation.js#L55
const pickerStyles = {
  circle: {
    width: '8px',
    height: '8px',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 1.5px, rgba(0, 0, 0, 0.3) 0px 0px 1px 1px inset, rgba(0, 0, 0, 0.4) 0px 0px 1px 2px',
    borderRadius: '50%',
    cursor: 'pointer',
    transform: 'translate(-2px, -2px)',
  },
};

const Pointer = styled.div`
  ${pickerStyles.circle}

  transform: translate(-2px, 2px);
`;

const ColorPickerContent = styled.div`
  padding: 0 18px 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ColorPicker = ({ color, onChangeComplete, onChange, ...props }) => {
  const handleColorChange = newColor => {
    const { source, hex, h, s, l, a } = newColor;

    // If the previous color was black or white we need to reset the lightness to 0.5
    // Otherwise it will be always dark (black) or light (white)
    const colorLightness = tinycolor(color).toHsl().l;
    const lightness = colorLightness === 1 || colorLightness === 0 ? 0.5 : l;

    let colorSource;
    switch (source) {
      case 'hex':
        colorSource = hex;
        break;
      case 'hsl':
        colorSource = { h, s: s || 1, l: lightness, a };
        break;

      default:
        colorSource = newColor;
        break;
    }
    onChange(tinycolor(colorSource).toRgb());
  };

  return (
    /* eslint-disable @appcues/jsx-props-no-spreading */
    <ColorPickerWrapper>
      <ColorPickerTop>
        <Saturation
          {...props}
          color={color}
          onChange={handleColorChange}
          style={pickerStyles}
        />
      </ColorPickerTop>
      <ColorPickerContent>
        <ColorPickerBottom>
          <CurrentColor color={generateColor(color)} role="none" size={35} />
          <HueAndAlphaContainer>
            <div>
              <Hue
                {...props}
                color={color}
                pointer={Pointer}
                onChange={handleColorChange}
              />
            </div>
            <div>
              <Alpha
                {...props}
                color={color}
                pointer={Pointer}
                onChange={handleColorChange}
              />
            </div>
          </HueAndAlphaContainer>
        </ColorPickerBottom>
        <Fields {...props} color={color} onChange={handleColorChange} />
        <QuickColorPicker onChange={onChangeComplete} selectedColor={color} />
      </ColorPickerContent>
    </ColorPickerWrapper>

    /* eslint-enable @appcues/jsx-props-no-spreading */
  );
};

ColorPicker.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onChangeComplete: PropTypes.func,
};

// eslint-disable-next-line new-cap
export default CustomPicker(ColorPicker);
