import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Link, Switch } from '@appcues/sonar';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { FieldSet } from 'ext/components/ui';
import useToggle from 'ext/lib/hooks/use-toggle';
import {
  getClose,
  requestReviewType,
  getRequestReview,
  Shape as ActionsShape,
} from 'lib/actions';

export default function RequestReviewOption({ actions, config, onChange }) {
  const requestReview = actions?.some(
    action => action.type === requestReviewType
  );

  const [showAlert, toggleShowAlert] = useToggle(true);

  const handleRequestReviewChange = markRequest => {
    onChange({
      actions: [
        getClose({ markComplete: !!config?.markComplete }),
        ...(markRequest ? [getRequestReview()] : []),
      ],
    });
  };

  return (
    <>
      <FieldSet>
        <Switch
          id="prompt"
          checked={requestReview}
          onCheckedChange={handleRequestReviewChange}
          fullWidth
        >
          <Switch.Label htmlFor="prompt">Prompt app store rating</Switch.Label>
        </Switch>
      </FieldSet>
      {requestReview && showAlert && (
        <FieldSet>
          <Alert.Root onDismiss={toggleShowAlert}>
            <Alert.Message>
              <Alert.Description>
                This prompt opens at Apple's or Google's discretion and depends
                on the user's history in the app, previous ratings, and/or
                opt-outs.
                <br />
                See our{' '}
                <Link
                  href="https://docs.appcues.com/en_US/requesting-store-review"
                  icon={faExternalLinkAlt}
                  inline
                >
                  documentation
                </Link>{' '}
                for more information.
              </Alert.Description>
            </Alert.Message>
          </Alert.Root>
        </FieldSet>
      )}
    </>
  );
}

RequestReviewOption.propTypes = {
  actions: PropTypes.arrayOf(ActionsShape),
  config: PropTypes.shape({
    markComplete: PropTypes.bool,
  }),
  onChange: PropTypes.func,
};
