import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HorizontalLeft = (
  <path
    d="M4.5 4a.5.5 0 0 0-.5.5v15a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-15a.5.5 0 0 0-.5-.5h-1ZM8 7.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-2Zm0 7a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-2Z"
    fill="currentColor"
  />
);

const HorizontalCenter = (
  <path
    d="M11.5 4a.5.5 0 0 0-.5.5V7H5.5a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5H11v4H8.5a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5H11v2.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V17h2.5a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5H13v-4h5.5a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5H13V4.5a.5.5 0 0 0-.5-.5h-1Z"
    fill="currentColor"
  />
);

const HorizontalRight = (
  <path
    d="M18.5 4a.5.5 0 0 0-.5.5v15a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-15a.5.5 0 0 0-.5-.5h-1ZM4 7.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-2Zm6 7a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-2Z"
    fill="currentColor"
  />
);

const VerticalTop = (
  <path
    d="M4 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5v-1Zm10 4a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5ZM7.5 8a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-2Z"
    fill="currentColor"
  />
);

const VerticalCenter = (
  <path
    d="M7.5 5a.5.5 0 0 0-.5.5V11H4.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H7v5.5a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5V13h4v2.5a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5V13h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H17V8.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5V11h-4V5.5a.5.5 0 0 0-.5-.5h-2Z"
    fill="currentColor"
  />
);

const VerticalBottom = (
  <path
    d="M7.5 4a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-2Zm6.5 6.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5Zm-10 8a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5v-1Z"
    fill="currentColor"
  />
);

export const icons = {
  'horizontal-left': HorizontalLeft,
  'horizontal-center': HorizontalCenter,
  'horizontal-right': HorizontalRight,
  'vertical-top': VerticalTop,
  'vertical-center': VerticalCenter,
  'vertical-bottom': VerticalBottom,
};

const AlignmentIcons = ({ className, icon }) => (
  <svg
    className={className}
    role="img"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {icons[icon]}
  </svg>
);

AlignmentIcons.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(icons)),
};

export default styled(AlignmentIcons)``;
