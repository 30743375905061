import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { del } from 'object-path-immutable';
import { FieldSet, Label } from 'ext/components/ui';

import {
  update as updateStepGroup,
  EditorShape,
  backgroundContentTemplate,
} from 'entities/step-groups';
import { selectSelected, Shape as SelectedShape } from 'entities/selected';
import { TRAIT_LABELS } from 'lib/trait';
import ColorInput from 'components/ColorInput';
import ImageUploader from 'components/ImageUploader';
import AlignmentPicker from 'components/AlignmentPicker';

const Background = ({
  editor,
  selected,
  onGroupUpdate,
  valueFor,
  handleChangeFor,
}) => {
  const isBackgroundImageSet = editor.backgroundContent !== undefined;
  const { backgroundContent = {}, type } = editor;
  const traitLabel = TRAIT_LABELS[type];
  const { style = {} } = backgroundContent;

  const handleChange = useCallback(
    ({ patch = {}, traitToDelete }) => {
      const updatedEditor = traitToDelete ? del(editor, traitToDelete) : editor;
      const merge = { ...updatedEditor, ...patch };
      onGroupUpdate(selected.stepGroup, { editor: merge });
    },
    [editor, onGroupUpdate, selected]
  );

  const handleBackgroundImage = useCallback(
    params => {
      const {
        url: imageUrl,
        intrinsicSize,
        horizontalAlignment,
        verticalAlignment,
      } = params ?? {};

      if (!params) {
        handleChange({ traitToDelete: 'backgroundContent' });
        return;
      }

      const base = isBackgroundImageSet
        ? backgroundContent
        : backgroundContentTemplate().config.content;

      handleChange({
        patch: {
          backgroundContent: {
            ...base,
            style: {
              backgroundImage: {
                ...base?.style?.backgroundImage,
                ...(imageUrl && { imageUrl }),
                ...(intrinsicSize && { intrinsicSize }),
                ...(horizontalAlignment && { horizontalAlignment }),
                ...(verticalAlignment && { verticalAlignment }),
              },
            },
          },
        },
      });
    },
    [handleChange, backgroundContent, isBackgroundImageSet]
  );

  const handleAlignment = {
    horizontalAlignment: horizontalAlignment =>
      handleBackgroundImage({ horizontalAlignment }),
    verticalAlignment: verticalAlignment =>
      handleBackgroundImage({ verticalAlignment }),
  };

  return (
    <>
      <FieldSet>
        <Label htmlFor="trait-background-color-input">
          {traitLabel} background
        </Label>
        <ColorInput
          id="trait-background-color-input"
          color={valueFor.backgroundColor}
          onChange={handleChangeFor.backgroundColor}
        />
      </FieldSet>

      <FieldSet>
        <ImageUploader
          currentImage={style.backgroundImage?.imageUrl}
          onUpload={handleBackgroundImage}
        />
      </FieldSet>
      {isBackgroundImageSet && (
        <FieldSet>
          <AlignmentPicker
            alignments={[
              style?.backgroundImage?.horizontalAlignment,
              style?.backgroundImage?.verticalAlignment,
            ]}
            onClick={handleAlignment}
          />
        </FieldSet>
      )}
    </>
  );
};

Background.propTypes = {
  editor: EditorShape,
  onGroupUpdate: PropTypes.func,
  selected: SelectedShape,
  valueFor: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
  handleChangeFor: PropTypes.shape({
    backgroundColor: PropTypes.func,
  }),
};

const mapStateToProps = state => {
  const selected = selectSelected(state);

  return {
    selected,
  };
};

const mapDispatchToProps = {
  onGroupUpdate: updateStepGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Background);
