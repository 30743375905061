import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Confetti from 'react-confetti';
import { selectSelected } from 'entities/selected';
import { selectPreviewType, updatePreview } from 'entities/user-interface';
import { CONFETTI } from 'lib/trait';

const confettiCustomStyles = {
  zIndex: 0,
};

export function EffectsLayer({
  effects,
  width,
  height,
  stepGroupId,
  selectPreview,
  onPreviewUpdate,
}) {
  const {
    presentationStyle: presentation,
    duration,
    intensity,
    style,
  } = effects || {};
  const { colors } = style || {};

  const { show } = selectPreview(stepGroupId, presentation);

  const [runAnimation, setRunAnimation] = useState(false);

  useEffect(() => {
    if (show) {
      setRunAnimation(true);

      const interval = setInterval(() => {
        setRunAnimation(false);
      }, duration);

      return () => {
        clearInterval(interval);
      };
    }
    return null;
  }, [show, duration]);

  const onComplete = () => {
    onPreviewUpdate({
      blockId: stepGroupId,
      previewType: CONFETTI,
      show: !show,
    });
  };

  return presentation === CONFETTI && show ? (
    <Confetti
      width={width}
      height={height}
      numberOfPieces={intensity * 500}
      colors={colors}
      recycle={runAnimation}
      style={confettiCustomStyles}
      onConfettiComplete={onComplete}
    />
  ) : null;
}

EffectsLayer.propTypes = {
  effects: PropTypes.shape({
    presentationStyle: PropTypes.string,
    style: PropTypes.shape({
      colors: PropTypes.arrayOf(PropTypes.string),
      particles: PropTypes.number,
      speed: PropTypes.number,
    }),
  }),
  width: PropTypes.string,
  height: PropTypes.string,
  stepGroupId: PropTypes.string,
  selectPreview: PropTypes.func,
  onPreviewUpdate: PropTypes.func,
};

const mapStateToProps = state => {
  const { stepGroup: stepGroupId } = selectSelected(state) ?? {};
  const selectPreview = (blockId, previewType) =>
    selectPreviewType(state, { blockId, previewType });

  return {
    stepGroupId,
    selectPreview,
  };
};

const mapDispatchToProps = {
  onPreviewUpdate: updatePreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(EffectsLayer);
