import { selectAccountId } from 'ext/entities/account';
import { selectUserAccounts } from 'ext/entities/accounts';

export const selectCurrentAccountName = state => {
  const accounts = selectUserAccounts(state);
  const accountId = selectAccountId(state);

  return Object.prototype.hasOwnProperty.call(accounts, accountId)
    ? accounts[accountId].name
    : '';
};
