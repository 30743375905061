export const getContainerMetaData = containerRef => {
  if (!containerRef) {
    return {
      boundaries: { x: { min: 0, max: 0 }, y: { min: 0, max: 0 } },
      center: { x: 0, y: 0 },
    };
  }

  const containerRect = containerRef.getBoundingClientRect();
  const boundaries = {
    x: {
      min: containerRect.x,
      max: containerRect.x + containerRect.width,
    },
    y: {
      min: containerRect.y,
      max: containerRect.y + containerRect.height,
    },
  };

  const center = {
    x: containerRect.left + containerRect.width / 2,
    y: containerRect.top + containerRect.height / 2,
  };

  return {
    boundaries,
    center,
  };
};

export const isValidRange = ({ min, max }, currentValue) => {
  return currentValue >= min && currentValue <= max;
};

// atan2 returns the angle in the plane (in radians)
// but then we need to convert to degree in order to set
// the element rotation, so we use * (180 / Math.PI)
// to convert radians => degrees
export const getAngle = (clientX, clientY, center) => {
  return (
    Math.atan2(clientX - center.x, -(clientY - center.y)) * (180 / Math.PI)
  );
};

// @TODO reuse when merging code from border branch
export const isValidNumber = value => /^-?[\d.]+(?:e-?\d+)?$/.test(value);

export const formatInputValue = value => {
  if (value <= 90) return `${Math.ceil(value - 90) * -1}`;
  return `${Math.ceil(450 - value)}`;
};
