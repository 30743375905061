import React, { Fragment, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP, LAYOUT_TRAITS } from 'lib/trait';
import { VIDEO, SURVEY, BLOCK_CATEGORIES } from 'lib/block';
import ContentMenuButton from './ContentMenuButton';
import CONTENT_MENU_OPTIONS from './ContentMenuOptions';
import { ContentMenuWrapper, ContentMenuGrid, Category } from './styled';

const filteredBlockCategories = layoutTrait =>
  Object.keys(BLOCK_CATEGORIES).filter(
    category => !(layoutTrait === TOOLTIP && category === SURVEY)
  );

const handleElementCategories = (layoutTrait, category, onClick) => {
  const restrictedTooltipElements = new Set([VIDEO]);
  const filteredElements = Object.entries(
    CONTENT_MENU_OPTIONS[category]
  ).filter(
    ([element]) =>
      !(layoutTrait === TOOLTIP && restrictedTooltipElements.has(element))
  );

  return (
    <Fragment key={category}>
      <Category>{category}</Category>
      <ContentMenuGrid
        templateColumns={filteredElements.length % 3 === 0 ? 3 : 4}
      >
        {filteredElements.map(([element, { text }]) => (
          <ContentMenuButton
            key={element}
            element={element}
            text={text}
            icon={CONTENT_MENU_OPTIONS[category][element].icon}
            onClick={onClick}
          />
        ))}
      </ContentMenuGrid>
    </Fragment>
  );
};

const ContentMenu = forwardRef(({ layoutTrait, onClick }, ref) => (
  <ContentMenuWrapper ref={ref}>
    {filteredBlockCategories(layoutTrait).map(category =>
      handleElementCategories(layoutTrait, category, onClick)
    )}
  </ContentMenuWrapper>
));

ContentMenu.propTypes = {
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  onClick: PropTypes.func,
};

export default ContentMenu;
