/*
 * Check that the provided step group and step child are valid paths based on
 * the resolved flow
 *
 * @param {FlowSchema} transformed - Transformed flow schema
 * @param {string} stepGroup - Step group ID
 * @param {string} stepChild - Step child ID
 * @return {boolean} Whether the provided group and child are valid
 */
function validate(transformed, stepGroup, stepChild) {
  const { stepGroups, stepChildren } = transformed;

  if (!stepGroups[stepGroup]) {
    return false;
  }

  if (!stepChild) {
    return true;
  }

  if (!stepChildren[stepChild]) {
    return false;
  }

  return true;
}

/**
 * NOTE: The selected state only tracks `stepGroup` and `stepChild`! If we have
 * a flow selected, we can get the id from the flow record, which is a single
 * entity.
 *
 * `stepGroups` and `stepChildren` are collections, so we need to track pointers
 *
 * A `null` state means nothing is selected
 */

export default function onLoad(state, action) {
  const { experience, stepGroups } = action.payload;

  // If the experience is brand new, it will not have steps
  // and so cannot have selections
  if (
    typeof experience.steps === 'undefined' ||
    experience.steps.length === 0
  ) {
    return null;
  }

  // Else if there is a pre-existing selection
  if (state) {
    const { stepGroup, stepChild } = state;

    // Validate that the selection is still valid with the resolved experience
    if (validate(action.payload, stepGroup, stepChild)) {
      return { stepGroup, stepChild };
    }

    // Or check if only the step-child is selected in scenarios like "Open in
    // builder" where only the experience and child IDs are provided and derive the
    // parent step group
    //
    // FIXME: The above should be fixed so that "Open in builder" and the CRX
    // includes the step group as well
    if (stepChild && !stepGroup) {
      const parent = Object.keys(stepGroups).find(id => {
        const { children = [] } = stepGroups[id];
        return children.includes(state.stepChild);
      });

      // If the step group to the step child is found, update the selection with
      // the parent group
      if (parent) {
        return { stepGroup: parent, stepChild };
      }
    }
  }

  // Otherwise select the first group and child if no pre-existing selection
  // exists or the pre-existing selection was incorrect. Note that if the first
  // step group is an action that it will not have steps so guard against that
  // by falling back to an empty list if steps do not exist on the group.
  const [stepGroup] = experience.steps;
  const [stepChild] = stepGroups[stepGroup].children || [];

  return { stepGroup, stepChild };
}
