import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { selectExperience } from 'entities/experiences';
import { selectSelected } from 'entities/selected';
import {
  selectStepChild,
  update as updateStepChild,
  ContentShape,
} from 'entities/step-children';
import {
  InsertComponentPositionShape,
  selectInsertComponentPosition,
  updateInsertComponentPosition,
} from 'entities/user-interface';
import {
  selectCustomComponentsForApp,
  create as createCustomComponent,
  update as updateCustomComponent,
  remove as removeCustomComponent,
  Shape as CustomComponentShape,
} from 'entities/custom-components';
import { CUSTOM_COMPONENT } from 'lib/block';
import { getLocaleVariations } from 'lib/localization';
import { addElement } from 'components/BlockDivider/add-element';
import CustomComponentList from './CustomComponentList';

export function CustomComponentsSettings({
  customComponents,
  stepId,
  stepContent,
  insertComponentPosition,
  onUpdateStepChild,
  onUpdateInsertComponentPosition,
  onCustomComponentCreate,
  onCustomComponentUpdate,
  onCustomComponentRemove,
}) {
  const handleClick = async ({ id, componentDefinition }) => {
    const { rowId, position } = insertComponentPosition;
    const isSideBySide = position === 'right';

    const updated = addElement({
      content: stepContent,
      template: CUSTOM_COMPONENT,
      customComponentModel: { id, ...componentDefinition },
      position,
      newBlockId: uuid(),
      newElementId: uuid(),
      rowId,
      newRow: !isSideBySide,
      locales: getLocaleVariations(stepContent),
    });

    await onUpdateStepChild(stepId, updated);
    onUpdateInsertComponentPosition({});
  };

  const handleCreateComponent = componentContent => {
    onCustomComponentCreate({
      name: componentContent.identifier,
      content: componentContent,
    });
  };

  const handleUpdateComponent = (id, componentContent) => {
    onCustomComponentUpdate({
      id,
      name: componentContent.identifier,
      content: componentContent,
    });
  };

  const handleRemoveComponent = id => {
    onCustomComponentRemove(id);
  };

  return (
    <CustomComponentList
      customComponents={customComponents}
      handleClick={handleClick}
      handleCreateComponent={handleCreateComponent}
      handleUpdateComponent={handleUpdateComponent}
      handleRemoveComponent={handleRemoveComponent}
      handleCancel={() => onUpdateInsertComponentPosition({})}
    />
  );
}

CustomComponentsSettings.propTypes = {
  customComponents: PropTypes.arrayOf(CustomComponentShape),
  stepId: PropTypes.string,
  stepContent: ContentShape,
  insertComponentPosition: InsertComponentPositionShape,
  onUpdateStepChild: PropTypes.func,
  onUpdateInsertComponentPosition: PropTypes.func,
  onCustomComponentCreate: PropTypes.func,
  onCustomComponentUpdate: PropTypes.func,
  onCustomComponentRemove: PropTypes.func,
};

const mapStateToProps = state => {
  const { appId } = selectExperience(state);
  const customComponents = Object.values(
    selectCustomComponentsForApp(state, appId)
  );

  const { stepChild: stepId } = selectSelected(state) ?? {};
  const { content: stepContent } = selectStepChild(state, stepId) ?? {};
  const insertComponentPosition = selectInsertComponentPosition(state);

  return { customComponents, stepId, stepContent, insertComponentPosition };
};

const mapDispatchToProps = {
  onUpdateStepChild: updateStepChild,
  onUpdateInsertComponentPosition: updateInsertComponentPosition,
  onCustomComponentCreate: createCustomComponent,
  onCustomComponentUpdate: updateCustomComponent,
  onCustomComponentRemove: removeCustomComponent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomComponentsSettings);
