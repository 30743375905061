import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon, Popover } from '@appcues/sonar';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons/faCircleQuestion';
import useToggle from 'ext/lib/hooks/use-toggle';

const AI_LINK =
  'https://docs.appcues.com/en_US/mobile-building-experiences/localizing-mobile-experiences';

const PopoverContainer = styled.div``;

const QuestionIconButton = styled(Button).attrs({
  'aria-label': 'About Appcues AI',
  iconOnly: 'true',
  variant: 'tertiary',
  as: 'div',
  children: <Icon icon={faCircleQuestion} />,
})`
  && {
    svg {
      color: var(--blurple);
    }
  }
`;

const handleClick = link => window.open(link, '_blank');

const AiPopover = ({ link = AI_LINK }) => {
  const [isOpen, setIsOpen] = useToggle(false);

  return (
    <PopoverContainer
      onClick={event => {
        event.stopPropagation();
        setIsOpen(!isOpen);
      }}
    >
      <Popover.Root trigger={<QuestionIconButton />} onOpenChange={setIsOpen}>
        <Popover.Close>
          <Icon icon={faXmark} />
        </Popover.Close>
        <Popover.Description>
          To learn more about our AI <br />
          Assistant feature, please visit <br />
          our documentation.
        </Popover.Description>
        <Popover.Action>
          <Button
            onClick={() => handleClick(link)}
            variant="tertiary"
            type="button"
          >
            Learn more
            <Icon icon={faExternalLinkAlt} />
          </Button>
        </Popover.Action>
      </Popover.Root>
    </PopoverContainer>
  );
};

AiPopover.propTypes = {
  link: PropTypes.string,
};

export default AiPopover;
