import styled from 'styled-components';

export const Target = styled.div``;

export const Reset = styled.div`
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1;
`;

export const Attachment = styled.div`
  position: absolute;
`;
