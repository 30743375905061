import React from 'react';
import styled from 'styled-components';
import { Text } from '@appcues/sonar';

const EmptyLocalesImage = () => (
  <svg width="100" height="112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M90 56a40 40 0 1 0-76.48 16.5h72.87A39.85 39.85 0 0 0 90 56Z"
      fill="#F0F2FF"
    />
    <path
      d="M10.45 72.5h78M5.95 72.5h2.5M90.45 72.5h2.5"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.33 82.84c9.527 0 17.25-.775 17.25-1.73 0-.956-7.723-1.73-17.25-1.73-9.527 0-17.25.774-17.25 1.73 0 .955 7.723 1.73 17.25 1.73Z"
      fill="#F0F2FF"
    />
    <path
      d="M85 72.49a1.15 1.15 0 0 0-1.15-1.15h-15v3.45H85v-2.3Z"
      fill="#D6DAFF"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.33 71.34v3.45M81.53 71.34v3.45"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.1 67.89H68.3A1.15 1.15 0 0 0 67.15 69v1.15a1.16 1.16 0 0 0 1.15 1.15h15V69a1.14 1.14 0 0 0-1.2-1.11Z"
      fill="#D6DAFF"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.6 67.89v3.45M79.8 67.89v3.45M89.58 67.89l-9.2-1.15v1.15h-4.6v-1.15l-9.2 1.15"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M87.45 65.3c-1.53-1-7-4.24-9.37.86-2.06-4.63-7.88-1.68-9.41-.8a.59.59 0 0 0-.27.64l.41 1.61 7-.87v1.15h4.6v-1.15l6.49.81.73-1.55a.56.56 0 0 0-.18-.7Z"
      fill="#fff"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.18 69A1.15 1.15 0 0 0 24 67.89h-2.3A1.14 1.14 0 0 0 20.58 69v.57L19 73.17a1.159 1.159 0 0 0 1.06 1.62h5.66a1.15 1.15 0 0 0 1.05-1.62l-1.58-3.56-.01-.61Z"
      fill="#D6DAFF"
    />
    <path
      d="M20.88 64.37c-4.9 3.18-7.16-5.34-9.8-9.49a.579.579 0 0 1 .63-.87c7.14 1.75 12.79 7.99 9.17 10.36Z"
      fill="#fff"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.62 58.07a45.074 45.074 0 0 1 7.26 9.82M19.14 62.14H17.7"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.18 69A1.15 1.15 0 0 0 24 67.89h-2.3A1.14 1.14 0 0 0 20.58 69v.57L19 73.17a1.159 1.159 0 0 0 1.06 1.62h5.66a1.15 1.15 0 0 0 1.05-1.62l-1.58-3.56-.01-.61Z"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.99 66.74c9.146 0 16.56-7.414 16.56-16.56 0-9.146-7.414-16.56-16.56-16.56-9.146 0-16.56 7.414-16.56 16.56 0 9.146 7.414 16.56 16.56 16.56Z"
      fill="#fff"
    />
    <path
      d="M52.24 37.87a2.76 2.76 0 0 0 1.21 3.71A1.38 1.38 0 1 1 52.2 44l-6.52-3.31a5 5 0 0 0-6.62 1.77 4.83 4.83 0 0 0 2 6.72l1.85.94a2.76 2.76 0 0 0 1.21 3.71L43 56.12a4.31 4.31 0 0 0 1.52 5.67 4.141 4.141 0 0 0 5.76-1.71l2.5-4.92A2.77 2.77 0 0 0 56.46 54L54 52.7l.62-1.23a2.761 2.761 0 0 1 3.71-1.22l4.07 2.07a16.55 16.55 0 0 0-7.21-15.9 2.75 2.75 0 0 0-2.95 1.45Z"
      fill="#D6DAFF"
    />
    <path
      d="M52.78 72.26h-6.9v5.52h6.9v-5.52Z"
      fill="#D6DAFF"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m63.93 32.24-6.21 6.21M34.26 61.91l-6.21 6.21"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60 80.54a.59.59 0 0 0 .31-1.08 20.66 20.66 0 0 0-21.64 0 .58.58 0 0 0 .3 1.08H60Z"
      fill="#D6DAFF"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.4 66.77a23.461 23.461 0 1 0 33.18-33.18L60.14 36a20.011 20.011 0 0 1-28.3 28.3l-2.44 2.47Z"
      fill="#D6DAFF"
    />
    <path
      d="M29.4 66.77a23.461 23.461 0 1 0 33.18-33.18L60.14 36a20.011 20.011 0 0 1-28.3 28.3l-2.44 2.47Z"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.99 66.74c9.146 0 16.56-7.414 16.56-16.56 0-9.146-7.414-16.56-16.56-16.56-9.146 0-16.56 7.414-16.56 16.56 0 9.146 7.414 16.56 16.56 16.56Z"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.24 37.87a2.76 2.76 0 0 0 1.21 3.71A1.38 1.38 0 1 1 52.2 44l-6.52-3.31a5 5 0 0 0-6.62 1.77 4.83 4.83 0 0 0 2 6.72l1.85.94a2.76 2.76 0 0 0 1.21 3.71L43 56.12a4.31 4.31 0 0 0 1.52 5.67 4.141 4.141 0 0 0 5.76-1.71l2.5-4.92A2.77 2.77 0 0 0 56.46 54L54 52.7l.62-1.23a2.761 2.761 0 0 1 3.71-1.22l4.07 2.07a16.55 16.55 0 0 0-7.21-15.9 2.75 2.75 0 0 0-2.95 1.45v0Z"
      stroke="#4550E5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const EmptyLocalesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 24px auto 0px;
  text-align: center;
`;

const EmptyLocales = () => (
  <EmptyLocalesWrapper>
    <EmptyLocalesImage />
    <Text size="medium" weight="bold">
      No additional languages
    </Text>
    <Text>
      If you plan to translate your content for multiple languages, add the ones
      you wish to support here.
    </Text>
  </EmptyLocalesWrapper>
);

export default EmptyLocales;
