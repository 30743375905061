import { get } from 'object-path-immutable';
import collect from 'lib/path-builder';
import { selectSelected } from 'entities/selected';
import { selectExperience } from 'entities/experiences';
import { selectStepChild } from 'entities/step-children';
import { selectStepGroup } from 'entities/step-groups';
import { selectActiveLocale } from 'entities/locales';
import { BLOCK_TYPES } from './block';

/**
 * Return selected block content
 *
 * @param {State} state - redux state
 * @returns {ContentShape} Selected block content
 */
export function selectBlockContent(state) {
  const { stepChild, block } = selectSelected(state) || {};
  const step = selectStepChild(state, stepChild) || {};
  const { id: localeId } = selectActiveLocale(state) ?? {};

  // If there's no block selected, just return null
  if (!block) return null;

  const paths = collect(step);

  // Localized blocks that are inside composed and has the path-builder tracked
  const localizedPath = paths[`${block}/${localeId}`];
  const composedBlock = localizedPath && get(step, localizedPath);

  if (composedBlock) {
    return BLOCK_TYPES.includes(composedBlock.type) ? composedBlock : null;
  }

  const blockContent = get(step, paths[block]);
  if (!blockContent) return null;

  // We can also have primitive blocks with variations inside
  const content = blockContent.content?.variations?.[localeId] || blockContent;

  return BLOCK_TYPES.includes(content?.type) ? content : null;
}

/**
 * Derive whether the selected step is experience first step
 * @param {State} state - redux state
 * @returns {boolean} True if selected step is the first of the experience
 */
export function selectIsExperienceFirstStep(state) {
  const { stepGroup, stepChild } = selectSelected(state) ?? {};
  const [firstStepGroupId] = selectExperience(state)?.steps ?? [];
  const { children } = selectStepGroup(state, firstStepGroupId) ?? {};

  return stepGroup === firstStepGroupId && children?.indexOf(stepChild) === 0;
}

/**
 * Return the first child of the specified step group
 * @param {State} state - redux state
 * @param {string} stepGroupId - Step group id
 * @param {string} stepChildId - Step child id
 * @returns {Step} First child of the step group or empty object if it's not
 */
export function selectStepGroupFirstChild(state, stepGroupId, stepChildId) {
  const { children } = selectStepGroup(state, stepGroupId) ?? {};

  if (!children || (stepChildId && children?.[0] !== stepChildId)) {
    return {};
  }

  const firstStepChild = stepChildId ?? children?.[0];
  return selectStepChild(state, firstStepChild) ?? {};
}
