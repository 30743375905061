import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Alert } from '@appcues/sonar';
import { FieldSet, Label, DebouncedInput } from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import {
  GroupedField,
  GroupedFieldSet,
  Controls,
} from 'components/SideBarSettings/Shared';
import InputWithValidation from 'components/InputWithValidation';
import { Error } from 'components/Form';
import {
  DEFAULT_ASPECT_RATIO,
  getVideoIntrinsicSize,
  parseVideo,
} from './parse-video';

function Video({ content, onChange }) {
  const { id, embed, intrinsicSize } = content ?? {};
  const [error, setError] = useState(false);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setError(false);

      const embedVideo = parseVideo(value);

      if (!embedVideo) {
        setError(true);
        return;
      }

      const videoIntrinsicSize = getVideoIntrinsicSize(value);

      onChange({
        blockId: id,
        contentChunk: {
          embed: embedVideo,
          intrinsicSize:
            videoIntrinsicSize === DEFAULT_ASPECT_RATIO
              ? intrinsicSize
              : videoIntrinsicSize,
        },
      });
    },
    [id, intrinsicSize, onChange]
  );

  const handleAspectRatioChange = debounce(
    (key, { target: { valueAsNumber } }) => {
      onChange({
        blockId: id,
        contentChunk: {
          intrinsicSize: {
            ...intrinsicSize,
            [key]: valueAsNumber,
          },
        },
      });
    },
    500
  );

  return (
    <Controls>
      <FieldSet>
        <Label htmlFor="embed-code">Embed code</Label>
        <DebouncedInput
          id="embed-code"
          defaultValue={embed}
          onChange={handleChange}
          placeholder="Paste your embed code here"
          type="text"
          inputType="textarea"
          style={{ height: '160px' }}
        />
        {error && <Error>Please enter a valid embed code</Error>}
      </FieldSet>

      <FieldSet>
        <Label htmlFor="aspect-ratio-width">Aspect Ratio</Label>
        <GroupedFieldSet>
          <GroupedField half>
            <InputWithValidation
              id="aspect-ratio-width"
              value={intrinsicSize?.width}
              onChange={event => handleAspectRatioChange('width', event)}
              type="number"
              placeholder="Auto"
              suffix="X"
              min="1"
            />
          </GroupedField>

          <GroupedField half>
            <InputWithValidation
              id="aspect-ratio-height"
              value={intrinsicSize?.height}
              onChange={event => handleAspectRatioChange('height', event)}
              type="number"
              placeholder="Auto"
              suffix="Y"
              min="1"
            />
          </GroupedField>
        </GroupedFieldSet>
      </FieldSet>

      <FieldSet>
        <Alert.Root variant="info">
          <Alert.Message>
            <Alert.Description>
              Extra CSS will be automatically removed. Use the aspect ratio
              fields to adjust the width and height if needed.
            </Alert.Description>
          </Alert.Message>
        </Alert.Root>
      </FieldSet>
    </Controls>
  );
}

Video.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
};

export default Video;
