import { ITEM_INSERTED, ITEM_REMOVED } from './action-types';

const shouldActFor =
  actionType =>
  ({ type, meta }, childType) =>
    !!(meta && meta.type === childType && meta.parent && type === actionType);

const shouldInsert = shouldActFor(ITEM_INSERTED);
const shouldRemove = shouldActFor(ITEM_REMOVED);

const getChild = ({ payload, meta }) => {
  // simple case
  if (payload.id) {
    return payload;
  }

  // resolve-siblings case
  const [item] = Object.values(payload[meta.type]);
  return item;
};

const insert = (basket, { id, index }) => [
  ...basket.slice(0, index),
  id,
  ...basket.slice(index),
];

const remove = (basket, { id }) => basket.filter(item => item !== id);

export default function hasMany(field, childType) {
  return (state, action) => {
    const { meta } = action;

    if (shouldInsert(action, childType)) {
      const parent = state[meta.parent];
      const child = getChild(action);
      if (parent) {
        return {
          ...state,
          [parent.id]: {
            ...parent,
            [field]: insert(parent[field], child),
          },
        };
      }

      return {
        ...state,
        [field]: insert(state[field], child),
      };
    }

    if (shouldRemove(action, childType)) {
      const parent = state[meta.parent];
      const child = action.payload.id;
      if (parent) {
        return {
          ...state,
          [parent.id]: {
            ...parent,
            [field]: remove(parent[field], { id: child }),
          },
        };
      }

      return {
        ...state,
        [field]: remove(state[field], { id: child }),
      };
    }

    return state;
  };
}
