import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { Button, Icon, Text } from '@appcues/sonar';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import useEscape from 'ext/lib/hooks/use-escape';
import {
  Modal,
  WarningIcon,
  ErrorContainer,
  ScrollableErrorContainer,
} from './styled';

export const ErrorModal = ({ title, description, error, setError }) => {
  const open = error;
  const $modal = useRef();
  const cleanError = () => setError(null);

  useClickOutside([$modal], open && cleanError);
  useEscape(open && cleanError);

  return (
    <Modal.Root open={open} size="small">
      <Modal.Container ref={$modal}>
        <Modal.Title>
          <WarningIcon size="large" /> {title}
        </Modal.Title>
        <Modal.Close aria-label="Close x" onClick={cleanError}>
          <Icon icon={faXmark} size="large" />
        </Modal.Close>
        <ErrorContainer>
          <Text size="medium">{description}</Text>
          <ScrollableErrorContainer>
            <Text>{error?.message}</Text>
          </ScrollableErrorContainer>
        </ErrorContainer>
        <Modal.Actions>
          <Button onClick={cleanError}>Close</Button>
        </Modal.Actions>
      </Modal.Container>
    </Modal.Root>
  );
};

ErrorModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  setError: PropTypes.func,
};

export default ErrorModal;
