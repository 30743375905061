import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import startCase from 'lodash.startcase';
import { completeInteraction, FETCH_USER_PROPERTIES } from 'ext/lib/track';
import { INITIALIZE } from 'ext/root/root-actions';
import { resolve, reject } from './actions';

/*
 * Format user properties based on existing logic from `crx-ui`
 *
 * @param {object<string, object>} response - User properties response
 * @return {UserProperty[]} Parsed user properties
 */
const parse = (response, disabledUserProperties) =>
  Object.entries(response)
    .filter(([property]) => !disabledUserProperties.includes(property))
    .map(([property, values]) => {
      return {
        name: startCase(property),
        value: property,
        options: Object.keys(values).map(value => ({ name: value })),
      };
    });

export function* fetchUserProperties() {
  try {
    const { getUserProperties, getUserPropertiesLabels } = yield getContext(
      'api'
    );

    let disabledUserProperties = [];

    if (getUserPropertiesLabels) {
      const userPropertiesLabels = yield call(getUserPropertiesLabels);
      disabledUserProperties = userPropertiesLabels
        .filter(({ showInUI }) => !showInUI)
        .map(({ name }) => name);
    }

    const response = yield call(getUserProperties);
    yield put(resolve(parse(response, disabledUserProperties)));
    yield put(completeInteraction(FETCH_USER_PROPERTIES));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* userPropertiesSaga() {
  yield takeEvery(INITIALIZE, fetchUserProperties);
}
