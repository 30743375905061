import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalHeader,
  P,
} from 'ext/components/ui';

export function DeleteModal({
  onClose,
  onDelete,
  visible,
  entityName,
  theme = 'dark',
}) {
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal onClose={onClose} visible={visible} theme={theme}>
      <ModalHeader>
        <Heading>Confirm deletion</Heading>
        <ModalHeader.Close onClose={onClose} />
      </ModalHeader>
      <P>
        Are you sure you want to delete this{entityName && ` ${entityName}`}?
      </P>
      <ButtonGroup right>
        <Button kind="tertiary" onClick={onClose} type="button">
          Cancel
        </Button>
        <Button kind="danger" onClick={handleDelete} type="button">
          Delete
        </Button>
      </ButtonGroup>
    </Modal>
  );
}

DeleteModal.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  entityName: PropTypes.string,
  visible: PropTypes.bool,
};

export default DeleteModal;
