import { TARGET_RECTANGLE } from 'lib/trait';

export const targetRectangleHighlightTemplate = {
  width: 50,
  height: 50,
  x: -25,
  y: -25,
};

export const emptyTargetRectangleHighlightTemplate = {
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

export const targetRectangleTemplate = {
  type: TARGET_RECTANGLE,
  config: {
    contentPreferredPosition: 'top',
    contentDistanceFromTarget: 20,
    relativeX: 0.5,
    relativeY: 0.5,
    ...targetRectangleHighlightTemplate,
  },
};
