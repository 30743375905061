import styled from 'styled-components';
import { ButtonGroup } from '@appcues/sonar';
import { Modal as PureModal } from 'ext/components/ui';

export const Modal = styled(PureModal)`
  && {
    width: 460px;
  }

  ${ButtonGroup} {
    justify-content: flex-end;
    margin-top: 24px;
  }
`;
