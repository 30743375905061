import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonGroup, Button, Text } from '@appcues/sonar';
import { Heading, Modal, ModalHeader } from 'ext/components/ui';
import { change } from 'entities/auth';
import { Account, AccountName, Body } from './styled';

/**
 * Alphabetize accounts with ID fallbacks
 *
 * Borrowed from studio's Accounts page
 *
 * @param {object<Account>} accounts - Accounts for user
 * @return {Account[]} Sorted accounts list
 */
const alphabetize = (accounts = {}) =>
  Object.values(accounts).sort((first, second) => {
    switch (true) {
      // Compare names if both exist
      case Boolean(first.name && second.name):
        return first.name.localeCompare(second.name);

      // Else prefer the account with a name
      case Boolean(first.name && !second.name):
        return -1;
      case Boolean(!first.name && second.name):
        return 1;

      // Otherwise compare account IDs
      default:
        return first.id.localeCompare(second.id);
    }
  });

export function AccountsModal({
  accounts,
  onChange,
  onClose,
  selected: initial,
  visible,
}) {
  // Determines if an account switch has been requested
  const [isPending, setIsPending] = useState(false);

  // Immediately set newly selected account for instant UI feedback
  const [selected, setSelected] = useState(initial);

  // Sort accounts by name and ID
  const sorted = useMemo(() => {
    return alphabetize(accounts);
  }, [accounts]);

  // Create click handler for given account ID. Also only handle click if
  // different account has been selected and if there is no pending requests
  const handleClickFor = useCallback(
    id => async () => {
      if (!isPending) {
        setIsPending(true);
        setSelected(id);
        onChange(id);
      }
    },
    [onChange, isPending]
  );

  return (
    <Modal onClose={onClose} visible={visible} theme="light">
      <ModalHeader>
        <Heading>Your Accounts</Heading>
        <ModalHeader.Close onClose={onClose} />
      </ModalHeader>

      <Body>
        {sorted.map(({ id, name }) => {
          const isActive = id === selected;
          const isDisabled = isPending || isActive;

          return (
            <Account
              key={id}
              disabled={isDisabled}
              onClick={!isDisabled ? handleClickFor(id) : null}
              $selected={isActive}
            >
              <AccountName>{name || `Account ${id}`}</AccountName>
              {isActive && <Text>Currently in use</Text>}
            </Account>
          );
        })}

        <ButtonGroup>
          <Button variant="tertiary" onClick={onClose} type="button">
            Cancel
          </Button>
        </ButtonGroup>
      </Body>
    </Modal>
  );
}

AccountsModal.propTypes = {
  accounts: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  selected: PropTypes.string,
  visible: PropTypes.bool,
};

const mapDispatchToProps = {
  onChange: change,
};

export default connect(null, mapDispatchToProps)(AccountsModal);
