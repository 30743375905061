import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import history from 'history/browser';
import { initialize } from 'ext/root/root-actions';
import getReduxConfig from 'ext/lib/redux-config';
import { create as createLoggerClient } from 'ext/lib/loggers';
import { create as createApiClient } from 'lib/api-client';
import { parseSelected } from 'lib/location';
import { getUserPreferences } from 'lib/user-preferences';

export default function configureStore({
  auth = {},
  user = {},
  rootReducer,
  rootSaga,
}) {
  // Create configurations for Redux via Reactotron
  const { composeEnhancers, sagaMonitor, logStateChange } = getReduxConfig();

  // Create saga middleware with context
  const sagaMiddleware = createSagaMiddleware({
    sagaMonitor,
    context: {
      history,
      api: createApiClient(auth),
      logger: createLoggerClient({
        account: {
          accountId: auth.accountId,
          userId: auth.userId,
        },
      }),
    },
  });

  // Generate initial state based on URL
  const { pathname, hash } = history.location;

  // Create initial data before creating store
  const state = {
    account: {
      ...auth,
      accountId: auth.accountId,
      userId: auth.userId,
      email: user.email,
      isSpoofing: user.isSpoofing,
    },
    selected: parseSelected({ pathname, hash }),
    userPreferences: getUserPreferences(),
  };

  // Create enhancers for Redux dispatch
  const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware));

  // Create middleware enhanced store
  const store = createStore(rootReducer, state, enhancers);

  // Run the root saga
  sagaMiddleware.run(rootSaga);

  // Dispatch initialize action to kick off any start up tasks
  store.dispatch(initialize(auth));

  // Log state change to Reactotron
  logStateChange(store, 'Mobile builder');

  // Return configured Redux store
  return store;
}
