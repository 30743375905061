import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Accordion, FieldSet, FontIcon, Label } from 'ext/components/ui';
import { StyleShape } from 'entities/step-children';
import { selectUserPreferences } from 'entities/user-preferences';
import { THEMES } from 'lib/user-preferences';
import ColorInput from 'components/ColorInput';
import InputWithValidation from 'components/InputWithValidation';
import {
  Controls,
  GroupedField,
  GroupedFieldSet,
  useStyleSettings,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';

function BoxStyle({ style, onChange, theme }) {
  const trackAccordion = useAccordionClick();

  const handleChange = useCallback(
    patch => onChange({ style: patch }),
    [onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
    theme,
  });

  return (
    <Accordion.Item value="style">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion('TextField', 'Box style')}
        >
          Box style
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Label htmlFor="text-field-background">Background color</Label>
            <ColorInput
              id="text-field-background"
              color={valueFor.backgroundColor}
              onChange={handleChangeFor.backgroundColor}
            />
          </FieldSet>

          <GroupedFieldSet>
            <GroupedField>
              <Label htmlFor="text-field-border-color">Border color</Label>
              <ColorInput
                id="text-field-border-color"
                color={valueFor.borderColor}
                onChange={handleChangeFor.borderColor}
              />
            </GroupedField>

            <GroupedField>
              <Label htmlFor="text-field-thickness">Thickness</Label>
              <InputWithValidation
                id="text-field-thickness"
                key={valueFor.borderWidth}
                defaultValue={valueFor.borderWidth}
                onChange={handleChangeFor.borderWidth}
                type="number"
                placeholder="0"
                min="0"
              />
            </GroupedField>
          </GroupedFieldSet>

          <FieldSet>
            <InputWithValidation
              label="Corner radius"
              value={valueFor.cornerRadius}
              onChange={handleChangeFor.cornerRadius}
              max={100}
              min={0}
              range
            />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

BoxStyle.propTypes = {
  style: StyleShape,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
};

const mapStateToProps = state => ({
  theme: selectUserPreferences(state).theme,
});

export default connect(mapStateToProps)(BoxStyle);
